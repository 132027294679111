import React from "react";
import { makeStyles, Theme, createStyles, Dialog, Fab, Box } from "@material-ui/core";
import { CloseIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    dialog_paper: {
        overflow: "visible",
        padding: 0,
        margin: 0,
    },
    closeBtn: {
        position: "absolute",
        top: theme.spacing(-2),
        right: theme.spacing(-2),
    },
    videoContainer: {
        width: "100%",
        height: 0,
        paddingBottom: "56.25%", // format 16/9
        overflow: "hidden",
        position: "relative",
        "& > iframe, & > video": {
            width: "100%!important",
            height: "100%!important",
            position: "absolute",
            top: 0,
            left: 0,
        }
    }
}));

interface VideoDialogProps {
    video: any;
    open: boolean;
    onClose: () => void;
}

export default function VideoDialog({ video, open, onClose }: VideoDialogProps) {
    const classes = useStyles();

    return <Dialog
        open={open}
        onClose={onClose}
        classes={{
            paper: classes.dialog_paper
        }}
        fullWidth
    >
        <Box className={classes.videoContainer}>
            {video}
        </Box>
        <Fab
            size="small"
            onClick={onClose}
            className={classes.closeBtn}
        >
            <CloseIcon />
        </Fab>
    </Dialog>
}