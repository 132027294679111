import React, { useState } from "react";
import {
    createStyles, Theme, ThemeProvider, withStyles,
    IconButton, Dialog, DialogTitle, DialogActions,
    Button, DialogContent,
    Table, TableHead, TableRow, TableCell, TableBody, Typography, Tooltip, alpha
} from "@material-ui/core";

import { CloseIcon, darkTheme, InformationIcon } from "App/Theme";

const CustomButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
            backgroundColor: alpha(theme.palette.background.default, 0.7),
            "&:hover": {
                backgroundColor: theme.palette.background.paper,
            },
        }
    })
)(IconButton);

export interface Control {
    command: string;
    details: string;
}

export const DESKTOP_CONTROLS = [
    {
        command: "Right click + mouse move",
        details: "move images"
    },
    {
        command: "Ctrl + Right click + mouse move",
        details: "zoom in/out images"
    },
    {
        command: "Mouse scroll",
        details: "increment/decrement slice index"
    },
    {
        command: "Left click + mouse move",
        details: "move slice index point"
    },
    {
        command: "Ctrl + Left click + mouse move",
        details: "move fusion point"
    }
] as Control[];

export const MOBILE_CONTROLS = [
    {
        command: "Two fingers + move",
        details: "move images"
    },
    {
        command: "Two fingers + pinch/zoom ",
        details: "zoom in/out images"
    },
    {
        command: "One finger + move",
        details: "move fusion point"
    }
] as Control[];

export const DRAWING_CONTROLS = [
    {
        command: "Left click + mouse move",
        details: "draw / erase contour <br />*<em>select a contour first<em>",
    },
    {
        command: "Shift + Left click + mouse move",
        details: "erase contour <br />*<em>select a contour first<em>",
    }
] as Control[];

export interface ControlsInformationsProps {
    controls: Control[];
}

export default function ControlsInformations({ controls }: ControlsInformationsProps) {
    const [open, setOpen] = useState(false);

    const openDialog = () => setOpen(true);
    const closeDialog = () => setOpen(false);

    return <>
        <ThemeProvider theme={darkTheme}>
            <Tooltip title="List of controls" placement="top-start" arrow>
                <CustomButton onClick={openDialog}>
                    <InformationIcon />
                </CustomButton>
            </Tooltip>

            <Dialog open={open} onClose={closeDialog}>
                <DialogTitle>
                    List of controls
                </DialogTitle>

                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Controls</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {controls.map((x, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>
                                        <Typography component="span" variant="overline">
                                            {x.command}
                                        </Typography>
                                    </TableCell>
                                    <TableCell dangerouslySetInnerHTML={{ __html: x.details }} />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        onClick={closeDialog}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </ThemeProvider>
    </>
}
