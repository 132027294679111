import React from "react";
import { Link } from "react-router-dom";

import { Box, createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core";

import { fullPath } from "tools/Route";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(8, 2, 0, 2),
        gap: theme.spacing(2),
        maxWidth: 380,
    },
    logo: {
        height: 40,
    },
    paper: {
        padding: theme.spacing(3, 2),
    },
    actions: {
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        "& a:hover": {
            textDecoration: "underline",
        }
    }
}));

export interface LogLayoutProps {
    title: string;
    children: any;
}

const LINKS = [
    {
        label: "Home",
        href: fullPath("Home")
    },
    {
        label: "Contact us",
        href: fullPath("ContactUs")
    },
    {
        label: "Terms",
        href: fullPath("TermsOfService")
    },
    {
        label: "Privacy",
        href: fullPath("PrivacyPolicy")
    },
];

export default function LogLayout({ title, children }: LogLayoutProps) {

    const classes = useStyles();

    return (
        <Box className={classes.container}>

            <Box className={classes.wrapper}>
                <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="logo" className={classes.logo} />
                <Typography variant="h5">
                    {title}
                </Typography>

                <Paper className={classes.paper}>
                    {children}
                </Paper>

                <Box className={classes.actions}>
                    {LINKS.map(x => 
                        <Link to={x.href} key={x.href}>
                            <Typography variant="caption">
                                {x.label}
                            </Typography>
                        </Link>
                        )}
                </Box>
            </Box>

        </Box>
    );
}