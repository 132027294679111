import React from "react";

import { fullPath } from "tools/Route";
import { Tabs, Tab, makeStyles, Theme, createStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => createStyles({
    tabs: {
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.divider,
    }
}));

const TABS = [
    {
        name: "profile",
        label: "profile",
        route: "Profile",
    },
    {
        name: "atlas",
        label: "your atlas",
        route: "ProfileListClinicalCases",
    },
    {
        name: "workshops",
        label: "your workshops",
        route: "ProfileListContouringWorkshops",
    }
] as const;

export type ProfileTab = typeof TABS[number]["name"];

export interface ProfileTabsProps {
    tab?: ProfileTab;
}

export default function ProfileTabs({ tab }: ProfileTabsProps) {
    const classes = useStyles();
    const history = useHistory();

    const value = TABS.findIndex(x => x.name === tab);

    return <Tabs
        value={value}
        variant="scrollable"
        aria-label="profile tabs"
        className={classes.tabs}
    >
        {
            TABS.map((tab) =>
                <Tab
                    key={tab.name}
                    label={tab.label}
                    onClick={() => history.push(fullPath(tab.route))}
                />
            )
        }
    </Tabs>
}