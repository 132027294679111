import React, { useCallback } from "react";
import { Box, Button } from "@material-ui/core";

import useProfileInformations from "domain/hooks/useProfileInformations";
import { ProfileInformationsResponse } from "domain/public/response/ProfileInformationsResponse";
import { Form, FormFieldsBuilder } from "components/Form";
import { UpdateProfileInformationsCommand } from "domain/public/command/UpdateProfileInformationsCommand";
import { GetNewAccessTokenQuery } from "domain/public/query/GetNewAccessTokenQuery";
import { useAuthContext } from "contexts/Auth";
import { sendMessage } from "tools/Message";
import { AccessTokenResponse } from "domain/public/response/AccessTokenResponse";
import { refreshJwtService } from "services";
import { useNotificationContext } from "cocoreact";
import { SaveIcon } from "App/Theme";

const fields = buildFields();

export default function ProfileInformationsForm() {
    const [, infos,] = useProfileInformations();
    const { setToken } = useAuthContext();
    const { success, error } = useNotificationContext();

    const buildMessage = useCallback((data: ProfileInformationsResponse) => {
        return new UpdateProfileInformationsCommand({ ...data });
    }, []);

    const onSuccess = useCallback(async () => {
        const message = new GetNewAccessTokenQuery();
        const response = await sendMessage<AccessTokenResponse>(message);
        refreshJwtService.setToken(response.accessToken);
        setToken(response.accessToken);
        success("Your personal nformations updated with success")
    }, [setToken, success]);

    const onError = useCallback(() => {
        error("An error occurred while updating your personal informations !")
    }, [error]);

    return <Form<ProfileInformationsResponse>
        fields={fields}
        initial={infos}
        buildMessage={buildMessage}
        onSuccess={onSuccess}
        onError={onError}
    >
        {() => (
            <Box textAlign="right" marginTop={1} marginBottom={2}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                >
                    update informations
                </Button>
            </Box>
        )}
    </Form>
}

function buildFields() {

    const builder = new FormFieldsBuilder<ProfileInformationsResponse>();

    const fields = ProfileInformationsResponse.Fields;

    builder
        .initialize(fields)
        .setDefaultAutoFocusEnabled(false)
        .hidden(fields.id)
        .hidden(fields.role)
        .hidden(fields.email)
        .set(fields.firstname, {
            position: 0,
            fullWidth: false,
            margin: "dense",
            style: { width: "50%" },
        })
        .set(fields.lastname, {
            position: 1,
            fullWidth: false,
            margin: "dense",
            style: { width: "calc(50% - 16px)", marginLeft: 16 },
        })
        .set(fields.status, {
            position: 3,
            placeholder: "ex : Radiation oncologist",
            required: false,
            margin: "dense",
        })
        .set(fields.abstract, {
            position: 4,
            type: "richtext",
            required: false,
            margin: "dense",
        })
        .set(fields.professionalLink, {
            position: 5,
            required: false,
            placeholder: "ex : https://www.linkedin.com/...",
            margin: "dense",
        })
        .set(fields.scientificLink, {
            position: 6,
            required: false,
            placeholder: "ex : https://scholar.google.fr/citations?user=...",
            margin: "dense",
        })
        ;

    return builder.build();
}