//Generated code, do not modify

const guidRegexp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

export type Guid = string;

export function IsGuid(data: any) {
  return typeof data === "string" && guidRegexp.test(data);
}

export function EmptyGuid() {
  return "00000000-0000-0000-0000-000000000000";
}

export function IsEmptyGuid(data: any) {
  return IsGuid(data) && data === EmptyGuid();
}

export function IsValidGuid(data: any) {
  return IsGuid(data) && data !== EmptyGuid();
}
