import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    makeStyles, Theme, createStyles, Paper, Slide, Box, Typography, Button
} from "@material-ui/core";
import { useNotificationContext } from "cocoreact";

import { Guid } from "domain/static/Guid";
import { FileParentType } from "domain/static/FileParentType";
import { ContouringWorkshopViewerResponse, Contour } from "domain/public/response/ContouringWorkshopViewerResponse";
import { UpdateContouringWorkshopContoursCommand } from "domain/public/command/UpdateContouringWorkshopContoursCommand";
import { ContouringWorkshopUserPermission } from "domain/static/ContouringWorkshopUserPermission";
import ContourListPanel from "./ContourListPanel";
import { HeaderCover, Tabs, InformationTab, TabContent } from "components/ClinicalData";
import { SummaryPanel } from "components/ContouringWorkshop";
import { useViewerContext } from "contexts/Viewer";
import { SaveIcon } from "App/Theme";
import { ContourDrawn } from "tools/Viewer";
import { FilePartUploader } from "tools/FileExtension";
import { toArrayBuffer } from "tools/StringExtension";
import { sendMessage } from "tools/Message";
import { formatLongDateTime } from "tools/DateExtension";
import { Spacer } from "components/Page";
import { Prompt } from "react-router-dom";
import ContourPanel from "./ContourPanel";
import { EditableVoxelVolume } from "dline-viewer/dist/data";
import { enumHasFlag } from "tools/EnumExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: theme.mixins.viewer.smallWidth,
        minWidth: theme.mixins.viewer.smallWidth,
        maxWidth: theme.mixins.viewer.smallWidth,
        zIndex: theme.zIndex.drawer,
    },
    actions: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        padding: theme.spacing(2, 4),
        borderTop: `1px ${theme.palette.divider} solid`,
        boxShadow: theme.shadows[8],
        "& > *": {
            marginBottom: theme.spacing(1),
        },
        "& > :last-child": {
            marginBottom: 0,
        },
    },
}));

async function updateContoursCommand(
    command: UpdateContouringWorkshopContoursCommand,
    contours: Contour[],
    contoursDrawn: ContourDrawn[]
) {
    const fileUploader = new FilePartUploader(
        "ReadOnlyROI_polygonVolume.json",
        "application/json"
    );

    command.contours = [];
    for (const contour of contours) {
        if (contour.isEditable === true) {
            const id = contour.id;
            let color = contour.color;
            let fileId = contour.fileId;

            const contourDrawn = contoursDrawn.find(x => x.id === id);
            if (contourDrawn) {
                fileId = null as any;
                color = contourDrawn.color;
                if (contourDrawn.polygonVolume) {
                    const dataRoi = JSON.stringify(contourDrawn.polygonVolume);
                    fileUploader.setParent(id, FileParentType.ClinicalContour);
                    fileId = await fileUploader.send(toArrayBuffer(dataRoi));
                }
            }

            command.contours.push({ id, color, fileId });
        }
    }
}

export interface DrawerProps {
    open: boolean;
    contouringWorkshop: ContouringWorkshopViewerResponse;
}

export default function Drawer({ open, contouringWorkshop }: DrawerProps) {
    const classes = useStyles();

    const [tab, setTab] = useState<InformationTab>("summary");
    const [selectContour, setSelectContour] = useState<Guid | null>(null);

    const { success, error } = useNotificationContext();
    const {
        app: viewerApp,
        dispatcher: viewerDispatcher
    } = useViewerContext();

    const isEditable = useMemo(
        () => enumHasFlag(contouringWorkshop.userPermission, ContouringWorkshopUserPermission.Edit),
        [contouringWorkshop.userPermission]
    );

    const worker = useRef(new Worker(`${process.env.PUBLIC_URL}/workers/viewer.worker.js`));

    const onUnselectHandle = useCallback(() => {
        setSelectContour(null);
        viewerDispatcher({
            type: "CONTOUR_DRAWING",
            contourId: null,
        });
    }, [viewerDispatcher]);

    const workerMessageHandler = React.useCallback(async (ev: MessageEvent) => {
        const contourId = ev.data.contourId as Guid;
        const editableVoxelVolume = ev.data.editableVoxelVolume as EditableVoxelVolume;

        const contourHelper = viewerApp.getContourById(contourId);

        if (contourHelper) {
            await contourHelper.updateEditableVolume(editableVoxelVolume);

            viewerDispatcher({
                type: "LOADING",
                loading: false,
            }, "data");

            await viewerDispatcher({
                type: "CONTOUR_DRAWING",
                contourId: contourId,
            });
        }
    }, [viewerApp, viewerDispatcher]);

    const onSelectHandle = useCallback(async (contourId: Guid) => {
        setSelectContour(contourId);
        if (!isEditable) return;

        const contour = contouringWorkshop.contours.find(x => x.id === contourId);
        if (!contour || contour.isEditable !== true) return;

        const contourHelper = viewerApp.getContourById(contourId);
        const readonlyRoi = contourHelper ? contourHelper.getReadonlyRoi() : null;
        if (readonlyRoi && contourHelper && contourHelper.mode !== "drawing") {
            viewerDispatcher({
                type: "LOADING",
                loading: true,
            }, "data");
            await contourHelper.initializeEditableVolume();

            worker.current.onmessage = workerMessageHandler;
            worker.current.postMessage({
                msg: "POLYGON_TO_VOXEL",
                polygonVolume: readonlyRoi.polygonVolume,
                contourId: contourId,
            });
        }
        else if (contourHelper && contourHelper.mode === "drawing") {
            await viewerDispatcher({
                type: "CONTOUR_DRAWING",
                contourId: contourId,
            });
        }
    }, [contouringWorkshop.contours, isEditable, viewerApp, viewerDispatcher, workerMessageHandler]);

    const onSaveContoursHandle = useCallback(async () => {
        try {
            const contoursDrawn = viewerApp.getContoursDrawn();
            const command = new UpdateContouringWorkshopContoursCommand({ id: contouringWorkshop.id });
            await updateContoursCommand(command, contouringWorkshop.contours, contoursDrawn);
            await sendMessage(command);

            success("You contours has been save with success");
        }
        catch (e) {
            error("An error occured while saving your contours !")
        }
    }, [contouringWorkshop, error, success, viewerApp]);

    useEffect(() => {
        if (isEditable) {
            window.onbeforeunload = () => true;
        }
        return () => {
            window.onbeforeunload = null;
        };
    }, [isEditable]);

    return <>
        {isEditable &&
            <Prompt
                message="Did you save your contours ? Are you sure to leave the page ?"
            />
        }

        <Slide in={open} direction="left">
            <Paper className={classes.root} elevation={12} square={true}>

                <HeaderCover
                    title={contouringWorkshop.name}
                    coverId={contouringWorkshop.coverId}
                    size="small"
                />

                <Tabs
                    value={tab}
                    onChange={setTab}
                    textColor="primary"
                    indicatorColor="primary"
                />

                <TabContent hidden={tab !== "summary"}>
                    <SummaryPanel contouringWorkshop={contouringWorkshop} />
                </TabContent>

                <TabContent hidden={tab !== "contours" || selectContour !== null}>
                    <ContourListPanel
                        contours={contouringWorkshop.contours}
                        onClick={onSelectHandle}
                        disabledEdition={!isEditable}
                    />
                </TabContent>

                <TabContent hidden={tab !== "contours" || selectContour === null}>
                    <ContourPanel
                        contourId={selectContour as Guid}
                        onClose={onUnselectHandle}
                        contouringWorkshop={contouringWorkshop}
                        disabledEdition={!isEditable}
                    />
                </TabContent>

                <Spacer />

                {isEditable && <>
                    <Box className={classes.actions}>
                        <Typography variant="caption" component="p">
                            You can edit the reference contours any time you wan't while the
                            contouring workshop is not started (<strong>{formatLongDateTime(contouringWorkshop.startAt)}</strong>).
                        </Typography>

                        <Button
                            fullWidth
                            size="large"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={onSaveContoursHandle}
                        >
                            save contours
                        </Button>
                    </Box>
                </>}

            </Paper>
        </Slide >

    </>
}
