import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNotificationContext } from "cocoreact";
import { Button, CircularProgress, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { EmailOutlined as EmailIcon } from "@material-ui/icons";

import { useWindowEffectScrollTop } from "tools/useWindowEffectScrollTop";
import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { ContactFormFields, Footer, MailIssueWarning, useContactForm } from "components/Page";
import { ContactCommand } from "domain/public/command/ContactCommand";
import { sendMessage } from "tools/Message";
import { SendIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    layout: {
        margin: theme.spacing(6, 2),
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            margin: theme.spacing(6, "auto"),
        }
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    p: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(2, 0),
        "& a": {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.button.fontWeight,
        },
        "& svg": {
            marginRight: theme.spacing(0.5),
        }
    }
}));

// settings file
declare var APP_CONTACT_EMAIL: string;

export default function ContactPage() {

    const classes = useStyles();
    const { success, error } = useNotificationContext();

    useWindowEffectScrollTop();

    const [loading, setLoading] = useState(false);
    const [formValue, setFieldValue, cleanFromValues] = useContactForm();

    const onSubmitHandle = useCallback(async (e: React.SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);

        try {
            const message = new ContactCommand(formValue);
            await sendMessage(message);

            success("Your message has been sending successfully");
            cleanFromValues();
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
            error("An error occurred while sending your demand !");
        }
    }, [cleanFromValues, formValue, error, success]);

    return <PageLayout tab={undefined}>

        <Helmet>
            <title>Dline - Contact us</title>
        </Helmet>

        <CentralColumnLayout size="md" className={classes.layout}>

            <Typography variant="h5" component="h1" className={classes.title}>
                Contact us
            </Typography>

            <MailIssueWarning />

            <Typography className={classes.p}>
                You can write to us by sending your message to :
            </Typography>

            <Typography className={classes.p}>
                <EmailIcon />
                <a href={`mailto:${APP_CONTACT_EMAIL}`}>{APP_CONTACT_EMAIL}</a>
            </Typography>

            <Typography className={classes.p}>
                Or by using the form below and we'll do our best to get back to you as soon as possible.
            </Typography>

            <form onSubmit={onSubmitHandle}>

                <ContactFormFields
                    value={formValue}
                    onChange={setFieldValue}
                    disabled={loading}
                />

                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            endIcon={loading ? <CircularProgress size={16} /> : <SendIcon />}
                            disabled={loading}
                        >
                            contact us
                        </Button>
                    </Grid>
                </Grid>

            </form>

        </CentralColumnLayout>

        <Footer />

    </PageLayout>
}


