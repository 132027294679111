//Generated code, do not modify

export enum FileParentType {
  None = 0,
  ClinicalCase = 1,
  User = 2,
  ClinicalImage = 3,
  ClinicalContour = 4,
  ContouringWorkshop = 5,
  ParticipantContour = 6,
  ContourStack = 7,
}

export const FileParentTypeOptions = [
  {
    label: "None",
    value: FileParentType.None,
  },
  {
    label: "ClinicalCase",
    value: FileParentType.ClinicalCase,
  },
  {
    label: "User",
    value: FileParentType.User,
  },
  {
    label: "ClinicalImage",
    value: FileParentType.ClinicalImage,
  },
  {
    label: "ClinicalContour",
    value: FileParentType.ClinicalContour,
  },
  {
    label: "ContouringWorkshop",
    value: FileParentType.ContouringWorkshop,
  },
  {
    label: "ParticipantContour",
    value: FileParentType.ParticipantContour,
  },
  {
    label: "ContourStack",
    value: FileParentType.ContourStack,
  },
];
