// import React from "react";
// import { makeStyles, Theme, Typography, createStyles, Box } from "@material-ui/core";

// const useStyles = makeStyles((theme: Theme) => createStyles({
//     root: {
//         backgroundColor: theme.palette.warning.light,
//         color: theme.palette.getContrastText(theme.palette.error.light),
//         padding: theme.spacing(2),
//         borderRadius: theme.shape.borderRadius,
//         "& > a": {
//             fontWeight: "bold",
//             textDecoration: "underline",
//         }
//     }
// }));

// settings file
// declare var APP_CONTACT_EMAIL: string;

export default function MailIssueWarning() {
    return null
    // const classes = useStyles();

    // return <Box marginBottom={1}>
    //     <Typography variant="body2" component="p" className={classes.root}>
    //         We are currently having difficulty sending emails.
    //         To be sure of your request, we advise you to write to us directly at <a href={`mailto:${APP_CONTACT_EMAIL}`}>{APP_CONTACT_EMAIL}</a>.
    //     </Typography>
    // </Box>
}
