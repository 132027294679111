import { capitalize } from "@material-ui/core";
import {
    FormFieldOptionsBuilder as CocoFormFieldOptionsBuilder,
    IFormField,
    IFormWidgetPropsBase,
    TextFormWidgetProps,
    SelectFormWidgetProps,
} from "cocoreact";

export interface IFormFieldOptions<T> extends IFormField<T> {
    fullWidth?: IFormWidgetPropsBase<T>["fullWidth"];
    color?: IFormWidgetPropsBase<T>["color"];
    margin?: IFormWidgetPropsBase<T>["margin"];
    style?: IFormWidgetPropsBase<T>["style"];

    autoClearAdornment?: TextFormWidgetProps["autoClearAdornment"];
    autoClearIcon?: TextFormWidgetProps["autoClearIcon"];
    options?:
    | SelectFormWidgetProps["options"];
    MenuProps?: SelectFormWidgetProps["MenuProps"];

    startAdornment?:
    | TextFormWidgetProps["startAdornment"]
    | SelectFormWidgetProps["startAdornment"];

    multiline?: TextFormWidgetProps["multiline"];
    maxRows?: TextFormWidgetProps["maxRows"];
    minRows?: TextFormWidgetProps["minRows"];
}

export default class FormFieldsBuilder<T>
    extends CocoFormFieldOptionsBuilder<T, IFormFieldOptions<T>> {

    constructor() {
        super(true, (field) => {
            let label = field.label;
            if (label !== "") {
                label = label ? capitalize(label) : capitalize(field.name);
            }
            return {
                ...field,
                label,
                required: field.required !== undefined ? field.required : true,
                margin: field.margin ? field.margin : "normal",
                color: field.color ? field.color : "primary",
                autoComplete: field.autoComplete ? field.autoComplete : "off",
                autoFocus: field.autoFocus ? field.autoFocus : false,
                disabled: field.disabled ? field.disabled : false,
                fullWidth: field.fullWidth !== undefined ? field.fullWidth : true,
            } as IFormFieldOptions<T>;
        });
    }
}
