import React, { useCallback } from "react";
import { Box, Button, Typography } from "@material-ui/core";

import { AskPasswordResetQuery } from "domain/public/query/AskPasswordResetQuery";
import { sendMessage } from "tools/Message";
import { useAuthContext } from "contexts/Auth";

export default function ResetPasswordSection({ email }: { email?: string }) {
    const { setToken } = useAuthContext();

    const onResetPassword = useCallback(async () => {
        if (email) {
            const message = new AskPasswordResetQuery({ email });
            await sendMessage(message);
            setToken(null);
        }
    }, [email, setToken]);

    return <Box component="section">
        <Typography component="p">
            To change your password click to the following button.
        </Typography>
        <Typography component="p" variant="caption">
            You will receive an email which contains a reset link to change your password.
        </Typography>
        <Box marginTop={1} textAlign="right">
            <Button
                variant="outlined"
                onClick={onResetPassword}
                disabled={!email}
            >
                reset my password
            </Button>
        </Box>
    </Box>
}
