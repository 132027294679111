import React, { useCallback } from "react";
import clsx from "clsx";
import {
    createStyles, makeStyles, Theme,
    IconButton, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Box,
} from "@material-ui/core";

import { KeyboardArrowRightIcon, UnvisibleIcon, VisibleIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        minHeight: 64,
        maxHeight: 64,
        borderBottom: `1px ${theme.palette.divider} solid`,
        "& > .MuiListItemAvatar-root": {
            marginRight: theme.spacing(1),
        },
        "& > .MuiListItemIcon-root": {
            marginRight: theme.spacing(-2),
        },
        "&:hover": {
            backgroundColor: theme.palette.action.hover
        },
    },
    small: {
        minHeight: 48,
        maxHeight: 48,
    },
    disabled: {
        color: theme.palette.text.disabled,
    },
    indicator: {
        marginRight: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        color: theme.palette.action.active,
        "& > *": {
            marginLeft: theme.spacing(1),
        },
        "& > :first-child": {
            marginLeft: 0,
        },
    },
}));

export interface ContourItemProps {
    id: string;
    color: string;
    label: string;
    sublabel?: string;
    visibled: boolean;
    onToggleVisibled: (id: string, visibled: boolean) => void;
    indicator?: React.ReactNode;
    onClick?: (id: string) => void;
    onHighlight?: (enabled: boolean) => void;
    size?: "normal" | "small";
    className?: string;
}

export default function ContourItem({
    id, color, label, sublabel, visibled, onToggleVisibled, indicator, onClick, onHighlight, size, className
}: ContourItemProps) {
    const classes = useStyles();

    const allClassName = clsx(
        classes.root,
        visibled === false ? classes.disabled : undefined,
        size === "small" ? classes.small : undefined,
        className
    );

    const onClickHandle = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        onClick && onClick(id);
    }, [id, onClick]);

    const onVisibiledHandle = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        onToggleVisibled(id, !visibled);
    }, [id, onToggleVisibled, visibled]);

    return <ListItem
        button={!!onClick as any}
        className={allClassName}
        onClick={onClick && onClickHandle}
        onMouseEnter={onHighlight ? () => onHighlight(true) : undefined}
        onMouseLeave={onHighlight ? () => onHighlight(false) : undefined}
    >
        <ListItemAvatar>
            <IconButton size="medium" onClick={onVisibiledHandle}>
                {visibled
                    ? <VisibleIcon style={{ color: color }} />
                    : <UnvisibleIcon style={{ color: color, opacity: 0.3 }} />
                }
            </IconButton>
        </ListItemAvatar>

        <ListItemText primary={label} secondary={sublabel} />

        {indicator && <Box className={classes.indicator}>{indicator}</Box>}

        {onClick &&
            <ListItemIcon color="inherit">
                <KeyboardArrowRightIcon color="inherit" />
            </ListItemIcon>
        }
    </ListItem>
}
