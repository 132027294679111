import React from "react";
import { Chip, ChipProps, createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
    chip: {
        // color: theme.palette.grey[100],
        // backgroundColor: theme.palette.grey[600],
        // boxShadow: theme.shadows[4]
    },
}));

export interface ImportantChipProps {
    label: string;
    size?: ChipProps["size"];
}

export default function ImportantChip({ label, size }: ImportantChipProps) {
    const classes = useStyles();

    return <Chip
        label={label}
        size={size}
        variant="outlined"
        className={classes.chip}
    />
}
