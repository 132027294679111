//Generated code, do not modify

export enum ClinicalCaseUserRole {
  None = 0,
  Editor = 1,
  Reviewer = 2,
}

export const ClinicalCaseUserRoleOptions = [
  {
    label: "None",
    value: ClinicalCaseUserRole.None,
  },
  {
    label: "Editor",
    value: ClinicalCaseUserRole.Editor,
  },
  {
    label: "Reviewer",
    value: ClinicalCaseUserRole.Reviewer,
  },
];
