import React from "react";
import {
    createStyles, makeStyles, Theme,
    Divider, Typography, Paper, Box,
} from "@material-ui/core";
import clsx from "clsx";
import { ClinicalCaseViewerResponse } from "domain/public/response/ClinicalCaseViewerResponse";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        cursor: "pointer",
        borderRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: theme.spacing(1.5, 2),
        zIndex: theme.zIndex.appBar,
    },
    divider: {
        height: 4,
        borderRadius: theme.shape.borderRadius,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: theme.spacing(1),
        maxWidth: 64,
    },
    title: {
        textAlign: "center",
    },
}));

export interface BottomSheetProps {
    clinicalCase: ClinicalCaseViewerResponse;
    onClick: () => void;
    className?: string;
}

export default function BottomSheet({ clinicalCase, onClick, className }: BottomSheetProps) {
    const classes = useStyles();

    return <Paper className={clsx(classes.root, className)} onClick={onClick}>
        <Box position="relative">
            <Divider variant="middle" className={classes.divider} />

            <Typography variant="subtitle2" component="h1" className={classes.title} noWrap>
                {clinicalCase.name}
            </Typography>
        </Box>
    </Paper>
}