//Generated code, do not modify

export enum VolumeType {
  None = 0,
  Target = 1,
  AtRisk = 2,
  Anatomy = 3,
}

export const VolumeTypeOptions = [
  {
    label: "None",
    value: VolumeType.None,
  },
  {
    label: "Target",
    value: VolumeType.Target,
  },
  {
    label: "AtRisk",
    value: VolumeType.AtRisk,
  },
  {
    label: "Anatomy",
    value: VolumeType.Anatomy,
  },
];
