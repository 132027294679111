import React, { useCallback, useState } from "react";
import { Grid, TextField } from "@material-ui/core";

export type ContactForm = {
    firstname: string;
    lastname: string;
    email: string;
    content: string;
};

export type ContactFormKeys = Extract<keyof ContactForm, string>;

export function useContactForm(): [ContactForm, (field: ContactFormKeys, value: string) => void, () => void] {
    const [state, setState] = useState<ContactForm>({
        firstname: "",
        lastname: "",
        email: "",
        content: "",
    });

    const setFieldValue = useCallback((field: ContactFormKeys, value: string) => {
        setState(ss => ({
            ...ss,
            [field]: value
        }));
    }, []);

    const cleanState = useCallback(() => {
        setState({
            firstname: "",
            lastname: "",
            email: "",
            content: "",
        });
    }, []);

    return [state, setFieldValue, cleanState]
}

function PickContactFormProps<Key extends keyof ContactForm>(key: Key, value: ContactForm) {
    return {
        name: key as ContactFormKeys,
        value: value[key]
    }
}

export interface ContactFormFieldsProps {
    value: ContactForm;
    disabled: boolean;
    onChange: (field: ContactFormKeys, value: string) => void;
}

export default function ContactFormFields({ value, onChange, disabled }: ContactFormFieldsProps) {

    const onChangeHandle = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        e.persist();
        onChange(e.target.name as ContactFormKeys, e.target.value);
    }, [onChange]);

    return <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
            <TextField
                fullWidth
                required
                variant="outlined"
                disabled={disabled}
                label="Firstname"
                {...PickContactFormProps("firstname", value)}
                onChange={onChangeHandle}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                fullWidth
                required
                variant="outlined"
                disabled={disabled}
                label="Lastname"
                {...PickContactFormProps("lastname", value)}
                onChange={onChangeHandle}
            />
        </Grid>

        <Grid item xs={12}>
            <TextField
                fullWidth
                required
                variant="outlined"
                disabled={disabled}
                type="email"
                label="Email"
                {...PickContactFormProps("email", value)}
                onChange={onChangeHandle}
            />
        </Grid>

        <Grid item xs={12}>
            <TextField
                fullWidth
                required
                placeholder="Your message here *"
                multiline
                minRows={5}
                maxRows={10}
                variant="outlined"
                disabled={disabled}
                {...PickContactFormProps("content", value)}
                onChange={onChangeHandle}
            />
        </Grid>
    </Grid>
}
