import React, { useState } from "react";
import {
    createStyles, Theme,
    Select as MuiSelect, withStyles,
    ThemeProvider, InputLabel,
    FormControl, InputBase, alpha, Tooltip
} from "@material-ui/core";

import { darkTheme } from "App/Theme";

const CustomLabel = withStyles((theme: Theme) => createStyles({
    root: {
        marginTop: 12,
        marginLeft: 9,
        color: theme.palette.text.secondary,
    },
    focused: {
        color: `${theme.palette.text.secondary}!important`,
    }
}))(InputLabel);

const CustomInput = withStyles((theme: Theme) => createStyles({
    root: {
        "& svg": {
            marginRight: theme.spacing(1),
        },
    },
    input: {
        position: "relative",
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        padding: theme.spacing(2.6, 2, 1, 3),
        borderRadius: theme.shape.borderRadius * 8,
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.body1.fontWeight,
        boxShadow: theme.shadows[4],
        backgroundColor: alpha(theme.palette.background.default, 0.6),
        "&:hover": {
            backgroundColor: theme.palette.background.default,
        },
        "&:focus": {
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius * 8,
        },
    },
}),
)(InputBase);

const CustomTooltip = withStyles((theme: Theme) =>
    createStyles({
        popper: {
            zIndex: 0
        },
    }),
)(Tooltip);

export interface SelectProps<T> {
    label: string;
    value: T;
    tooltip?: React.ReactNode;
    children: any;
    onChange: React.Dispatch<T>;
}

export default function Select<T = any>({
    label, value, tooltip, children, onChange
}: SelectProps<T>) {

    const id = `select-${label}`;

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const openTooltip = () => setTooltipOpen(true);
    const closeTooltip = () => setTooltipOpen(false);

    const control = (
        <FormControl variant="outlined">
            <CustomLabel htmlFor={id}>
                {label}
            </CustomLabel>
            <MuiSelect
                id={id}
                value={value}
                onChange={e => onChange(e.target.value as T)}
                input={<CustomInput />}
                onMouseEnter={openTooltip}
                onMouseLeave={closeTooltip}
                onBlur={closeTooltip}
            >
                {children}
            </MuiSelect>
        </FormControl>
    );


    if (!tooltip) {
        return <ThemeProvider theme={darkTheme}>
            {control}
        </ThemeProvider>
    }

    return <ThemeProvider theme={darkTheme}>
        <CustomTooltip
            arrow
            placement="bottom"
            open={tooltipOpen}
            title={tooltip}
        >
            {control}
        </CustomTooltip>
    </ThemeProvider>
}