import { useCallback, useEffect, useMemo, useState } from "react";
import { GetProfileInformationsQuery } from "domain/public/query/GetProfileInformationsQuery";
import { ProfileInformationsResponse } from "domain/public/response/ProfileInformationsResponse";
import { sendMessage } from "tools/Message";

export function EmptyProfileInformationsResponse() {
  const response = new ProfileInformationsResponse();
  response.firstname = "";
  response.lastname = "";
  return response;
}

export default function useProfileInformations(): [
  boolean,
  ProfileInformationsResponse,
  () => Promise<void>,
  Error | null
] {
  const query = useMemo(() => new GetProfileInformationsQuery(), []);

  const [state, setState] = useState({
    loading: true,
    data: EmptyProfileInformationsResponse(),
    error: null as Error | null,
  });

  const updateData = useCallback(async () => {
    setState((s) => ({ ...s, loading: true, error: null }));
    try {
      const data = await sendMessage<ProfileInformationsResponse>(query);
      setState((s) => ({ data, loading: false, error: null }));
    } catch (e) {
      setState((s) => ({ ...s, loading: false, error: e as any }));
    }
  }, [query]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return [state.loading, state.data, updateData, state.error];
}
