import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class ListAnatomicZonesQuery implements IMessage {

    public constructor(init?: Partial<ListAnatomicZonesQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/anatomic-zones`;

    getQueryString = () => undefined;

    getBody = () => undefined;

    needAuthentication = () => false;
}
