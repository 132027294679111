import React, { useCallback, useMemo, useState } from "react";
import { Box } from "@material-ui/core";

import { VolumeType } from "domain/static/VolumeType";
import { Guid } from "domain/static/Guid";
import { Contour } from "domain/public/response/ClinicalCaseViewerResponse";
import { filterByVolumeType } from "tools/ContourExtension";
import { useViewerContext } from "contexts/Viewer";
import {
    ContourList, ContourSeparator,
    ContourItem as BaseContourItem,
    ItemComponentProps,
    EditableIndicator,
    LockIndicator
} from "components/ClinicalData";
import { contourInfoLabel } from "tools/StringExtension";

function ContourItem({ contour, onClick, disabledEdition }: ItemComponentProps<Contour> & { disabledEdition?: boolean }) {
    const {
        data: viewerData,
        app: viewerApp,
        dispatcher: viewerDispatcher
    } = useViewerContext();

    const contourData = viewerData.getContourById(contour.id);
    const contourHelper = viewerApp.getContourById(contour.id);

    const onHighlightHandle = useCallback((enabled: boolean) => {
        if (!contourHelper || !contourData) return;

        if (contourData.visible && !contourData.drawing) {
            contourHelper.setHighlight(enabled);
        }
    }, [contourHelper, contourData]);

    const onVisibilityHandle = useCallback((id: string, visibled: boolean) => {
        viewerDispatcher({
            type: "CONTOURS_VISIBILITY",
            contourIds: [id],
            visibled: visibled,
        });
    }, [viewerDispatcher]);

    return <BaseContourItem
        id={contour.id}
        color={contour.color}
        label={contourInfoLabel(contour)}
        sublabel={contour.organizationName}
        visibled={contourData ? contourData.visible : true}
        onToggleVisibled={onVisibilityHandle}
        onClick={onClick}
        onHighlight={onHighlightHandle}
        indicator={disabledEdition === true
            ? undefined
            : contour.isEditable ? <EditableIndicator /> : <LockIndicator />
        }
    />
}

interface ContourListByVolumeTypeProps {
    label: string;
    type: VolumeType | VolumeType[];
    contours: Contour[];
    initialCollapsed?: boolean;
    onClick: (contourId: Guid) => void;
    disabledEdition?: boolean;
}

function ContourListByVolumeType({
    label, type, contours, initialCollapsed, onClick, disabledEdition
}: ContourListByVolumeTypeProps) {
    const { dispatcher: viewerDispatcher } = useViewerContext();

    const [checked, setChecked] = useState(true);
    const [collapsed, setCollapsed] = useState(initialCollapsed ?? true);

    const fileteredContours = useMemo(
        () => filterByVolumeType(contours, type),
        [contours, type]
    );

    const onCheckHandle = useCallback((_checked: boolean) => {
        const contourIds = fileteredContours.map(x => x.id);
        viewerDispatcher({
            type: "CONTOURS_VISIBILITY",
            contourIds: contourIds,
            visibled: _checked,
        });
        setChecked(_checked);
    }, [fileteredContours, viewerDispatcher]);

    if (fileteredContours.length === 0) return null;

    return <>
        <ContourSeparator
            label={`${label} (${fileteredContours.length})`}
            checked={checked}
            onCheck={onCheckHandle}
            collapsed={collapsed}
            onCollapse={setCollapsed}
        />

        <ContourList
            contours={fileteredContours}
            ItemComponent={(props) => <ContourItem {...props} disabledEdition={disabledEdition} />}
            onClick={onClick}
            collapsed={collapsed}
        />
    </>
}

export interface ContourListPanelProps {
    contours: Contour[];
    onClick: (contourId: Guid) => void;
    disabledEdition?: boolean;
}

export default function ContourListPanel({ contours, onClick, disabledEdition }: ContourListPanelProps) {
    return <Box>
        <ContourListByVolumeType
            label="target"
            type={VolumeType.Target}
            contours={contours}
            onClick={onClick}
            disabledEdition={disabledEdition}
        />
        <ContourListByVolumeType
            label="at risk / anatomy"
            type={[VolumeType.AtRisk, VolumeType.Anatomy]}
            contours={contours}
            onClick={onClick}
            disabledEdition={disabledEdition}
        />
        <ContourListByVolumeType
            label="unclassified"
            type={VolumeType.None}
            contours={contours}
            onClick={onClick}
            initialCollapsed={false}
            disabledEdition={disabledEdition}
        />
    </Box>
}