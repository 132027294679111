import React from "react";
import clsx from "clsx";
import {
    createStyles,
    makeStyles,
    SvgIconProps,
    Theme,
    Typography as MuiTypography,
    TypographyProps as MuiTypographyProps,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        alignItems: "center",
        "& svg": {
            marginRight: theme.spacing(1),
            fontSize: "inherit",
        }
    }
}));

export interface TypographyProps extends MuiTypographyProps {
    startIcon?: React.ReactElement<SvgIconProps>;
}

export default function Typography({ startIcon, className, children, ...props }: TypographyProps) {
    const classes = useStyles();
    return <MuiTypography className={clsx(classes.root, className)} {...props}>
        {startIcon}{children}
    </MuiTypography>
}
