import { Guid } from "domain/static/Guid";
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import { ContouringWorkshopViewerResponse } from "domain/public/response/ContouringWorkshopViewerResponse";
import {
    ArticleBib,
    ContourPanelContent,
    ContourPanelInformations,
    ContourPanelLabel,
    ContourPanelLoader,
    LinearIndicator
} from "components/ClinicalData";
import { useViewerContext } from "contexts/Viewer";
import { numberFormat } from "tools/StringExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(1, 2, 2, 2),
    },
}));

export interface ContourPanelProps {
    contourId: Guid;
    contouringWorkshop: ContouringWorkshopViewerResponse;
    onClose: () => void;
}

export default function ContourPanel({ contourId, contouringWorkshop, onClose }: ContourPanelProps) {
    const classes = useStyles();
    const { data: viewerData } = useViewerContext();

    const contour = contouringWorkshop.contours.find(x => x.id === contourId);
    const contourData = viewerData.getContourById(contourId);

    if (!contour) {
        return <ContourPanelLoader />
    }

    const participantDice = contour.participantResults.length > 0
        ? contour.participantResults[0].dice : 0.0;

    const article = contouringWorkshop.contourArticles.find(x => x.id === contour.articleId);

    return <Box className={classes.root}>
        <ContourPanelLabel contour={contour} onClose={onClose} />

        <ContourPanelInformations
            contour={contour}
            color={contourData ? contourData.color : "white"}
        />

        {contour.isEditable &&
            <Box marginTop={2}>
                <Typography component="p" variant="body1">
                    My contour dice : {numberFormat(participantDice)}
                </Typography>
                <LinearIndicator value={participantDice} />
            </Box>
        }

        <ContourPanelContent value={contour.content} />

        {article && <ArticleBib article={article} collapsable={false} />}

    </Box>
}

