import { useCallback, useEffect, useMemo, useState } from "react";
import { Guid } from "domain/static/Guid";
import { GetUserPublicInformationsQuery } from "domain/public/query/GetUserPublicInformationsQuery";
import { UserPublicInformationsResponse } from "domain/public/response/UserPublicInformationsResponse";
import { sendMessage } from "tools/Message";

export function EmptyUserPublicInformationsResponse(id: Guid) {
  const response = new UserPublicInformationsResponse();
  response.id = id;
  response.firstname = "";
  response.lastname = "";
  response.status = "";
  response.abstract = "";
  response.professionalLink = "";
  response.scientificLink = "";
  return response;
}

export default function useProfileInformations(
  id: Guid
): [
  boolean,
  UserPublicInformationsResponse,
  () => Promise<void>,
  Error | null
] {
  const query = useMemo(() => new GetUserPublicInformationsQuery({ id }), [id]);

  const [state, setState] = useState({
    loading: true,
    data: EmptyUserPublicInformationsResponse(id),
    error: null as Error | null,
  });

  const updateData = useCallback(async () => {
    setState((s) => ({ ...s, loading: true, error: null }));
    try {
      const data = await sendMessage<UserPublicInformationsResponse>(query);
      setState((s) => ({ data, loading: false, error: null }));
    } catch (e) {
      setState((s) => ({ ...s, loading: false, error: e as any }));
    }
  }, [query]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return [state.loading, state.data, updateData, state.error];
}
