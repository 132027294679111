//Generated code, do not modify

export enum ClinicalCaseState {
  None = 0,
  Draft = 1,
  Submitted = 2,
  Published = 3,
}

export const ClinicalCaseStateOptions = [
  {
    label: "None",
    value: ClinicalCaseState.None,
  },
  {
    label: "Draft",
    value: ClinicalCaseState.Draft,
  },
  {
    label: "Submitted",
    value: ClinicalCaseState.Submitted,
  },
  {
    label: "Published",
    value: ClinicalCaseState.Published,
  },
];
