import {
	TypeWidgetOptionsBuilder,
	TextFormWidget,
	SelectFormWidget,
	SwitchFormWidget
} from "cocoreact";
import RichTextFormWidget from "./widgets/RichTextFormWidget";

const formWidgetOptions = new TypeWidgetOptionsBuilder()
	.set("string", TextFormWidget)
	.set("number", TextFormWidget)
	.set("email", TextFormWidget)
	.set("password", TextFormWidget)
	.set("select", SelectFormWidget)
	.set("boolean", SwitchFormWidget)
	.set("richtext", RichTextFormWidget)
	.build();

export default formWidgetOptions;
