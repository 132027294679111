import React, { useCallback, useEffect } from "react";
import { DialogContentText } from "@material-ui/core";
import { useNotificationContext } from "cocoreact";

import { SendIcon } from "App/Theme";
import { ContactCommand } from "domain/public/command/ContactCommand";
import { ContactFormFields, MailIssueWarning, ResponsiveFormDialog, useContactForm } from "components/Page";
import { sendMessage } from "tools/Message";

export interface ContributeDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function ContributeDialog({ open, onClose }: ContributeDialogProps) {

    const { success, error } = useNotificationContext();
    const [formValue, setFieldValue, cleanFromValues] = useContactForm();

    useEffect(() => {
        if (open === false) {
            cleanFromValues();
        }
    }, [cleanFromValues, open])

    const onSubmitHandle = useCallback(async () => {
        try {
            const message = new ContactCommand(formValue);
            await sendMessage(message);

            success("Your demand has been sending successfully");
            onClose();
        }
        catch (e) {
            error("An error occurred while sending your demand !");
        }
    }, [error, formValue, onClose, success]);

    return <ResponsiveFormDialog
        open={open}
        onClose={onClose}
        title="You want to contribute ?"
        actionLabel="contact us"
        actionIcon={<SendIcon />}
        onSubmit={onSubmitHandle}
    >
        {({ loading }) => <>

            <MailIssueWarning />
            
            <DialogContentText>
                You are welcome to help us to improve atlas database.
            </DialogContentText>

            <DialogContentText>
                You can tell us a bit more about yourself and precise how you want to contribute.
            </DialogContentText>

            <ContactFormFields
                value={formValue}
                onChange={setFieldValue}
                disabled={loading}
            />

        </>}
    </ResponsiveFormDialog>
}
