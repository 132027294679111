import { Guid } from "domain/static/Guid";
import {
    Box, createStyles, makeStyles, Theme,
    Tabs as MuiTabs, Tab, Divider, Typography, Slider, List, ListItem, withStyles, Tooltip
} from "@material-ui/core";
import { Area, AreaChart } from "recharts";

import { Range as DRange } from "domain/static/Range";
import { Contour, ParticipantResult, ContouringWorkshopViewerResponse } from "domain/public/response/ContouringWorkshopViewerResponse";
import { StackData, useViewerContext } from "contexts/Viewer";
import {
    ArticleBib,
    CircularIndicator,
    ContourPanelContent,
    ContourPanelLabel,
    ContourPanelLoader,
    ContourItem as BaseContourItem,
    ContourPanelInformations
} from "components/ClinicalData";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ContouringWorkshopState } from "domain/static/ContouringWorkshopState";
import { numberFormat } from "tools/StringExtension";
import { getParticipantId, getStackId } from "tools/ContourExtension";
import { ContouringWorkshopUserPermission } from "domain/static/ContouringWorkshopUserPermission";
import { enumHasFlag } from "tools/EnumExtension";


const StackSliderHorizontal = withStyles({
    root: {
        color: "transparent",
        background: "linear-gradient(90deg, rgba(0,5,255,1) 0%, rgba(0,255,214,1) 23%, rgba(0,255,115,1) 50%, rgba(255,247,0,1) 74%, rgba(255,0,0,1) 100%);",
    },
    thumb: {
        height: 36,
        width: 8,
        backgroundColor: "white",
        border: "2px solid grey",
        borderRadius: 8,
        marginLeft: -4,
        marginTop: "-17px!important",
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
    },
    markLabel: {
        marginTop: 8,
    },
    track: {
        // height: 8,
        // borderRadius: 4,
    },
    rail: {
        // height: 8,
        // borderRadius: 4,
    },
})(Slider);

const useStyles = makeStyles((theme: Theme) => createStyles({
    guidelinePanel: {
        overflowX: "hidden",
        overflowY: "auto",
        padding: theme.spacing(0, 2, 2, 2),
    },
    resultsPanel: {
        overflow: "hidden",
        overflowY: "auto",
    },
    tabs_indicator: {
        backgroundColor: theme.palette.text.secondary
    },
    diceFilter: {
        minHeight: 64,
        maxHeight: 64,
        borderBottom: `1px ${theme.palette.divider} solid`,
        backgroundColor: theme.palette.action.selected,
    },
    diceFilter_indicatorWrapper: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        "& > *": {
            marginLeft: theme.spacing(2),
        },
        "& > *:first-child": {
            marginLeft: 0,
        },
    },
    diceFilter_input: {
        fontSize: theme.typography.body2.fontSize,
        border: `1px ${theme.palette.divider} solid`,
        borderRadius: theme.shape.borderRadius,
        minWidth: 44,
        maxWidth: 44,
        padding: theme.spacing(1),
    },
    contourItem_referrer: {
        // backgroundColor: theme.palette.action.focus,
        "& .MuiListItemText-root span": {
            display: "inline",
            padding: theme.spacing(0.5, 2),
            border: `1px ${theme.palette.divider} solid`,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.action.focus,
        }
    },
    contourItem_diceIndicator: {
        marginRight: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        "& > *": {
            marginLeft: theme.spacing(1),
        },
        "& > :first-child": {
            marginLeft: 0,
        },
    },
    contourItem_hidden: {
        display: "none",
    },
    stackLut: {
        height: "100%",
        width: 24,
        background: "linear-gradient(0deg, rgba(0,5,255,1) 0%, rgba(0,255,214,1) 23%, rgba(0,255,115,1) 50%, rgba(255,247,0,1) 74%, rgba(255,0,0,1) 100%);",
    },
}));

// ------------------------------------------------------------------

type ContourTab = "guideline" | "results_stack" | "results_list";

interface TabsProps {
    value: ContourTab;
    onChange: (newTab: ContourTab) => void;
}

function Tabs({ value, onChange }: TabsProps) {
    const classes = useStyles();

    return <>
        <MuiTabs
            variant="fullWidth"
            onChange={(_e, newTab: ContourTab) => onChange(newTab)}
            value={value}
            classes={{
                indicator: classes.tabs_indicator
            }}
        >
            <Tab label="guideline" value={"guideline" as ContourTab} />
            <Tab label="results stack" value={"results_stack" as ContourTab} />
            <Tab label="results list" value={"results_list" as ContourTab} />
        </MuiTabs>

        <Divider />
    </>
}

// ------------------------------------------------------------------

type DicesChartsProps = {
    contours: { dice: number }[];
    width: number;
    height: number;
};

function DicesCharts({ contours, width, height }: DicesChartsProps) {
    const dices = useMemo(() => {
        const diceSteps = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1].map(x => ({
            name: "" + x / 10,
            count: 0
        }));
        contours.forEach(x => {
            const val = Math.round(x.dice * 10);
            diceSteps[val].count++;
        });
        return diceSteps;
    }, [contours]);

    return <AreaChart width={width} height={height} data={dices}>
        <defs>
            <linearGradient id="colorCount" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={"#00796b"} stopOpacity={0.9} />
                <stop offset="95%" stopColor={"#00796b"} stopOpacity={0.2} />
            </linearGradient>
        </defs>
        <Area
            type="natural"
            dataKey="count"
            stroke={"#00796b"}
            fillOpacity={1}
            fill="url(#colorCount)"
            animationDuration={100}
        />
    </AreaChart>
}

// ------------------------------------------------------------------

type ParticipantResultWithId = { id: string } & ParticipantResult;

type Range = [number, number];

function inRange(c: ParticipantResult, range: Range) {
    return c.dice >= range[0] && c.dice <= range[1];
}

function getInOoutRangeIds(contours: ParticipantResultWithId[], range: Range) {
    const ids = [[], []] as [string[], string[]];
    for (const contour of contours) {
        if (inRange(contour, range)) {
            ids[0].push(contour.id);
        }
        else {
            ids[1].push(contour.id);
        }
    }
    return ids;
}

interface DiceFilterProps {
    value: Range;
    minMax: Range;
    onChange: (newValue: Range) => void;
    onChangeCommitted: (newValue: Range) => void;
    contours: ParticipantResult[];
}

function DiceFilter({ value, minMax, onChange, onChangeCommitted, contours }: DiceFilterProps) {
    const classes = useStyles();

    return <ListItem className={classes.diceFilter}>
        <Box className={classes.diceFilter_indicatorWrapper} onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
        }}>
            <Typography
                component="span"
                variant="caption"
                className={classes.diceFilter_input}
            >
                {numberFormat(value[0])}
            </Typography>

            <Box position="relative" width={300} height="100%">
                <Box position="absolute" style={{ opacity: 0.2 }} top={-14}>
                    <DicesCharts contours={contours} width={300} height={62} />
                </Box>
                <Slider
                    color="secondary"
                    valueLabelDisplay="off"
                    min={minMax[0]}
                    max={minMax[1]}
                    step={0.01}
                    value={value}
                    onChange={(e, newValue) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onChange(newValue as Range);
                    }}
                    onChangeCommitted={(e, newValue) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onChangeCommitted(newValue as Range);
                    }}
                />
            </Box>

            <Typography
                component="span"
                variant="caption"
                className={classes.diceFilter_input}
            >
                {numberFormat(value[1])}
            </Typography>
        </Box>
    </ListItem>
}

// ------------------------------------------------------------------

interface ParticipantContourItemProps {
    contour: ParticipantResultWithId;
    className?: string;
}

function ParticipantContourItem({ contour, className }: ParticipantContourItemProps) {
    const classes = useStyles();
    const {
        data: viewerData,
        dispatcher: viewerDispatcher,
        app: viewerApp
    } = useViewerContext();

    const contourData = viewerData.getContourById(contour.id);
    const contourHelper = viewerApp.getContourById(contour.id);
    const visibled = contourData?.visible ?? true;
    const color = contourData?.color ?? "white";

    const onVisibiledHandle = useCallback((id: string, visibled: boolean) => {
        viewerDispatcher({
            type: "CONTOURS_VISIBILITY",
            contourIds: [id],
            visibled: visibled,
        });
    }, [viewerDispatcher]);

    const onHighlightHandle = useCallback((enabled: boolean) => {
        if (!contourHelper || !contourData) return;

        if (contourData.visible) {
            contourHelper.setHighlight(enabled);
        }
    }, [contourData, contourHelper]);

    return <BaseContourItem
        size="small"
        className={className}
        id={contour.id}
        color={color}
        label={contour.fileId.slice(0, 8)}
        visibled={visibled}
        onToggleVisibled={onVisibiledHandle}
        onHighlight={onHighlightHandle}
        indicator={
            <Box className={classes.contourItem_diceIndicator}>
                <Typography variant="body2" component="span">
                    {numberFormat(contour.dice)}
                </Typography>
                <CircularIndicator
                    size={32}
                    thickness={16}
                    value={contour.dice}
                    disabled={!visibled}
                />
            </Box>
        }
    />
}

// ------------------------------------------------------------------

interface StackFilterProps {
    contourId: Guid;
    stack: StackData;
    onChange: (contourId: Guid, newValue: number[]) => void;
}

function StackFilter({ contourId, stack, onChange }: StackFilterProps) {
    const stackData = useMemo(() => stack.getData(), [stack]);
    const rangeMax = useMemo(() => stackData.getDataMinMax()[1], [stackData]);

    const marks = useMemo(
        () => [0.00, 0.25, 0.50, 0.75, 1.00].map(x => {
            const step = Math.round(x * rangeMax);
            return { value: step, label: step }
        }),
        [rangeMax]
    );

    const histo = useMemo(() => {
        const histogram = stackData.getHistogram();
        const h = [];
        for (const k in histogram) {
            if (k === '0') continue;
            h.push({ name: k, count: histogram[k] });
        }
        for (let i = 1; i < h.length; i++) {
            h[i].count += h[i - 1].count;
        }
        for (let i = 0; i < h.length; i++) {
            h[i].count -= h[0].count;
        }
        return h;
    }, [stackData]);

    return <Box marginY={6} height={350} display="flex" flexDirection="column" alignItems="center">
        <AreaChart width={420} height={300} data={histo}>
            <defs>
                <linearGradient id="colorCount" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={"#00796b"} stopOpacity={0.9} />
                    <stop offset="95%" stopColor={"#00796b"} stopOpacity={0.2} />
                </linearGradient>
            </defs>
            <Area
                type="natural"
                dataKey="count"
                stroke={"#00796b"}
                fillOpacity={1}
                fill="url(#colorCount)"
                animationDuration={100}
            />
        </AreaChart>
        <StackSliderHorizontal
            style={{ maxWidth: 420 }}
            valueLabelDisplay="auto"
            ValueLabelComponent={({ children, open, value }) =>
                <Tooltip open={open} enterTouchDelay={0} placement="top" title={value} arrow color="primary">
                    {children}
                </Tooltip>
            }
            min={0}
            max={rangeMax}
            marks={marks}
            value={[stack.windowing.min, stack.windowing.max]}
            step={1}
            onChange={(e, newValue) => {
                e.stopPropagation();
                e.preventDefault();
                onChange(contourId, newValue as number[]);
            }}
        />
    </Box>
}

// ------------------------------------------------------------------

export interface ContourPanelProps {
    contourId: Guid;
    contouringWorkshop: ContouringWorkshopViewerResponse;
    onClose: () => void;
}

export default function ContourPanel({ contourId, contouringWorkshop, onClose }: ContourPanelProps) {
    const classes = useStyles();
    const {
        data: viewerData,
        dispatcher: viewerDispatcher
    } = useViewerContext();

    const [tab, setTab] = useState<ContourTab>("results_stack");
    const [diceMinMax, setDiceMinMax] = useState<Range>([0.0, 1.0]);
    const [diceRange, setDiceRange] = useState<Range>([0.0, 1.0]);
    const currentContour = useRef<Contour | undefined>(undefined);

    const contour = useMemo(
        () => contouringWorkshop.contours.find(x => x.id === contourId),
        [contourId, contouringWorkshop.contours]
    );
    const contourData = useMemo(
        () => viewerData.getContourById(contourId),
        [contourId, viewerData]
    );
    const contourStackData = useMemo(
        () => viewerData.getStackById(getStackId(contourId)),
        [contourId, viewerData]
    );

    const resultsAvailable = useMemo(() => {
        const { userPermission, state } = contouringWorkshop;
        if (state === ContouringWorkshopState.Past) {
            return enumHasFlag(userPermission, ContouringWorkshopUserPermission.PastResults);
        }
        if (state === ContouringWorkshopState.Present) {
            return enumHasFlag(userPermission, ContouringWorkshopUserPermission.PresentResults);
        }
        return false;
    }, [contouringWorkshop]);

    const participantContours = useMemo(() => {
        const contours = contour ? contour.participantResults : [];
        return contours.map(x => ({
            ...x,
            id: getParticipantId(x.contourId, x.participantId)
        } as ParticipantResultWithId))
    }, [contour]);

    const setContoursVisibled = useCallback((id: string | string[], visibled: boolean) => {
        const ids = Array.isArray(id) ? id : [id];
        viewerDispatcher({
            type: "CONTOURS_VISIBILITY",
            contourIds: ids,
            visibled: visibled,
        });
    }, [viewerDispatcher]);

    const setStackVisibled = useCallback((id: string, visibled: boolean) => {
        viewerDispatcher({
            type: "STACKS_VISIBILITY",
            stackIds: [getStackId(id)],
            visibled: visibled,
        });
    }, [viewerDispatcher]);

    const setStackWindowing = useCallback((id: string, newValue: number[]) => {
        viewerDispatcher({
            type: "STACK_WINDOWING",
            stackId: getStackId(id),
            windowing: new DRange(newValue)
        });
    }, [viewerDispatcher]);

    // on contour changed (initialization)
    useEffect(() => {
        if (contour && !currentContour.current) {
            currentContour.current = contour;
            const diceMin = participantContours.reduce((acc, x) => acc < x.dice ? acc : x.dice, 1.0);
            const diceMax = participantContours.reduce((acc, x) => acc > x.dice ? acc : x.dice, 0.0);
            setDiceMinMax([diceMin, diceMax]);
            setDiceRange([diceMin, diceMax]);
            setContoursVisibled(contour.id, true);
            setTab("results_stack");
        }
        else if (!contour) {
            if (currentContour.current) {
                const participantContourIds = currentContour.current
                    .participantResults.map(x => getParticipantId(x.contourId, x.participantId));
                setContoursVisibled(participantContourIds, false);
                setStackVisibled(currentContour.current.id, false);
                setStackWindowing(currentContour.current.id, [1, currentContour.current.numberOfParticipantResult]);
            }
            currentContour.current = undefined;
            setDiceMinMax([0.0, 1.0]);
            setDiceRange([0.0, 1.0]);
        }
    }, [contour, participantContours, setStackWindowing]);

    // when tab changed
    useEffect(() => {
        if (contour && resultsAvailable) {
            if (tab === "guideline") return;
            const participantContourIds = participantContours.map(x => x.id);
            if (tab === "results_list") {
                onVisibledOutRangeHandle()
                setStackVisibled(contour.id, false);
            }
            else if (tab === "results_stack") {
                setContoursVisibled(participantContourIds, false);
                setStackVisibled(contour.id, true);
            }
        }
    }, [contour, participantContours, resultsAvailable, tab]);

    // when change contour list dice range changed
    const onVisibledOutRangeHandle = useCallback(() => {
        const [inRangeIds, outRangeIds] = getInOoutRangeIds(participantContours, diceRange);
        setContoursVisibled(inRangeIds, true);
        setContoursVisibled(outRangeIds, false);
    }, [participantContours, diceRange]);

    if (!contourId) return null;
    if (!contour || !contourData) {
        return <ContourPanelLoader />
    }

    const { content, articleId, numberOfParticipantResult } = contour;
    const { contourArticles, numberOfParticipant } = contouringWorkshop;
    const article = contourArticles.find(x => x.id === articleId);

    const NotAvailableResults = () => <Box marginX={2} marginY={2}>
        <Typography component="p" variant="body1">
            You do not have the necessary permissions to view the detailed results.
        </Typography>
    </Box>

    const EmptyResults = () => <Box marginX={2} marginY={2}>
        <Typography component="p" variant="body1">
            There is no results to show for the moment.
        </Typography>
    </Box>

    return <>
        <Box marginX={2} marginY={1}>
            <ContourPanelLabel contour={contour} onClose={onClose} />

            <ContourPanelInformations
                contour={contour}
                color={contourData.color}
            />

            <Box marginY={1} marginTop={2}>
                <Typography component="p" variant="body1">
                    {contour.isEditable
                        ? <>
                            <strong>{numberOfParticipantResult} over {numberOfParticipant}
                                participants</strong> registered for the workshop have done this contour at this time.
                        </>
                        :
                        <>
                            <strong>This contour was not open to contouring for the participants.</strong>
                        </>
                    }
                </Typography>
            </Box>
        </Box>

        {contour.isEditable &&
            <Tabs value={tab} onChange={setTab} />
        }

        <Box
            hidden={contour.isEditable ? tab !== "guideline" : false}
            className={classes.guidelinePanel}
        >
            <ContourPanelContent value={content} />
            {article && <ArticleBib article={article} collapsable={false} />}
        </Box>

        <Box
            hidden={tab !== "results_list" || !contour.isEditable}
            className={classes.resultsPanel}
        >
            {resultsAvailable === false && <NotAvailableResults />}

            {resultsAvailable && participantContours.length === 0 && <EmptyResults />}

            {resultsAvailable && participantContours.length > 0 &&
                <>
                    <DiceFilter
                        value={diceRange}
                        minMax={diceMinMax}
                        onChange={setDiceRange}
                        onChangeCommitted={onVisibledOutRangeHandle}
                        contours={participantContours}
                    />
                    <List style={{ padding: 0 }}>
                        {participantContours.map(pc =>
                            <ParticipantContourItem
                                key={pc.id}
                                contour={pc}
                                className={inRange(pc, diceRange) ? undefined : classes.contourItem_hidden}
                            />
                        )}
                    </List>
                </>
            }
        </Box>

        <Box
            hidden={tab !== "results_stack" || !contour.isEditable}
            className={classes.resultsPanel}
        >
            {resultsAvailable === false && <NotAvailableResults />}

            {resultsAvailable && !contourStackData && <EmptyResults />}

            {resultsAvailable && contourStackData &&
                <Box marginX={2} marginY={2}>
                    <StackFilter
                        contourId={contourId}
                        stack={contourStackData}
                        onChange={setStackWindowing}
                    />
                </Box>
            }
        </Box>
    </>
}

