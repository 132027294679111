import React, { useState } from "react";
import {
    createStyles, makeStyles, Theme,
    Dialog, Slide, Toolbar, Typography, IconButton, Box, DialogContent,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";

import { KeyboardArrowDownIcon, PreviousIcon } from "App/Theme";
import { Tabs, InformationTab, TabContent } from "components/ClinicalData";
import { SummaryPanel } from "components/ClinicalCase";
import ContourListPanel from "./ContourListPanel";
import ContourPanel from "./ContourPanel";
import { Guid } from "domain/static/Guid";
import { ClinicalCaseViewerResponse } from "domain/public/response/ClinicalCaseViewerResponse";

const useStyles = makeStyles((theme: Theme) => createStyles({
    dialogContent: {
        padding: 0,
        display: "flex",
        flexDirection: "column",
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        padding: theme.spacing(0, 1),
    },
    toolbar_action: {
        marginRight: theme.spacing(2),
    },
    tabs: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    tabs_indication: {
        backgroundColor: theme.palette.background.default,
    },
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface InformationsDialogProps {
    open: boolean;
    onClose: () => void;
    clinicalCase: ClinicalCaseViewerResponse;
}

export default function InformationsDialog({
    open, onClose, clinicalCase
}: InformationsDialogProps) {
    const classes = useStyles();

    const [tab, setTab] = useState<InformationTab>("summary");
    const [contourId, setContourId] = useState<Guid | null>(null);

    return <Dialog open={open} fullScreen TransitionComponent={Transition} scroll="paper" keepMounted>
        <Toolbar className={classes.toolbar}>
            {contourId === null &&
                <IconButton
                    color="inherit"
                    aria-label="Close atlas informations"
                    className={classes.toolbar_action}
                    onClick={onClose}
                >
                    <KeyboardArrowDownIcon />
                </IconButton>
            }
            {contourId !== null &&
                <IconButton
                    color="inherit"
                    aria-label="Close contour informations"
                    className={classes.toolbar_action}
                    onClick={() => setContourId(null)}
                >
                    <PreviousIcon />
                </IconButton>
            }

            <Typography variant="h5" noWrap>
                {clinicalCase.name}
            </Typography>
        </Toolbar>

        <Box hidden={contourId !== null}>
            <Tabs
                value={tab}
                onChange={setTab}
                classes={{
                    root: classes.tabs,
                    indicator: classes.tabs_indication,
                }}
            />
        </Box>

        <DialogContent className={classes.dialogContent}>
            <TabContent hidden={tab !== "summary"}>
                <SummaryPanel clinicalCase={clinicalCase} />
            </TabContent>

            <TabContent hidden={tab !== "contours" || contourId !== null}>
                <ContourListPanel
                    contours={clinicalCase.contours}
                    onClick={setContourId}
                />
            </TabContent>

            <TabContent hidden={tab !== "contours" || contourId === null}>
                <ContourPanel
                    contourId={contourId as Guid}
                    clinicalCase={clinicalCase}
                />
            </TabContent>

        </DialogContent>
    </Dialog>
}