import React from "react";
import {
    Box, Divider
} from "@material-ui/core";

import useProfileInformations from "domain/hooks/useProfileInformations";
import ProfileInformationSection from "./ProfileInformationSection";
import ResetPasswordSection from "./ResetPasswordSection";
import ArchiveAccountSection from "./ArchiveAccountSection";

export default function ProfilePage() {

    const [profileLoading, profile] = useProfileInformations();

    return <>
        <ProfileInformationSection />

        <Box marginY={4}><Divider /></Box>

        <ResetPasswordSection email={profileLoading ? undefined : profile.email} />

        <Box marginY={4}><Divider /></Box>

        <ArchiveAccountSection email={profileLoading ? undefined : profile.email} />
    </>
}

