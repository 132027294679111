import React, { useCallback, useEffect, useRef, useState } from "react";

import Viewer, { ViewerProps } from "./Viewer";
import { OrientationType } from "domain/static/OrientationType";
import { useViewerContext } from "contexts/Viewer";

export interface Viewer1AxeProps {
    orientation: OrientationType;
    viewerClasses?: ViewerProps["classes"];
}

export default function Viewer1Axe({ orientation, viewerClasses }: Viewer1AxeProps) {

    const [dimensions, setDimensions] = useState([1, 1, 1]);
    const [slices, setSlices] = useState([0, 0, 0]);
    const [scenesProps, setScenesProps] = useState<[any, any, any]>([undefined, undefined, undefined]);

    const containerRef = useRef<HTMLDivElement>(null);

    const { data: viewerData, app: viewerApp } = useViewerContext();

    const onViewerSliceIndexChangeHandle = useCallback((orientation: OrientationType, index: number) => {
        setSlices(ss => {
            const idx = ss[orientation];
            if (idx !== index) {
                const newSlices = [...ss];
                newSlices[orientation] = index;
                return newSlices;
            }
            return ss;
        });
    }, []);

    const onSliderIndexChangeHandle = useCallback(async (orientation: OrientationType, index: number) => {
        const scene = viewerApp.getSceneByOrientation(orientation);
        if (scene) {
            await scene.setSliceIndex(index);
        }
    }, [viewerApp]);

    useEffect(() => {
        return () => {
            viewerApp.clear();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const _initializeScenes = async () => {
            if (!containerRef.current) return;

            await viewerApp.createScene(orientation, containerRef.current, onViewerSliceIndexChangeHandle);

            await viewerApp.setFusionType(viewerData.defaultFusionType);

            const dims = viewerApp.getImageDimensionsMax();
            setDimensions(dims);
            setSlices(dims.map(x => Math.floor(x / 2)));
        };

        const _updateMainOrientation = async () => {
            const scene = viewerApp.getSceneAt(0);
            const oldOrientation = scene.getOrientation();
            const oldSceneProps = await scene.getProps();

            const sceneProps = scenesProps[orientation];
            await scene.setOrientation(orientation);
            if (sceneProps) {
                await scene.setProps(sceneProps);
            }
            if (oldSceneProps) {
                setScenesProps(sp => {
                    sp[oldOrientation] = oldSceneProps;
                    return [...sp];
                })
            }
        }

        if (viewerData.numberOfImages > 0 && viewerApp.numberOfScenes === 0) {
            _initializeScenes();
        }
        else if (viewerApp.numberOfScenes === 1 && !viewerApp.getSceneAt(0).isOrientation(orientation)) {
            _updateMainOrientation();
        }
    }, [onViewerSliceIndexChangeHandle, orientation, scenesProps, viewerApp, viewerData]);

    return <Viewer
        classes={viewerClasses}
        ref={containerRef}
        orientation={orientation}
        value={slices[orientation]}
        dimension={dimensions[orientation]}
        onSliceChange={onSliderIndexChangeHandle}
    />
}
