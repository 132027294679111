import { IAuthService, IJwtStorage } from "cocoreact";
import JwtDeocde from "jwt-decode";
import RefreshAccessToken from "./RefreshAccessToken";
import { IAuthPayload } from "./IAuthPayload";

export default class AuthTokenService implements IAuthService<IAuthPayload> {
  private _jwtStorage: IJwtStorage;
  private _refreshToken: RefreshAccessToken;

  constructor(jwtStorage: IJwtStorage, refreshToken: RefreshAccessToken) {
    this._jwtStorage = jwtStorage;
    this._refreshToken = refreshToken;
  }

  getToken = async () => {
    let token = this._jwtStorage.get();
    await this._refreshToken.update(token, true);
    token = this._jwtStorage.get();
    return token;
  };

  setToken = (token: string | null) => {
    this._refreshToken.update(token);
    if (token === null) {
      this._jwtStorage.remove();
    } else {
      this._jwtStorage.set(token);
    }
  };

  getPayload(token: string) {
    const parts = JwtDeocde<IAuthPayload>(token);
    return parts;
  }
}
