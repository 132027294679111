import {
  JsonSerializer,
  DateConverter,
  ODataFilterConverter,
  ODataSelectConverter,
  ODataOrderByConverter,
} from "cocoreact";

export default class Serializer extends JsonSerializer {
  constructor() {
    super([
      new DateConverter(),
      new ODataFilterConverter(),
      new ODataSelectConverter(),
      new ODataOrderByConverter(),
    ]);
  }
}
