import React from "react";
import { Helmet } from "react-helmet-async";

import { PageLayout } from "components/Layouts";
import { Footer } from "components/Page";
import { useWindowEffectScrollTop } from "tools/useWindowEffectScrollTop";

import BannerSection from "./BannerSection";
import MissionSection from "./MissionSection";
import AtlasSection from "./AtlasSection";
import ContributeSection from "./ContributeSection";
import WorkshopSection from "./WorkshopSection";
import SupportSection from "./SupportSection";

export default function HomePage() {

    useWindowEffectScrollTop();

    return <PageLayout tab="home">

        <Helmet>
            <title>Dline - Contouring guidelines and training for radiation-oncologist</title>
            <meta
                name="description"
                content="Interactive clinical cases based on international contouring guidelines and easy to use contouring workshops service."
            />
        </Helmet>

        <BannerSection />

        <MissionSection />
        <AtlasSection />
        <ContributeSection />
        <WorkshopSection />
        <SupportSection />

        <Footer />

    </PageLayout>
}


