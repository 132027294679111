import React, { useCallback, useState } from "react";
import { Box, Collapse, createStyles, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { Guid } from "domain/static/Guid";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        border: `1px ${theme.palette.divider} solid`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
    }
}));

interface IArticle {
    id: Guid;
    title: string;
    authors: string;
    date: string;
    editor: string;
    page: string;
    doi: string;
    pmid: string;
    url: string;
}

export interface ArticleBibProps {
    article: IArticle;
    collapsable?: boolean;
}

export default function ArticleBib({ article, collapsable }: ArticleBibProps) {
    const classes = useStyles();

    const [collapsed, setCollapsed] = useState(collapsable === false ? true : false);

    const linkLabel = article.url
        ? "Access this article"
        : "Search on google scholar";

    const LinkHref = article.url
        ? article.url
        : `https://scholar.google.com/scholar?q=${encodeURI(article.title)}`;

    const onClickHandle = useCallback(() => {
        if (collapsable !== false) {
            setCollapsed(!collapsed)
        }
    }, [collapsable, collapsed]);

    return <Box className={classes.root} onClick={onClickHandle} style={{ cursor: collapsable === false ? "auto" : "pointer" }}>
        <Typography variant="body1" component="p">
            {article.title}
        </Typography>

        <Collapse in={collapsed}>
            <p></p>

            <Typography variant="body2" component="p">
                {article.authors}
            </Typography>

            <Typography variant="body2" component="p">
                {article.editor}
                {article.date ? `, ${article.date}` : null}
                {article.page ? `, ${article.page}` : null}
            </Typography>

            {article.doi &&
                <Typography variant="caption" component="p">DOI : {article.doi}</Typography>
            }
            {article.pmid &&
                <Typography variant="caption" component="p">PMID : {article.pmid}</Typography>
            }

            <p></p>

            <Link
                href={LinkHref}
                color="primary"
                target="_blank"
                rel="noreferrer"
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    e.stopPropagation();
                }}
            >
                {linkLabel}
            </Link>

        </Collapse>
    </Box>
}