import { Url } from "cocoreact";

const routesConfigurations = {
  Home: new Url("", "/"),
  TermsOfService: new Url("", "/terms-of-service"),
  PrivacyPolicy: new Url("", "/privacy-policy"),
  CookiesSettings: new Url("", "/cookies-settings"),
  ContactUs: new Url("", "/contact-us"),
  AboutUs: new Url("", "/about-us"),

  SignIn: new Url("", "/login"),
  ResetPassword: new Url("", "/reset-password"),

  ListClinicalCases: new Url("", "/atlas"),
  ShowClinicalCase: new Url("", "/atlas/:slug"),

  Workshops: new Url("", "/workshops"),

  PublicReviewClinicalCase: new Url("", "/review/:id"),

  PublicUserInformations: new Url("", "/users/:id/:slug"),

  Profile: new Url("", "/profile"),
  ProfileListClinicalCases: new Url("", "/profile/atlas"),
  ProfileShowClinicalCase: new Url("", "/profile/atlas/:slug"),
  ProfileListContouringWorkshops: new Url("", "/profile/contouring-workshops"),
  ProfileShowContouringWorkshop: new Url("", "/profile/contouring-workshops/:slug"),
};

// settings file
declare var APP_WEBSITE_NAME: string;

export type RouteName = keyof typeof routesConfigurations;

export interface RouteOptions {
  params?: Record<string, string>;
  queryString?: Record<string, string>;
  hash?: string;
  baseUrl?: boolean;
  cleanEndSlash?: boolean;
}

export function fullPath(name: RouteName, options?: RouteOptions) {
  const url = routesConfigurations[name].clone();

  if (options?.params) {
    url.setParameters(options.params);
  }

  let path = url.fullPath();

  if (options?.baseUrl === true) {
    path = APP_WEBSITE_NAME + path;
  }

  if (options?.cleanEndSlash === true) {
    if (path[path.length - 1] === "/") {
      path = path.slice(0, -1);
    }
  }

  if (options?.queryString) {
    let qs = "";
    for (const [key, value] of Object.entries(options.queryString)) {
      if (qs.length > 0) {
        qs = qs + "&";
      }
      qs = qs + encodeURI(key) + "=" + encodeURI(value);
    }
    path = path + "?" + qs;
  }

  if (options?.hash) {
    const hash = `#${options.hash}`.replace("##", "#");
    path = path + hash;
  }

  return path;
}
