import React from "react";
import {
    alpha, Box, CircularProgress, createStyles, Icon, LinearProgress, makeStyles,
    Theme, Tooltip
} from "@material-ui/core";
import {
    BrushOutlined as EditIcon,
    LockOutlined as LockIcon
} from "@material-ui/icons";

import { DrawCreateIcon, DrawEditIcon } from "App/Theme"

import { percent2color } from "tools/ColorExtension";

export function LockIndicator() {
    return <Tooltip
        arrow
        placement="left"
        title="∅ Content not editable !"
        aria-label={"∅ Content not editable !"}
    >
        <LockIcon />
    </Tooltip>
}

export function EditableIndicator() {
    return <Tooltip
        arrow
        placement="left"
        title="√ You can edit this contour"
        aria-label={"√ You can edit this contour"}
    >
        <EditIcon />
    </Tooltip>
}

export function DrawCreateIndicator() {
    return <Tooltip
        arrow
        placement="left"
        title="√ Start drawing this contour"
        aria-label={"√ Start drawing this contour"}
    >
        <Icon>
            <DrawCreateIcon />
        </Icon>
    </Tooltip>
}

export function DrawEditIndicator() {
    return <Tooltip
        arrow
        placement="left"
        title="√ Edit outline of this contour"
        aria-label={"√ Edit outline of this contour"}
    >
        <Icon>
            <DrawEditIcon />
        </Icon>
    </Tooltip>
}

// --------------------------------------------------------

const useLinearIndicatorStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: 12,
        borderRadius: 4,
    },
    colorPrimary: {
    },
    bar: {
        borderRadius: 4,
        backgroundColor: alpha(theme.palette.common.black, 0.3)
    },
}));

export interface LinearIndicatorProps {
    value: number;
    className?: string;
}

export function LinearIndicator({ value }: LinearIndicatorProps) {
    const classes = useLinearIndicatorStyles();
    const value100 = Math.round(value * 100);

    return <LinearProgress
        title={`${value}`}
        variant="determinate"
        color="primary"
        value={value100}
        style={{ backgroundColor: percent2color(value) }}
        classes={{
            root: classes.root,
            colorPrimary: classes.colorPrimary,
            bar: classes.bar,
        }}
    />
}

// --------------------------------------------------------

const useCircularIndicatorStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        position: "relative",
        display: "inline-flex",
    },
    labelWrapper: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.text.secondary,
    },
    bottom: {
        color: theme.palette.grey[200],
    },
    top: {
        position: 'absolute',
        left: 0,
    },
}));

export interface CircularIndicatorProps {
    value: number;
    size?: number;
    thickness?: number;
    disabled?: boolean;
}

export function CircularIndicator({ value, disabled, ...props }: CircularIndicatorProps) {
    const classes = useCircularIndicatorStyles();
    const value100 = Math.round(value * 100);
    const size = props.size ?? 40;
    const thickness = props.thickness ?? 6;

    return <Box className={classes.container} title={`${value}`}>
        <CircularProgress
            variant="determinate"
            className={classes.bottom}
            thickness={thickness}
            size={size}
            value={100}
            style={{
                opacity: disabled ? 0.3 : undefined,
            }}
        />
        <CircularProgress
            variant="determinate"
            className={classes.top}
            thickness={thickness}
            size={size}
            value={value100}
            style={{
                color: percent2color(value),
                opacity: disabled ? 0.3 : undefined,
            }}
        />
    </Box>
}

