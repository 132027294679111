import React from "react";
import { Helmet } from "react-helmet-async";
import { Box, CircularProgress, createStyles, Grid, Hidden, makeStyles, Theme, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { Footer, Markdown } from "components/Page";
import { useWindowEffectScrollTop } from "tools/useWindowEffectScrollTop";
import useUserPublicInformations from "domain/hooks/useUserPublicInformations";
import { stripMarkdown } from "tools/StringExtension";
import { AccountCircleRounded } from "@material-ui/icons";
import { formatLongDate } from "tools/DateExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    layout: {
        margin: theme.spacing(6, 2),
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            margin: theme.spacing(6, "auto"),
        }
    },
    loader: {
        margin: theme.spacing(4, "auto"),
        textAlign: "center"
    },
    picto: {
        color: theme.palette.text.hint,
        width: 80,
        height: "auto",
        [theme.breakpoints.up("sm")]: {
            width: "100%",
        }
    },
    separator: {
        margin: theme.spacing(2, 0),
    },
    link: {
        color: theme.palette.primary.main,
        "&:hover": {
            textDecoration: "underline",
        }
    }
}));

export default function UserPublicInformationsPage() {

    const classes = useStyles();
    const params = useParams<{ id: string, slug: string }>();

    const [entityLoading, entity,] = useUserPublicInformations(params.id);

    useWindowEffectScrollTop();

    return <PageLayout tab={undefined}>

        <Helmet>
            <title>{entityLoading ? "Loading user profile ..." : `Profile of ${entity.firstname} ${entity.lastname}`} - Dline</title>
            <meta
                name="description"
                content={entityLoading ? "loading ..." : `${entity.firstname} ${entity.lastname} profile, ${entity.status} : ${entity.abstract ? stripMarkdown(entity.abstract) : ""}`}
            />
        </Helmet>

        <CentralColumnLayout size="md" className={classes.layout}>

            {entityLoading === true &&
                <Box className={classes.loader}>
                    <CircularProgress size={64} color="primary" />
                </Box>
            }

            {entityLoading === false &&
                <Grid container direction="row" spacing={4}>
                    <Hidden xsDown>
                        <Grid item md={2} sm={2} xs={false}>
                            <AccountCircleRounded className={classes.picto} />
                        </Grid>
                    </Hidden>
                    <Grid item md={10} sm={10} xs={12}>
                        <Grid container direction="column">
                            <Typography variant="h4" component="h1">
                                {entity.firstname} {entity.lastname}
                            </Typography>
                            <Typography variant="h6" component="h3" color="textSecondary">
                                {entity.status ?? "No status available"}
                            </Typography>

                            <Box className={classes.separator}>
                                {entity.abstract
                                    ?
                                    <Markdown className={classes.separator}>
                                        {entity.abstract}
                                    </Markdown>
                                    :
                                    <Typography>
                                        No informations about this person.
                                    </Typography>
                                    }
                            </Box>

                            {entity.professionalLink &&
                                <a href={entity.professionalLink} target="_blank" rel="noreferrer">
                                    <Typography component="span" variant="subtitle2" className={classes.link}>
                                        {entity.professionalLink}
                                    </Typography>
                                </a>
                            }
                            {entity.scientificLink &&
                                <a href={entity.scientificLink} target="_blank" rel="noreferrer">
                                    <Typography component="span" variant="subtitle2" className={classes.link}>
                                        {entity.scientificLink}
                                    </Typography>
                                </a>
                            }

                            <Typography component="p" variant="caption" className={classes.separator}>
                                Registered since {formatLongDate(entity.createdAt)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }

        </CentralColumnLayout>


        <Footer />

    </PageLayout >
}


