import React from "react";

import {
  Dialog, DialogContent, DialogTitle,
  DialogActions, DialogContentText,
  Button
} from "@material-ui/core";
import { ErrorComponentProps } from "cocoreact";

// settings file
declare var APP_CONTACT_EMAIL: string;

export default function FatalErrorDialog(_props: ErrorComponentProps) {

  const contactEmail = APP_CONTACT_EMAIL;

  const refreshPage = () => {
    document.location.reload();
  };

  return (
    <Dialog open={true}>

      <DialogTitle>
        Une erreur s'est produite :(
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Il semblerait qu'une erreur se soit produite : pas de panique.
          Cela peut être dû à un problème réseau ou une maintenance du serveur.
          Veuillez ré-essayer de recharger la page.
        </DialogContentText>
        <DialogContentText>
          Si l'erreur persiste, merci de contacter l'administrateur du site
        via <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={refreshPage}
        >
          Recharger la page
          </Button>
      </DialogActions>

    </Dialog>
  )
}
