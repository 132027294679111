import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class UpdateClinicalCaseContoursCommand implements IMessage {
    id!: Guid;
    contours!: Contour[];

    public constructor(init?: Partial<UpdateClinicalCaseContoursCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/profile/clinical-cases/${this.id.toString()}/contours`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            contours: this.contours,
        };
    }
    
    needAuthentication = () => true;
}

export class Contour {
    id!: Guid;
    color!: string;
    fileId!: Guid | null;
}
