import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async';

import { useWindowEffectScrollTop } from 'tools/useWindowEffectScrollTop';
import { PageLayout } from 'components/Layouts';
import { Footer } from 'components/Page';

import BannerSection from './BannerSection';
import ServiceSection from './ServiceSection';
import PresentationSection from './PresentationSection';
import TimelineSection from './TimelineSection';
import RequestQuoteDialog from './RequestQuoteDialog';

export default function HomePage() {

  useWindowEffectScrollTop();

  const [dialogOpen, setDialogOpen] = useState(false);

  return <PageLayout tab="workshops">

    <Helmet>
      <title>Dline - Delineation workshops</title>
      <meta
        name="description"
        content="A new way to organize online private workshops for training your collaborators (students, partners, ...) to perform contours according guidelines."
      />
    </Helmet>

    <BannerSection onRequestQuote={() => setDialogOpen(true)} />
    <ServiceSection />
    <PresentationSection onRequestQuote={() => setDialogOpen(true)} />
    <TimelineSection onRequestQuote={() => setDialogOpen(true)} />

    <Footer />

    <RequestQuoteDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />

  </PageLayout>
}
