import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class AuthenticateUserCommand implements IMessage {
    email!: string;
    password!: string;

    public constructor(init?: Partial<AuthenticateUserCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST" as RequestMethod;

    getPath = () => `/users/access-token`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            email: this.email,
            password: this.password,
        };
    }
    
    needAuthentication = () => false;
}
