import React from "react";
import {
    createStyles, makeStyles, Theme,
    Menu, MenuItem, useTheme
} from "@material-ui/core";

import ColorPicker, { ColorPickerProps } from "./ColorPicker";

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
        marginLeft: -5,
        overflow: "visible", // triangle
        backgroundColor: "transparent"
    },
    menu: {
        padding: 0,
        backgroundColor: theme.palette.background.default,
    },
    menuItem: {
        padding: 0,
        overflow: "visible", // triangle
        "&:hover": {
            backgroundColor: "transparent"
        }
    }
}));

export interface ColorPickerPopupProps {
    anchorEl: HTMLElement | null;
    color: string;
    onChange: ColorPickerProps["onChange"];
    onClose: () => void;
}

export default function ColorPickerPopup({ anchorEl, color, onChange, onClose }: ColorPickerPopupProps) {
    const classes = useStyles();
    const theme = useTheme();

    return <Menu
        keepMounted
        id="color-picker-popup"
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
        }}
        classes={{
            paper: classes.paper,
            list: classes.menu,
        }}
    >
        <MenuItem className={classes.menuItem}>
            <ColorPicker
                color={color}
                onChange={onChange}
                triangle="top-right"
                styles={{
                    default: {
                        card: {
                            maxWidth: theme.mixins.viewer.smallWidth - 48,
                        },
                        triangle: {
                            borderColor: `transparent transparent ${theme.palette.background.default}`
                        }
                    }
                }}
            />
        </MenuItem>
    </Menu>
}