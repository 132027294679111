import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class ArchiveProfileCommand implements IMessage {

    public constructor(init?: Partial<ArchiveProfileCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PATCH" as RequestMethod;

    getPath = () => `/profile/archive`;

    getQueryString = () => undefined;

    getBody = () => undefined;
    needAuthentication = () => true;
}
