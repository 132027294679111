import React from "react";
import { Link } from "react-router-dom";
import {
    Box, Chip, createStyles, Hidden, lighten, makeStyles, Theme, Typography
} from "@material-ui/core";
import clsx from "clsx";

import useFileQueryPath from "domain/hooks/useFileQueryPath";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { stripMarkdown } from "tools/StringExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        boxShadow: theme.shadows[6],
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        overflow: "hidden",
    },
    cover: {
        minHeight: 120,
        width: 180,
        minWidth: 180,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
    },
    column: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
        width: "100%",
    },
    chips_wrapper: {
        marginBottom: theme.spacing(1),
        "& > *": {
            marginLeft: theme.spacing(1),
        },
        "& > :first-child": {
            marginLeft: 0,
        }
    },
    title: {
        color: lighten(theme.palette.text.primary, 0.3),
        lineHeight: "1.2em",
        overflow: "hidden",
        display: "-webkit-box",
        alignItems: "center",
        lineClamp: 2,
        boxOrient: "vertical",
        maxHeight: "2.4em",
    },
    subtitle: {
        color: theme.palette.text.secondary,
    },
    content: {
        margin: theme.spacing(1, 0, 2, 0),
        color: lighten(theme.palette.text.primary, 0.3),
        lineHeight: "1.4em",
        overflow: "hidden",
        display: "-webkit-box",
        lineClamp: 3,
        boxOrient: "vertical",
        maxHeight: "4em",
    },
}));

export interface ListItemProps {
    href: string;
    title: string;
    subtitle: string;
    content: string;
    markers?: string[];
    coverId?: Guid;
    chips?: React.ReactNode | React.ReactNode[];
    className?: string;
}

export default function ListItem({ href, title, subtitle, content, markers, coverId, chips, className }: ListItemProps) {
    const classes = useStyles();
    const coverPath = useFileQueryPath(coverId ?? EmptyGuid());
    const coverUrl = coverPath ?? `${process.env.PUBLIC_URL}/cover_default.png`;

    return <Link to={href} title={title}>
        <Box className={clsx(classes.root, className)}>

            <Hidden xsDown>
                <Box className={classes.cover} style={{ backgroundImage: `url(${coverUrl})` }} />
            </Hidden>

            <Box className={classes.column}>

                <Box className={classes.chips_wrapper}>
                    {markers && markers.map((x, idx) =>
                        <Chip key={idx} label={x} size="small" color="secondary" />
                    )}
                    {chips}
                </Box>

                <Typography className={classes.title} component="h5" variant="h6">
                    {title}
                </Typography>

                <Typography className={classes.subtitle} component="span" variant="caption">
                    {subtitle}
                </Typography>

                <Typography className={classes.content} variant="body2" component="p">
                    {stripMarkdown(content)}
                </Typography>
            </Box>

        </Box>
    </Link>
}
