import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { PageLayout } from "components/Layouts";
import { MobileNotSupported, OnDesktop, OnMobile, Spacer } from "components/Page";
import usePublicReviewerClinicalCase from "domain/hooks/usePublicReviewerClinicalCase";
import { PublicReviewClinicalCaseCommand } from "domain/public/command/PublicReviewClinicalCaseCommand";
import { PageNotFoundError } from "pages/Errors";
import { ViewerContextProvider } from "contexts/Viewer";
import { useSearchParams } from "tools/useSearchParams";
import ViewerContainer from "pages/ClinicalCases/Viewer/ViewerContainer";
import TokenInvalid from "./TokenInvalid";
import { ReviewerToolbar } from "components/ClinicalCase";
import { sendMessage } from "tools/Message";

export default function ReviewClinicalCasePage() {
    const searchParams = useSearchParams<{ token: string }>();
    const params = useParams<{ id: string }>();

    const id = useMemo(() => params.id, [params.id]);
    const token = useMemo(() => searchParams.token, [searchParams.token]);

    const [entityLoading, entity, entityError] = usePublicReviewerClinicalCase(id, token);
    const [tokenInvalid, setTokenInvalid] = useState(false);

    useEffect(() => {
        const response = entityError?.response;
        if (!response) return;

        if (response.status === 498) {
            setTokenInvalid(true);
        }
        else {
            throw new PageNotFoundError();
        }
    }, [entityError]);

    const onReviewHandle = useCallback(async (accepted: boolean, comment: string) => {
        const message = new PublicReviewClinicalCaseCommand({ id });
        message.accepted = accepted;
        message.comment = comment;
        message.token = token;

        await sendMessage(message);
    }, [id, token]);

    return <PageLayout tab={undefined}>

        <Helmet>
            <title>{entityLoading ? "Loading clinical case ..." : `Review ${entity.name}`} - Dline</title>
        </Helmet>

        <OnMobile>
            <MobileNotSupported />
        </OnMobile>

        <OnDesktop>
            {tokenInvalid && <TokenInvalid />}

            {!tokenInvalid &&
                <ViewerContextProvider>
                    <ViewerContainer loading={entityLoading} entity={entity}>
                        <Spacer />
                        <ReviewerToolbar entity={entity} onReview={onReviewHandle} />
                    </ViewerContainer>
                </ViewerContextProvider>
            }
        </OnDesktop>

    </PageLayout >
}
