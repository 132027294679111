import { AppDerivation, App } from "dline-viewer/dist/app";
import { SceneAxisIndexEntity } from "dline-viewer/dist/app/Entity/Scene/SceneAxisIndexEntity";
import { SceneSliceIndexEntity } from "dline-viewer/dist/app/Entity/Scene/SceneSliceIndexEntity";
import { SceneEntity } from "./SceneHelper";

export class AppViewerDerivation extends AppDerivation {
  _app: App | null;

  constructor() {
    super();
    this._app = null;
    this._onResize = this._onResize.bind(this);
  }

  Configure(app: App) {
    super.Configure(app);
    this.ConfigureSceneIndexDerivation(app);

    this._app = app;
    this._initializeListeners();
  }

  clear() {
    this._clearListeners();
  }

  private _initializeListeners() {
    window.addEventListener("resize", this._onResize);
  }

  private _clearListeners() {
    window.removeEventListener("resize", this._onResize);
  }

  _onResize() {
    if (!this._app) return;

    const scenes = this._app.db.GetAll(SceneEntity);
    this._app.derivation.UpdateAsync(...scenes);
  }

  ConfigureSceneIndexDerivation(app: App) {
    app.derivation.UpdateRules.Add3("SceneSliceIndexCallback", {
      tupleFunc: () =>
        app.db.Select111(
          SceneEntity,
          SceneAxisIndexEntity,
          SceneSliceIndexEntity,
          (x, y, z) => x.id === y.idScene && x.id === z.idScene
        ),
      updateFunc: (sceneEntity, axisIndexEntity, sliceIndexEntity) => {
        sceneEntity.onIndexChange &&
          sceneEntity.onIndexChange(
            axisIndexEntity.axisIndex,
            sliceIndexEntity.sliceIndex
          );
      },
    });
  }
}
