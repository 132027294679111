import { useCallback, useEffect, useState } from "react";
import { sendMessage } from "tools/Message";
import { IdNameResponse } from "domain/public/response/IdNameResponse";
import { ListAnatomicZonesQuery } from "domain/public/query/ListAnatomicZonesQuery";
import { serializer } from "services";

const KEY = "app__anatomic_zones";

export default function useListAnatomicZones(): [boolean, IdNameResponse[]] {
  const [state, setState] = useState({
    loading: true,
    results: [] as IdNameResponse[],
  });

  const updateData = useCallback(async () => {
    try {
      const query = new ListAnatomicZonesQuery();
      const anatomicZones = await sendMessage<IdNameResponse[]>(query);

      if (anatomicZones.length > 0) {
        const stringified = serializer.serialize(anatomicZones) as string;
        sessionStorage.setItem(KEY, stringified);
      }

      setState({
        loading: false,
        results: anatomicZones,
      });
    } catch (e) {
      setState({
        loading: false,
        results: [],
      });
    }
  }, []);

  useEffect(() => {
    const persistent = sessionStorage.getItem(KEY);
    if (persistent) {
      const anatomicZones = serializer.deserialize<IdNameResponse[]>(
        persistent
      );
      setState({
        loading: false,
        results: anatomicZones,
      });
    } else {
      updateData();
    }
  }, [updateData]);

  return [state.loading, state.results];
}
