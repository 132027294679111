import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress, createStyles, alpha, makeStyles, Theme, useTheme } from "@material-ui/core";

import { OrientationType } from "domain/static/OrientationType";
import { IsEmptyGuid } from "domain/static/Guid";
import { ContouringWorkshopViewerResponse } from "domain/public/response/ContouringWorkshopViewerResponse";
import { PacmanLoader } from "components/Page";
import { loadItkImage } from "tools/ImageExtension";
import { createReadOnlyRoi, loadReadOnlyRoi, readOnlyRoiToEditableVoxelVolume } from "tools/ContourExtension";
import { ImageData, ContourData, useViewerContext, ViewerData } from "contexts/Viewer";
import {
    ControlsInformations, DrawingTools,
    DESKTOP_CONTROLS, DRAWING_CONTROLS,
    ImagesSettings, OrientationSelect,
    Viewer3Axes, MPRButton, CornerZone,
    ImageOverlaySelect, ImagePrimarySelect, ImageInformations
} from "components/Viewer";
import Drawer from "./Drawer";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        width: "100%",
        height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
        backgroundColor: theme.palette.grey[300],
        overflow: "hidden",
        position: "relative",
    },
    loader: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.grey[900],
    },
    viewerLoader: {
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: alpha(theme.palette.grey[600], 0.5),
        zIndex: theme.zIndex.drawer + 100,
    },
}));


async function buildViewerData(
    entity: ContouringWorkshopViewerResponse,
    setLoaderLabel: (label: string) => void
) {
    const data = new ViewerData();
    const globalToLoad = entity.globalContentLength;
    let globalLoaded = 0;

    const nbImage = entity.images.length;
    for (let idx = 0; idx < nbImage; idx++) {
        const image = entity.images[idx];

        const img = new ImageData();
        img.id = image.id;
        img.windowing = image.windowing;
        img.lut = image.lut;
        img.default = image.default;

        img.data = await loadItkImage(
            image.fileId,
            // eslint-disable-next-line no-loop-func
            (loaded, _) => setLoaderLabel(
                `image${nbImage > 1 ? "s" : ""} ${idx + 1}/${nbImage} (${Math.round((globalLoaded + loaded) / globalToLoad * 100)}%)`
            )
        );

        globalLoaded += image.fileContentLength;

        data.images.push(img);
    }

    const grid = data.images[0].data.grid;

    const nbContour = entity.contours.length;
    for (let idx = 0; idx < nbContour; idx++) {
        const contour = entity.contours[idx];

        const c = new ContourData();
        c.id = contour.id;
        c.color = contour.color;
        c.visible = true;
        c.drawing = false;

        const referenceReadonlyRoi = await loadReadOnlyRoi(contour.fileId, contour.id, contour.color,
            // eslint-disable-next-line no-loop-func
            (loaded, _) => setLoaderLabel(
                `contour${nbContour > 1 ? "s" : ""} ${idx + 1}/${nbContour} (${Math.round((globalLoaded + loaded) / globalToLoad * 100)}%)`
            )
        );

        if (contour.isEditable !== true) {
            c.data = referenceReadonlyRoi;

            globalLoaded += contour.fileContentLength;
    
            data.contours.push(c);

            continue;
        }

        c.referenceData = readOnlyRoiToEditableVoxelVolume(referenceReadonlyRoi, grid);
        c.referenceData.id = c.id;

        globalLoaded += contour.fileContentLength;

        if (contour.participantResults.length > 0) {
            c.data = await loadReadOnlyRoi(contour.participantResults[0].fileId, contour.id, contour.color,
                // eslint-disable-next-line no-loop-func
                (loaded, _) => setLoaderLabel(
                    `contour${nbContour > 1 ? "s" : ""} ${idx + 1}/${nbContour} (${Math.round((globalLoaded + loaded) / globalToLoad * 100)}%)`
                )
            );
        }
        else {
            c.data = createReadOnlyRoi(contour.id, contour.color, grid);
            setLoaderLabel(
                `contour${nbContour > 1 ? "s" : ""} ${idx + 1}/${nbContour} (${Math.round((globalLoaded) / globalToLoad * 100)}%)`
            );
        }

        globalLoaded += contour.participantsContentLength;

        data.contours.push(c);
    }

    return data;
}

export interface ViewerContainerProps {
    entity: ContouringWorkshopViewerResponse;
}

export default function ViewerContainer({ entity }: ViewerContainerProps) {
    const classes = useStyles();
    const theme = useTheme();

    const {
        app: viewerApp,
        data: viewerData,
        dispatcher: viewerDispatcher
    } = useViewerContext();

    const [loaderState, setLoaderState] = useState({
        loading: true,
        label: undefined as string | undefined,
    });
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [orientation, setOrientation] = useState(OrientationType.Axial);

    useEffect(() => {
        const _downloadAndInitialize = async () => {
            if (entity.images.length === 0) {
                setLoaderState({
                    loading: false,
                    label: "error to load this scene"
                });
                return;
            }
            setLoaderState({
                loading: true,
                label: "loading data ..."
            });

            const data = await buildViewerData(entity, (label: string) => {
                setLoaderState({ loading: true, label });
            });

            viewerDispatcher({
                type: "INITIALIZE",
                data: data,
            }, "viewer");
        };
        if (!IsEmptyGuid(entity.id)
            && viewerData.numberOfImages === 0) {
            _downloadAndInitialize();
        }
    }, [entity, viewerData, viewerDispatcher]);

    useEffect(() => {
        if (viewerData.numberOfImages) {
            setLoaderState({
                loading: false,
                label: undefined,
            });
        }
    }, [viewerData.numberOfImages]);

    const onOrientationChangeHandle = useCallback(async (newOrientation: OrientationType) => {
        setOrientation(newOrientation);
        await viewerApp.getSceneAt(0).setOrientation(newOrientation);
    }, [viewerApp]);

    let rightShift = 0;
    rightShift += viewerData.getContourDrawing() ? theme.spacing(2) : -theme.spacing(8);
    rightShift += drawerOpen ? theme.mixins.viewer.smallWidth : 0;
    const rightZoneStyle = { right: rightShift } as React.CSSProperties;

    return <Box className={classes.container}>

        {loaderState.loading === true &&
            <Box className={classes.loader}>
                <PacmanLoader label={loaderState.label} />
            </Box>
        }

        {viewerData.loading === true &&
            <Box className={classes.viewerLoader}>
                <CircularProgress size={64} color="secondary" />
            </Box>
        }

        {loaderState.loading === false && <>
            <Viewer3Axes orientation={orientation} />

            <CornerZone placement="top-left">
                <OrientationSelect value={orientation} onChange={onOrientationChangeHandle} />
                <ImagePrimarySelect images={entity.images} />
                <ImageOverlaySelect images={entity.images} />
                <ImageInformations image={entity.images[0]} />
            </CornerZone>

            <CornerZone placement="top-right">
                <ImagesSettings images={entity.images} />
                <MPRButton
                    mode={drawerOpen ? "one" : "three"}
                    onClick={() => setDrawerOpen(!drawerOpen)}
                />
            </CornerZone>

            <CornerZone placement="bottom-left">
                <ControlsInformations controls={[...DESKTOP_CONTROLS, ...DRAWING_CONTROLS]} />
            </CornerZone>

            <CornerZone placement="middle-right" style={rightZoneStyle}>
                <DrawingTools contours={entity.contours} />
            </CornerZone>

            <Drawer contouringWorkshop={entity} open={drawerOpen} />
        </>}

    </Box>
}
