import { useCallback, useEffect, useState } from "react";
import { Guid, IsValidGuid } from "domain/static/Guid";
import { ProfileClinicalCaseItemResponse } from "domain/public/response/ProfileClinicalCaseItemResponse";
import { ListProfileClinicalCasesQuery } from "domain/public/query/ListProfileClinicalCasesQuery";
import { sendMessage } from "tools/Message";

export default function useListProfileClinicalCases(
  userId: Guid
): [boolean, ProfileClinicalCaseItemResponse[]] {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<ProfileClinicalCaseItemResponse[]>([]);

  const _updateItems = useCallback(async () => {
    setLoading(true);
    let _items = [] as ProfileClinicalCaseItemResponse[];
    if (IsValidGuid(userId)) {
      const query = new ListProfileClinicalCasesQuery();
      _items = await sendMessage<ProfileClinicalCaseItemResponse[]>(query);
    }
    setItems(_items);
    setLoading(false);
  }, [userId]);

  useEffect(() => {
    _updateItems();
  }, [_updateItems]);

  return [loading, items];
}
