import React from "react";
import {
    People as EditorIcon,
    AccountCircle as ReviewerIcon
} from "@material-ui/icons";

import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { ClinicalCaseViewerResponse } from "domain/public/response/ClinicalCaseViewerResponse";
import { PersonList, SummaryPanel as BaseSummaryPanel } from "components/ClinicalData";

export interface SummaryPanelProps {
    clinicalCase: ClinicalCaseViewerResponse;
}

export default function SummaryPanel({ clinicalCase }: SummaryPanelProps) {

    const { editors, articles, reviewers, reviewAccepted, summary, state, publishedAt } = clinicalCase;

    const isEditors = editors && editors.length > 0;
    const isReviewers = reviewers && reviewers.length > 0 && reviewAccepted === true;

    return <BaseSummaryPanel
        summary={summary}
        articles={articles}
        publishedAt={state === ClinicalCaseState.Published && publishedAt ? publishedAt : undefined}
        metas={<>
            {isEditors &&
                <PersonList
                    label="Edited by"
                    icon={<EditorIcon fontSize="small" />}
                >
                    {editors}
                </PersonList>
            }

            <PersonList
                label={isReviewers ? "Reviewed by" : "Content no reviewed"}
                icon={<ReviewerIcon fontSize="small" />}
            >
                {isReviewers ? reviewers : []}
            </PersonList>
        </>
        }
    />
}