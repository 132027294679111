import React, { useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button } from "@material-ui/core";

import useProfileClinicalCaseViewer from "domain/hooks/useProfileClinicalCaseViewer";
import { ClinicalCaseUserRole } from "domain/static/ClinicalCaseUserRole";
import { PageLayout } from "components/Layouts";
import { MobileNotSupported, OnDesktop, OnMobile } from "components/Page";
import { ViewerContextProvider } from "contexts/Viewer";
import { PageNotFoundError } from "pages/Errors";
import { KeyboardArrowLeftIcon } from "App/Theme";
import { useWindowEffectScrollTop } from "tools/useWindowEffectScrollTop";
import { fullPath } from "tools/Route";
import EditorViewerContainer from "./Edit/ViewerContainer";
import ReviewerViewerContainer from "./Review/ViewerContainer";

export default function ShowClinicalCasePage() {
    useWindowEffectScrollTop();

    const params = useParams<{ slug: string }>();
    const slug = useMemo(() => params.slug, [params.slug]);
    const [entityLoading, entity, entityError] = useProfileClinicalCaseViewer(slug);

    useEffect(() => {
        if (entityError) {
            throw new PageNotFoundError();
        }
    }, [entityError]);

    return <>
        <PageLayout tab={undefined}>

            <Helmet>
                <title>{entityLoading ? "Loading clinical case ..." : entity.name} - Dline</title>
            </Helmet>

            <OnMobile>
                <MobileNotSupported>
                    <Link to={fullPath("ProfileListClinicalCases")}>
                        <Button
                            color="inherit"
                            variant="outlined"
                            startIcon={<KeyboardArrowLeftIcon />}
                        >
                            back to profile
                        </Button>
                    </Link>
                </MobileNotSupported>
            </OnMobile>

            <OnDesktop>
                {entityLoading === false &&
                    <ViewerContextProvider>
                        {entity.userRole === ClinicalCaseUserRole.Editor &&
                            <EditorViewerContainer entity={entity} />
                        }

                        {entity.userRole === ClinicalCaseUserRole.Reviewer &&
                            <ReviewerViewerContainer entity={entity} />
                        }
                    </ViewerContextProvider>
                }
            </OnDesktop>

        </PageLayout >
    </>
}

