import React, { useState } from "react";
import {
    makeStyles, Theme, createStyles, lighten,
    Dialog,
    Box,
} from "@material-ui/core";
import SearchInput from "./SearchInput";
import { ClinicalCaseCardResponse } from "domain/public/response/ClinicalCaseCardResponse";
import SearchResults from "./SearchResults";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    paper: {
        margin: 0,
        width: "100%",
        maxWidth: "100%",
        borderRadius: 0,
        maxHeight: "none",
    },
    inputRoot: {
        backgroundColor: lighten(theme.palette.primary.main, 0.8),
    },
    resultsWrapper: {
        overflowY: "auto",
    }
}));

export interface SearchMobileDialogProps {
    open: boolean;
    loading: boolean;
    results: ClinicalCaseCardResponse[];
    onClose: () => void;
    onChange: (keywords: string) => void;
}

export default function SearchMobileDialog({ open, loading, results, onClose, onChange }: SearchMobileDialogProps) {
    const classes = useStyles();

    const [keywords, setKeywords] = useState("");

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            classes={{
                container: classes.container,
                paper: classes.paper,
            }}
        >
            <SearchInput
                onChange={(k: string) => {
                    onChange(k);
                    setKeywords(k);
                }}
                onClose={onClose}
                loading={loading}
                classes={{
                    root: classes.inputRoot,
                }}
            />

            <Box className={classes.resultsWrapper}>
                <SearchResults 
                    loading={loading}
                    keywords={keywords}
                    clinicalCases={results} 
                />
            </Box>
            
        </Dialog>
    )
}
