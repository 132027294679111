import { App } from "dline-viewer/dist/app";
import { AppSegmentationBrushEntity } from "dline-viewer/dist/app/Entity/App/AppSegmentationBrushEntity";
import { AppSegmentationModeEntity } from "dline-viewer/dist/app/Entity/App/AppSegmentationModeEntity";
import { SceneDrawInteractorRenderedSegmentsEntity } from "dline-viewer/dist/app/Entity/MouseInteractors/SceneDrawInteractorRenderedSegmentsEntity";
import { AppIdSelectedRoiEntity } from "dline-viewer/dist/app/Entity/Roi/AppIdSelectedRoiEntity";

import ContourHelper from "./ContourHelper";

export default class DrawingHelper {
  private _app: App;

  private _contour: ContourHelper | null;

  private _entities: {
    selectedRoi: AppIdSelectedRoiEntity;
    mode: AppSegmentationModeEntity;
    brush: AppSegmentationBrushEntity;
  };

  constructor(app: App) {
    this._app = app;

    this._contour = null;
    this._entities = {} as any;
    this._initializeEntities();
  }

  private _initializeEntities() {
    const selectedRoi = this._app.db.First(AppIdSelectedRoiEntity);
    const mode = this._app.db.First(AppSegmentationModeEntity);
    const brush = this._app.db.First(AppSegmentationBrushEntity);

    this._entities = { selectedRoi, mode, brush };
  }

  async setErase(isErase: boolean) {
    const { brush } = this._entities;

    brush.isErase = isErase;
    await this._app.derivation.UpdateAsync(brush);
  }

  async setSize(size: number) {
    const { brush } = this._entities;

    brush.radius = size;
    await this._app.derivation.UpdateAsync(brush);
  }

  isDrawingOn(contourId: string) {
    return this._contour && this._contour.id === contourId;
  }

  async off() {
    const { selectedRoi, mode } = this._entities;

    mode.isSegmentationEnabled = false;
    selectedRoi.idRoi = null as any;

    await this._app.derivation.UpdateAsync(mode, selectedRoi);

    if (this._contour) {
      await this._contour.setHighlight(false);
      this._contour = null;
    }

    this._app.undoRedoDraw.Clear();

    await this._app.derivation.CreateAndDeleteAllAsync();
  }

  async on(contour: ContourHelper) {
    if (this._contour) {
      await this.off();
    }

    if (contour.mode !== "drawing") {
      // compute voxel volume from readonly here
      await contour.setMode("drawing");
    }

    const { selectedRoi, mode } = this._entities;

    mode.isSegmentationEnabled = true;
    selectedRoi.idRoi = contour.drawingId;

    await this._app.derivation.UpdateAsync(mode, selectedRoi);

    this._contour = contour;
    await this.updateInteractorsColor();
    await this._contour.setHighlight(true);
    await this._contour.setVisible(true);

    await this._app.derivation.CreateAndDeleteAllAsync();
  }

  async updateInteractorsColor() {
    if (!this._contour) return;
    await this.setInteractorsColor(this._contour.getColor());
  }

  async setInteractorsColor(color: number[]) {
    const interactors = this._app.db.GetAll(
      SceneDrawInteractorRenderedSegmentsEntity
    );
    interactors.forEach((x) => (x.color = color));

    await this._app.derivation.UpdateAsync(...interactors);
  }
}
