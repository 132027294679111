import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  NotificationContextProvider,
  ErrorBoundary,
  Notification,
  INotificationProps,
} from "cocoreact";
import { HelmetProvider } from "react-helmet-async";

import App from "./App";
import {
  FatalErrorDialog,
  PageNotFoundPage,
  PageNotFoundError,
} from "pages/Errors";
import { ThemeContextProvider } from "contexts/Theme";
import { AuthContextProvider } from "contexts/Auth";

function CustomNotification(props: INotificationProps) {
  return <Notification {...props} position={{ horizontal: "left", vertical: "bottom" }} />
}

export default function AppContainer() {
  return (
    <ThemeContextProvider>
      <CssBaseline />
      <ErrorBoundary component={FatalErrorDialog}>
        <ErrorBoundary component={PageNotFoundPage} renderWhen={(error: any) => error instanceof PageNotFoundError}>
          <HelmetProvider>
            <NotificationContextProvider autoHideDuration={2000} component={CustomNotification}>
              <AuthContextProvider>
                <App />
              </AuthContextProvider>
            </NotificationContextProvider>
          </HelmetProvider>
        </ErrorBoundary>
      </ErrorBoundary>
    </ThemeContextProvider>
  )
}
