import React from "react";

import {
    Form as CocoForm,
    FormProps as CocoFormProps
} from "cocoreact";
import { formErrorService } from "services";
import { sendMessage } from "tools/Message";
import formWidgetOptions from "./formWidgetOptions";
import { IFormFieldOptions } from "./FormFieldsBuilder";

export interface FormProps<T, R = any> {
    initial: T;
    fields: IFormFieldOptions<T>[];
    buildMessage: CocoFormProps<T, R>["buildMessage"];
    onSuccess?: CocoFormProps<T, R>["onSuccess"];
    onError?: CocoFormProps<T, R>["onError"];
    onComplete?: CocoFormProps<T, R>["onComplete"];
    children?: CocoFormProps<T, R>["children"];
}

export default function Form<T, R = any>(props: FormProps<T, R>) {
    return (
        <CocoForm<T, R>
            {...props}
            sendMessage={sendMessage}
            widgetOptions={formWidgetOptions}
            errorService={formErrorService}
        />
    );
}
