import {
  EditableVoxelVolume,
  Grid3D,
  PolygonVolume,
  ReadOnlyRoi,
  RTStructPolygon,
  RTStructROI,
  VoxelVolumeByBlock,
} from "dline-viewer/dist/data";
import { RTStructLoader } from "dline-viewer/dist/IO";
import {
  PolygonVolumeToEditableVoxelVolume,
  RTStructROIToReadOnlyRoi,
} from "dline-viewer/dist/processing";
import { GetFileQuery } from "domain/public/query/GetFileQuery";
import { Guid } from "domain/static/Guid";
import { OrientationType } from "domain/static/OrientationType";
import { VolumeType } from "domain/static/VolumeType";
import { httpClient, serializer } from "services";
import { HexToRgb } from "./ColorExtension";
import { fromArrayBuffer } from "./StringExtension";

async function _loadData(
  queryOrId: GetFileQuery | Guid,
  onDownloadProgress?: (loaded: number, total: number) => void
) {
  const query =
    queryOrId instanceof GetFileQuery
      ? queryOrId
      : new GetFileQuery({ id: queryOrId });

  const request = serializer.serializeMessage(query);
  const config = httpClient.buildConfiguration(request);
  config.responseType = "arraybuffer";
  if (onDownloadProgress) {
    config.onDownloadProgress = (progressEvent: ProgressEvent) => {
      onDownloadProgress(progressEvent.loaded, progressEvent.total);
    };
  }
  const response = await httpClient.fetchRequest(config);

  return response;
}

export function parseRTStructRois(arrayBuffer: ArrayBuffer) {
  const loader = new RTStructLoader();
  return loader.ReadRTStruct(arrayBuffer);
}

export function voxelizeRTStructRoi(
  rtStructROI: RTStructROI,
  grid: Grid3D,
  blockSize: number = 10
) {
  const createVoxelVolume = (_grid: Grid3D) =>
    new VoxelVolumeByBlock(blockSize, _grid);

  return RTStructROIToReadOnlyRoi.VoxelizeOne(
    rtStructROI,
    grid,
    createVoxelVolume
  );
}

export async function loadRTStructRoi(
  queryOrId: GetFileQuery | Guid,
  id: Guid,
  color: string,
  onDownloadProgress?: (loaded: number, total: number) => void
) {
  const response = await _loadData(queryOrId, onDownloadProgress);

  const data = JSON.parse(fromArrayBuffer(response.data)) as RTStructPolygon[];

  const contours = data.map((x) => {
    const contour = new RTStructPolygon();
    contour.numberOfPoints = x.numberOfPoints;
    contour.planeConstant = x.planeConstant;
    contour.points = [...x.points];
    return contour;
  });

  const res = new RTStructROI();
  res.name = id;
  res.color = HexToRgb(color);
  res.contours = contours;

  return res;
}

interface ReadOnlyRoiResponse {
  grid: Grid3D;
  polygons: Record<number, number>[][][];
}

export function createReadOnlyRoi(id: Guid, color: string, grid?: Grid3D) {
  const roi = new ReadOnlyRoi();
  roi.name = id;
  roi.color = HexToRgb(color);
  if (grid) {
    roi.polygonVolume = new PolygonVolume(grid);
  }
  return roi;
}

export async function loadReadOnlyRoi(
  queryOrId: GetFileQuery | Guid,
  id: Guid,
  color: string,
  onDownloadProgress?: (loaded: number, total: number) => void
) {
  const response = await _loadData(queryOrId, onDownloadProgress);

  const data = JSON.parse(
    fromArrayBuffer(response.data)
  ) as ReadOnlyRoiResponse;

  const grid = new Grid3D();
  grid.Set(data.grid.size, data.grid.spacing, data.grid.origin);

  const polygons = new PolygonVolume(grid);
  for (let axisIndex = 0; axisIndex < data.polygons.length; axisIndex++) {
    for (
      let sliceIndex = 0;
      sliceIndex < data.polygons[axisIndex].length;
      sliceIndex++
    ) {
      const objArrays = data.polygons[axisIndex][sliceIndex];
      for (const objArray of objArrays) {
        const arr = new Float32Array(Object.values(objArray));
        polygons.AddPolygon(axisIndex, sliceIndex, arr);
      }
    }
  }

  const roi = createReadOnlyRoi(id, color);
  roi.polygonVolume = polygons;

  return roi;
}

export function readOnlyRoiToEditableVoxelVolume(
  readOnlyRoi: ReadOnlyRoi,
  grid: Grid3D,
  blockSize: number = 10
) {
  const editableVoxelVolume = new EditableVoxelVolume(
    new VoxelVolumeByBlock(blockSize, grid)
  );

  PolygonVolumeToEditableVoxelVolume.VoxelizeTo(
    readOnlyRoi.polygonVolume,
    OrientationType.Axial,
    editableVoxelVolume
  );

  return editableVoxelVolume;
}

export function filterByVolumeType<C extends { volumeType: VolumeType }>(
  contours: C[],
  type: VolumeType | VolumeType[]
) {
  if (Array.isArray(type)) {
    return contours.filter(
      (x) => x.volumeType && type.indexOf(x.volumeType) !== -1
    );
  }
  return type === VolumeType.None
    ? contours.filter((x) => !x.volumeType)
    : contours.filter((x) => x.volumeType && x.volumeType === type);
}

export function getParticipantId(contourId: Guid, participantId?: Guid) {
  if (participantId) {
    return `${contourId}-participant-${participantId}`;
  }
  return `${contourId}-participant`;
}

export function isParticipantId(contourId: string) {
  return contourId.includes("participant");
}

export function getStackId(contourId: Guid) {
  return `${contourId}-contour-stack`;
}

export function isStackId(stackId: string) {
  return stackId.includes("contour-stack");
}