import { Guid } from "domain/static/Guid";
import { ContouringWorkshopViewerResponse } from "domain/public/response/ContouringWorkshopViewerResponse";
import { useViewerContext } from "contexts/Viewer";
import { ContourPanel as BaseContourPanel, ContourPanelLoader } from "components/ClinicalData";

export interface ContourPanelProps {
    contourId: Guid;
    contouringWorkshop: ContouringWorkshopViewerResponse;
    onClose: () => void;
}

export default function ContourPanel({ contourId, contouringWorkshop, onClose }: ContourPanelProps) {
    const {
        data: viewerData,
        app: viewerApp,
    } = useViewerContext();

    const contour = contouringWorkshop.contours.find(x => x.id === contourId);
    const contourData = viewerData.getContourById(contourId);
    const contourHelper = viewerApp.getContourById(contourId);

    if (!contour) {
        return <ContourPanelLoader />
    }

    const article = contouringWorkshop.contourArticles.find(x => x.id === contour.articleId);

    const onCleanHandle = async () => {
        if (contourHelper) {
            await contourHelper.clean();
        }
    }

    return <BaseContourPanel
        contour={contour}
        color={contourData ? contourData.color : "white"}
        onClose={onClose}
        article={article}
        onClean={contour.isEditable !== true ? undefined : onCleanHandle}
    />
}

