import React from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => createStyles({
    topLeftZone: {
        position: "absolute",
        top: theme.spacing(2),
        left: theme.spacing(2),
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            display: "inline-flex",
            marginRight: theme.spacing(1),
        },
        "& > :last-child": {
            marginRight: 0,
        }
    },
    topRightZone: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(2),
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            right: theme.spacing(2) + theme.mixins.viewer.smallWidth,
        },
        "& > *": {
            display: "inline-flex",
            marginLeft: theme.spacing(1),
        },
        "& > :first-child": {
            marginLeft: 0,
        }
    },
    bottomLeftZone: {
        position: "absolute",
        left: theme.spacing(2),
        bottom: theme.spacing(14),
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            bottom: theme.spacing(6),
        },
        "& > *": {
            display: "inline-flex",
            marginRight: theme.spacing(1),
        },
        "& > :last-child": {
            marginRight: 0,
        }
    },
    middleRightZone: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        top: "50%",
        bottom: "50%",
        right: theme.mixins.viewer.smallWidth + theme.spacing(2),
        transition: theme.transitions.create(["right"]),
    },
}));

export type CornerPlacement = "top-left" | "bottom-left" | "top-right" | "middle-right";

export interface CornerZoneProps {
    placement: CornerPlacement;
    children: any;
    style?: React.CSSProperties;
    className?: string;
}

export default function CornerZone({ placement, children, style, className }: CornerZoneProps) {
    const classes = useStyles();

    let _className = undefined as string | undefined;
    switch (placement) {
        case "top-left": _className = classes.topLeftZone; break;
        case "bottom-left": _className = classes.bottomLeftZone; break;
        case "top-right": _className = classes.topRightZone; break;
        case "middle-right": _className = classes.middleRightZone; break;
    }

    return <Box className={clsx(_className, className)} style={style}>{children}</Box>;
}