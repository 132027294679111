import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { use100vh } from "react-div-100vh";

import {
    makeStyles, Theme, createStyles, Box, useTheme
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { OnDesktop, OnMobile, PacmanLoader } from "components/Page";
import { IsEmptyGuid } from "domain/static/Guid";
import { OrientationType } from "domain/static/OrientationType";
import { ClinicalCaseViewerResponse } from "domain/public/response/ClinicalCaseViewerResponse";
import {
    OrientationSelect, ImagesSettings, ImageOverlaySelect,
    MPRButton, Viewer3Axes, Viewer1Axe, ControlsInformations,
    MOBILE_CONTROLS, DESKTOP_CONTROLS, CornerZone,
    ImagePrimarySelect
} from "components/Viewer";
import DesktopDrawer from "./Drawer.desktop";
import BottomSheet from "./BottomSheet";
import MobileDialog from "./Dialog.mobile";
import { loadItkImage } from "tools/ImageExtension";
import { createReadOnlyRoi, loadReadOnlyRoi } from "tools/ContourExtension";
import { useViewerContext, ViewerData, ImageData, ContourData } from "contexts/Viewer";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        width: "100%",
        height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
        backgroundColor: theme.palette.grey[300],
        overflow: "hidden",
        position: "relative",
    },
    loader: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        backgroundColor: theme.palette.grey[900],
        [theme.breakpoints.up("md")]: {
            right: theme.mixins.viewer.smallWidth,
        },
    },
    bottomSheet_mobile: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
    },
    slider_mobile: {
        bottom: theme.spacing(8),
    }
}));


export async function buildViewerData(
    entity: ClinicalCaseViewerResponse,
    setLoaderLabel: (label: string) => void
) {
    const data = new ViewerData();
    const globalToLoad = entity.globalContentLength;
    let globalLoaded = 0;

    const nbImage = entity.images.length;
    for (let idx = 0; idx < nbImage; idx++) {
        const image = entity.images[idx];

        const img = new ImageData();
        img.id = image.id;
        img.windowing = image.windowing;
        img.lut = image.lut;
        img.default = image.default;

        img.data = await loadItkImage(
            image.fileId,
            // eslint-disable-next-line no-loop-func
            (loaded, _) => setLoaderLabel(
                `image${nbImage > 1 ? "s" : ""} ${idx + 1}/${nbImage} (${Math.round((globalLoaded + loaded) / globalToLoad * 100)}%)`
            )
        );

        globalLoaded += image.fileContentLength;

        data.images.push(img);
    }

    const grid = data.images[0].data.grid;

    const nbContour = entity.contours.length;
    for (let idx = 0; idx < nbContour; idx++) {
        const contour = entity.contours[idx];

        const c = new ContourData();
        c.id = contour.id;
        c.color = contour.color;
        c.visible = true;
        c.drawing = false;

        if (contour.fileId) {
            c.data = await loadReadOnlyRoi(contour.fileId, contour.id, contour.color,
                // eslint-disable-next-line no-loop-func
                (loaded, _) => setLoaderLabel(
                    `contour${nbContour > 1 ? "s" : ""} ${idx + 1}/${nbContour} (${Math.round((globalLoaded + loaded) / globalToLoad * 100)}%)`
                )
            );
        }
        else {
            c.data = createReadOnlyRoi(contour.id, contour.color, grid);
            setLoaderLabel(
                `contour${nbContour > 1 ? "s" : ""} ${idx + 1}/${nbContour} (${Math.round((globalLoaded) / globalToLoad * 100)}%)`
            );
        }

        globalLoaded += contour.fileContentLength;

        data.contours.push(c);
    }

    return data;
}

export interface ViewerContainerProps {
    loading: boolean;
    entity: ClinicalCaseViewerResponse;
    children?: React.ReactNode | React.ReactNode[];
}

export default function ViewerContainer({ loading: entityLoading, entity, children }: ViewerContainerProps) {
    const classes = useStyles();
    const height100vh = use100vh();
    const theme = useTheme();

    const containerStyle = useMemo(() => {
        if (!height100vh) return undefined;
        return {
            height: `${height100vh - (theme.mixins.toolbar.height as number)}px`
        } as React.CSSProperties;
    }, [height100vh, theme.mixins.toolbar.height]);

    const [drawerDestopOpen, setDrawerDestopOpen] = useState(true);
    const [dialogMobileOpen, setDialogMobileOpen] = useState(false);
    const [orientation, setOrientation] = useState(OrientationType.Axial);
    const [loaderState, setLoaderState] = useState({
        loading: true,
        label: undefined as string | undefined,
    });
    const isInitialized = useRef(false);
    const history = useHistory();

    const {
        data: viewerData,
        dispatcher: viewerDispatcher
    } = useViewerContext();

    useEffect(() => {
        const _downloadAndInitialize = async () => {
            const data = await buildViewerData(entity, (label: string) => {
                setLoaderState({ loading: true, label });
            });

            viewerDispatcher({
                type: "INITIALIZE",
                data: data,
            }, "viewer");
        };

        if (!entityLoading
            && !IsEmptyGuid(entity.id)
            && viewerData.numberOfImages === 0
            && !isInitialized.current) {
            isInitialized.current = true;
            _downloadAndInitialize();
        }
    }, [entity, entityLoading, viewerData, viewerDispatcher]);


    useEffect(() => {
        if (viewerData.numberOfImages) {
            setLoaderState({
                loading: false,
                label: undefined,
            });
        }
    }, [viewerData.numberOfImages]);

    const setMobileOpenHandle = useCallback((opened: boolean) => {
        if (opened) {
            history.push("#informations");
        }
        else {
            history.goBack();
        }
    }, [history]);

    useEffect(() => {
        if (history.location.hash === "#informations") {
            setDialogMobileOpen(true);
        }
        else {
            setDialogMobileOpen(false);
        }
    }, [history.location.hash]);

    return (
        <Box className={classes.container} style={containerStyle}>

            <OnDesktop>
                <DesktopDrawer
                    open={drawerDestopOpen}
                    clinicalCase={entity}
                >
                    {children}
                </DesktopDrawer>
            </OnDesktop>

            <OnMobile>
                <BottomSheet
                    onClick={() => setMobileOpenHandle(true)}
                    clinicalCase={entity}
                    className={classes.bottomSheet_mobile}
                />
                <MobileDialog
                    open={dialogMobileOpen}
                    onClose={() => setMobileOpenHandle(false)}
                    clinicalCase={entity}
                />
            </OnMobile>

            {loaderState.loading === true && <>
                <Box className={classes.loader}>
                    <PacmanLoader label={loaderState.label} />
                </Box>
            </>}

            {loaderState.loading === false && <>
                <OnDesktop>
                    <Viewer3Axes orientation={orientation} />
                </OnDesktop>

                <OnMobile>
                    <Viewer1Axe orientation={orientation} viewerClasses={{ slider: classes.slider_mobile }} />
                </OnMobile>

                <CornerZone placement="top-left">
                    <OrientationSelect
                        value={orientation}
                        onChange={setOrientation}
                    />
                    <OnDesktop>
                        <ImagePrimarySelect images={entity.images} />
                        <ImageOverlaySelect images={entity.images} />
                    </OnDesktop>
                </CornerZone>

                <CornerZone placement="top-right">
                    <ImagesSettings images={entity.images} />
                    <OnDesktop>
                        <MPRButton
                            mode={drawerDestopOpen ? "one" : "three"}
                            onClick={() => setDrawerDestopOpen(!drawerDestopOpen)}
                        />
                    </OnDesktop>
                </CornerZone>

                <CornerZone placement="bottom-left">
                    <OnDesktop>
                        <ControlsInformations controls={DESKTOP_CONTROLS} />
                    </OnDesktop>
                    <OnMobile>
                        <ControlsInformations controls={MOBILE_CONTROLS} />
                    </OnMobile>
                </CornerZone>
            </>}

        </Box>
    );
}
