import React from "react";
import { Helmet } from "react-helmet-async";
import {
    Box, Button, CircularProgress, Hidden,
    makeStyles, Theme, Typography, createStyles
} from "@material-ui/core";
import { Link, matchPath, Route, Switch, useLocation } from "react-router-dom";
import { AccountCircleRounded } from "@material-ui/icons";

import { Footer } from "components/Page";
import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { useAuthContext } from "contexts/Auth";
import { LogoutIcon } from "App/Theme";
import { fullPath, RouteName } from "tools/Route";
import ProfileTabs, { ProfileTab } from "./ProfileTabs";
import { EditPanel } from "./Edit";
import { ListClinicalCasesPanel } from "./ClinicalCases";
import { ListContouringWorkshopsPanel } from "./ContouringWorkshops";
import { slugify } from "tools/StringExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    layout: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(0, 2),
        [theme.breakpoints.up("md")]: {
            padding: 0,
        },
    },
    accountCirle: {
        fontSize: "4em",
        color: theme.palette.action.active,
        marginRight: theme.spacing(2),
    },
    publicProfileLink: {
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        }
    }
}));

function getTab(loc: string) {
    const tabs = {
        "ProfileListContouringWorkshops": "workshops",
        "ProfileListClinicalCases": "atlas",
        "Profile": "profile",
    } as Record<RouteName, ProfileTab>;
    for (const [routeName, tabName] of Object.entries(tabs)) {
        if (matchPath(loc, { path: fullPath(routeName as RouteName) })) {
            return tabName;
        }
    }
    return undefined;
}

function getTitle(loc: string): string {
    const titles = {
        "ProfileListContouringWorkshops": "My workshops",
        "ProfileListClinicalCases": "My atlas",
        "Profile": "My profile",
    } as Record<RouteName, string>;

    for (const [routeName, title] of Object.entries(titles)) {
        if (matchPath(loc, { path: fullPath(routeName as any) })) {
            return title;
        }
    }
    return "Profile";
}

export default function ProfilePage() {

    const classes = useStyles();

    const authContext = useAuthContext();
    const { setToken, mounted, payload } = authContext;

    const location = useLocation();
    const tab = React.useMemo(() => getTab(location.pathname), [location.pathname]);
    const title = React.useMemo(() => getTitle(location.pathname), [location.pathname]);

    const loading = !mounted || !payload;

    return <PageLayout tab="account">
        <Helmet>
            <title>{loading ? "Loading profile ..." : title} - Dline</title>
        </Helmet>

        <CentralColumnLayout size="lg" className={classes.layout}>

            {loading &&
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            }

            {!loading && <>
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Box display="flex" alignItems="center">
                        <Hidden xsDown>
                            <AccountCircleRounded className={classes.accountCirle} />
                        </Hidden>
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h4" component="h2">
                                {payload.firstname} {payload.lastname}
                            </Typography>
                            <Link to={fullPath("PublicUserInformations", {
                                params: {
                                    id: payload.userId,
                                    slug: slugify(payload.firstname + " " + payload.lastname)
                                }
                            })}
                            >
                                <Typography variant="caption" className={classes.publicProfileLink}>
                                    Public profile
                                </Typography>
                            </Link>
                        </Box>
                    </Box>
                    <Button
                        variant="outlined"
                        onClick={() => setToken(null)}
                        endIcon={<LogoutIcon />}
                    >
                        sign out
                    </Button>
                </Box>

                <Box marginY={4}>
                    <ProfileTabs tab={tab} />
                </Box>

                <Switch>

                    <Route exact path={fullPath("Profile")} component={EditPanel} />
                    <Route exact path={fullPath("ProfileListClinicalCases")} component={ListClinicalCasesPanel} />
                    <Route exact path={fullPath("ProfileListContouringWorkshops")} component={ListContouringWorkshopsPanel} />

                </Switch>
            </>}

        </CentralColumnLayout>

        <Footer />

    </PageLayout >
}

