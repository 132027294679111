import React, { useCallback, useState } from "react";
import { Box } from "@material-ui/core";

import { Guid } from "domain/static/Guid";
import { Contour } from "domain/public/response/ContouringWorkshopViewerResponse";
import { useViewerContext } from "contexts/Viewer";
import {
    ContourList, ContourSeparator,
    ContourItem as BaseContourItem,
    ItemComponentProps,
    LockIndicator,
    DrawCreateIndicator,
    DrawEditIndicator,
} from "components/ClinicalData";
import { contourInfoLabel } from "tools/StringExtension";

function ContourItem({ contour, onClick }: ItemComponentProps<Contour>) {
    const {
        data: viewerData,
        app: viewerApp,
        dispatcher: viewerDispatcher
    } = useViewerContext();

    const contourData = viewerData.getContourById(contour.id);
    const contourHelper = viewerApp.getContourById(contour.id);

    const onHighlightHandle = useCallback((enabled: boolean) => {
        if (!contourHelper || !contourData) return;

        if (contourData.visible && !contourData.drawing) {
            contourHelper.setHighlight(enabled);
        }
    }, [contourData, contourHelper]);

    const onVisibilityHandle = useCallback((id: string, visibled: boolean) => {
        viewerDispatcher({
            type: "CONTOURS_VISIBILITY",
            contourIds: [id],
            visibled: visibled,
        });
    }, [viewerDispatcher]);

    var indicator = <LockIndicator />
    if (contour.isEditable) {
        indicator = contour.participantResults.length
            ? <DrawEditIndicator /> : <DrawCreateIndicator />;
        indicator = contourHelper && contourHelper.mode === "drawing"
            ? <DrawEditIndicator /> : indicator;
    }

    return <BaseContourItem
        id={contour.id}
        color={contour.color}
        label={contourInfoLabel(contour)}
        sublabel={contour.organizationName}
        visibled={contourData ? contourData.visible : true}
        onToggleVisibled={onVisibilityHandle}
        onClick={onClick}
        onHighlight={onHighlightHandle}
        indicator={indicator}
    />
}

export interface ContourListPanelProps {
    contours: Contour[];
    onClick: (contourId: Guid) => void;
}

export default function ContourListPanel({ contours, onClick }: ContourListPanelProps) {
    const { dispatcher: viewerDispatcher } = useViewerContext();

    const [checked, setChecked] = useState(true);

    const onCheckHandle = useCallback((_checked: boolean) => {
        const contourIds = contours.map(x => x.id);
        viewerDispatcher({
            type: "CONTOURS_VISIBILITY",
            contourIds: contourIds,
            visibled: _checked,
        });
        setChecked(_checked);
    }, [contours, viewerDispatcher]);

    return <Box>
        <ContourSeparator
            label={`all (${contours.length})`}
            checked={checked}
            onCheck={onCheckHandle}
        />

        <ContourList
            contours={contours}
            ItemComponent={ContourItem}
            onClick={onClick}
        />
    </Box>
}