import React, { useEffect, useState } from "react";
import {
    Box, Button, createStyles,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, makeStyles, TextField, Theme, Typography
} from "@material-ui/core";
import { Block as RejectIcon } from "@material-ui/icons";
import { Done as AcceptIcon } from "@material-ui/icons";

import { Spacer } from "components/Page";
import { ClinicalCaseViewerResponse } from "domain/public/response/ClinicalCaseViewerResponse";
import { useNotificationContext } from "cocoreact";

const useStyles = makeStyles((theme: Theme) => createStyles({
    actions: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        padding: theme.spacing(2),
        borderTop: `1px ${theme.palette.divider} solid`,
        boxShadow: theme.shadows[8],
    },
}));

interface ActionProps {
    onClick: (accepted: boolean) => void;
    disabled?: boolean;
    fullWidth?: boolean;
}

function RejectButton({ disabled, fullWidth, onClick }: ActionProps) {
    return <Button
        size="medium" variant="outlined"
        color="primary" fullWidth={fullWidth}
        startIcon={<RejectIcon />}
        disabled={disabled} onClick={() => onClick(false)}
    >
        reject
    </Button>
}

function AcceptButton({ disabled, fullWidth, onClick }: ActionProps) {
    return <Button
        size="medium" variant="contained"
        color="primary" fullWidth={fullWidth}
        startIcon={<AcceptIcon />}
        disabled={disabled} onClick={() => onClick(true)}
    >
        accept
    </Button>
}

export interface ReviewerToolbarProps {
    entity: ClinicalCaseViewerResponse;
    onReview: (accepted: boolean, comment: string) => Promise<void>;
}

export default function ReviewerToolbar({ entity, onReview }: ReviewerToolbarProps) {
    const classes = useStyles();
    const { success, error } = useNotificationContext();

    const [state, setState] = useState({
        open: false,
        accepted: false,
        comment: "",
    });
    const [isReviewed, setReviewed] = useState(false);
    const [actionsDisabled, setActionsDisabled] = useState(false);

    useEffect(() => {
        if (entity && (entity.reviewAccepted === true || entity.reviewAccepted === false)) {
            setReviewed(true);
        }
    }, [entity]);

    const onSelectActionHandle = (accepted: boolean) =>
        setState({ open: true, accepted, comment: state.comment });

    const onCloseHandle = () =>
        setState({ open: false, accepted: state.accepted, comment: state.comment });

    const onCommentChangeHandle = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        setState({ open: state.open, accepted: state.accepted, comment: e.target.value });

    const onConfirmActionHandle = async (accepted: boolean) => {
        setActionsDisabled(true);

        try {
            await onReview(accepted, state.comment ?? "");
            setActionsDisabled(false);
            setReviewed(true);
            onCloseHandle();

            success("Clinical case has beeen reviewed with success");
        }
        catch (_) {
            error("Error while reviewing clinical case !");
        }
    }

    return <>
        <Box className={classes.actions}>
            {isReviewed && <Box textAlign="center">
                <Typography>
                    This clinical case has already been reviewed.
                </Typography>
            </Box>}
            {!isReviewed && <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap={16}>
                <RejectButton onClick={onSelectActionHandle} disabled={actionsDisabled} fullWidth />
                <AcceptButton onClick={onSelectActionHandle} disabled={actionsDisabled} fullWidth />
            </Box>}
        </Box>

        <Dialog open={state.open} fullWidth>
            <DialogTitle>Comment your decision</DialogTitle>

            <DialogContent>
                {state.accepted === false && <>
                    <DialogContentText>
                        <strong>You are going to reject the clinical case.</strong>
                    </DialogContentText>
                    <DialogContentText>
                        Explain your decision in the following area before confirm your choice.
                    </DialogContentText>
                </>}
                {state.accepted === true && <>
                    <DialogContentText>
                        <strong>You are going to accept the clinical case.</strong>
                    </DialogContentText>
                    <DialogContentText>
                        You can precise your decision in the following area before confirm your choice.
                    </DialogContentText>
                </>}
                <TextField
                    fullWidth
                    multiline
                    minRows={5}
                    maxRows={12}
                    variant="outlined"
                    placeholder="Precise your decision here"
                    value={state.comment}
                    onChange={onCommentChangeHandle}
                />
            </DialogContent>

            <DialogActions>
                <Box marginBottom={1} marginX={2} width="100%" display="flex">
                    <Button
                        onClick={onCloseHandle}
                        disabled={actionsDisabled}
                    >
                        cancel
                    </Button>

                    <Spacer />

                    {state.accepted === false &&
                        <RejectButton onClick={onConfirmActionHandle} disabled={actionsDisabled} />
                    }
                    {state.accepted === true &&
                        <AcceptButton onClick={onConfirmActionHandle} disabled={actionsDisabled} />
                    }
                </Box>
            </DialogActions>
        </Dialog>
    </>
}
