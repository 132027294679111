import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import {
    Box, createStyles, lighten, makeStyles, Theme, Typography,
    Card as MuiCard, CardMedia, CardContent,
    Chip,
    CardActionArea,
} from "@material-ui/core";
import clsx from "clsx";

import useFileQueryPath from "domain/hooks/useFileQueryPath";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { stripMarkdown } from "tools/StringExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    grid: {
        display: "grid",
        gridGap: theme.spacing(2),
        gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    },
    card: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
    },
    card_media: {
        display: "none",
        height: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            height: 140,
            display: "block",
        }
    },
    card_content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        gap: theme.spacing(1),
        width: "100%",
        "&::last-child": {
            padding: 0,
        }
    },
    chip: {
        pointerEvents: "none",
        height: 20,
        borderRadius: 4,
        textTransform: "uppercase",
        fontSize: 12,
    },
    chip_bold: {
        fontWeight: theme.typography.fontWeightBold,
    },
    chip_noBorder: {
        borderWidth: 0,
    },
    title: {
        color: lighten(theme.palette.text.primary, 0.3),
        lineHeight: "1.2em",
        overflow: "hidden",
        display: "-webkit-box",
        alignItems: "center",
        lineClamp: 2,
        boxOrient: "vertical",
        maxHeight: "2.4em",
    },
    content: {
        color: lighten(theme.palette.text.primary, 0.3),
        lineHeight: "1.4em",
        overflow: "hidden",
        display: "-webkit-box",
        lineClamp: 3,
        boxOrient: "vertical",
        maxHeight: "4em",
    },
    spacer: {
        display: "flex",
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",
        marginTop: theme.spacing(-1),
    },
}));

export interface CardProps {
    href: string;
    coverId?: Guid;
    title: string;
    anatomicZone: string;
    content: string;
    imagingModalities: string[];
    role?: string;
    subRole?: string;
}

export default function Card({
    href, title, anatomicZone, content, imagingModalities, coverId, role, subRole
}: CardProps) {
    const classes = useStyles();
    const coverPath = useFileQueryPath(coverId ?? EmptyGuid());
    const coverUrl = coverPath ?? `${process.env.PUBLIC_URL}/cover_default.png`;

    return <Link to={href} title={title}>
        <MuiCard
            title={title}
            elevation={4}
            className={classes.card}
        >
            <CardActionArea className={classes.card}>
                <CardMedia
                    className={classes.card_media}
                    image={coverUrl}
                    title={title}
                />
                <CardContent className={classes.card_content}>

                    <Box display="flex" justifyContent="space-between">
                        <Chip
                            label={anatomicZone}
                            color="primary"
                            className={clsx(classes.chip, classes.chip_bold)}
                        />
                        <Box display="flex" gridGap={8}>
                            {imagingModalities.map(x =>
                                <Chip label={x} key={x} className={classes.chip} />
                            )}
                        </Box>
                    </Box>

                    <Typography component="h3" variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <Typography variant="body2" component="p" className={classes.content}>
                        {stripMarkdown(content)}
                    </Typography>

                    <Box className={classes.spacer} />

                    {role && subRole &&
                        <Box display="flex" justifyContent="space-between">
                            <Chip
                                label={role}
                                color="secondary"
                                disabled
                                className={clsx(classes.chip, classes.chip_bold)}
                            />
                            <Chip
                                label={subRole}
                                color="secondary"
                                variant="outlined"
                                disabled
                                className={clsx(classes.chip, classes.chip_bold, classes.chip_noBorder)}
                            />
                        </Box>
                    }

                </CardContent>
            </CardActionArea>
        </MuiCard>
    </Link>
}

export function CardGrid({ children }: PropsWithChildren<{}>) {
    const classes = useStyles();
    return <Box className={classes.grid}>
        {children}
    </Box>
}
