import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class GetProfileClinicalCaseViewerQuery implements IMessage {
    slug!: string;

    public constructor(init?: Partial<GetProfileClinicalCaseViewerQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/profile/clinical-cases/${this.slug.toString()}`;

    getQueryString = () => undefined;

    getBody = () => undefined;

    needAuthentication = () => true;
}
