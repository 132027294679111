import React from "react";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { useAuthContext } from "contexts/Auth";
import { fullPath } from "tools/Route";
import { useSearchParams } from "tools/useSearchParams";
import { LogLayout } from "components/Layouts";
import SignInForm from "./SignInForm";

export default function SignInPage() {

    const { return_to } = useSearchParams<{ return_to: string }>();
    const { mounted, payload } = useAuthContext();

    const returnTo = return_to ?? fullPath("Profile");

    if (mounted && payload) {
        return <Redirect to={returnTo} />
    }

    return <LogLayout title="Sign in to Dline">

        <Helmet>
            <title>Sign in - Dline</title>
        </Helmet>

        <SignInForm />

    </LogLayout>
}
