import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import { fullPath } from "tools/Route";

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        position: "relative",
        backgroundColor: theme.palette.background.default,
        '&::before': {
            content: '""',
            display: "block",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.background.default,
            opacity: 0.05,
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/workshop-bg.png)`,
            backgroundBlendMode: "normal",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
        }
    },
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(6, 1),
        margin: 0,
        gap: theme.spacing(4),
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            flexDirection: "row",
            justifyContent: "space-between",
            padding: theme.spacing(8, 0),
            margin: theme.spacing(0, "auto"),
            maxWidth: 992,
        },
    },
    title: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        alignItems: "center",
        textAlign: "center",
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            textAlign: "left",
            alignItems: "flex-start",
        }
    },
    cover: {
        height: 160,
        width: "100%",
        textAlign: "center",
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/workshop-device2.png)`,
        backgroundSize: "contain",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            maxWidth: 480,
        }
    }
}));

export default function WorkshopSection() {
    const classes = useStyles();

    return <Box className={classes.wrapper}>
        <Box className={classes.container}>
            <Box className={classes.title}>
                <Typography component="h4" variant="h4" color="primary">
                    <strong>Delineation Workshop</strong>
                </Typography>
                <Typography component="h6" variant="h6" color="textSecondary">
                    A new way to learn how to master contouring online.
                </Typography>

                <Link to={fullPath("Workshops")}>
                    <Button variant="outlined" color="primary">
                        about workshops
                    </Button>
                </Link>
            </Box>


            <Box className={classes.cover} />
        </Box>
    </Box>
}
