//Generated code, do not modify

export enum OrientationType {
  Sagittal = 0,
  Coronal = 1,
  Axial = 2,
}

export const OrientationTypeOptions = [
  {
    label: "Sagittal",
    value: OrientationType.Sagittal,
  },
  {
    label: "Coronal",
    value: OrientationType.Coronal,
  },
  {
    label: "Axial",
    value: OrientationType.Axial,
  },
];
