import React from "react";
import { Box, Typography, useTheme } from "@material-ui/core";
import "./pacman.css";

export interface PacmanLoaderProps {
    label?: string;
}

export default function PacmanLoader({ label }: PacmanLoaderProps) {
    const theme = useTheme();

    return <Box width={200} color={theme.palette.grey[100]}>
        <div
            className="la-pacman la-3x"
        >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>

        <Typography variant="overline" component="div"
            style={{ textAlign: "center", marginTop: theme.spacing(2) }}
        >
            loading resources
        </Typography>

        {label &&
            <Typography variant="caption" component="div"
                style={{ textAlign: "center" }}
            >
                {label}
            </Typography>
        }
    </Box>
}
