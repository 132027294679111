import JwtDeocde from "jwt-decode";
import { IJwtStorage } from "cocoreact";

import { sendMessage } from "tools/Message";
import { IAuthPayload } from "./IAuthPayload";
import { GetNewAccessTokenQuery } from "domain/public/query/GetNewAccessTokenQuery";
import { AccessTokenResponse } from "domain/public/response/AccessTokenResponse";

export default class RefreshAccessToken {
  private _jwtStorage: IJwtStorage;

  private _timeout: NodeJS.Timeout | null;
  private _token: string | null;

  constructor(jwtStorage: IJwtStorage) {
    this._jwtStorage = jwtStorage;

    this._timeout = null;
    this._token = null;

    this.update = this.update.bind(this);
    this._refreshToken = this._refreshToken.bind(this);
    this._clearTimeout = this._clearTimeout.bind(this);
  }

  async update(token: string | null, force: boolean = false) {
    this._clearTimeout();

    this._token = token;
    if (!this._token) return;

    const delay = this._getDelay();
    if (force || delay >= 0) {
      // console.log("refresh token in ", Math.round(delay / 1000), "sec ...");
      this._timeout = setTimeout(this._refreshToken, delay);
    }
  }

  private _clearTimeout() {
    if (!this._timeout) return;

    clearTimeout(this._timeout);
    this._timeout = null;
  }

  private _getDelay() {
    if (!this._token) return -1;

    const parts = JwtDeocde<IAuthPayload>(this._token);
    if (parts && parts.exp) {
      const now = Date.now();
      const exp = parts.exp * 1000;
      const delay = Math.round((exp - now) * 0.9);
      return delay && delay > 0 ? delay : 0;
    }

    return -1;
  }

  private async _refreshToken() {
    try {
      const query = this.getRefreshQuery();
      const response = await sendMessage<AccessTokenResponse>(query);
      const token = response.accessToken;

      this._jwtStorage.set(token);
      this.update(response.accessToken);
    } catch (_e) {
      this._jwtStorage.remove();
      document.location.reload();
    }
  }

  public getRefreshQuery() {
    return new GetNewAccessTokenQuery();
  }
}
