import React from "react";
import clsx from "clsx";
import { Box, Button, Chip, createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { OnDesktop, OnMobile } from "components/Page";
import { Link } from "react-router-dom";
import { fullPath } from "tools/Route";
import { AccountIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(6, 1),
        margin: 0,
        textAlign: "center",
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            padding: theme.spacing(6, 0),
            margin: theme.spacing(0, "auto"),
            maxWidth: 992,
        },
    },
    timeline_mobile: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        margin: theme.spacing(2, 0),
        paddingLeft: theme.spacing(6),
        "&::before": {
            position: "absolute",
            content: '""',
            width: 0,
            height: "100%",
            top: 0,
            bottom: 0,
            left: theme.spacing(2.4),
            borderLeft: `3px solid ${theme.palette.secondary.light}`,
        }
    },
    card: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        textAlign: "start",
        padding: theme.spacing(2),
        gap: theme.spacing(1),
        position: "relative",
        maxWidth: 500,
        "&::before": {
            position: "absolute",
            content: '""',
            width: theme.spacing(3),
            height: theme.spacing(3),
            top: `calc(50% - ${theme.spacing(1.5)}px)`,
            backgroundColor: theme.palette.secondary.light,
            borderRadius: "100%",
        },
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            maxWidth: 300,
        }
    },
    timeline_desktop: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        width: 656,
        margin: theme.spacing(4, "auto"),
        "&::before": {
            position: "absolute",
            content: '""',
            width: 0,
            height: "100%",
            top: 0,
            bottom: 0,
            left: "calc(50% - 1px)",
            borderLeft: `3px solid ${theme.palette.secondary.light}`,
        }
    },
    card_left: {
        gridColumn: 0,
        "&::before": {
            left: theme.spacing(-5),
        }
    },
    card_right: {
        gridColumn: 1,
        "&::before": {
            right: theme.spacing(-5),
        }
    },
    row: {
        zIndex: 5,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "& .MuiChip-root": {
            backgroundColor: theme.palette.secondary.light,
        }
    },
    row_left: {
        justifyContent: "flex-start",
    },
    row_right: {
        justifyContent: "flex-end",
    },
    row_both: {
        justifyContent: "space-between",
    },
    actions: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(1),
        "& button": {
            width: 200,
        },
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            flexDirection: "row",
            gap: theme.spacing(2),
        },
    },
}));

interface CardProps {
    title: string;
    content: string;
    chipPosition?: "left" | "right";
}

function Card({ title, content, ...props }: CardProps) {
    const classes = useStyles();
    const chipPosition = props.chipPosition ?? "left"
    const chipClasse = chipPosition === "left" ? classes.card_left : classes.card_right;

    return <Paper className={clsx(classes.card, chipClasse)}>
        <Typography component="p" variant="h6">
            {title}
        </Typography>
        <Typography component="p" variant="body2">
            {content}
        </Typography>
    </Paper>
}

interface RowProps {
    className?: string;
    children: any;
}

function Row({ className, children }: RowProps) {
    const classes = useStyles();
    return <Box className={clsx(classes.row, className)}>
        {children}
    </Box>
}

const ORGANIZER_CARDS = [
    {
        title: "#1 - Let’s meet up !",
        content: "Use our contact form to describe your project and define together a schedule and budget.",
    },
    {
        title: "#2 - Configure workshop",
        content: "Our team will help you to configure your contouring workshop (images, expert contours, participants inscription, etc.).",
    },
    {
        title: "#3 - Follow contouring",
        content: "Real time access to all participants results. Notifications sending to keep them motivated.",
    },
    {
        title: "#4 - Get all results",
        content: "Calculation of the best-known concordance indices and consolidated view (contour stack).",
    },
    {
        title: "#5 - Export data",
        content: "Export results through various formats such as Nifti, Dicom, etc and use them for analytics and publications.",
    },
]

const PARTICIPANT_CARDS = [
    {
        title: "#1 - Initialize account",
        content: "Finalize account creation and registration (no personal information required).",
    },
    {
        title: "#2 - Start your contours",
        content: "Use our online segmentation tools to make your contours according to instructions and guildelines.",
    },
    {
        title: "#3 - Edit your contours",
        content: "Update your contours all along the workshop session.",
    },
    {
        title: "#4 - Get your results",
        content: "Calculation of the best-known concordance indices (Dices, Haussdorff distance, Jacar).",
    },
]

export default function TimelineSection({ onRequestQuote }: { onRequestQuote: React.DispatchWithoutAction }) {
    const classes = useStyles();

    return <Box className={classes.container}>
        <Typography component="h4" variant="h4">
            <strong>Workshop Timeline</strong>
        </Typography>
        <Typography component="h5" variant="h6" color="textSecondary">
            Our team accompanies you step by step all the way.
        </Typography>

        <OnMobile>
            <Box className={classes.timeline_mobile}>
                <Typography component="h6" variant="h6" align="left">
                    Organizer
                </Typography>
                {ORGANIZER_CARDS.map((c, idx) => <Card key={idx} {...c} />)}
            </Box>
            <Box className={classes.timeline_mobile}>
                <Typography component="h6" variant="h6" align="left">
                    Participant
                </Typography>
                {PARTICIPANT_CARDS.map((c, idx) => <Card key={idx} {...c} />)}
            </Box>
        </OnMobile>

        <OnDesktop>
            <Box display="flex" marginTop={2}>
                <Typography component="h6" variant="h6">
                    Organizer
                </Typography>
                <Typography component="h6" variant="h6" style={{ marginLeft: 150 }}>
                    Participant
                </Typography>
            </Box>
            <Box className={classes.timeline_desktop}>
                <Row className={classes.row_left}>
                    <Card {...ORGANIZER_CARDS[0]} chipPosition="right" />
                </Row>
                <Row className={classes.row_left}>
                    <Card {...ORGANIZER_CARDS[1]} chipPosition="right" />
                </Row>
                <Row className={classes.row_right}>
                    <Card {...PARTICIPANT_CARDS[0]} chipPosition="left" />
                </Row>

                <Row>
                    <Chip label="workshop begins" color="secondary" />
                </Row>

                <Row className={classes.row_right}>
                    <Card {...PARTICIPANT_CARDS[1]} chipPosition="left" />
                </Row>
                <Row className={classes.row_left}>
                    <Card {...ORGANIZER_CARDS[2]} chipPosition="right" />
                </Row>
                <Row className={classes.row_right}>
                    <Card {...PARTICIPANT_CARDS[2]} chipPosition="left" />
                </Row>

                <Row>
                    <Chip label="workshop is over" color="secondary" />
                </Row>

                <Row className={classes.row_both}>
                    <Card {...ORGANIZER_CARDS[3]} chipPosition="right" />
                    <Card {...PARTICIPANT_CARDS[3]} chipPosition="left" />
                </Row>
                <Row className={classes.row_left}>
                    <Card {...ORGANIZER_CARDS[4]} chipPosition="right" />
                </Row>
            </Box>
        </OnDesktop>

        <Box className={classes.actions}>
            <Button variant="contained" color="primary" onClick={onRequestQuote}>
                request a quote
            </Button>
            <Link to={fullPath("ProfileListContouringWorkshops")}>
                <Button variant="outlined" color="primary" startIcon={<AccountIcon />}>
                    your workshops
                </Button>
            </Link>
        </Box>

    </Box>
}
