//Generated code, do not modify

export enum ContouringWorkshopUserPermission {
  None = 0,
  Edit = 1,
  PresentResults = 2,
  PastResults = 4,
}

export const ContouringWorkshopUserPermissionOptions = [
  {
    label: "None",
    value: ContouringWorkshopUserPermission.None,
  },
  {
    label: "Edit",
    value: ContouringWorkshopUserPermission.Edit,
  },
  {
    label: "PresentResults",
    value: ContouringWorkshopUserPermission.PresentResults,
  },
  {
    label: "PastResults",
    value: ContouringWorkshopUserPermission.PastResults,
  },
];
