import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import { formatLongDateTime } from "tools/DateExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        width: "100%",
        height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
        backgroundColor: theme.palette.grey[900],
        overflow: "hidden",
        position: "relative",
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        color: theme.palette.grey[300],
        marginTop: theme.spacing(-4),
    },
    date: {
        fontSize: "1.3em",
        lineHeight: "1.3em",
        textAlign: "center",
        margin: theme.spacing(4, 0)
    }
}));

export interface FutureAdvertisementProps {
    name: string;
    startAt: Date;
}

export default function FutureAdvertisement({ name, startAt }: FutureAdvertisementProps) {
    const classes = useStyles();
    const history = useHistory();

    return <Box className={classes.container}>
        <Box className={classes.wrapper}>
            <Typography component="p">
                Contouring workshop
            </Typography>
            <Typography component="h1" variant="h6">
                {name}
            </Typography>

            <Typography variant="overline" component="p" className={classes.date}>
                Available on
                <br />
                {formatLongDateTime(startAt)}
            </Typography>

            <Button
                color="inherit"
                size="small"
                variant="outlined"
                onClick={() => history.goBack()}
            >
                back to profile
            </Button>
        </Box>
    </Box>
}
