import React from "react";
import {
  makeStyles, Theme, createStyles, alpha, Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { CloseIcon, useNotificationContext } from "cocoreact";

import { useAuthContext } from "contexts/Auth";
import { ArchiveProfileCommand } from "domain/public/command/ArchiveProfileCommand";
import { sendMessage } from "tools/Message";
import { fullPath } from "tools/Route";
import { DeleteIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
  deleteBtn: {
    color: theme.palette.error.dark,
    borderColor: theme.palette.error.dark,
    "&:hover": {
      backgroundColor: alpha(theme.palette.error.dark, 0.1),
    }
  },
}));

export default function ArchiveAccountSection({ email }: { email?: string }) {
  const classes = useStyles();

  const { success, error } = useNotificationContext()
  const { setToken } = useAuthContext();
  const [deleteConfirm, setDeleteConfirm] = React.useState(false)

  const onAcceptDelete = React.useCallback(async () => {
    if (email) {
      try {
        const message = new ArchiveProfileCommand();
        await sendMessage(message);
        setDeleteConfirm(false);
        setToken(null);
        success("Your account has been closed with success !");
      }
      catch (e) {
        error("An error occurred : retry operation or contact administrator");
      }
    }

  }, [error, success, email, setToken]);

  return <>
    <Box component="section">

      <Typography component="p">
        To close your account click to the following button.
      </Typography>
      <Typography component="p" variant="caption">
        According with <Link to={fullPath("TermsOfService")} style={{ textDecoration: "underline" }}>our policy</Link>, we will keep necessary data to ensure the proper functioning of the site.
      </Typography>

      <Box marginTop={1} textAlign="right">
        <Button
          variant="outlined"
          onClick={() => setDeleteConfirm(true)}
          disabled={!email}
          startIcon={<DeleteIcon />}
          className={classes.deleteBtn}
        >
          archive my account
        </Button>
      </Box>
    </Box>

    <Dialog open={deleteConfirm}>
      <DialogTitle>
        Close account confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to archive your account. By confirming this operation you will no longer be able to access your account.
        </DialogContentText>
        <DialogContentText>
          Are you sure you want to perform this operation?
        </DialogContentText>
      </DialogContent>
      <DialogActions >
        <Box margin={1} width="100%" display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            onClick={() => setDeleteConfirm(false)}
            startIcon={<CloseIcon />}
          >
            close
          </Button>
          <Button
            variant="outlined"
            onClick={onAcceptDelete}
            startIcon={<DeleteIcon />}
            className={classes.deleteBtn}
          >
            yes, archive my account
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  </>
}
