import { useCallback, useEffect, useMemo, useState } from "react";
import { Guid } from "domain/static/Guid";
import { GetPublicReviewerClinicalCaseQuery } from "domain/public/query/GetPublicReviewerClinicalCaseQuery";
import { ClinicalCaseViewerResponse } from "domain/public/response/ClinicalCaseViewerResponse";
import { EmptyClinicalCaseViewerResponse } from "./useClinicalCaseViewer";
import { sendMessage } from "tools/Message";

export default function useReviewerClinicalCase(
  id: Guid,
  token: string
): [boolean, ClinicalCaseViewerResponse, any | undefined] {
  const query = useMemo(
    () =>
      new GetPublicReviewerClinicalCaseQuery({
        id,
        token,
      }),
    [id, token]
  );

  const [state, setState] = useState({
    loading: true,
    data: EmptyClinicalCaseViewerResponse(),
    error: undefined as any | undefined,
  });

  const updateData = useCallback(async () => {
    setState((s) => ({ ...s, loading: true, error: undefined }));
    try {
      const data = await sendMessage<ClinicalCaseViewerResponse>(query);
      setState((s) => ({ data, loading: false, error: undefined }));
    } catch (e) {
      setState((s) => ({ ...s, loading: false, error: e }));
    }
  }, [query]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return [state.loading, state.data, state.error];
}
