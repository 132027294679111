import React, { useCallback, useState } from "react";
import { createStyles, FormControl, FormHelperText, InputLabel, makeStyles, Theme } from "@material-ui/core";
import { IFormWidgetPropsBase } from "cocoreact";
import ReactMde, { ReactMdeProps } from "react-mde";
import "react-mde/lib/styles/css/react-mde.css";
import "react-mde/lib/styles/css/react-mde-toolbar.css";
import "react-mde/lib/styles/css/react-mde-editor.css";

import { Markdown } from "components/Page";

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        marginTop: theme.spacing(3),
        "& .w-md-editor-toolbar li button": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: theme.spacing(2),
        }
    },
    markdown_wrapper: {
        maxWidth: theme.mixins.viewer.smallWidth,
        margin: theme.spacing(0, "auto"),
    },
}));

type TabSelected = ReactMdeProps["selectedTab"];

export interface RichTextFormWidgetProps extends IFormWidgetPropsBase<string> {
}

export default function RichTextFormWidget({ name, value, onChange, ...props }: RichTextFormWidgetProps) {
    const classes = useStyles();

    const [selectedTab, setSelectedTab] = useState<TabSelected>("write");

    const onChangeHandle = useCallback((newValue: string) => {
        onChange && onChange(name, newValue);
    }, [name, onChange]);

    return (
        <FormControl
            required={props.required}
            disabled={props.disabled}
            fullWidth={props.fullWidth}
            margin={props.margin}
            error={props.error !== undefined}
            className={props.className}
            style={props.style}
            aria-label={props.label}
        >
            {props.label && <InputLabel shrink id="text-form-label">{props.label}</InputLabel>}

            <div className={classes.wrapper}>
                <ReactMde
                    value={value}
                    onChange={onChangeHandle}
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    generateMarkdownPreview={markdown => {
                        return Promise.resolve(<Markdown children={markdown} className={classes.markdown_wrapper} />)
                    }}
                    toolbarCommands={[
                        ["bold", "italic"],
                        ["link", "unordered-list"]
                    ]}
                />
            </div>

            {props.error && <FormHelperText>{props.error}</FormHelperText>}

        </FormControl>
    )
}