import React from "react";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";

import { SearchIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    button: {
        padding: theme.spacing(1, 3),
        color: theme.palette.text.secondary,
    },
}));

export interface SearchButtonProps {
    onClick: () => void;
}

export default function SearchButton({ onClick }: SearchButtonProps) {
    const classes = useStyles();

    return (
        <Button
            startIcon={<SearchIcon />}
            onClick={onClick}
            className={classes.button}
        >
            search
        </Button>
    )
}
