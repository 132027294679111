import { Guid } from "domain/static/Guid";
import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class SetStateClinicalCaseCommand implements IMessage {
    id!: Guid;
    state!: ClinicalCaseState;
    comment!: string;

    public constructor(init?: Partial<SetStateClinicalCaseCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PATCH" as RequestMethod;

    getPath = () => `/profile/clinical-cases/${this.id.toString()}/state`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            state: this.state,
            comment: this.comment,
        };
    }
    
    needAuthentication = () => true;
}
