import React from "react";
import { Chip, ChipProps, createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { ContouringWorkshopState, ContouringWorkshopStateOptions } from "domain/static/ContouringWorkshopState";
import { formatDistance } from "tools/DateExtension";
import { enumValueToString } from "tools/EnumExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    chip: {
        cursor: "inherit",
        opacity: 1.0,
    },
    Present: {},
    Future: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.getContrastText(theme.palette.primary.light),
    },
    Past: {
        backgroundColor: theme.palette.grey[600],
        color: theme.palette.getContrastText(theme.palette.grey[600]),
    },
}));

export interface StateChipProps {
    state: ContouringWorkshopState;
    startAt: Date;
    endAt: Date;
    size?: ChipProps["size"];
}

export default function StateChip({ state, startAt, endAt, size }: StateChipProps) {
    const classes = useStyles();
    let label = enumValueToString(state, ContouringWorkshopStateOptions);
    const clasName = (classes as any)[label];

    label = state === ContouringWorkshopState.Present
        ? `${label} (${formatDistance(endAt)} left)`
        : label;

    label = state === ContouringWorkshopState.Future
        ? `${label} (start in ${formatDistance(startAt)})`
        : label;

    if (state !== ContouringWorkshopState.None) {
        return <Chip className={clsx(classes.chip, clasName)} size={size}
            label={label} color="primary"
        />
    }

    return null;
}
