import React from "react";
import { TwitterPicker, TwitterPickerProps } from "react-color";

export const DEFAULT_COLORS = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b"
];

export type { ColorResult } from "react-color";

export interface ColorPickerProps extends TwitterPickerProps { }

export default function ColorPicker({ styles, ...props }: ColorPickerProps) {

    return <TwitterPicker
        colors={DEFAULT_COLORS}
        width="inherit"
        triangle="hide"
        {...props}
        styles={{
            default: {
                ...styles?.default,
                card: {
                    ...styles?.default?.card,
                    backgroundColor: "inherit",
                },
            }
        }}
    />
}