import { SelectOption } from "cocoreact";

export function enumValueToString(value: any, options: SelectOption[]) {
  let result = undefined as string | undefined;
  options.forEach((opt) => {
    if (opt.value === value) {
      result = opt.label;
      return;
    }
  });
  if (!result) {
    throw new Error(
      `undefined enum value '${value}' for options ${JSON.stringify(options)}`
    );
  }
  return result;
}

export function enumOptionsNoneToAll(options: SelectOption[]) {
  return options.map((x) => {
    if (x.value === 0) {
      return {
        label: "All",
        value: "undefined",
      };
    }
    return x;
  });
}

export function enumHasFlag(value: any, flag: any) {
  return (value & flag) === flag;
}
