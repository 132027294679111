import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Switch, Route, RouteProps, Redirect, useLocation } from "react-router-dom";
import ReactGA from "react-ga";

import CookiesSettings, { useCookiesConsent } from "./CookiesSettings";
import { fullPath } from "tools/Route";
import { useAuthContext } from "contexts/Auth";
import { CguPage, ContactPage, CookiesSettingsPage, AboutPage } from 'pages/static';
import { HomePage } from "pages/Home";
import { WorkshopsPage } from "pages/Workshops";
import { ListClinicalCasesPage, ViewerClinicalCasePage } from 'pages/ClinicalCases';
import { ReviewClinicalCasePage } from "pages/Review";
import { PageNotFoundError } from 'pages/Errors';
import {
  ProfilePage,
  ProfileShowClinicalCasePage,
  ProfileShowContouringWorkshopPage,
} from 'pages/Profile';
import { ResetPasswordPage, SignInPage } from 'pages/Auth';
import { UserPublicInformationsPage } from "pages/Users";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
});

function PrivateRoute(props: RouteProps) {
  const location = useLocation();
  const { payload, mounted } = useAuthContext();

  if (!mounted) {
    return null;
  }

  if (!payload) {
    let url = fullPath("SignIn");
    if (location.pathname !== "/") {
      url += `?return_to=${encodeURI(location.pathname + location.search)}`
    }
    return <Redirect to={url} />
  }

  return <Route {...props} />
}

export default function App() {

  const classes = useStyles();
  const location = useLocation();
  const [consent] = useCookiesConsent();

  useEffect(() => {
    if (consent === true) {
      ReactGA.initialize("UA-149176106-1");
    }
  }, [consent]);

  useEffect(() => {
    if (consent === true) {
      const uri = location.pathname + location.search + location.hash;
      ReactGA.pageview(uri);
    }
  }, [consent, location]);

  return (
    <div className={classes.root}>

      <Switch>
        <Route exact path={fullPath("Home")} component={HomePage} />
        <Route exact path={fullPath("Workshops")} component={WorkshopsPage} />

        <Route exact path={fullPath("TermsOfService")} component={CguPage} />
        <Route exact path={fullPath("PrivacyPolicy")} component={CguPage} />
        <Route exact path={fullPath("ContactUs")} component={ContactPage} />
        <Route exact path={fullPath("AboutUs")} component={AboutPage} />
        <Route exact path={fullPath("CookiesSettings")} component={CookiesSettingsPage} />

        <Route exact path={fullPath("ListClinicalCases")} component={ListClinicalCasesPage} />
        <Route exact path={fullPath("ShowClinicalCase")} component={ViewerClinicalCasePage} />

        <Route exact path={fullPath("PublicReviewClinicalCase")} component={ReviewClinicalCasePage} />

        <Route exact path={fullPath("PublicUserInformations")} component={UserPublicInformationsPage} />

        <PrivateRoute exact path={fullPath("ProfileShowClinicalCase")} component={ProfileShowClinicalCasePage} />
        <PrivateRoute exact path={fullPath("ProfileShowContouringWorkshop")} component={ProfileShowContouringWorkshopPage} />
        <PrivateRoute path={fullPath("Profile")} component={ProfilePage} />

        <Route exact path={fullPath("SignIn")} component={SignInPage} />
        <Route exact path={fullPath("ResetPassword")} component={ResetPasswordPage} />

        <Route path={"/"} render={() => { throw new PageNotFoundError(); }} />

      </Switch>

      <CookiesSettings />

    </div>
  );

}
