import React from "react";

import { Guid } from "domain/static/Guid";
import { ClinicalCaseViewerResponse } from "domain/public/response/ClinicalCaseViewerResponse";
import { ContourPanel as BaseContourPanel, ContourPanelLoader } from "components/ClinicalData";

export interface ContourPanelProps {
    contourId: Guid;
    clinicalCase: ClinicalCaseViewerResponse;
    onClose?: () => void;
}

export default function ContourPanel({ contourId, clinicalCase, onClose }: ContourPanelProps) {
    const contour = clinicalCase.contours.find(x => x.id === contourId);

    if (!contour) {
        return <ContourPanelLoader />
    }

    const article = clinicalCase.contourArticles.find(x => x.id === contour.articleId);

    return <BaseContourPanel
        contour={contour}
        color={contour.color}
        onClose={onClose}
        article={article}

    />
}
