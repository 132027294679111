import { Guid } from "domain/static/Guid";
import { GetFileQuery } from "domain/public/query/GetFileQuery";
import { ItkImageLoader } from "dline-viewer/dist/IO";
import { httpClient, serializer } from "services";

export async function loadItkImage(
  queryOrId: GetFileQuery | Guid,
  onDownloadProgress?: (loaded: number, total: number) => void
) {
  const query =
    queryOrId instanceof GetFileQuery
      ? queryOrId
      : new GetFileQuery({ id: queryOrId });

  const request = serializer.serializeMessage(query);
  const config = httpClient.buildConfiguration(request);
  config.responseType = "blob";
  if (onDownloadProgress) {
    config.onDownloadProgress = (progressEvent: ProgressEvent) => {
      onDownloadProgress(progressEvent.loaded, progressEvent.total);
    };
  }
  const response = await httpClient.fetchRequest(config);

  let filename = "";
  const contentDisposition = response.headers["content-disposition"];
  if (contentDisposition) {
    filename = contentDisposition;
    filename = filename.replace("attachment;", "");
    filename = filename.replace("filename=", "");
    filename = filename.replace(/"/g, ""); // remove all "
    filename = filename.replace(/ /g, ""); // remove all space
  }

  if (!filename) {
    throw new Error(
      "undefined filename inside content-disposition response header"
    );
  }

  const blob = await response.data;

  return await new ItkImageLoader().loadImageBlob(blob, filename);
}
