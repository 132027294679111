import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, createStyles, makeStyles, Paper, SvgIconProps, Theme, Typography } from "@material-ui/core";
import {
    Settings as InitializeIcon,
    Edit as OngoingIcon,
    NetworkCheck as ResultsIcon,
} from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import { fullPath } from "tools/Route";

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        position: "relative",
        backgroundColor: theme.palette.background.default,
        '&::before': {
            content: '""',
            display: "block",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            opacity: 0.1,
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/montain-bg.png)`,
            backgroundBlendMode: "normal",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
        }
    },
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(6, 1),
        margin: 0,
        textAlign: "center",
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            padding: theme.spacing(6, 0),
            margin: theme.spacing(0, "auto"),
            maxWidth: 992,
        },
    },
    cards: {
        flexWrap: "wrap",
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(4),
        margin: theme.spacing(4, 0),
    },
    card: {
        position: "relative",
        padding: theme.spacing(4, 2),
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: theme.spacing(2),
        "& h6": {
            lineHeight: 1.2,
        },
        "& ul": {
            padding: theme.spacing(0, 2),
        },
        "& .icon": {
            position: "absolute",
            width: 40,
            height: 40,
            top: -20,
            left: "calc(50% - 20px)",
            backgroundColor: theme.palette.background.paper,
            borderRadius: "100%",
            boxShadow: theme.shadows[4],
            padding: 8,
        },
        [theme.breakpoints.up("sm")]: {
            maxWidth: 280,
        }
    },
    actions: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(1),
        "& button": {
            width: 200,
        },
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            flexDirection: "row",
            gap: theme.spacing(2),
        }
    }
}));

interface CardProps {
    title: string;
    points: string[]
    icon: React.ReactElement<SvgIconProps>;
}

function Card({ title, points, icon }: CardProps) {
    const classes = useStyles();

    return <Paper className={classes.card}>
        <Box className="icon" component="span">
            {icon}
        </Box>
        <Typography component="h6" variant="h6" align="center">
            {title}
        </Typography>
        <Typography component="ul" variant="body2" align="justify">
            {points.map((p, idx) => <li key={idx}>{p}</li>)}
        </Typography>
    </Paper>
}

export default function PresentationSection({ onRequestQuote }: { onRequestQuote: React.DispatchWithoutAction }) {
    const classes = useStyles();

    return <>
        <Box className={classes.wrapper}>
            <Box className={classes.container}>
                <Typography component="h4" variant="h4" color="primary">
                    <strong>Workshop Presentation</strong>
                </Typography>
                <Typography component="h6" variant="h6" color="textSecondary">
                    Our team remains at your disposal and guides you step by step.
                </Typography>

                <Box className={classes.cards}>
                    <Card
                        icon={<InitializeIcon />}
                        title="Initialize"
                        points={[
                            "Participants (collect and inscription)",
                            "Schedules",
                            "Multimodal imaging with overlay support",
                            "Compatible with Dicom and Rtstruct segmentation",
                            "Statements and instructions for the participants",
                            "Live preview before broadcast",
                        ]}
                    />
                    <Card
                        icon={<OngoingIcon />}
                        title="Ongoing"
                        points={[
                            "Segmentation tools such as paint, erase, brush, slice interpolation, margins and boolean contour operators to easily make your contouring delineations",
                            "Refer to associated guidelines directly accessible from working frame",
                            "Saving of the current work session",
                            "Modification of contours all along workshop duration",
                            "Send notification to participants to keep them motivated",
                            "Real time access of all participant results for organizer (anonymous)",
                        ]}
                    />
                    <Card
                        icon={<ResultsIcon />}
                        title="Results"
                        points={[
                            "All participants contours (anonymous)",
                            "Consolidated view (contour stack presentation)",
                            "Calculation of the best-known concordance indices (Dices, Haussdorff distance, Jacar, etc.)",
                            "Export results through various formats (such as Nifti, Dicom, etc.) and you can use them for your own analytics and publications",
                        ]}
                    />
                </Box>

                <Box className={classes.actions}>
                    <Button variant="contained" color="primary" onClick={onRequestQuote}>
                        request a quote
                    </Button>
                    <Link to={fullPath("AboutUs")}>
                        <Button variant="outlined" color="default"
                            startIcon={<InfoIcon />}
                        >
                            about us
                        </Button>
                    </Link>
                </Box>
            </Box>
        </Box>
    </>
}
