import React from "react";

import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import { CentralColumnLayout } from "components/Layouts";
import { Link } from "react-router-dom";
import { fullPath } from "tools/Route";
import { LinkedinIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        backgroundColor: theme.palette.grey[300],
    },
    layout: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(4, 2),
        marginBottom: 0,
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(4, 0),
        },
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
        }
    },
    iconLink: {
        color: "inherit",
        display: "flex",
        "&:hover": {
            color: theme.palette.text.secondary,
        }
    }
}));

const LINKS = [
    {
        label: "Contact us",
        href: fullPath("ContactUs")
    },
    {
        label: "About us",
        href: fullPath("AboutUs")
    },
    {
        label: "Terms of service",
        href: fullPath("TermsOfService")
    },
    {
        label: "Privacy policy",
        href: fullPath("PrivacyPolicy")
    },
    {
        label: "Cookies settings",
        href: fullPath("CookiesSettings")
    },
];

export default function Footer() {
    const classes = useStyles();
    return <Box className={classes.wrapper}>
        <CentralColumnLayout size="lg" className={classes.layout}>
            <Typography variant="subtitle2">
                © {new Date().getFullYear()} Dline Inc.
                {LINKS.map((x, idx) => <span key={idx}>
                    &nbsp;·&nbsp;
                    <Link to={x.href} className={classes.link}>{x.label}</Link>
                </span>)}
            </Typography>
            <a
                target="_blank"
                rel="noreferrer"
                title="Dline on LinkedIn"
                href="https://www.linkedin.com/company/dline-medical/"
                className={classes.iconLink}
            >
                <LinkedinIcon />
            </a>
        </CentralColumnLayout>
    </Box>
}
