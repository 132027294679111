import React from "react";
import { MenuItem } from "@material-ui/core";

import Select from "./Select";
import { OrientationType } from "domain/static/OrientationType";

export interface OrientationSelectProps {
    value: OrientationType;
    onChange: (newOrientation: OrientationType) => void;
}

export default function OrientationSelect({ value, onChange }: OrientationSelectProps) {
    return (
        <Select
            label="Orientation"
            value={value}
            onChange={onChange}
        >
            <MenuItem value={OrientationType.Coronal}>Coronal</MenuItem>
            <MenuItem value={OrientationType.Sagittal}>Sagittal</MenuItem>
            <MenuItem value={OrientationType.Axial}>Axial</MenuItem>
        </Select>
    );
}