//Generated code, do not modify

export enum ImagingDefault {
  None = 0,
  Primary = 1,
  Overlay = 2,
}

export const ImagingDefaultOptions = [
  {
    label: "None",
    value: ImagingDefault.None,
  },
  {
    label: "Primary",
    value: ImagingDefault.Primary,
  },
  {
    label: "Overlay",
    value: ImagingDefault.Overlay,
  },
];
