import React, { useCallback } from "react";

import { Spacer } from "components/Page";
import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { ReviewClinicalCaseCommand } from "domain/public/command/ReviewClinicalCaseCommand";
import { ClinicalCaseViewerResponse } from "domain/public/response/ClinicalCaseViewerResponse";
import { ViewerContextProvider } from "contexts/Viewer";
import PublicViewerContainer from "pages/ClinicalCases/Viewer/ViewerContainer";
import DraftAdvertisement from "./DraftAdvertisement";
import { ReviewerToolbar } from "components/ClinicalCase";
import { sendMessage } from "tools/Message";

export interface ViewerContainerProps {
    entity: ClinicalCaseViewerResponse;
}

export default function ViewerContainer({ entity }: ViewerContainerProps) {

    const onReviewHanlde = useCallback(async (accepted: boolean, comment: string) => {
        const message = new ReviewClinicalCaseCommand({ id: entity.id });
        message.accepted = accepted;
        message.comment = comment;

        await sendMessage(message);
    }, [entity.id]);

    return <>
        {entity.state === ClinicalCaseState.Draft &&
            <DraftAdvertisement name={entity.name} />
        }

        {(entity.state === ClinicalCaseState.Submitted ||
            entity.state === ClinicalCaseState.Published) &&
            <ViewerContextProvider>
                <PublicViewerContainer loading={false} entity={entity}>
                    <Spacer />
                    <ReviewerToolbar entity={entity} onReview={onReviewHanlde} />
                </PublicViewerContainer>
            </ViewerContextProvider>
        }
    </>
}
