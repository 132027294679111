//Generated code, do not modify

export enum FusionType {
  Cross = 0,
  Vertical = 1,
  Horizontal = 2,
  Opacity = 3,
}

export const FusionTypeOptions = [
  {
    label: "Cross",
    value: FusionType.Cross,
  },
  {
    label: "Vertical",
    value: FusionType.Vertical,
  },
  {
    label: "Horizontal",
    value: FusionType.Horizontal,
  },
  {
    label: "Opacity",
    value: FusionType.Opacity,
  },
];
