import { Guid } from "domain/static/Guid";
import { ImagingDefault } from "domain/static/ImagingDefault";
import { VolumeType } from "domain/static/VolumeType";
import { Range } from "domain/static/Range";
import { Coordinate } from "domain/static/Coordinate";
import { ContouringWorkshopState } from "domain/static/ContouringWorkshopState";
import { ContouringWorkshopUserRole } from "domain/static/ContouringWorkshopUserRole";
import { ContouringWorkshopUserPermission } from "domain/static/ContouringWorkshopUserPermission";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ContouringWorkshopViewerResponse {
    globalContentLength!: number;
    id!: Guid;
    name!: string;
    slug!: string;
    summary!: string;
    anatomicZoneName!: string;
    limitOfParticipants!: number;
    numberOfParticipant!: number;
    coverId!: Guid | null;
    startAt!: Date;
    endAt!: Date;
    state!: ContouringWorkshopState;
    userRole!: ContouringWorkshopUserRole;
    userPermission!: ContouringWorkshopUserPermission;
    isPublished!: boolean;
    publishedAt!: Date | null;
    images!: Image[];
    contours!: Contour[];
    articles!: Article[];
    contourArticles!: Article[];
    referrers!: User[];

    public static Fields = {
        globalContentLength: { name: "globalContentLength", type: "number" },
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        slug: { name: "slug", type: "string" },
        summary: { name: "summary", type: "string" },
        anatomicZoneName: { name: "anatomicZoneName", type: "string" },
        limitOfParticipants: { name: "limitOfParticipants", type: "number" },
        numberOfParticipant: { name: "numberOfParticipant", type: "number" },
        coverId: { name: "coverId", type: "Guid" },
        startAt: { name: "startAt", type: "Date" },
        endAt: { name: "endAt", type: "Date" },
        state: { name: "state", type: "ContouringWorkshopState" },
        userRole: { name: "userRole", type: "ContouringWorkshopUserRole" },
        userPermission: { name: "userPermission", type: "ContouringWorkshopUserPermission" },
        isPublished: { name: "isPublished", type: "boolean" },
        publishedAt: { name: "publishedAt", type: "Date" },
        images: { name: "images", type: "Image[]" },
        contours: { name: "contours", type: "Contour[]" },
        articles: { name: "articles", type: "Article[]" },
        contourArticles: { name: "contourArticles", type: "Article[]" },
        referrers: { name: "referrers", type: "User[]" },
    } as Record<keyof ContouringWorkshopViewerResponse, IField>;
}

export class Image {
    id!: Guid;
    name!: string;
    windowing!: Range;
    range!: Range;
    spacing!: Coordinate;
    size!: Coordinate;
    lut!: string;
    fileId!: Guid;
    fileContentLength!: number;
    default!: ImagingDefault;
    imagingModalityName!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        windowing: { name: "windowing", type: "Range" },
        range: { name: "range", type: "Range" },
        spacing: { name: "spacing", type: "Coordinate" },
        size: { name: "size", type: "Coordinate" },
        lut: { name: "lut", type: "string" },
        fileId: { name: "fileId", type: "Guid" },
        fileContentLength: { name: "fileContentLength", type: "number" },
        default: { name: "default", type: "ImagingDefault" },
        imagingModalityName: { name: "imagingModalityName", type: "string" },
    } as Record<keyof Image, IField>;
}

export class Contour {
    numberOfParticipantResult!: number;
    participantsContentLength!: number;
    id!: Guid;
    name!: string;
    content!: string;
    articleId!: Guid | null;
    color!: string;
    isEditable!: boolean;
    anatomicStructureName!: string;
    volumeType!: VolumeType;
    targetTypeName!: string;
    organizationName!: string;
    fileId!: Guid;
    fileContentLength!: number;
    stackFileId!: Guid | null;
    stackFileContentLength!: number;
    participantResults!: ParticipantResult[];

    public static Fields = {
        numberOfParticipantResult: { name: "numberOfParticipantResult", type: "number" },
        participantsContentLength: { name: "participantsContentLength", type: "number" },
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        content: { name: "content", type: "string" },
        articleId: { name: "articleId", type: "Guid" },
        color: { name: "color", type: "string" },
        isEditable: { name: "isEditable", type: "boolean" },
        anatomicStructureName: { name: "anatomicStructureName", type: "string" },
        volumeType: { name: "volumeType", type: "VolumeType" },
        targetTypeName: { name: "targetTypeName", type: "string" },
        organizationName: { name: "organizationName", type: "string" },
        fileId: { name: "fileId", type: "Guid" },
        fileContentLength: { name: "fileContentLength", type: "number" },
        stackFileId: { name: "stackFileId", type: "Guid" },
        stackFileContentLength: { name: "stackFileContentLength", type: "number" },
        participantResults: { name: "participantResults", type: "ParticipantResult[]" },
    } as Record<keyof Contour, IField>;
}

export class ParticipantResult {
    contourId!: Guid;
    participantId!: Guid;
    fileId!: Guid;
    fileContentLength!: number;
    dice!: number;
    updatedAt!: Date;

    public static Fields = {
        contourId: { name: "contourId", type: "Guid" },
        participantId: { name: "participantId", type: "Guid" },
        fileId: { name: "fileId", type: "Guid" },
        fileContentLength: { name: "fileContentLength", type: "number" },
        dice: { name: "dice", type: "number" },
        updatedAt: { name: "updatedAt", type: "Date" },
    } as Record<keyof ParticipantResult, IField>;
}

export class Article {
    id!: Guid;
    title!: string;
    authors!: string;
    date!: string;
    editor!: string;
    page!: string;
    doi!: string;
    pmid!: string;
    url!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        title: { name: "title", type: "string" },
        authors: { name: "authors", type: "string" },
        date: { name: "date", type: "string" },
        editor: { name: "editor", type: "string" },
        page: { name: "page", type: "string" },
        doi: { name: "doi", type: "string" },
        pmid: { name: "pmid", type: "string" },
        url: { name: "url", type: "string" },
    } as Record<keyof Article, IField>;
}

export class User {
    id!: Guid;
    firstname!: string;
    lastname!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        firstname: { name: "firstname", type: "string" },
        lastname: { name: "lastname", type: "string" },
    } as Record<keyof User, IField>;
}
