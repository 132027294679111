import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, createStyles, makeStyles, Theme, Typography, lighten } from "@material-ui/core";

import { fullPath } from "tools/Route";

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        position: "relative",
        backgroundColor: lighten(theme.palette.primary.light, 0.6),
        '&::before': {
            content: '""',
            display: "block",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            opacity: 0.1,
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/atlas-bg.png)`,
            backgroundBlendMode: "normal",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
        }
    },
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(12, 1),
        margin: 0,
        gap: theme.spacing(4),
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            padding: theme.spacing(12, 0),
            margin: theme.spacing(0, "auto"),
            maxWidth: 992,
        },
    },
    title: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        textAlign: "center",
        zIndex: 1,
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            textAlign: "start",
            maxWidth: "60%",
        }
    },
    actions: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(1),
        zIndex: 1,
        "& button": {
            width: 200,
        },
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            flexDirection: "row",
            gap: theme.spacing(2),
        }
    },
    cover: {
        height: 320,
        width: "100%",
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/devices-atlas.png)`,
        backgroundSize: "contain",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        zIndex: 0,
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            position: "absolute",
            top: theme.spacing(4),
            right: 0,
            height: "85%",
            width: "100%",
            backgroundPosition: "center right",
        }
    },
}));

export default function BannerSection() {
    const classes = useStyles();

    return <Box className={classes.wrapper}>
        <Box className={classes.container}>            
            <Box className={classes.title}>
                <Typography component="h1" variant="h4">
                    Contouring guidelines and training for radiation-oncologist
                </Typography>
                <Typography component="h2" variant="h6" color="textSecondary">
                    Interactive clinical cases based on international contouring guidelines and easy to use contouring workshops service.
                </Typography>
            </Box>

            <Box className={classes.actions}>
                <Link to={fullPath("ListClinicalCases")}>
                    <Button variant="contained" color="primary">
                        browse atlas
                    </Button>
                </Link>
                <Link to={fullPath("Workshops")}>
                    <Button variant="outlined" color="primary">
                        about workshops
                    </Button>
                </Link>
            </Box>

            <Box className={classes.cover} />
        </Box>
    </Box>
}
