import React from "react";
import {
    makeStyles, Theme, createStyles,
    Box, Typography
} from "@material-ui/core";
import { Devices as MobileUnsupportedIcon } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => createStyles({
    mobile_wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
        backgroundColor: theme.palette.grey[900],
        flexDirection: "column",
        color: theme.palette.getContrastText(theme.palette.grey[900]),
        "& svg": {
            fontSize: "4em",
        },
    },
    mobile_text: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    }
}));

export interface MobileNotSupportedProps {
    children?: React.ReactNode;
}

export default function MobileNotSupported({ children }: MobileNotSupportedProps) {
    const classes = useStyles();

    return <Box className={classes.mobile_wrapper}>
        <MobileUnsupportedIcon fontSize="large" color="inherit" />
        <Typography className={classes.mobile_text}>
            Mobile device not supported for this feature
        </Typography>
        {children}
    </Box>
}
