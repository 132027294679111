import {
  IRefreshJwtService,
  IRequest,
  ISerializer,
  IJwtStorage,
} from "cocoreact";
import { AccessTokenResponse } from "domain/public/response/AccessTokenResponse";
import RefreshAccessToken from "./RefreshAccessToken";

export default class RefreshJwtService implements IRefreshJwtService {
  responseErrorCode: number;
  private _jwtStorage: IJwtStorage;
  private _serializer: ISerializer;
  private _refreshToken: RefreshAccessToken;

  constructor(
    responseErrorCode: number,
    jwtStorage: IJwtStorage,
    serializer: ISerializer,
    refreshToken: RefreshAccessToken
  ) {
    this.responseErrorCode = responseErrorCode;
    this._jwtStorage = jwtStorage;
    this._serializer = serializer;
    this._refreshToken = refreshToken;
  }

  getToken(): string {
    const token = this._jwtStorage.get();
    if (token !== null) {
      return token;
    }
    throw new Error("non-existant jwt token !");
  }

  setToken(token: string): void {
    this._refreshToken.update(token);
    this._jwtStorage.set(token);
  }

  buildRefreshRequest(_failedOn: any): IRequest {
    const query = this._refreshToken.getRefreshQuery();
    return this._serializer.serializeMessage(query);
  }

  getNewTokenFromResponse(response: any): string {
    const tmp = this._serializer.deserialize<AccessTokenResponse>(response);
    return tmp.accessToken;
  }
}
