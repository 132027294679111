import React from "react"
import clsx from "clsx";
import {
    createStyles, makeStyles, Theme,
    ListItem, ListItemAvatar, ListItemIcon, ListItemText, Switch, Typography,
} from "@material-ui/core"
import { ArrowDownIcon, ArrowUpIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        minHeight: 64,
        maxHeight: 64,
        borderBottom: `1px ${theme.palette.divider} solid`,
        "& > .MuiListItemAvatar-root": {
            marginRight: theme.spacing(1),
        },
        "& > .MuiListItemIcon-root": {
            marginRight: theme.spacing(-2),
        },
        backgroundColor: theme.palette.action.selected,
        "&:hover": {
            backgroundColor: theme.palette.action.focus
        },
    },
    small: {
        minHeight: 48,
        maxHeight: 48,
    }
}));

export interface ContourSeparatorProps {
    label: string;
    checked: boolean;
    onCheck: (checked: boolean) => void;
    collapsed?: boolean;
    onCollapse?: (collapsed: boolean) => void;
    disabled?: boolean;
    indicator?: React.ReactNode;
    size?: "normal" | "small";
}

export default function ContourSeparator({
    label, checked, onCheck, collapsed, onCollapse, disabled, indicator, size
}: ContourSeparatorProps) {
    const classes = useStyles();

    const onCheckHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        onCheck(!checked);
    };

    const className = clsx(
        classes.root,
        size === "small" ? classes.small : undefined
    );

    if (collapsed === undefined || onCollapse === undefined) {
        return <ListItem className={className}>
            <ListItemAvatar>
                <Switch checked={checked} onClick={onCheckHandle} disabled={disabled} />
            </ListItemAvatar>

            <ListItemText
                primary={<Typography component="p" variant="button">{label}</Typography>}
            />

            {indicator}
        </ListItem>
    }

    return <ListItem button className={className} onClick={() => onCollapse(!collapsed)}>
        <ListItemAvatar>
            <Switch checked={checked} onClick={onCheckHandle} disabled={disabled} />
        </ListItemAvatar>

        <ListItemText
            primary={<Typography component="p" variant="button">{label}</Typography>}
        />

        {indicator}

        <ListItemIcon>
            {collapsed === true ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </ListItemIcon>
    </ListItem>
}
