import { Guid } from "domain/static/Guid";
import { IField } from "cocoreact";

//Generated code, do not modify

export class UserPublicInformationsResponse {
    id!: Guid;
    firstname!: string;
    lastname!: string;
    status!: string;
    abstract!: string;
    professionalLink!: string;
    scientificLink!: string;
    createdAt!: Date;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        firstname: { name: "firstname", type: "string" },
        lastname: { name: "lastname", type: "string" },
        status: { name: "status", type: "string" },
        abstract: { name: "abstract", type: "string" },
        professionalLink: { name: "professionalLink", type: "string" },
        scientificLink: { name: "scientificLink", type: "string" },
        createdAt: { name: "createdAt", type: "Date" },
    } as Record<keyof UserPublicInformationsResponse, IField>;
}
