import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class GetUserPublicInformationsQuery implements IMessage {
    id!: Guid;

    public constructor(init?: Partial<GetUserPublicInformationsQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/users/${this.id.toString()}/informations`;

    getQueryString = () => undefined;

    getBody = () => undefined;

    needAuthentication = () => false;
}
