import { Guid } from "domain/static/Guid";
import { FileParentType } from "domain/static/FileParentType";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class CreateFileCommand implements IMessage {
    fileName!: string;
    mimeType!: string;
    base64Content!: string;
    parentType!: FileParentType;
    parentId!: Guid | null;

    public constructor(init?: Partial<CreateFileCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST" as RequestMethod;

    getPath = () => `/files`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            fileName: this.fileName,
            mimeType: this.mimeType,
            base64Content: this.base64Content,
            parentType: this.parentType,
            parentId: this.parentId,
        };
    }
    
    needAuthentication = () => true;
}
