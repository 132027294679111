import React from "react";
import {
    Tabs as MuiTabs, Tab, Divider, TabsProps as MuiTabsProps,
    Box, createStyles, makeStyles
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => createStyles({
    tabContent: {
        width: "100%",
        overflow: "hidden",
        overflowY: "auto",
    },
}));
export interface TabContentProps {
    hidden?: boolean;
    children: any;
    className?: string;
}

export function TabContent({ hidden, children, className }: TabContentProps) {
    const classes = useStyles();
    const allClassName = clsx(
        classes.tabContent,
        hidden === true ? undefined : className
    );
    return <Box hidden={hidden} className={allClassName}>
        {children}
    </Box>
}

export type InformationTab = "summary" | "contours";

export interface TabsProps {
    value: InformationTab;
    onChange: (newTab: InformationTab) => void;
    textColor?: MuiTabsProps["textColor"];
    indicatorColor?: MuiTabsProps["indicatorColor"];
    className?: MuiTabsProps["className"];
    classes?: MuiTabsProps["classes"];
}

export default function Tabs({ value, onChange, ...tabsProps }: TabsProps) {
    return <>
        <MuiTabs
            variant="fullWidth"
            onChange={(_e, newTab: InformationTab) => onChange(newTab)}
            value={value}
            {...tabsProps}
        >
            <Tab label="summary" value={"summary" as InformationTab} />
            <Tab label="contours" value={"contours" as InformationTab} />
        </MuiTabs>

        <Divider />
    </>
}