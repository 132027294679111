import React from "react";
import { Link } from "react-router-dom";
import {
    Tabs, Tab,
    makeStyles, Theme, createStyles
} from "@material-ui/core";

import { APP_MENU, MenuTab } from "App";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
    },
    indicator: {
        backgroundColor: theme.palette.background.default,
    },
    tab: {
        height: 56,
        padding: 0,
    },
    link: {
        height: 56,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    hidden: {
        display: "none",
    }
}));

export interface MenuTabsProps {
    tab?: MenuTab;
}

export default function MenuTabs({ tab }: MenuTabsProps) {
    const classes = useStyles();

    const hidden_tabs = ["account", "empty_tab"]

    return <Tabs
        value={tab ?? "empty_tab"}
        centered={true}
        aria-label="main menu tabs"
        classes={{
            root: classes.root,
            indicator: classes.indicator
        }}
    >
        {APP_MENU
            .filter(x => !hidden_tabs.includes(x.tab))
            .map(item =>

                <Tab
                    key={item.tab}
                    value={item.tab}
                    label={
                        <Link to={item.href} className={classes.link}>
                            {item.label}
                        </Link>
                    }
                    className={classes.tab}
                />
            )}

        {hidden_tabs.map(x =>
            <Tab value={x} key={x} hidden className={classes.hidden} />)
        }
    </Tabs>
}