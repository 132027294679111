import { Guid } from "domain/static/Guid";
import { UserRole } from "domain/static/UserRole";
import { IField } from "cocoreact";

//Generated code, do not modify

export class ProfileInformationsResponse {
    id!: Guid;
    lastname!: string;
    firstname!: string;
    email!: string;
    role!: UserRole;
    status!: string;
    abstract!: string;
    professionalLink!: string;
    scientificLink!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        lastname: { name: "lastname", type: "string" },
        firstname: { name: "firstname", type: "string" },
        email: { name: "email", type: "string" },
        role: { name: "role", type: "UserRole" },
        status: { name: "status", type: "string" },
        abstract: { name: "abstract", type: "string" },
        professionalLink: { name: "professionalLink", type: "string" },
        scientificLink: { name: "scientificLink", type: "string" },
    } as Record<keyof ProfileInformationsResponse, IField>;
}
