import React from "react";
import { Helmet } from "react-helmet-async";
import { createStyles, Grid, makeStyles, Switch, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from "@material-ui/core";

import { useWindowEffectScrollTop } from "tools/useWindowEffectScrollTop";
import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { Footer } from "components/Page";
import { useCookiesConsent } from "App/CookiesSettings";

const useStyles = makeStyles((theme: Theme) => createStyles({
    layout: {
        margin: theme.spacing(6, 2),
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            margin: theme.spacing(6, "auto"),
        }
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    subtitle: {
        margin: theme.spacing(2, 0, 1, 0),
    },
    p: {
        margin: theme.spacing(1, 0),
    },
    table: {
        "& th": {
            padding: theme.spacing(1, 2),
            backgroundColor: theme.palette.grey[200],
        },
        "& tr": {
            border: `1px ${theme.palette.divider} solid`,
        },
        "& tbody td": {
            verticalAlign: "center",
            padding: theme.spacing(1, 2),
        },
    }
}));

export default function CookiesSettingsPage() {

    const classes = useStyles();

    useWindowEffectScrollTop();
    const [consent, setConsent] = useCookiesConsent();

    return <PageLayout tab={undefined}>

        <Helmet>
            <title>Dline - Cookies settings</title>
        </Helmet>

        <CentralColumnLayout size="md" className={classes.layout}>

            <Typography variant="h5" component="h1" className={classes.title}>
                Cookies settings
            </Typography>

            <Typography component="p" className={classes.p}>
                When you visit a website, certain information can be collected, mainly in the form of cookies.
                This information may relate to your preferences, to the device you are using, or to you personally.
                They are mainly used to make the site work as you expect.
                They generally do not identify you directly, but they can give you a more personalized browsing
                experience on the site. As we respect your right to privacy, you can choose not to allow certain
                types of cookies. Click on the different category headings to learn more and change our default settings.
                Be aware, however, that blocking certain types of cookies may have an impact on your use of
                the site and on the services we can offer.
            </Typography>

            <Typography variant="h6" component="h3" className={classes.subtitle}>
                Strictly necessary cookies
            </Typography>

            <Typography component="p" className={classes.p}>
                These cookies are necessary for the website to function and cannot be disabled in our systems.
                They are generally only filed in response to actions by you corresponding to a request for
                services, such as setting your privacy preferences, logging in.
            </Typography>

            <Grid container justifyContent="center">
                <Grid item xs={12} sm={8} md={6}>
                    <Table padding="none" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Deactivate all</TableCell>
                                <TableCell align="center">
                                    <Switch checked={true} disabled={true} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Security</TableCell>
                                <TableCell align="center">
                                    <Switch checked={true} disabled={true} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>User Experience</TableCell>
                                <TableCell align="center">
                                    <Switch checked={true} disabled={true} />
                                </TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell>Language settings</TableCell>
                                <TableCell align="center">
                                    <Switch checked={true} disabled={true} />
                                </TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell>Dline</TableCell>
                                <TableCell align="center">
                                    <Switch checked={true} disabled={true} />
                                </TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell>Google Recaptcha</TableCell>
                                <TableCell align="center">
                                    <Switch checked={true} disabled={true} />
                                </TableCell>
                            </TableRow> */}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

            <Typography variant="h6" component="h3" className={classes.subtitle}>
                Fonctionnal cookies
            </Typography>

            <Typography component="p" className={classes.p}>
                These cookies allow us to count the number of visits and traffic sources, in order to measure
                and improve the performance of our site. They help us to know which pages are more or less
                visited, and how visitors navigate through the site. If you do not allow these cookies,
                they cannot be used to improve site performance.
            </Typography>

            <Grid container justifyContent="center">
                <Grid item xs={12} sm={8} md={6}>
                    <Table padding="none" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Deactivate all</TableCell>
                                <TableCell align="center">
                                    <Switch checked={consent ?? false} onChange={() => setConsent(!consent)} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Google Analytics</TableCell>
                                <TableCell align="center">
                                    <Switch checked={consent ?? false} onChange={() => setConsent(!consent)} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

        </CentralColumnLayout>

        <Footer />

    </PageLayout>
}


