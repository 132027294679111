import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class PublicReviewClinicalCaseCommand implements IMessage {
    id!: Guid;
    token!: string;
    accepted!: boolean;
    comment!: string;

    public constructor(init?: Partial<PublicReviewClinicalCaseCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/clinical-cases/${this.id.toString()}/review`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            token: this.token,
            accepted: this.accepted,
            comment: this.comment,
        };
    }
    
    needAuthentication = () => false;
}
