import React from 'react'
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import { SupportUsIcon } from 'App/Theme';

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(6, 1),
        margin: 0,
        gap: theme.spacing(1),
        textAlign: "center",
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            padding: theme.spacing(6, 0),
            margin: theme.spacing(0, "auto"),
            maxWidth: 992,
        },
    },
    list: {
        listStyle: "none",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: 0,
        margin: theme.spacing(2, 0),
        gap: theme.spacing(8),
    }
}));

export default function SupportSection() {
    const classes = useStyles();

    return <Box className={classes.container}>
        <Typography component="h4" variant="h4">
            <strong>They Support Us</strong>
        </Typography>
        <SupportUsIcon color="action" />

        <ul className={classes.list}>
            <li>
                <a href="https://www.sfjro.fr" target="_blank" rel="noreferrer">
                    <img src={`${process.env.PUBLIC_URL}/img/logo_sfjro.jpg`} alt="logo SFjRO" height={100} width="auto" />
                </a>
            </li>
            <li>
                <a href="https://www.radioterapiaitalia.it/airo-giovani/" target="_blank" rel="noreferrer">
                    <img src={`${process.env.PUBLIC_URL}/img/logo_airo.png`} alt="logo AIRO" height={100} width="auto" />
                </a>
            </li>
            <li>
                <img src={`${process.env.PUBLIC_URL}/img/logo_gfrp.jpg`} alt="logo GFRP" height={100} width="auto" />
            </li>
        </ul>
    </Box>
}
