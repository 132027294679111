import React from "react";
import { Box, Typography as MuiTypography } from "@material-ui/core";

import { Typography } from "components/Page";
import ProfileInformationsForm from "./ProfileInformationsForm";
import { EditIcon } from "App/Theme";

export default function ProfileInformationSection() {
  return <Box component="section">
    <Box marginBottom={2}>
      <Typography variant="h5" startIcon={<EditIcon fontSize="small" />}>
        Edit my informations
      </Typography>
    </Box>
    <ProfileInformationsForm />

    <MuiTypography component="p" variant="body2">
      Note : all previous informations are public for all people acceding to dline.io (member or ananoymous).
      Be carefull about informations you decide to show at public.
    </MuiTypography>

  </Box>
}
