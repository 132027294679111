import React from "react";

import { Guid } from "domain/static/Guid";
import { ContouringWorkshopState, ContouringWorkshopStateOptions } from "domain/static/ContouringWorkshopState";
import { Card as ClinicalDataCard } from "components/ClinicalData";
import { removeDuplicates } from "tools/ArrayExtension";
import { ContouringWorkshopUserRole, ContouringWorkshopUserRoleOptions } from "domain/static/ContouringWorkshopUserRole";
import { enumValueToString } from "tools/EnumExtension";
import { formatDistance } from "tools/DateExtension";

export interface ICardData {
    name: string;
    summary: string;
    startAt: Date;
    endAt: Date;
    state: ContouringWorkshopState;
    userRole?: ContouringWorkshopUserRole;
    coverId?: Guid | null;
    anatomicZoneName: string;
    imagingModalities?: string[];
}

export interface CardProps {
    href: string;
    value: ICardData;
}

export default function Card({ href, value }: CardProps) {
    const imagingModalities = value.imagingModalities ? removeDuplicates(value.imagingModalities) : [];
    const userRoleLabel = value.userRole ? enumValueToString(value.userRole, ContouringWorkshopUserRoleOptions) : undefined;
    const stateLabel = value.state === ContouringWorkshopState.Present
        ? `${formatDistance(value.endAt)} left`
        : enumValueToString(value.state, ContouringWorkshopStateOptions);


    return <ClinicalDataCard
        href={href}
        coverId={value.coverId ?? undefined}
        title={value.name}
        anatomicZone={value.anatomicZoneName}
        content={value.summary}
        imagingModalities={imagingModalities}
        role={userRoleLabel}
        subRole={stateLabel}
    />
}
