import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    makeStyles, Theme, createStyles,
    Box, Paper, Slide, Button, Typography
} from "@material-ui/core";
import { useNotificationContext } from "cocoreact";
import { Prompt } from "react-router-dom";

import { Guid } from "domain/static/Guid";
import { ContouringWorkshopViewerResponse, Contour } from "domain/public/response/ContouringWorkshopViewerResponse";
import { UpdateParticipantContoursCommand } from "domain/public/command/UpdateParticipantContoursCommand";
import ContourListPanel from "./ContourListPanel";
import { useViewerContext } from "contexts/Viewer";
import { SaveIcon } from "App/Theme";
import { ContourDrawn } from "tools/Viewer";
import { FilePartUploader } from "tools/FileExtension";
import { toArrayBuffer } from "tools/StringExtension";
import { sendMessage } from "tools/Message";
import ContourPanel from "./ContourPanel";
import { EditableVoxelVolume } from "dline-viewer/dist/data";
import { formatLongDateTime } from "tools/DateExtension";
import { HeaderCover, Tabs, InformationTab, TabContent } from "components/ClinicalData";
import { Spacer } from "components/Page";
import { SummaryPanel } from "components/ContouringWorkshop";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: theme.mixins.viewer.smallWidth,
        minWidth: theme.mixins.viewer.smallWidth,
        maxWidth: theme.mixins.viewer.smallWidth,
        zIndex: theme.zIndex.drawer,
    },
    actions: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        padding: theme.spacing(2, 4),
        borderTop: `1px ${theme.palette.divider} solid`,
        boxShadow: theme.shadows[8],
        "& > *": {
            marginBottom: theme.spacing(1),
        },
        "& > :last-child": {
            marginBottom: 0,
        },
    },
}));

async function updateContoursCommand(
    command: UpdateParticipantContoursCommand,
    contours: Contour[],
    contoursDrawn: ContourDrawn[]
) {
    const fileUploader = new FilePartUploader(
        "ReadOnlyROI_polygonVolume.json",
        "application/json"
    );

    command.contours = [];
    for (const contour of contours) {
        if (contour.isEditable) {
            const id = contour.id;
            let fileId = contour.participantResults.length > 0 ?  contour.participantResults[0].fileId : null;
            let dice = contour.participantResults.length > 0 ?  contour.participantResults[0].dice : 0.0;
    
            const contourDrawn = contoursDrawn.find(x => x.id === id);
            if (contourDrawn) {
                fileId = null;
                dice = contourDrawn.dice;
                if (contourDrawn.polygonVolume) {
                    const dataRoi = JSON.stringify(contourDrawn.polygonVolume);
                    fileId = await fileUploader.send(toArrayBuffer(dataRoi));
                }
            }
    
            command.contours.push({ id, fileId, dice });
        }
    }
}

export interface DrawerProps {
    open: boolean;
    contouringWorkshop: ContouringWorkshopViewerResponse;
}

export default function Drawer({ open, contouringWorkshop }: DrawerProps) {
    const classes = useStyles();

    const [tab, setTab] = useState<InformationTab>("summary");
    const [selectContour, setSelectContour] = useState<Guid | null>(null);

    const { success, error } = useNotificationContext();
    const {
        app: viewerApp,
        dispatcher: viewerDispatcher,
        data: viewerData
    } = useViewerContext();

    const worker = useRef(new Worker(`${process.env.PUBLIC_URL}/workers/viewer.worker.js`));

    const onUnselectHandle = useCallback(() => {
        setSelectContour(null);
        viewerDispatcher({
            type: "CONTOUR_DRAWING",
            contourId: null,
        });
    }, [viewerDispatcher]);

    const workerMessageHandler = React.useCallback(async (ev: MessageEvent) => {
        const contourId = ev.data.contourId as Guid;
        const editableVoxelVolume = ev.data.editableVoxelVolume as EditableVoxelVolume;

        const contourHelper = viewerApp.getContourById(contourId);
        if (contourHelper) {
            await contourHelper.updateEditableVolume(editableVoxelVolume);

            viewerDispatcher({
                type: "LOADING",
                loading: false,
            }, "data");

            await viewerDispatcher({
                type: "CONTOUR_DRAWING",
                contourId: contourId,
            });
        }
    }, [viewerApp, viewerDispatcher]);

    const onSelectHandle = useCallback(async (contourId: Guid) => {
        setSelectContour(contourId);

        const contour = contouringWorkshop.contours.find(x => x.id === contourId);
        if (!contour || contour.isEditable !== true) return;

        const contourHelper = viewerApp.getContourById(contourId);
        const readonlyRoi = contourHelper ? contourHelper.getReadonlyRoi() : null;
        if (readonlyRoi && contourHelper && contourHelper.mode !== "drawing") {
            viewerDispatcher({
                type: "LOADING",
                loading: true,
            }, "data");
            await contourHelper.initializeEditableVolume();

            worker.current.onmessage = workerMessageHandler;
            worker.current.postMessage({
                msg: "POLYGON_TO_VOXEL",
                polygonVolume: readonlyRoi.polygonVolume,
                contourId: contourId,
            });
        }
        else if (contourHelper && contourHelper.mode === "drawing") {
            await viewerDispatcher({
                type: "CONTOUR_DRAWING",
                contourId: contourId,
            });
        }
    }, [contouringWorkshop.contours, viewerApp, viewerDispatcher, workerMessageHandler]);

    const onSaveContoursHandle = useCallback(async () => {
        try {
            const referencesContours = viewerData.getContoursReferences();
            const contoursDrawn = viewerApp.getContoursDrawn(referencesContours);
            const command = new UpdateParticipantContoursCommand({ id: contouringWorkshop.id });
            await updateContoursCommand(command, contouringWorkshop.contours, contoursDrawn);

            await sendMessage(command);

            success("You contours has been save with success");
        }
        catch (e) {
            error("An error occured while saving your contours !")
        }

    }, [viewerData, viewerApp, contouringWorkshop.id, contouringWorkshop.contours, success, error]);

    useEffect(() => {
        window.onbeforeunload = () => true;
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    return <>
        <Prompt
            message="Did you save your contours ? Are you sure to leave the page ?"
        />

        <Slide in={open} direction="left">
            <Paper className={classes.root} elevation={12} square={true}>

                <HeaderCover
                    title={contouringWorkshop.name}
                    coverId={contouringWorkshop.coverId}
                    size="small"
                />

                <Tabs
                    value={tab}
                    onChange={setTab}
                    textColor="primary"
                    indicatorColor="primary"
                />

                <TabContent hidden={tab !== "summary"}>
                    <SummaryPanel contouringWorkshop={contouringWorkshop} />
                </TabContent>

                <TabContent hidden={tab !== "contours" || selectContour !== null}>
                    <ContourListPanel
                        contours={contouringWorkshop.contours}
                        onClick={onSelectHandle}
                    />
                </TabContent>

                <TabContent hidden={tab !== "contours" || selectContour === null}>
                    <ContourPanel
                        contourId={selectContour as Guid}
                        onClose={onUnselectHandle}
                        contouringWorkshop={contouringWorkshop}
                    />
                </TabContent>

                <Spacer />

                <Box
                    hidden={tab !== "contours"}
                    className={classes.actions}
                >
                    <Typography variant="caption" component="p">
                        Save your contours as any times as you wan't
                        until <strong>{formatLongDateTime(contouringWorkshop.endAt)}</strong>.
                    </Typography>

                    <Button
                        fullWidth
                        size="large"
                        color="primary"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        onClick={onSaveContoursHandle}
                    >
                        save contours
                    </Button>
                </Box>
            </Paper>
        </Slide >
    </>
}
