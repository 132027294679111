import { useCallback, useEffect, useMemo, useState } from "react";
import { EmptyGuid } from "domain/static/Guid";
import { GetClinicalCaseViewerQuery } from "domain/public/query/GetClinicalCaseViewerQuery";
import { ClinicalCaseViewerResponse } from "domain/public/response/ClinicalCaseViewerResponse";
import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { sendMessage } from "tools/Message";
import { ClinicalCaseUserRole } from "domain/static/ClinicalCaseUserRole";

export function EmptyClinicalCaseViewerResponse() {
  const response = new ClinicalCaseViewerResponse();
  response.id = EmptyGuid();
  response.name = "";
  response.slug = "";
  response.summary = "";
  response.anatomicZoneName = "";
  response.state = ClinicalCaseState.None;
  response.coverId = null;
  response.submittedAt = null;
  response.publishedAt = null;
  response.reviewedAt = null;
  response.reviewAccepted = null;
  response.globalContentLength = 0;
  response.userRole = ClinicalCaseUserRole.None;
  response.images = [];
  response.contours = [];
  response.articles = [];
  response.contourArticles = [];
  response.editors = [];
  response.reviewers = [];
  return response;
}

export default function useClinicalCaseViewer(
  slug: string
): [boolean, ClinicalCaseViewerResponse, Error | null] {
  const query = useMemo(
    () =>
      new GetClinicalCaseViewerQuery({
        slug,
      }),
    [slug]
  );

  const [state, setState] = useState({
    loading: true,
    data: EmptyClinicalCaseViewerResponse(),
    error: null as Error | null,
  });

  const updateData = useCallback(async () => {
    setState((s) => ({ ...s, loading: true, error: null }));
    try {
      const data = await sendMessage<ClinicalCaseViewerResponse>(query);
      setState((s) => ({ data, loading: false, error: null }));
    } catch (e) {
      setState((s) => ({ ...s, loading: false, error: e as any }));
    }
  }, [query]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return [state.loading, state.data, state.error];
}
