import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class GetPublicReviewerClinicalCaseQuery implements IMessage {
    id!: Guid;
    token!: string;

    public constructor(init?: Partial<GetPublicReviewerClinicalCaseQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/clinical-cases/${this.id.toString()}/review`;

    getQueryString = () => {
        return {
            token: this.token,
        }
    }
    

    getBody = () => undefined;

    needAuthentication = () => false;
}
