import React from "react";

import { Guid } from "domain/static/Guid";
import { ClinicalCaseState, ClinicalCaseStateOptions } from "domain/static/ClinicalCaseState";
import { Card as ClinicalDataCard } from "components/ClinicalData";
import { removeDuplicates } from "tools/ArrayExtension";
import { ClinicalCaseUserRole, ClinicalCaseUserRoleOptions } from "domain/static/ClinicalCaseUserRole";
import { enumValueToString } from "tools/EnumExtension";

export interface ICardData {
    name: string;
    summary: string;
    date: Date;
    state?: ClinicalCaseState;
    userRole?: ClinicalCaseUserRole;
    coverId?: Guid | null;
    anatomicZoneName: string;
    imagingModalities?: string[];
}

export interface CardProps {
    href: string;
    value: ICardData;
}

export default function Card({ href, value }: CardProps) {
    const imagingModalities = value.imagingModalities ? removeDuplicates(value.imagingModalities) : [];
    const userRoleLabel = value.userRole ? enumValueToString(value.userRole, ClinicalCaseUserRoleOptions) : undefined;
    const stateLabel = value.state ? enumValueToString(value.state, ClinicalCaseStateOptions) : undefined;

    return <ClinicalDataCard
        href={href}
        coverId={value.coverId ?? undefined}
        title={value.name}
        anatomicZone={value.anatomicZoneName}
        content={value.summary}
        imagingModalities={imagingModalities}
        role={userRoleLabel}
        subRole={stateLabel}
    />
}
