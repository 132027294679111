import React, { useState } from "react";
import {
    makeStyles, createStyles, Theme,
    AppBar, Toolbar as MuiToolbar, Slide, useScrollTrigger, Typography, Divider
} from "@material-ui/core";

import Toolbar, { ToolbarProps } from "App/Toolbar";
import clsx from "clsx";
import { FeedbackDialog, OnMobile } from "components/Page";
import { APP_MENU } from "App";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => createStyles({
    toolbar: theme.mixins.toolbar,
    content: {
        position: "relative",
        flexGrow: 1,
        overflow: "auto",
    },
}));

interface PageLayoutStyles {
    toolbar: string;
    content: string;
}

export interface PageContentProps {
    classes?: Partial<PageLayoutStyles>;
    children?: React.ReactNode | React.ReactNode[];
}

export function PageContent({ classes, children }: PageContentProps) {
    const styles = useStyles();

    return <main className={clsx(styles.content, classes?.content)}>
        <div className={clsx(styles.toolbar, classes?.toolbar)} />
        {children}
    </main>
}

export interface PageLayoutProps {
    mobileActionType?: ToolbarProps["mobileActionType"];
    mobileTitle?: ToolbarProps["mobileTitle"];
    mobileActions?: ToolbarProps["mobileActions"];
    tab?: ToolbarProps["tab"];
    hideMobileBar?: boolean;
    children?: PageContentProps["children"];
    classes?: PageContentProps["classes"];
}

export default function PageLayout({
    hideMobileBar, children, classes, ...toolbarProps
}: PageLayoutProps) {

    const [feedbackOpen, setFeedbackOpen] = useState(false);

    return <>
        <Toolbar
            onFeedbackClick={() => setFeedbackOpen(true)}
            {...toolbarProps}
        />

        <FeedbackDialog open={feedbackOpen} onClose={() => setFeedbackOpen(false)} />

        <PageContent classes={classes}>{children}</PageContent>

        <OnMobile>
            <MobileAppBar tab={toolbarProps.tab} hidden={hideMobileBar === true} />
        </OnMobile>

    </>

}

interface MobileAppBarProps {
    tab?: ToolbarProps["tab"];
    hidden: boolean;
}

function MobileAppBar({ tab, hidden }: MobileAppBarProps) {
    const trigger = useScrollTrigger();

    if (hidden === true) {
        return null
    }

    return <Slide appear={false} direction="up" in={!trigger}>

        <AppBar position="fixed" style={{ top: "auto", bottom: 0, width: "100%" }} color="default" elevation={8}>
            <Divider />
            <MuiToolbar style={{ padding: 0, width: "100%", display: "flex" }}>

                {APP_MENU
                    .filter(x => x.tab !== "about")
                    .map(x =>
                        <Link
                            to={x.href}
                            key={x.tab}
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                color: tab === x.tab ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.54)"
                            }}
                        >
                            {tab === x.tab ? x.selectedIcon : x.icon}
                            <Typography variant="body2" component="span">
                                {x.label}
                            </Typography>
                        </Link>
                    )}

            </MuiToolbar>
        </AppBar>

    </Slide>
}