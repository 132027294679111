import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
    Box, Button, CircularProgress, ClickAwayListener,
    Divider, Fab, Grow, MenuItem, MenuList, Paper,
    Popper, Typography
} from "@material-ui/core";
import AccountIcon from "@material-ui/icons/AccountCircleOutlined";

import { useAuthContext } from "contexts/Auth";
import { fullPath } from "tools/Route";
import { IAuthPayload } from "services";

function Loader() {
    return <Fab size="small" color="primary">
        <CircularProgress size={16} color="inherit" />
    </Fab>
}

function SignInButton() {
    const location = useLocation();
    const return_to = encodeURI(location.pathname + location.search)
    const url = fullPath("SignIn", { queryString: { return_to } });

    return <Link to={url}>
        <Button
            color="inherit"
            variant="outlined"
            size="medium"
            startIcon={<AccountIcon />}
        >
            sign in
        </Button>
    </Link>
}

type ProfileButtonProps = {
    payload: IAuthPayload;
}

function ProfileButton({ payload }: ProfileButtonProps) {
    const { setToken } = useAuthContext();
    const location = useLocation();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    React.useEffect(() => setOpen(false), [location.pathname]);

    return <>
        <Fab size="small" color="primary" ref={anchorRef} onClick={handleToggle}>
            {payload.firstname[0].toUpperCase()}
            {payload.lastname[0].toUpperCase()}
        </Fab>

        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            style={{ zIndex: 1300, marginTop: 8 }}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow">
                                <Box display="flex" flexDirection="column" paddingY={2} paddingLeft={3} maxWidth={380}>
                                    <Typography variant="body2">
                                        Signed in as <strong>{payload.firstname} {payload.lastname}</strong>
                                    </Typography>
                                </Box>
                                <Divider />
                                <MenuItem component={Link} style={{ paddingLeft: 24, minWidth: 220 }} to={fullPath("Profile")}>Your profile</MenuItem>
                                <MenuItem component={Link} style={{ paddingLeft: 24, minWidth: 220 }} to={fullPath("ProfileListClinicalCases")}>Your atlas</MenuItem>
                                <MenuItem component={Link} style={{ paddingLeft: 24, minWidth: 220 }} to={fullPath("ProfileListContouringWorkshops")}>Your workshops</MenuItem>
                                <Divider />
                                <MenuItem button style={{ paddingLeft: 24, minWidth: 220 }} onClick={() => setToken(null)}>Sign out</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>
}

export default function AccountButton() {
    const { payload, mounted } = useAuthContext();

    if (!mounted) {
        return <Loader />
    }

    if (!payload) {
        return <SignInButton />
    }

    return <ProfileButton payload={payload} />
}