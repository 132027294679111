import React, { ReactNode } from "react";
import clsx from "clsx";

import {
  createStyles, makeStyles, Theme
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(1) * 6,
  },
  sm: {
    maxWidth: 576,
  },
  md: {
    maxWidth: 768,
  },
  lg: {
    maxWidth: 992,
  },
  xl: {
    maxWidth: 1200,
  },
}));

export interface CentralColumnLayoutProps {
  size?: "sm" | "md" | "lg" | "xl";
  children?: ReactNode | ReactNode[];
  className?: string;
}

export default function CentralColumnLayout({ size, children, className}: CentralColumnLayoutProps) {

  const classes = useStyles();
  const _size = size ? size : "lg";

  return (
    <div className={clsx(classes.container, classes[_size], className)}>

      {children}

    </div>
  )
}
