import React from "react";
import {
    ThemeContextProvider as CocoThemeProvider,
    ThemeContextProviderProps as CocoThemeProviderProps,
} from "cocoreact";
import { themes } from "App/Theme/Theme";
import { themeStorage } from "services";

export interface ThemeContextProviderProps {
    children: CocoThemeProviderProps["children"],
}

export default function ThemeContextProvider({ children }: ThemeContextProviderProps) {
    return (
        <CocoThemeProvider defaultName="light" themes={themes} storage={themeStorage}>
            {children}
        </CocoThemeProvider>
    );
}
