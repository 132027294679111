import React, { useState } from "react";
import { Box } from "@material-ui/core";

import useListProfileContouringWorkshops from "domain/hooks/useListProfileContouringWorkshops";
import { ContouringWorkshopState } from "domain/static/ContouringWorkshopState";
import { Typography } from "components/Page";
import { CardGrid } from "components/ClinicalData";
import { Card } from "components/ContouringWorkshop";
import { fullPath } from "tools/Route";
import { useAuthContext } from "contexts/Auth";
import { IAuthPayload } from "services";
import FilterAndOrderRow from "./List/FilterAndOrderRow";

export default function ListContouringWorkshopsPanel() {
    const authContext = useAuthContext();
    const payload = authContext.payload as IAuthPayload;

    const [loading, entities] = useListProfileContouringWorkshops(payload.userId);
    const [stateFilter, setStateFilter] = useState(ContouringWorkshopState.None);
    const [orderBy, setOrderBy] = useState<"asc" | "desc">("asc");

    let items = stateFilter === ContouringWorkshopState.None
        ? entities
        : entities.filter(x => x.state === stateFilter);

    items = orderBy === "asc"
        ? items.sort((a, b) => a.startAt < b.startAt ? 1 : -1)
        : items.sort((a, b) => a.startAt > b.startAt ? 1 : -1);

    return <>

        <Box marginBottom={2}>
            <FilterAndOrderRow
                orderBy={orderBy}
                onOrderChanged={setOrderBy}
                stateFilter={stateFilter}
                onStateFilterChanged={setStateFilter}
            />
        </Box>

        {!loading &&
            <CardGrid>
                {items.map(x => <Card
                    key={x.id}
                    href={fullPath("ProfileShowContouringWorkshop", { params: { slug: x.slug } })}
                    value={x}
                />)}
            </CardGrid>
        }

        {!loading && items.length === 0 &&
            <Typography variant="caption">
                No contouring workshops found.
            </Typography>
        }

    </>
}

