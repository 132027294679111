import React, { useState } from "react";
import { Box, Button, createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { EmailOutlined as EmailIcon } from "@material-ui/icons";
import ContributeDialog from "./ContributeDialog";
import { Link } from "react-router-dom";
import { fullPath } from "tools/Route";
import { AccountIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(6, 1),
        margin: 0,
        textAlign: "center",
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            padding: theme.spacing(6, 0),
            margin: theme.spacing(0, "auto"),
            maxWidth: 992,
        },
    },
    cards: {
        margin: theme.spacing(2, 0),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        }
    },
    card: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        textAlign: "start",
        padding: theme.spacing(2),
        gap: theme.spacing(1),
        [theme.breakpoints.up("sm")]: {
            maxWidth: 256,
        }
    },
    actions: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(1),
        "& button": {
            width: 200,
        },
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            flexDirection: "row",
            gap: theme.spacing(2),
        }
    }
}));

interface CardProps {
    title: string;
    content: string;
}

function Card({ title, content }: CardProps) {
    const classes = useStyles();

    return <Paper className={classes.card}>
        <Typography component="h6" variant="h6">
            {title}
        </Typography>
        <Typography component="p" variant="body2">
            {content}
        </Typography>
    </Paper>
}

export default function ContributeSection() {
    const classes = useStyles();

    const [dialogOpen, setDialogOpen] = useState(false);

    return <>
        <Box className={classes.container}>
            <Typography component="h4" variant="h4">
                <strong>You wish to CONTRIBUTE ?</strong>
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary">
                The atlas database is built in collaboration with radiation-oncologists.
            </Typography>

            <Box className={classes.cards}>
                <Card
                    title="As Editor"
                    content="We provide online segmentation tools such as paint, erase, brush, slice interpolation, margins and boolean contour operators."
                />
                <Card
                    title="As Reviewer"
                    content="To guarantee the relevance of the atlas database, we are looking for radiation-oncologists with targeted skills."
                />
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gridGap={8}>
                <Typography component="p" variant="body1" color="textSecondary">
                    Contact us to share clinical cases with the community or reviews based on your practical skills.
                </Typography>
                <Box className={classes.actions}>
                    <Link to={fullPath("ProfileListClinicalCases")}>
                        <Button variant="outlined" color="primary" startIcon={<AccountIcon />}>
                            your atlas
                        </Button>
                    </Link>
                    <Button
                        variant="outlined" color="default"
                        startIcon={<EmailIcon />}
                        onClick={() => setDialogOpen(true)}
                    >
                        contact us
                    </Button>
                </Box>
            </Box>

        </Box>

        <ContributeDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
        />
    </>
}
