import { App } from "dline-viewer/dist/app";
import { ItkImageDataEntity } from "dline-viewer/dist/app/Entity/ItkImage/ItkImageDataEntity";
import { ItkImageEntity } from "dline-viewer/dist/app/Entity/ItkImage/ItkImageEntity";
import { ItkImageLutPresetEntity } from "dline-viewer/dist/app/Entity/ItkImage/ItkImageLutPresetEntity";
import { ItkImageUrlEntity } from "dline-viewer/dist/app/Entity/ItkImage/ItkImageUrlEntity";
import { ItkImageWindowEntity } from "dline-viewer/dist/app/Entity/ItkImage/ItkImageWindowEntity";
import { ItkImageFusionIndexEntity } from "dline-viewer/dist/app/Entity/Fusion/ItkImageFusionIndexEntity";
import { ItkImage } from "dline-viewer/dist/data";

import { ImagingDefault } from "domain/static/ImagingDefault";
import { Range } from "domain/static/Range";

export default class ImageHelper {
  private _app: App;
  private _imagingDefault: ImagingDefault;

  private _id: string;
  private _entities: {
    url: ItkImageUrlEntity;
    image: ItkImageEntity;
    data: ItkImageDataEntity;
    windowing: ItkImageWindowEntity;
    lut: ItkImageLutPresetEntity;
    fusionIndex: ItkImageFusionIndexEntity;
  };

  constructor(app: App, imagingDefault: ImagingDefault) {
    this._app = app;
    this._imagingDefault = imagingDefault;

    this._id = "undefined";
    this._entities = {} as any;
  }

  private _initializeEntities(urlEntityId: string) {
    const url = this._app.db.First(
      ItkImageUrlEntity,
      (x) => x.id === urlEntityId
    );

    const image = this._app.db.First(
      ItkImageEntity,
      (x) => x.idItkImageUrl === url.id
    );

    const data = this._app.db.First(
      ItkImageDataEntity,
      (x) => x.idItkImage === image.id
    );

    const windowing = this._app.db.First(
      ItkImageWindowEntity,
      (x) => x.idItkImage === image.id
    );

    const lut = this._app.db.First(
      ItkImageLutPresetEntity,
      (x) => x.idItkImage === image.id
    );

    const fusionIndex = new ItkImageFusionIndexEntity();
    fusionIndex.fusionIndex = this._imagingDefault;
    fusionIndex.idItkImage = image.id;

    this._entities = { url, image, data, windowing, lut, fusionIndex };
  }

  get id() {
    return this._id;
  }

  isImaging(imagingDefault: ImagingDefault) {
    return this._imagingDefault === imagingDefault;
  }

  async setImage(id: string, image: ItkImage) {
    const fakeUrl = "/fakeurl/" + id;
    if (!this._app.itkImageLoader.isFakeUrl(fakeUrl)) {
      this._app.itkImageLoader.addFakeUrl(fakeUrl, () => image);
    }

    // this._app.itkImageLoader.loadImage(fakeUrl); // what for ?!!!
    this._id = id;

    const { url } = this._entities;

    if (url && url.url !== fakeUrl) {
      url.url = fakeUrl;
      await this._app.derivation.UpdateAsync(url);
    } else {
      const urlEntity = new ItkImageUrlEntity();
      urlEntity.url = fakeUrl;
      await this._app.derivation.InsertAsync(urlEntity);

      this._initializeEntities(urlEntity.id);
    }
  }

  getFusionIndexEntity() {
    const { fusionIndex } = this._entities;
    return fusionIndex;
  }

  getItkImage() {
    const { data } = this._entities;
    return data.itkImage;
  }

  getDataMinMax() {
    const { data } = this._entities;
    return data.itkImage.getDataMinMax();
  }

  async setWindowing(windowingRange: Range) {
    const { windowing } = this._entities;

    windowing.window = [windowingRange.min, windowingRange.max];
    await this._app.derivation.UpdateAsync(windowing);
  }

  async setLutPreset(lutPreset: string) {
    const { lut } = this._entities;

    lut.lutPreset = lutPreset;
    await this._app.derivation.UpdateAsync(lut);
  }
}
