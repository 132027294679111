import { Guid, IsEmptyGuid } from "domain/static/Guid";
import { ClinicalCaseCardResponse } from "domain/public/response/ClinicalCaseCardResponse";
import { SearchClinicalCaseCardsQuery } from "domain/public/query/SearchClinicalCaseCardsQuery";
import { useCallback, useEffect, useRef, useState } from "react";
import { sendMessage } from "tools/Message";

export default function useFilterClinicalCases(
  anatomicZoneId: Guid
): [boolean, ClinicalCaseCardResponse[]] {
  const [state, setState] = useState({
    loading: false,
    results: [] as ClinicalCaseCardResponse[],
  });

  const query = useRef(
    new SearchClinicalCaseCardsQuery({
      top: 100,
      skip: 0,
      anatomicZoneId: null,
      keywords: "",
    })
  );

  const updateResults = useCallback(async () => {
    setState((s) => ({ ...s, loading: true }));
    query.current.anatomicZoneId = IsEmptyGuid(anatomicZoneId)
      ? null
      : anatomicZoneId;

    const results = await sendMessage<ClinicalCaseCardResponse[]>(
      query.current
    );

    setState({ loading: false, results });
  }, [anatomicZoneId]);

  useEffect(() => {
    updateResults();
  }, [updateResults]);

  return [state.loading, state.results];
}
