import { App } from "dline-viewer/dist/app";
import { ItkImageDataEntity } from "dline-viewer/dist/app/Entity/ItkImage/ItkImageDataEntity";
import { ItkImageEntity } from "dline-viewer/dist/app/Entity/ItkImage/ItkImageEntity";
import { ItkImageLutPresetEntity } from "dline-viewer/dist/app/Entity/ItkImage/ItkImageLutPresetEntity";
import { ItkImageWindowEntity } from "dline-viewer/dist/app/Entity/ItkImage/ItkImageWindowEntity";
import { ItkImageAlphaEntity } from "dline-viewer/dist/app/Entity/ItkImage/ItkImageAlphaEntity";
import { ItkImage } from "dline-viewer/dist/data";
import { AppContourStackHandler } from "dline-viewer/dist/app/Handlers";

import { Range } from "domain/static/Range";

export default class StackHelper {
  private _app: App;

  private _id: string;
  private _entities: {
    image: ItkImageEntity;
    data: ItkImageDataEntity;
    windowing: ItkImageWindowEntity;
    lut: ItkImageLutPresetEntity;
    alpha: ItkImageAlphaEntity;
  };

  constructor(app: App) {
    this._app = app;
    this._id = "undefined";
    this._entities = {} as any;
  }

  private _initializeEntities(itkImageId: string) {
    const image = this._app.db.First(
      ItkImageEntity,
      (x) => x.id === itkImageId
    );

    const data = this._app.db.First(
      ItkImageDataEntity,
      (x) => x.idItkImage === image.id
    );

    const windowing = this._app.db.First(
      ItkImageWindowEntity,
      (x) => x.idItkImage === image.id
    );

    const lut = this._app.db.First(
      ItkImageLutPresetEntity,
      (x) => x.idItkImage === image.id
    );

    const alpha = this._app.db.First(
      ItkImageAlphaEntity,
      (x) => x.idItkImage === image.id
    );

    this._entities = { image, data, windowing, lut, alpha };
  }

  get id() {
    return this._id;
  }

  async setImage(id: string, image: ItkImage) {
    const fakeUrl = "/fakeurl/" + id;
    if (!this._app.itkImageLoader.isFakeUrl(fakeUrl)) {
      this._app.itkImageLoader.addFakeUrl(fakeUrl, () => image);
    }

    const handler = new AppContourStackHandler(this._app);
    const stack = await handler.LoadContourStack(fakeUrl);

    this._id = id;
    this._initializeEntities(stack.idItkImage);
  }

  async setVisible(visibled: boolean) {
    const { alpha } = this._entities;
    alpha.alpha = visibled ? 0.4 : 0.0;
    await this._app.derivation.UpdateAsync(alpha);
  }

  async setWindowing(windowingRange: Range) {
    const { windowing } = this._entities;
    windowing.window = [windowingRange.min, windowingRange.max];
    await this._app.derivation.UpdateAsync(windowing);
  }
}
