import React, { useCallback, useEffect } from "react";
import { DialogContentText } from "@material-ui/core";
import { useNotificationContext } from "cocoreact";

import { SendIcon } from "App/Theme";
import { ContactCommand } from "domain/public/command/ContactCommand";
import { ContactFormFields, MailIssueWarning, ResponsiveFormDialog, useContactForm } from "components/Page";
import { sendMessage } from "tools/Message";

export interface RequestQuoteDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function RequestQuoteDialog({ open, onClose }: RequestQuoteDialogProps) {

    const { success, error } = useNotificationContext();
    const [formValue, setFieldValue, cleanFromValues] = useContactForm();

    useEffect(() => {
        if (open === false) {
            cleanFromValues();
        }
    }, [cleanFromValues, open])

    const onSubmitHandle = useCallback(async () => {
        try {
            const message = new ContactCommand(formValue);
            await sendMessage(message);

            success("Your demand has been sending successfully");
            onClose();
        }
        catch (e) {
            error("An error occurred while sending your demand !");
        }
    }, [error, formValue, onClose, success]);

    return <ResponsiveFormDialog
        open={open}
        onClose={onClose}
        title="Delineation Workshops"
        actionLabel="Request a quote"
        actionIcon={<SendIcon />}
        onSubmit={onSubmitHandle}
    >
        {({ loading }) => <>

            <MailIssueWarning />
            
            <DialogContentText>
                Are you interested in organizing a delineation workshop with us ?
            </DialogContentText>

            <DialogContentText>
                In order to get a maximum of information about you and your project, 
                please fill in the form below. You can tell us more about yourself and 
                the kind of workshop you could be interested in : schedules start/end, 
                number of participants, tumor location, anatomical images or specific contours…
            </DialogContentText>

            <DialogContentText>
                We will specially study your request and get back to you as soon as possible.
            </DialogContentText>

            <ContactFormFields
                value={formValue}
                onChange={setFieldValue}
                disabled={loading}
            />

        </>}
    </ResponsiveFormDialog>
}
