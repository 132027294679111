import { VolumeType } from "domain/static/VolumeType";
import removeMd from "remove-markdown";
import _slugify from "slugify";

export type ContourInfo = {
  name: string;
  anatomicStructureName?: string;
  volumeType: VolumeType;
  targetTypeName: string;
};

export function contourInfoLabel(c: ContourInfo) {
  if (c.anatomicStructureName) {
    const target =
      c.volumeType === VolumeType.Target && c.targetTypeName
        ? ` [${c.targetTypeName}]`
        : "";
    return `${c.anatomicStructureName}${target}`;
  }
  return c.name;
}

export function slugify(str: string): string {
  str = _slugify(str);
  str = str.toLocaleLowerCase();
  str = str.replace(/[^a-z0-9\s]/g, " ");
  str = str.replace(/\s+/g, " ");
  str = str.trim();
  str = str.replace(/ /g, "-");
  return str;
}

export function nlReplace(str: string, replace: string): string {
  return str.replaceAll(/(\n)+/g, replace);
}

export function nl2br(str: string): string {
  return nlReplace(str, "<br />");
}

export function wrap(str: string, limit: number) {
  return str.slice(0, limit) + (str.length > limit ? "..." : "");
}

export function stripMarkdown(str: string) {
  return removeMd(nlReplace(str, " "));
}

export function toArrayBuffer(str: string) {
  return new TextEncoder().encode(str);
}

export function fromArrayBuffer(buf: ArrayBuffer) {
  return new TextDecoder().decode(buf);
}

export function numberFormat(value: number, nbFractionDigit = 2) {
  var nf = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: nbFractionDigit,
    maximumFractionDigits: nbFractionDigit,
  });
  return nf.format(value);
}
