import React, { useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button } from "@material-ui/core";

import { PageLayout } from "components/Layouts";
import { MobileNotSupported, OnDesktop, OnMobile } from "components/Page";
import useProfileContouringWorkshopViewer from "domain/hooks/useProfileContouringWorkshopViewer";
import { ContouringWorkshopUserRole } from "domain/static/ContouringWorkshopUserRole";
import { ViewerContextProvider } from "contexts/Viewer";
import { PageNotFoundError } from "pages/Errors";
import { KeyboardArrowLeftIcon } from "App/Theme";
import { useWindowEffectScrollTop } from "tools/useWindowEffectScrollTop";
import { fullPath } from "tools/Route";
import ParticipantViewerContainer from "./Participant/ViewerContainer"
import OrganizerViewerContainer from "./Organizer/ViewerContainer"

export default function ShowContouringWorkshopPage() {
    useWindowEffectScrollTop();

    const params = useParams<{ slug: string }>();
    const slug = useMemo(() => params.slug, [params.slug]);
    const [entityLoading, entity, entityError] = useProfileContouringWorkshopViewer(slug);

    useEffect(() => {
        if (entityError) {
            throw new PageNotFoundError();
        }
    }, [entityError]);

    return <>
        <PageLayout tab={undefined}>

            <Helmet>
                <title>{entityLoading ? "Loading contouring workshop ..." : entity.name} - Dline</title>
            </Helmet>

            <OnMobile>
                <MobileNotSupported>
                    <Link to={fullPath("ProfileListContouringWorkshops")}>
                        <Button
                            color="inherit"
                            variant="outlined"
                            startIcon={<KeyboardArrowLeftIcon />}
                        >
                            back to profile
                        </Button>
                    </Link>
                </MobileNotSupported>
            </OnMobile>

            <OnDesktop>
                {entityLoading === false &&
                    <ViewerContextProvider>
                        {entity.userRole === ContouringWorkshopUserRole.Participant &&
                            <ParticipantViewerContainer entity={entity} />
                        }
                        {entity.userRole === ContouringWorkshopUserRole.Organizer &&
                            <OrganizerViewerContainer entity={entity} />
                        }
                    </ViewerContextProvider>
                }
            </OnDesktop>

        </PageLayout >
    </>
}
