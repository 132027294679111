import React, { useCallback, useState } from "react";
import { Box, createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import {
    ViewComfy as ViewCardIcon,
    ViewList as ViewListIcon
} from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => createStyles({
    selected: {
        color: theme.palette.text.primary,
    },
    default: {
        color: theme.palette.text.disabled,
    }
}));

export type ViewModeType = "card" | "list";

export function useViewMode(): [ViewModeType, React.Dispatch<ViewModeType>] {
    const [viewMode, _setViewMode] = useState<ViewModeType>(() => {
        const mode = localStorage.getItem("app_preference_viewMode");
        return mode === "list" ? "list" : "card";
    });

    const setViewMode = useCallback((value: ViewModeType) => {
        localStorage.setItem("app_preference_viewMode", value);
        _setViewMode(value);
    }, []);

    return [viewMode, setViewMode];
}

export interface ViewModeProps {
    value: ViewModeType;
    onChange: (value: ViewModeType) => void;
    className?: string;
}

export default function ViewMode({ value, onChange, className }: ViewModeProps) {
    const classes = useStyles();

    return <Box className={className}>
        <IconButton
            size="small"
            title="card"
            onClick={() => onChange("card")}
            className={value === "card" ? classes.selected : classes.default}
        >
            <ViewCardIcon color="inherit" />
        </IconButton>

        <IconButton
            size="small"
            title="list"
            onClick={() => onChange("list")}
            className={value === "list" ? classes.selected : classes.default}
        >
            <ViewListIcon color="inherit" />
        </IconButton>
    </Box>
}
