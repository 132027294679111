import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { PageLayout } from "components/Layouts";
import useClinicalCaseViewer from "domain/hooks/useClinicalCaseViewer";
import { PageNotFoundError } from "pages/Errors";
import ViewerContainer from "./ViewerContainer";
import { ViewerContextProvider } from "contexts/Viewer";
import { stripMarkdown } from "tools/StringExtension";

export default function ViewerClinicalCasePage() {
    const params = useParams<{ slug: string }>();
    const slug = useMemo(() => params.slug, [params.slug]);

    const [entityLoading, entity, entityError] = useClinicalCaseViewer(slug);

    useEffect(() => {
        if (entityError) {
            throw new PageNotFoundError();
        }
    }, [entityError]);

    return (
        <PageLayout
            tab="atlas"
            mobileActionType="previous"
            mobileTitle="Atlas"
            hideMobileBar
        >
            <Helmet>
                <title>{entityLoading ? "Loading atlas ..." : `${entity.name} - Atlas`} - Dline</title>
                <meta
                    name="description"
                    content={entityLoading ? "loading ..." : `${entity.name} : ${stripMarkdown(entity.summary)}`}
                />
            </Helmet>

            <ViewerContextProvider>
                <ViewerContainer loading={entityLoading} entity={entity} />
            </ViewerContextProvider>
        </PageLayout >
    );
}

