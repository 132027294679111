import React, { useCallback, useMemo, useRef, useState } from "react";
import { Box } from "@material-ui/core";

import { VolumeType } from "domain/static/VolumeType";
import { Guid } from "domain/static/Guid";
import { Contour } from "domain/public/response/ClinicalCaseViewerResponse";
import { filterByVolumeType } from "tools/ContourExtension";
import { useViewerContext } from "contexts/Viewer";
import {
    ContourList, ContourSeparator,
    ContourItem as BaseContourItem,
    ItemComponentProps
} from "components/ClinicalData";
import { contourInfoLabel } from "tools/StringExtension";

function ContourItem({ contour, onClick }: ItemComponentProps<Contour>) {
    const {
        data: viewerData,
        dispatcher: viewerDispatcher,
        app: viewerApp
    } = useViewerContext();

    const hasClicked = useRef(false);

    const contourData = viewerData.getContourById(contour.id);
    const contourHelper = viewerApp.getContourById(contour.id);

    const onHighlightHandle = useCallback((enabled: boolean) => {
        if (hasClicked.current) {
            hasClicked.current = false;
            return;
        }
        if (!contourHelper || !contourData) return;

        if (contourData.visible) {
            contourHelper.setHighlight(enabled);
        }
    }, [contourData, contourHelper]);

    const onClickHandle = useCallback((id: string) => {
        if (onClick) {
            hasClicked.current = true;
            onClick(id);
        }
    }, [onClick]);

    const onVisibilityHandle = useCallback((id: string, visibled: boolean) => {
        viewerDispatcher({
            type: "CONTOURS_VISIBILITY",
            contourIds: [id],
            visibled: visibled,
        });
    }, [viewerDispatcher]);

    return <BaseContourItem
        id={contour.id}
        color={contour.color}
        label={contourInfoLabel(contour)}
        sublabel={contour.organizationName}
        visibled={contourData ? contourData.visible : true}
        onToggleVisibled={onVisibilityHandle}
        onClick={onClick && onClickHandle}
        onHighlight={onHighlightHandle}
    />
}

interface ContourListByVolumeTypeProps {
    label: string;
    type: VolumeType | VolumeType[];
    contours: Contour[];
    initialCollapsed?: boolean;
    onClick?: React.Dispatch<Guid>;
}

function ContourListByVolumeType({
    label, type, contours, initialCollapsed, onClick
}: ContourListByVolumeTypeProps) {
    const { dispatcher: viewerDispatcher, data: viewerData } = useViewerContext();

    const [checked, setChecked] = useState(true);
    const [collapsed, setCollapsed] = useState(initialCollapsed ?? true);

    const fileteredContours = useMemo(
        () => filterByVolumeType(contours, type),
        [contours, type]
    );

    const onCheckHandle = useCallback((_checked: boolean) => {
        const contourIds = fileteredContours.map(x => x.id);
        viewerDispatcher({
            type: "CONTOURS_VISIBILITY",
            contourIds: contourIds,
            visibled: _checked,
        });
        setChecked(_checked);
    }, [fileteredContours, viewerDispatcher]);

    if (fileteredContours.length === 0) return null;

    return <>
        <ContourSeparator
            label={`${label} (${fileteredContours.length})`}
            checked={checked}
            onCheck={onCheckHandle}
            collapsed={collapsed}
            onCollapse={setCollapsed}
            disabled={!viewerData.contours || viewerData.contours.length === 0}
        />

        <ContourList
            contours={fileteredContours}
            ItemComponent={ContourItem}
            onClick={onClick}
            collapsed={collapsed}
        />
    </>
}

export interface ContourListPanelProps {
    contours: Contour[];
    onClick: React.Dispatch<Guid>;
}

export default function ContourListPanel({ contours, onClick }: ContourListPanelProps) {
    return <Box>
        <ContourListByVolumeType
            label="target"
            type={VolumeType.Target}
            contours={contours}
            onClick={onClick}
        />
        <ContourListByVolumeType
            label="at risk / anatomy"
            type={[VolumeType.AtRisk, VolumeType.Anatomy]}
            contours={contours}
            onClick={onClick}
        />
        <ContourListByVolumeType
            label="unclassified"
            type={VolumeType.None}
            initialCollapsed={false}
            contours={contours}
        />
    </Box>
}