import React from "react";
import { Chip, ChipProps, createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { ClinicalCaseState, ClinicalCaseStateOptions } from "domain/static/ClinicalCaseState";
import { enumValueToString } from "tools/EnumExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    chip: {
        cursor: "inherit",
        opacity: 1.0,
    },
    Published: {},
    Submitted: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.getContrastText(theme.palette.primary.light),
    },
    Draft: {
        backgroundColor: theme.palette.grey[600],
        color: theme.palette.getContrastText(theme.palette.grey[600]),
    }
}));

export interface StateChipProps {
    size?: ChipProps["size"];
    state: ClinicalCaseState;
}

export default function StateChip({ state, size }: StateChipProps) {
    const classes = useStyles();
    const label = enumValueToString(state, ClinicalCaseStateOptions);
    const clasName = (classes as any)[label];

    if (state !== ClinicalCaseState.None) {
        return <Chip className={clsx(classes.chip, clasName)} size={size}
            label={label} color="primary" />
    }

    return null;
}
