import React from "react";
import {
    Box, createStyles, makeStyles,
    MenuItem, TextField, Theme,
} from "@material-ui/core";
import { ContouringWorkshopState, ContouringWorkshopStateOptions } from "domain/static/ContouringWorkshopState";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: theme.spacing(2),
    },
    spacer: {
        marginLeft: "auto",
    }
}));

const StateOptions = ContouringWorkshopStateOptions.map(x => {
    const res = { ...x };
    if (x.value === ContouringWorkshopState.None) {
        res.label = "All";
    }
    return res;
});

export type OrderMode = "asc" | "desc";

export interface FilterAndOrderRowProps {
    orderBy: OrderMode;
    onOrderChanged: (v: OrderMode) => void;
    stateFilter: ContouringWorkshopState;
    onStateFilterChanged: (v: ContouringWorkshopState) => void;
}

export default function FilterAndOrderRow({
    orderBy, onOrderChanged,
    stateFilter, onStateFilterChanged,
}: FilterAndOrderRowProps) {
    const classes = useStyles();

    return <Box className={classes.root}>
        <TextField
            select
            variant="outlined"
            size="small"
            label="order by"
            value={orderBy}
            onChange={e => onOrderChanged(e.target.value as OrderMode)}
        >
            <MenuItem value="asc">Newest</MenuItem>
            <MenuItem value="desc">Oldest</MenuItem>
        </TextField>

        <TextField
            select
            variant="outlined"
            size="small"
            label="filter"
            value={stateFilter}
            onChange={e => onStateFilterChanged(e.target.value as any)}
        >
            {StateOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    </Box>
}
