import React, { useCallback, useState } from "react";
import { Box, Typography } from "@material-ui/core";

import { Guid } from "domain/static/Guid";
import { Contour } from "domain/public/response/ContouringWorkshopViewerResponse";
import { useViewerContext } from "contexts/Viewer";
import {
    ContourList, ContourSeparator,
    ContourItem as BaseContourItem,
    ItemComponentProps,
    CircularIndicator,
    LockIndicator
} from "components/ClinicalData";
import { contourInfoLabel, numberFormat } from "tools/StringExtension";
import { getParticipantId } from "tools/ContourExtension";

function ContourItem({ contour, onClick }: ItemComponentProps<Contour>) {
    const {
        data: viewerData,
        dispatcher: viewerDispatcher
    } = useViewerContext();

    const contourData = viewerData.getContourById(contour.id);
    const visibled = contourData?.visible ?? true;

    const participantDice = contour.participantResults.length > 0
        ? contour.participantResults[0].dice : 0.0;

    const onVisibilityHandle = useCallback((id: string, visibled: boolean) => {
        viewerDispatcher({
            type: "CONTOURS_VISIBILITY",
            contourIds: [id, getParticipantId(id)],
            visibled: visibled,
        });
    }, [viewerDispatcher]);

    return <BaseContourItem
        id={contour.id}
        color={contour.color}
        label={contourInfoLabel(contour)}
        sublabel={contour.organizationName}
        visibled={visibled}
        onToggleVisibled={onVisibilityHandle}
        onClick={onClick}
        indicator={contour.isEditable
            ?
            <>
                <Typography variant="body2" component="span">
                    {numberFormat(participantDice)}
                </Typography>
                <CircularIndicator
                    size={32}
                    thickness={16}
                    value={participantDice}
                    disabled={!visibled}
                />
            </>
            :
            <LockIndicator />
        }
    />
}

export interface ContourListPanelProps {
    contours: Contour[];
    onClick: (contourId: Guid) => void;
}

export default function ContourListPanel({ contours, onClick }: ContourListPanelProps) {
    const { dispatcher: viewerDispatcher } = useViewerContext();

    const [checked, setChecked] = useState(true);

    const onCheckHandle = useCallback((_checked: boolean) => {
        const contourIds = contours.map(x => x.id);
        const participantContourIds = contours.map(x => getParticipantId(x.id));
        viewerDispatcher({
            type: "CONTOURS_VISIBILITY",
            contourIds: [...contourIds, ...participantContourIds],
            visibled: _checked,
        });
        setChecked(_checked);
    }, [contours, viewerDispatcher]);

    return <Box>
        <ContourSeparator
            label={`all (${contours.length})`}
            checked={checked}
            onCheck={onCheckHandle}
        />

        <ContourList
            contours={contours}
            ItemComponent={ContourItem}
            onClick={onClick}
        />
    </Box>
}