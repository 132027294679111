import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class AskPasswordResetQuery implements IMessage {
    email!: string;

    public constructor(init?: Partial<AskPasswordResetQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/users/password`;

    getQueryString = () => {
        return {
            email: this.email,
        }
    }
    

    getBody = () => undefined;

    needAuthentication = () => false;
}
