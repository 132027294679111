import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles, Box } from '@material-ui/core';

import Viewer from "./Viewer";
import { OrientationType } from "domain/static/OrientationType";
import { useViewerContext } from "contexts/Viewer";

const useStyles = makeStyles((theme: Theme) => createStyles({
    row: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        "& > div:first-child": {
            marginRight: 2,
        }
    },
    column: {
        width: theme.mixins.viewer.smallWidth,
        maxWidth: theme.mixins.viewer.smallWidth,
        minWidth: theme.mixins.viewer.smallWidth,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        "& > div:first-child": {
            marginBottom: 2,
        }
    },
}));

export interface Viewer3AxesProps {
    orientation: OrientationType;
}

export default function Viewer3Axes({ orientation }: Viewer3AxesProps) {
    const classes = useStyles();

    const [dimensions, setDimensions] = useState([1, 1, 1]);
    const [slices, setSlices] = useState([0, 0, 0]);
    const [orientations, setOrientations] = useState([
        orientation,
        (orientation + 1) % 3 as OrientationType,
        (orientation + 2) % 3 as OrientationType
    ]);

    const containerRef = useRef<HTMLDivElement>(null);
    const upContainerRef = useRef<HTMLDivElement>(null);
    const downContainerRef = useRef<HTMLDivElement>(null);

    const { data: viewerData, app: viewerApp } = useViewerContext();

    const onViewerSliceIndexChangeHandle = useCallback((orientation: OrientationType, index: number) => {
        setSlices(ss => {
            const idx = ss[orientation];
            if (idx !== index) {
                const newSlices = [...ss];
                newSlices[orientation] = index;
                return newSlices;
            }
            return ss;
        });
    }, []);

    const onSliderIndexChangeHandle = useCallback(async (orientation: OrientationType, index: number) => {
        const scene = viewerApp.getSceneByOrientation(orientation);
        if (scene) {
            await scene.setSliceIndex(index);
        }
    }, [viewerApp]);

    useEffect(() => {
        const _initializeScenes = async () => {
            if (!containerRef.current
                || !upContainerRef.current
                || !downContainerRef.current) return;

            // first is the main scene
            await viewerApp.createScene(orientations[0], containerRef.current, onViewerSliceIndexChangeHandle);
            // small scenes
            await viewerApp.createScene(orientations[1], upContainerRef.current, onViewerSliceIndexChangeHandle);
            await viewerApp.createScene(orientations[2], downContainerRef.current, onViewerSliceIndexChangeHandle);

            await viewerApp.setFusionType(viewerData.defaultFusionType);

            const dims = viewerApp.getImageDimensionsMax();
            setDimensions(dims);
        };

        const _updateMainOrientation = async () => {
            const mainIdx = 0;
            const smallIdx = orientations.findIndex(x => x === orientation);
            if (smallIdx === -1 || smallIdx === mainIdx) return;

            const mainScene = viewerApp.getSceneAt(mainIdx);
            const smallScene = viewerApp.getSceneAt(smallIdx);

            const mainProps = await mainScene.getProps();
            const smallProps = await smallScene.getProps();

            await mainScene.setOrientation(orientations[smallIdx]);
            await smallScene.setOrientation(orientations[mainIdx]);

            if (mainProps && smallProps) {
                await mainScene.setProps(smallProps);
                await smallScene.setProps(mainProps);
            }

            const newOrientations = [...orientations];
            newOrientations[mainIdx] = orientations[smallIdx];
            newOrientations[smallIdx] = orientations[mainIdx];
            setOrientations(newOrientations);
        }

        if (viewerData.numberOfImages > 0 && viewerApp.numberOfScenes === 0) {
            _initializeScenes();
        }
        else if (viewerApp.numberOfScenes === 3 && orientations[0] !== orientation) {
            _updateMainOrientation();
        }
    }, [onViewerSliceIndexChangeHandle, orientation, orientations, viewerApp, viewerData]);

    return <Box className={classes.row}>

        <Viewer
            ref={containerRef}
            orientation={orientations[0]}
            value={slices[orientations[0]]}
            dimension={dimensions[orientations[0]]}
            onSliceChange={onSliderIndexChangeHandle}
        />

        <Box className={classes.column}>
            <Viewer
                ref={upContainerRef}
                orientation={orientations[1]}
                value={slices[orientations[1]]}
                dimension={dimensions[orientations[1]]}
                onSliceChange={onSliderIndexChangeHandle}
            />
            <Viewer
                ref={downContainerRef}
                orientation={orientations[2]}
                value={slices[orientations[2]]}
                dimension={dimensions[orientations[2]]}
                onSliceChange={onSliderIndexChangeHandle}
            />
        </Box>

    </Box>
}
