import React from "react";
import { Helmet } from "react-helmet-async";
import { Box, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import { useWindowEffectScrollTop } from "tools/useWindowEffectScrollTop";
import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { Footer } from "components/Page";
import { fullPath } from "tools/Route";
import { LinkedinIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    layout: {
        margin: theme.spacing(6, 2),
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            margin: theme.spacing(6, "auto"),
        }
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    header: {
        marginTop: theme.spacing(3),
    },
    p: {
        margin: theme.spacing(1, 0),
        "& a": {
            color: theme.palette.primary.main,
            textDecoration: "underline",
        },
        "& svg": {
            marginRight: theme.spacing(0.5),
        }
    }
}));

// settings file
declare var APP_DEPLOY_DATE: string;
declare var APP_COMMIT_NUMBER: string;

export default function ContactPage() {

    const classes = useStyles();

    useWindowEffectScrollTop();

    return <PageLayout tab="about">

        <Helmet>
            <title>Dline - About us</title>
        </Helmet>

        <CentralColumnLayout size="md" className={classes.layout}>

            <Typography variant="h6" component="h1" className={classes.header}>
                Our mission
            </Typography>

            <Typography className={classes.p}>
                The objective of the Dline.io platform is to <strong>improve radiotherapy treatment and optimize practices</strong> with
                appropriate digital tools.
                Dline.io helps physicians in their practices, training them using databases of clinical cases and documented medical
                atlases as well as training modules.
            </Typography>

            <Typography className={classes.p}>
                The main goal is to <strong>reduce segmentation variability between physicians</strong> by offering them
                a web based tool to segment and visualize practical cases.
                Any method that reduces the risk factors in this step helps to optimize the management of patients treated with radiotherapy.
            </Typography>

            <Typography className={classes.p}>
                Dline.io wishes to facilitate and simplify access to the latest contouring recommendations (EORTC, RTOG, ANOCEF, etc.)
                whether it be on computer, tablet or mobile.
            </Typography>

            <Typography className={classes.p}>
                All the information on Dline.io is validated by experts.
            </Typography>

            <Typography variant="h6" component="h3" className={classes.header}>
                Team
            </Typography>
            <Typography className={classes.p}>
                Dline is made up of a small team, each passionate about their job.
            </Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4}>
                    <TeamCard
                        title="Maxime Gobeli"
                        subtitle="CEO - Founding Partner"
                        cover={`${process.env.PUBLIC_URL}/img/team-maxime.jpeg`}
                        abstract="Maxime is a radiation oncologist with a passion for new technology and improving quality in cancer care delivrance."
                        linkedin="https://www.linkedin.com/in/maxime-gobeli-293305107/"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TeamCard
                        title="Luc Lafitte"
                        subtitle="CTO - Founding Partner"
                        cover={`${process.env.PUBLIC_URL}/img/team-luc.jpeg`}
                        abstract="Luc has strong skills in front-end development and UX. Further more, his experience in medical imaging allows him to envision the best viewer design and image processing."
                        linkedin="https://www.linkedin.com/in/luc-lafitte/"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TeamCard
                        title="Anthony Moissant"
                        subtitle="Lead Dev - Founding Partner"
                        cover={`${process.env.PUBLIC_URL}/img/team-anthony.jpeg`}
                        abstract="Anthony is in charge of the technical part of back-end and server development. He also has a solid expertise in software architecture and security."
                        linkedin="https://www.linkedin.com/in/anthony-moissant/"
                    />
                </Grid>
            </Grid>

            <Typography variant="h6" component="h3" className={classes.header}>
                Contributing content
            </Typography>
            <Typography className={classes.p}>
                The Dline.io database is <strong>built in collaboration</strong> with radio therapy professionals. Each clinical case is reviewed and validated by an expert in order to guarantee the quality of the data.
            </Typography>
            <Typography className={classes.p}>
                The platform is designed to be open to contributions from professionals :
            </Typography>
            <Typography className={classes.p}>
                <strong>Editor</strong> :  you are a radiotherapy professional and would like to share a clinical case that 
                is a benchmark : <Link to={fullPath("ContactUs")}>contact us</Link> to suggest your segmented clinical 
                case and put your name on it.
            </Typography>
            <Typography className={classes.p}>
                <strong>Reviewer</strong> : you are an expert in radiotherapy and wish to improve the methodology used on a clinical case in 
                your specialty : <Link to={fullPath("ContactUs")}>contact us</Link> to review a clinical case 
                and put your name on it.
            </Typography>

            <Typography variant="h6" component="h3" className={classes.header}>
                Workshops
            </Typography>
            <Typography className={classes.p}>
                Dline.io offers a series of <strong>online training modules</strong> to learn and improve patient contouring and repositioning
                practices for physicians, dosimetrists and radiotherapy technicians.
                It provides a method to compare contours between participants and experts (Dices, …).
            </Typography>
            <Typography className={classes.p}>
                All of these metrics and participants contours are exportable for further exploitation.
            </Typography>
            <Typography className={classes.p}>
                More informations <Link to={fullPath("Workshops")}>about workshops</Link>.
            </Typography>

            <Typography variant="h6" component="h3" className={classes.header}>
                Image anonymisation
            </Typography>
            <Typography className={classes.p}>
                We convert dicom imagery data to nifti format via the ITK library.
                The Nifti format is an imaging format dedicated to research, only the geometric information is kept.
                Every personal information or imagery acquisition method is permanently deleted.
            </Typography>
            <Typography className={classes.p}>
                We also resample and change the orientation and isocenter of the image
                to avoid any possibility of matching (merging) with the original image.
            </Typography>
            <Typography className={classes.p}>
                To prevent any risk of identification of the patient via a 3d reconstruction face
                (facial recognition algorithm) the patient's eyes, nose and mouth are partially
                removed via a permanent deletion of the voxels.
            </Typography>

            <Typography variant="h6" component="h3" className={classes.header}>
                Contact Us
            </Typography>
            <Typography className={classes.p}>
                For further question or suggestion, <Link to={fullPath("ContactUs")}>contact us</Link>.
            </Typography>

            <Box marginTop={4}>
                <Typography variant="caption">
                    version {APP_COMMIT_NUMBER}-{APP_DEPLOY_DATE}
                </Typography>
            </Box>

        </CentralColumnLayout>

        <Footer />

    </PageLayout>
}

type TeamCardProps = {
    title: string;
    subtitle: string;
    cover: string;
    abstract: string;
    linkedin: string;
};

function TeamCard({ title, subtitle, cover, abstract, linkedin }: TeamCardProps) {
    return <Box style={{ textAlign: "center" }}>
        <img src={cover} alt={title} style={{ width: 150, borderRadius: "50%", filter: "grayscale(100%)" }} />
        <Typography variant="h6" style={{}} color="secondary">
            {title}
        </Typography>
        <Typography variant="caption" style={{ opacity: 0.8 }}>
            {subtitle}
        </Typography>
        <Typography component="p" variant="body2" style={{ marginTop: 8, opacity: 0.8 }}>
            {abstract}
        </Typography>
        <a href={linkedin} target="_blank" rel="noreferrer" style={{ opacity: 0.5, marginTop: "100%" }}>
            <LinkedinIcon />
        </a>
    </Box>
}
