import {
  InfoOutlined as AboutIconOut,
  Info as AboutIcon,
} from "@material-ui/icons";
import { fullPath } from "tools/Route";
import {
  HomeIcon, HomeIconOut,
  ClinicalCaseIcon, ClinicalCaseIconOut,
  ContouringWorkshopIcon, ContouringWorkshopIconOut, AccountIcon,
} from "./Theme";

const APP_MENU = [
  {
    label: "Home",
    tab: "home",
    icon: <HomeIconOut />,
    selectedIcon: <HomeIcon />,
    href: fullPath("Home"),
  },
  {
    label: "Atlas",
    tab: "atlas",
    icon: <ClinicalCaseIconOut />,
    selectedIcon: <ClinicalCaseIcon />,
    href: fullPath("ListClinicalCases"),
  },
  {
    label: "Workshops",
    tab: "workshops",
    icon: <ContouringWorkshopIconOut />,
    selectedIcon: <ContouringWorkshopIcon />,
    href: fullPath("Workshops"),
  },
  {
    label: "About",
    tab: "about",
    icon: <AboutIconOut />,
    selectedIcon: <AboutIcon />,
    href: fullPath("AboutUs"),
  },
  {
    label: "Account",
    tab: "account",
    icon: <AccountIcon />,
    selectedIcon: <AccountIcon />,
    href: fullPath("Profile"),
  },
] as const;

export type MenuTab = typeof APP_MENU[number]['tab'];

export { APP_MENU };