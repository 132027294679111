import React from "react";
import { Box, Button, createStyles, makeStyles, Theme, Typography, lighten } from "@material-ui/core";
import { Link } from "react-router-dom";
import { fullPath } from "tools/Route";
import { AccountIcon } from "App/Theme";


const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        position: "relative",
        backgroundColor: lighten(theme.palette.primary.light, 0.6),
        '&::before': {
            content: '""',
            display: "block",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            opacity: 0.1,
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/workshop-bg.png)`,
            backgroundBlendMode: "normal",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
        }
    },
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: theme.spacing(12, 1),
        margin: 0,
        gap: theme.spacing(4),
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            padding: theme.spacing(11, 0),
            margin: theme.spacing(0, "auto"),
            maxWidth: 992,
        },
    },
    actions: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(1),
        "& button": {
            width: 200,
        },
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            flexDirection: "row",
            gap: theme.spacing(2),
        },
    },
}));

export default function BannerSection({ onRequestQuote }: { onRequestQuote: React.DispatchWithoutAction }) {
    const classes = useStyles();

    return <Box className={classes.wrapper}>
        <Box className={classes.container}>
            <Box style={{ textTransform: "uppercase" }}>
                <Typography component="h1" variant="h4">
                    <strong>Delineation Workshop</strong>
                </Typography>
                <Typography component="h2" variant="h4">
                    A new way to learn how to master contouring online
                </Typography>
            </Box>
            <Typography component="h3" variant="h6" color="textSecondary" style={{ maxWidth: 780 }}>
                Organize online private workshops to train your collaborators (students, partners, ...)
                to perform contours following guidelines.
            </Typography>

            <Box className={classes.actions}>
                <Button variant="contained" color="primary" onClick={onRequestQuote}>
                    request a quote
                </Button>
                <Link to={fullPath("ProfileListContouringWorkshops")}>
                    <Button variant="outlined" color="primary" startIcon={<AccountIcon />}>
                        your workshops
                    </Button>
                </Link>
            </Box>
        </Box>
    </Box>
}
