import React, { useCallback, useMemo } from "react";
import { IField } from "cocoreact";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";

import { Form, FormFieldsBuilder } from "components/Form";
import { AskPasswordResetQuery } from "domain/public/query/AskPasswordResetQuery";

const useStyles = makeStyles((theme: Theme) => createStyles({
    submitBtn: {
        marginTop: theme.spacing(2),
    }
}));

class AskResetPasswordResponse {
    email!: string;

    public static Fields = {
        email: { name: "email", type: "email" },
    } as Record<keyof AskResetPasswordResponse, IField>;
}

export interface AskResetPasswordFormProps {
    onAfterSubmit?: () => void;
}

export default function AskResetPasswordForm({ onAfterSubmit }: AskResetPasswordFormProps) {
    const classes = useStyles();

    const fields = useMemo(buildField, []);

    const buildMessage = useCallback((data: AskResetPasswordResponse) => {
        return new AskPasswordResetQuery({
            email: data.email,
        });
    }, []);

    const onComplete = useCallback(() => {
        onAfterSubmit && onAfterSubmit();
    }, [onAfterSubmit]);

    return (
        <Form<AskResetPasswordResponse>
            fields={fields}
            initial={{ email: "" }}
            buildMessage={buildMessage}
            onComplete={onComplete}
        >
            {() => (
                <>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submitBtn}
                    >
                        send password reset email
                    </Button>
                </>
            )}
        </Form>
    );
}

function buildField() {

    const builder = new FormFieldsBuilder<AskResetPasswordResponse>();

    const fields = AskResetPasswordResponse.Fields;

    builder
        .initialize(fields)
        .set(fields.email, {
            placeholder: "Enter your email adress",
            label: "email adress",
            position: 0,
        })
        ;

    return builder.build();
}
