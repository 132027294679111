import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { Footer, Markdown } from "components/Page";
import { fullPath } from "tools/Route";
import { formatLongDate } from "tools/DateExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    layout: {
        margin: theme.spacing(6, 2),
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            margin: theme.spacing(6, "auto"),
        }
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    articles: {
        "& > *": {
            padding: theme.spacing(4, 0, 4, 0),
            borderBottom: `1px ${theme.palette.divider} solid`,
        },
        "& > *:nth-last-child(-n+2)": {
            borderBottomWidth: 0,
        }
    },
    article_title: {
        textTransform: "uppercase",
        marginBottom: theme.spacing(2),
    },
}));

// settings file
declare var APP_CONTACT_EMAIL: string;

const POSTAL_ADRESS = "31 cours Journu Auber, 33000 Bordeaux";
const CONTACT_EMAIL = APP_CONTACT_EMAIL;
const DPO_EMAIL = "dpo@dline.io";
const LAST_UDPATE_DATE = new Date(2021, 10, 22);

const TERMS_OF_SERVICES_ARTICLES = [
    {
        name: "article 1. mentions légales",
        content: `
En vertu de l’article 6 de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, 
il est précisé dans cet article l’identité des différents intervenants dans le cadre de la réalisation et de 
l’exploitation du site Internet accessible à l’adresse [${fullPath("Home", { baseUrl: true, cleanEndSlash: true })}](${fullPath("Home")}) 
et des services qui y sont disponibles (ci-après, le « Site »).

Le Site est édité par Dline, société par actions simplifiée au capital social de 10 000 euros, dont le siège social est 
situé au ${POSTAL_ADRESS} et immatriculée au RCS de Bordeaux sous le numéro 882891211, téléphone : 06.78.97.75.84 (ci-après, « Dline » ou « l’Éditeur »).

Le directeur de publication du Site est Monsieur Maxime GOBELI.

Le Site est hébergé par OVH SAS, 2 rue Kellermann 59100 Roubaix, téléphone : 09.72.10.10.07.
        `
    },
    {
        name: "article 2. présentation du site et disclaimer",
        content: `
Le Site a pour objet d’accompagner les internes en radiothérapie dans leur formation 
initiale et participer à la formation continue des médecins en radiothérapie. 
Il constitue une base de données interactive à vocation éducationnelle et de recherche, 
concernant les lignes directrices en matière de contourage (guidelines) et des cours de 
formation pour les internes et les médecins en radiothérapie. Il offre également la 
possibilité à tout interne en radiothérapie ou professionnel de santé en radiothérapie 
de créer un espace personnel, afin de pouvoir communiquer à Dline des données d’imagerie 
médicale (selon la procédure décrite dans l’espace personnel de l’utilisateur), 
afin que celles-ci alimentent la base de données du Site et soient publiées sur celui-ci, 
le cas échéant. 

Le Site ne saurait être utilisé à l’occasion d’activités de prévention, de diagnostic, 
de soins ou de suivi médico-social auprès de personnes physiques et l’Éditeur ne saurait 
être considéré comme exerçant de telles activités du fait de la mise à disposition du Site. 
Toutes activités de prévention, de diagnostic, de soins ou de suivi médico-social 
auprès de personnes physiques demeurent la responsabilité des médecins et professionnels 
de santé qui les mettent en œuvre, le cas échéant.
        `
    },
    {
        name: "article 3. contact",
        content: `
Pour toute question ou demande d’information concernant le Site, ou tout signalement de contenu ou d’activités 
illicites, l’Utilisateur peut contacter l’Éditeur :
- par courrier à l’adresse : *Dline, ${POSTAL_ADRESS}* ;
- par courrier électronique à l’adresse [${CONTACT_EMAIL}](mailto:${CONTACT_EMAIL}) ; ou
- via le formulaire de contact accessible à l'adresse [${fullPath("ContactUs", { baseUrl: true })}](${fullPath("ContactUs")}).
        `
    },
    {
        name: "article 4. Champ d'application des conditions générales d'utilisation",
        content: `
L’accès et l’utilisation du Site sont soumis à l’acceptation et au respect par toute personne 
(ci-après « Utilisateur » ou « vous ») des présentes conditions générales d’utilisation (ci-après, 
les « Conditions Générales d’Utilisation » ou « CGU »). L’accès, la connexion et la navigation 
sur le Site valent acceptation sans réserve par l’Utilisateur des présentes CGU, quels que 
soient les moyens techniques d’accès et les terminaux que vous utilisez (par exemple, ordinateur, 
smartphone, tablette).

L’Éditeur se réserve le droit de modifier, à tout moment et sans préavis, le Site ainsi que les 
présentes CGU, notamment pour s’adapter aux évolutions technologiques ou au cadre légal et 
règlementaire applicable. Il est donc conseillé à l’utilisateur de se référer régulièrement à la 
dernière version des CGU, accessible à tout moment sur le Site.

Le Site est réservé à l’usage individuel et privé de chaque Utilisateur. En cas de désaccord avec 
les CGU, il est demandé à l’Utilisateur de ne pas accéder au Site et de ne pas l’utiliser.
        `
    },
    {
        name: "article 5. inscription et compte personnel",
        content: `
Tout Utilisateur démontrant sa qualité d’interne en radiothérapie ou de professionnel de santé 
en radiothérapie peut demander à s’inscrire auprès de Dline, et demander à Dline de lui créer 
un espace personnel sur le Site, afin de pouvoir communiquer à Dline des données d’imagerie 
médicale (selon la procédure décrite dans l’espace personnel de l’utilisateur). Ces données 
alimenteront la base de données de Dline et seront, le cas échéant, publiées sur le Site par Dline, 
conformément aux dispositions des CGU.

L’Utilisateur qui dispose d’un compte personnel sur le Site dispose également du droit 
de supprimer ce compte à tout moment, en utilisant la fonction « Supprimer mon compte » 
disponible sur chaque compte.
        `
    },
    {
        name: "article 6. fonctionnement et disponibilité du site",
        content: `
L’Éditeur s’efforcera de permettre l’accès au Site 24 heures sur 24, 7 jours sur 7 dans la mesure du possible. 
Il pourra néanmoins à tout moment suspendre, limiter ou interrompre l’accès à tout ou partie du Site afin de 
procéder à des actions de maintenance, mises à jour, modifications de contenu, toute autre action jugée 
nécessaire au fonctionnement du Site ou rendue obligatoire par le cadre légal applicable, ainsi qu’en raison 
de contraintes liées au fonctionnement des réseaux de communication le cas échéant.

Il est rappelé que la transmission des données sur Internet ne peut être entièrement sécurisée et fiable, 
et que l’utilisation de ce réseau est aux risques de l’Utilisateur. En tout état de cause, Dline ne saurait 
être tenue responsable de dysfonctionnement ou interruptions liées à Internet ou plus largement aux réseaux 
de communication.

L’Éditeur pourra à tout moment et sans préavis supprimer tout contenu ou information pouvant perturber le 
fonctionnement du Site ou entrant en contravention avec les lois nationales ou internationales applicables.
                `
    },
    {
        name: "article 7. comportements prohibés",
        content: `
Le Site constitue un système de traitement automatisé de données : tout accès ou maintien frauduleux au sein 
de ce dernier par l’Utilisateur est strictement interdit et fera l’objet de sanctions pénales. Il en est de 
même pour toute entrave ou altération du fonctionnement du Site ou tentative d’entrave ou d’altération, 
ainsi qu’en cas d’introduction, de suppression ou de modification de toutes données qui y sont contenues. 
Par conséquent, l’Utilisateur s’engage à ne pas perturber le bon fonctionnement du Site et, notamment, à ne 
pas y introduire de virus, ni à endommager, accéder illégalement ou modifier les données stockées sur le Site 
ou l’apparence de ce dernier.

L’Utilisateur s’interdit d’extraire et/ou de réutiliser de façon systématique tout ou partie du Site et de 
son contenu sans l’autorisation préalable écrite de Dline. En particulier, l’Utilisateur ne doit pas utiliser 
de robot d’aspiration de données (bot), ou tout autre outil ou technique similaire de collecte de données pour 
extraire ou pour réutiliser une partie substantielle des éléments contenus sur le Site.
                `
    },
    {
        name: "article 8. responsabilités",
        content: `
#### 8.1. Responsabilité de l’Éditeur
L’Éditeur n’est responsable que des contenus qu’il a lui-même édités sur le Site.

L’Utilisateur reconnait que le Site, ainsi que tout contenu disponible sur le Site, 
en particulier toute imagerie médicale, est mis à disposition « en l’état » et que toute 
utilisation du Site ainsi que de tels contenus s’effectue à ses propres risques. Par ailleurs, 
Dline ne garantit pas l’exactitude, la complétude, et l’actualité des informations qui sont disponibles sur le Site.

En conséquence, Dline ne saurait en aucun cas être tenue responsable des dommages résultant de l’utilisation 
du Site et/ou des contenus qui s’y trouvent par l’Utilisateur.

En outre, il est précisé que la mise à disposition par Dline des contenus sur le Site, en ce inclus 
les données d’imagerie médicale le cas échéant, ne saurait être considérée comme l’exercice d’une 
quelconque activité de prévention, de diagnostic, de soins ou de suivi médico-social auprès de personnes 
physiques par Dline. L’utilisation de ces contenus ne saurait dispenser l’Utilisateur d’obtenir l’avis 
d’un professionnel de santé, le cas échéant. Dline ne saurait en aucun cas être tenue responsable d’une 
quelconque erreur médicale par un Utilisateur du fait des données d’imagerie médicales disponibles sur le Site.

Enfin, Dline ne saurait être tenue responsable en cas de dommages, problématiques ou défaillances techniques, 
informatiques ou de compatibilité du Site avec un matériel ou logiciel de l’Utilisateur, quel qu’il soit 
ainsi que des dommages directs ou indirects, matériels ou immatériels, prévisibles ou imprévisibles résultant 
de l’utilisation ou des difficultés d’utilisation du Site ou de ses fonctionnalités.

Dline fera ses meilleurs efforts pour sécuriser votre accès au Site, mais ne saurait en assurer la sécurité 
absolue. Dline ne saurait être tenu responsable en raison des dysfonctionnements ou dommages inhérents à 
l’usage du réseau Internet, tels que rupture de service, intrusion extérieure ou présence de virus informatiques, 
ou des atteintes à la sécurité informatique pouvant causer des dommages à vos matériels informatiques et à vos données.

#### 8.2. Responsabilité et obligations de l’Utilisateur

L’Utilisateur est responsable :
- de la protection de tout matériel utilisé pour accéder et naviguer sur le Site, ainsi que de ses données ;
- de l’utilisation qu’il fait du Site et de tout contenu s’y trouvant.

En outre, tout Utilisateur s’engage à se conformer à l’intégralité des directives de Dline régissant 
l’utilisation de l’espace personnel et le téléchargement de données sur le Site, y compris celles concernant 
le traitement et la désidentification des données d’imagerie médicales, telles que précisées par les CGU 
ou mises à disposition de l’Utilisateur par Dline sur le Site ou par tout autre moyen, et telles que mises 
à jour le cas échant. Ces directives sont décrites dans l’espace personnel de l’utilisateur.

Plus particulièrement, l’Utilisateur s’oblige à anonymiser toutes données d’imagerie médicale préalablement à 
tout envoi à Dline, de sorte que ces contenus soient expurgés de façon irréversible de toutes données à 
caractère personnel permettant d’identifier, directement ou indirectement, la personne physique à laquelle elles se rapportent. 

Dline se réserve la possibilité d’exclure tout Utilisateur titulaire d’un compte personnel sur le Site si 
celui-ci se trouve en violation des CGU, sans préjudice de toute action en justice que Dline pourrait choisir d’exercer. 

Par ailleurs, Dline ne saurait être tenue responsable des conséquences résultant de la violation par 
tout Utilisateur des présentes CGU. Ainsi, l’Utilisateur s’engage à indemniser Dline en cas d’action engagée 
par tout tiers, ou de condamnation de cette dernière résultant de votre fait.
`
    },
    {
        name: "article 9. liens vers des sites tiers",
        content: `
Le Site est susceptible de contenir des liens hypertextes menant vers des sites Internet tiers, qui ne sont pas 
édités par Dline et sur lesquels l’Éditeur n’exerce pas de contrôle. Malgré les vérifications préalables et 
régulières réalisées par l’Éditeur, celui-ci décline toute responsabilité quant aux contenus qu’il est possible 
de trouver sur ces sites Internet tiers.

L’Éditeur autorise la mise en place de liens hypertextes vers toute page ou document de son Site sous réserve 
que la mise en place de ces liens ne soit pas réalisée à des fins commerciales ou publicitaires. Sont exclus de 
cette autorisation les sites diffusant des informations à caractère illicite, violent, polémique, pornographique, 
xénophobe ou pouvant porter atteinte à la sensibilité du plus grand nombre. En outre, l’information préalable de 
l’Éditeur est nécessaire avant toute mise en place de tel lien hypertexte pointant vers le Site. 

Enfin, l’Éditeur se réserve le droit de faire supprimer à tout moment un lien hypertexte pointant vers son Site, 
s’il l’estime non conforme à sa politique éditoriale.
                `
    },
    {
        name: "article 10. propriété intellectuelle",
        content: `
#### 10.1. Droits de Dline

La structuration du Site mais aussi les textes, graphiques, images, photographies, sons, vidéos et 
applications informatiques qui le composent sont la propriété de l’Éditeur et sont protégés comme 
tels au titre de la propriété intellectuelle par les lois et règlements applicables en la matière.

Toute représentation, reproduction, adaptation ou exploitation partielle ou totale des contenus, 
marques déposées et services proposés par le Site, par quelque procédé que ce soit, sans l’autorisation 
préalable, expresse et écrite de Dline, est strictement interdite.

L’accès au Site ne vaut pas reconnaissance d’un droit envers l’Utilisateur et, de manière générale, 
ne confère à l’Utilisateur aucun droit de propriété intellectuelle relatif à un quelconque élément du Site, 
lesquels restent la propriété exclusive de l’Éditeur.

#### 10.2. Droits de l’Utilisateur

Tout contenu téléchargé ou partagé par l’Utilisateur sur le Site, comme les données d’imagerie médicale, 
peut être protégé par des droits de propriété intellectuelle. L’Utilisateur garantit qu’il possède tous les 
droits de propriété intellectuelle (tels que les droits d’auteur) ou qu’il a acquis tous les droits nécessaires 
portant sur tout contenu téléchargé ou partagé sur le Site ou avec Dline. Aucune disposition des présentes 
CGU n’a pour objet ou effet de priver l’Utilisateur de ses droits sur son propre contenu et l’Utilisateur 
demeure libre d’utiliser son contenu.

Afin que Dline puisse fournir le Site, lorsque l’Utilisateur partage, publie ou télécharge du contenu 
protégé par des droits de propriété intellectuelle sur le Site, l’Utilisateur accorde au titre des présentes 
CGU à Dline une licence non-exclusive, transférable, sous-licenciable, gratuite et mondiale, pendant toute 
la durée d’existence de Dline et de toute société venant aux droits de Dline le cas échéant, pour héberger, 
utiliser, distribuer, modifier, exécuter, copier, représenter publiquement et/ou afficher publiquement, 
traduire et créer des œuvres dérivées à partir d’un tel contenu.

L’Utilisateur garantit à Dline de la pleine jouissance des droits concédés au titre des présentes CGU et 
garantit pleinement Dline contre tout litige, action, dépossession ou plainte de toute forme en lien avec de tels droits. 

L’Utilisateur indemnisera Dline pour toute réclamation ou action relative aux contenus dont les droits sont 
cédés à Dline au titre des présentes, notamment et sans s‘y limiter, pour atteinte à un droit et/ou 
concurrence déloyale et/ou parasitisme initié par un tiers, et supportera tous les coûts et dommages 
et intérêts y afférents (y compris le cas échéant les honoraires raisonnables d’avocats exposés par Dline).

Dans le cas où l’interdiction d’utilisation de tout ou partie des contenus dont les droits sont cédés à 
Dline au titre des présentes résulte d’une telle réclamation ou action, l’Utilisateur devra, à ses frais : 
- obtenir le droit pour Dline de continuer à utiliser les contenus en accord avec les dispositions des CGU ;
- à défaut, remplacer ou modifier le(s) contenu(s) visé(s) par la réclamation ou plainte, afin d’éteindre ladite réclamation ou action.

Le présent article survivra à l’expiration des CGU et/ou à la fermeture par l’Utilisateur de son compte 
personnel sur le Site, pour quelque cause que ce soit, pour une durée de dix (10) années.
`
    },
    {
        name: "article 11. Protection des données à caractère personnel",
        content: `
Dline accorde une grande importance à la protection des données à caractère personnel et s’engage à traiter 
vos données à caractère personnel en conformité avec les lois et règlements applicables en la matière. 
Le Site dispose d’une politique de confidentialité qui décrit la manière dont les données à caractère personnel 
sont traitées lorsque l’Utilisateur accède au Site et l’utilise, disponible à l’adresse 
[${fullPath("PrivacyPolicy", { baseUrl: true })}](${fullPath("PrivacyPolicy")}). 

En naviguant sur le Site, l’utilisateur déclare avoir également pris connaissance de la politique de confidentialité.

Veuillez noter que Dline ne collecte ni ne traite de donnée de santé à des fins de prévention, de diagnostic, 
de soins ou de suivi médico-social auprès de personnes physiques.
                `
    },
    {
        name: "article 12. Loi applicatible et juridiction compétente",
        content: `
Les présentes Conditions Générales d’Utilisation sont régies et s’interprètent conformément à la loi française, 
sans égard à ses règles relatives aux conflits de lois. En cas de différend et à défaut de règlement amiable 
entre l’utilisateur et l’Éditeur, le litige sera porté devant les tribunaux français conformément aux règles de compétence en vigueur.
        `
    },
];

const PRIVACY_POLICY_ARTICLES = [
    {
        name: "préambule",
        content: `
Cette politique de confidentialité (ci-après, la « Politique ») s'applique au site internet accessible 
à l'adresse [${fullPath("Home", { baseUrl: true, cleanEndSlash: true })}](${fullPath("Home")}) 
(ci-après, le « Site »).

La présente Politique a pour but d'informer les utilisateurs du Site ainsi que les personnes titulaires 
d'un compte personnel sur le Site (ensemble, « Utilisateur(s) ») sur la manière dont sont collectées et 
traitées leurs données personnelles lorsqu'ils accèdent et utilisent le Site ou s'inscrivent sur le Site 
afin de créer leur compte personnel.

Cette politique de confidentialité complète les Conditions Générales d'Utilisation du Site, que les 
Utilisateurs peuvent consulter à l'adresse ci-après : 
[${fullPath("TermsOfService", { baseUrl: true })}](${fullPath("TermsOfService")}).
        `
    },
    {
        name: "article 1. qui sommes nous ?",
        content: `
Le Site est édité par Dline (ci-après « Dline » ou « nous »), société par actions simplifiée 
dont le siège social est situé au *${POSTAL_ADRESS}*, immatriculée au RCS de Bordeaux 
sous le numéro 882891211, agissant en qualité de responsable du traitement des données personnelles 
collectées sur le Site aux fins décrites ci-dessous.

Le délégué à la protection des données de Dline est M. Luc LAFITTE.

Dline et son délégué à la protection des données peuvent être contactés par courrier électronique 
à l’adresse suivante [${DPO_EMAIL}](mailto:${DPO_EMAIL}), ou via le formulaire de contact accessible à 
l’adresse [${fullPath("ContactUs", { baseUrl: true })}](${fullPath("ContactUs")}) .
        `
    },
    {
        name: "article 2. quelles donnéees personnelles sont collectées sur le site ?",
        content: `
Selon les fonctionnalités du Site que l’Utilisateur sera amené) à utiliser, ses choix et la configuration 
de l’appareil utilisé pour accéder au Site (en particulier pour les cookies), Dline est susceptible de 
collecter et traiter, en sa qualité de responsable du traitement, pour les finalités mentionnées à 
l’Article 3 ci-après, tout ou partie des données personnelles ci-dessous.

Des données que l’Utilisateur peut fournir volontairement à Dline :
- données d'identification (nom, prénom…) ;
- données de contact (email) ;
- profession (pour validation de votre statut); et
- historique de vos contacts avec Dline. 

Des données que nous collectons automatiquement lors l’Utilisateur se connecte ou navigue sur le Site :
- données de connexion (adresse IP) ;
- données de navigation (cookies). 

Sur les différentes pages du Site où elles sont collectées, les données dont la fourniture est obligatoire 
(en vertu d’une obligation réglementaire ou pour la conclusion ou l’exécution du contrat qui nous lie à 
l’Utilisateur (par exemple, les conditions générales d’utilisation du Site) sont signalées par le biais 
d’astérisques. 
Si l’Utilisateur refuse de fournir de telles données obligatoires, Dline ne sera pas en mesure de fournir 
les services demandés à l’Utilisateur, de traiter ses demandes ou de conclure le contrat en cause.
        `
    },
    {
        name: "article 3. finalités et bases légales du traitement des données",
        content: `
Nous fournissons dans le tableau ci-dessous une vue d’ensemble des différentes finalités du traitement 
de vos données personnelles par Dline ainsi que des justifications légales qui permettent à Dline de traiter 
ces données (les « bases légales »).

| Finalité du traitement | Base légale |
| --------------- | --------------- |
| Exploiter le Site, assurer sa sécurité et permettre votre navigation sur celui-ci. | Intérêt légitime de Dline d'assurer la fluidité et la sécurité de toute navigation sur le Site, d'améliorer l’expérience des Utilisateurs, de corriger tout incident, erreur ou bug ; Exécution du contrat conclu entre Dline et chaque Utilisateur (Conditions Générales d’Utilisation) pour l’utilisation du Site. |
| Vous permettre de vous connecter à votre espace personnel et d’utiliser les fonctionnalités du Site. | Exécution du contrat conclu entre Dline et chaque Utilisateur (Conditions Générales d’Utilisation) pour l’utilisation du Site. |
| Vous fournir les informations que vous nous demandez. | Intérêt légitime de Dline de répondre aux demandes d'informations que vous pourriez nous envoyer, notamment en prenant contact avec nous à travers le Site (via le formulaire de contact) ou par email. |
| Réaliser des analyses statistiques d’utilisation, d’audience, de performance. | Intérêt légitime de Dline d'améliorer le Site, ses fonctionnalités ainsi que ses produits et services. |
| Répondre à nos obligations légales et assurer la l’exercice, la démonstration et la défense de nos intérêts et de nos droits en cas de litige. | Obligations légales et réglementaires de Dline ; Intérêt légitime de Dline d’assurer l’exercice de ses droits et/ou sa défense en cas d'action en justice. |
| Préparer et/ou mettre en œuvre toute réorganisation des activités et/ou des actifs de Dline, ainsi que toute opération commerciale liée (p.ex. fusion ou acquisition). | Intérêt légitime de Dline de gérer et développer ses activités. |
        `
    },
    {
        name: "article 4. transmission des données à des tiers",
        content: `
Les données personnelles collectées par le biais du Site peuvent être transmises par Dline à des 
destinataires tiers, en particulier nos partenaires tiers (par exemple, cliniques, hôpitaux, 
centres de recherche) ainsi que nos prestataires et fournisseurs. A titre d’exemple, vos données sont 
fournies à l’hébergeur du Site, qui est établi en France. Nous pouvons également être amenés à 
communiquer les données aux autorités (administratives ou judiciaires) sur demande lorsque la 
règlementation applicable l’impose. 

Dline veille à ce que tout tiers autorisé à accéder aux données personnelles soit soumis à des 
obligations contractuelles ou légales appropriées, permettant d’assurer la sécurité et la 
confidentialité des données, en conformité avec la réglementation applicable.
        `
    },
    {
        name: "article 5. droits de l'utilisateur",
        content: `
Conformément à la réglementation concernant le traitement des données personnelles, 
en particulier le Règlement (UE) 2016/679 (GDPR) et la Loi 78-17 du 6 janvier 1978 modifiée 
(Loi Informatique et Libertés), tout Utilisateur dispose des droits suivants :
- droit d’accès : l’Utilisateur peut demander un accès aux données le concernant et des 
informations sur les modalités de traitement de ces données et la base juridique de ce traitement ; 
- droit de rectification : l’Utilisateur peut demander à rectifier les données personnelles 
erronées (y compris le droit de compléter des données personnelles incomplètes) le concernant ; 
- droit à l’effacement : l’Utilisateur peut demander la suppression des données le concernant 
(dans certains cas, ce droit ne pourra s'appliquer que lorsqu’elles ne sont plus nécessaires pour 
réaliser les finalités pour lesquelles elles avaient été collectées ou traitées) ; 
- droit à la limitation : l’Utilisateur peut demander la limitation du traitement des données 
personnelles le concernant lorsque :
    - l’exactitude des données personnelles est contestée ;
    - le traitement est illicite mais vous vous opposez à l’effacement de vos données personnelles ;
    - Dline n’a plus besoin de vos données personnelles mais elles sont encore nécessaires pour la constatation, l’exercice 
    ou la défense d’un droit en justice.
- droit à la portabilité des données : l’Utilisateur peut demander la portabilité des données le concernant, 
détenues par le Site, vers un autre organisation, dans un format lisible par une machine,  si le traitement 
par Dline est fondé sur le  consentement de l’Utilisateur ou sur l’exécution d’un contrat qui a été conclu avec l’Utilisateur.  
- héritage numérique : l’Utilisateur peut donner des instructions générales ou spécifiques 
sur les modalités de traitement et d’utilisation de ses données personnelles après son décès.
- Droit de ne pas faire l’objet d’une décision fondée exclusivement sur un procédé automatisé 
(y compris le profilage) : l’Utilisateur a le droit de ne pas faire l’objet d’une décision fondée 
exclusivement sur un procédé automatisé si la décision produit des effets juridiques le concernant, 
ou l’affecte de manière significative de façon similaire
- Retrait du consentement : l’Utilisateur peut retirer son consentement au traitement de ses données 
personnelles (si ce traitement repose sur la base légale du consentement), sans que ce retrait ne remette 
en cause la licéité du traitement fondé sur le consentement effectué avant le retrait de celui-ci. 

L’Utilisateur dispose également du droit de s’opposer à tout traitement de ses données personnelles 
lorsqu’un tel traitement est justifié par l’intérêt légitime de Dline, tel que décrit dans la présente 
Politique, dès lors que cet intérêt légitime est susceptible d'affecter ses droits et libertés.

Pour exercer vos droits, veuillez nous contacter à l’aide des coordonnées figurant à l’Article 12 « CONTACT » 
ci-dessous. Dline s’efforcera de répondre à votre demande dans les délais prévus par les lois de protection des données personnelles.

Dans l’hypothèse où Dline aurait des doutes sur votre identité à la suite d’une demande, nous pourrons 
vous demander un justificatif d’identité. Par ailleurs, dans le cadre de demandes manifestement infondées ou excessives, 
Dline pourra vous facturer les frais engagés pour satisfaire cette demande.
        `
    },
    {
        name: "article 6. données des personnes mineures",
        content: `
L’utilisation du Site et de ses fonctionnalités, en ce inclus la création d’un compte personnel, 
est réservée à des personnes majeures. Dline, en tant qu’éditeur du Site se réserve le droit de vérifier 
par tout moyen que l’utilisateur est âgé de 18 ans révolus.

Dline ne collecte ni ne traite volontairement de données personnelles relatives à des mineurs et 
s’efforcera de supprimer dans les meilleurs délais toute donnée relative à des mineurs qui lui serait signalée sur le Site.
    `
    },
    {
        name: "article 7. durées de conservation",
        content: `
Dline ne conserve vos données personnelles que pour la durée nécessaire à la réalisation des finalités pour 
lesquelles elles ont été collectées, telles que décrites par la présente Politique.

Plus spécifiquement :
- les données d'identification et de contact des Utilisateurs titulaires d’un compte personnel 
sont conservées pendant toute la durée d’utilisation du compte et du contrat (CGU) avec Dline ;
- les données d'identification et de contact des Utilisateurs du Site qui ne disposent pas d’un 
compte personnel sont conservées pendant  le temps nécessaire à Dline pour se conformer à ses 
obligations légales et, le cas échéant, pour répondre à la demande transmise par l’Utilisateur via le Site.

Dline peut être amenée à conserver vos données personnelles sous forme d’archive au-delà des 
durées spécifiées ci-dessus, afin de se conformer à ses obligations légales ou si cela est nécessaire 
compte tenu de la prescription applicable, pour exercer, défendre ou faire valoir un de ses droits.

Une fois que vos données personnelles ne sont plus nécessaires pour les finalités décrites ci-dessus, 
à des fins d’archivage ou encore pour satisfaire nos obligations légales, elles seront détruites ou anonymisées par Dline.
        `
    },
    {
        name: "article 8. transfert de données",
        content: `
Dline peut transférer vos données personnelles en dehors de l'Union européenne (« UE ») ou de 
l'Espace économique européen « EEE »), dans des pays qui ne bénéficient pas d’une décision d’adéquation 
valide de la Commission européenne et qui ne garantissent donc pas un niveau de protection des données similaire à celui de l'EEE.

Dline met en œuvre des mécanismes de transfert de données appropriés tels que requis par la 
règlementation des données personnelles applicables, y compris les clauses contractuelles type de la 
Commission européenne et les garanties supplémentaires nécessaires, afin d’assurer la légalité de ces transferts. 

L’Utilisateur peut obtenir une copie des mécanismes de transfert en contactant Dline aux coordonnées disponibles ci-dessous.
        `
    },
    {
        name: "article 9. liens vers des sites tiers",
        content: `
Le Site peut contenir des liens hypertextes menant vers des sites Internet tiers, 
qui ne sont pas édités par Dline. Dline n’exerçant aucun contrôle sur de tels sites tiers, 
nous ne saurions être tenus responsables de leurs pratiques en matière de protection des données personnelles. 
Nous vous encourageons à vérifier les politiques de ces sites tiers en matière de protection des données personnelles. 

La présente politique de confidentialité s’applique uniquement aux données personnelles 
collectées par le biais du Site ou dans le cadre de nos activités liées à l’exploitation du Site.
        `
    },
    {
        name: "article 10. cookies",
        content: `
Lorsque l’Utilisateur visite le Site, nous utilisons des cookies pour les fins décrites ci-dessous. 
Un cookie est un fichier de petite taille, stocké par le Site sur le disque dur de l’équipement 
utilisé par l’Utilisateur pour utiliser le Site (ordinateur, tablette, smartphone), et qui permet 
la collecte d’informations relatives notamment à l’équipement de l’Utilisateur ainsi qu’à son 
utilisation du Site et de ses fonctionnalités.

Ces fichiers lui permettent à Dline de collecter des informations relatives au trafic et à l’utilisation 
du Site, afin de faciliter la navigation et d’améliorer le Site et ses fonctionnalités.

Les cookies ne seront placés sur votre appareil que si vous donnez votre consentement. Veuillez noter 
que les cookies nécessaires (voir Article 10.3 ci-dessous) ne nécessitent pas votre consentement 
et seront toujours placés sur votre appareil.

#### 10.1. À quoi servent les cookies ?

Les cookies utilisés sur le Site poursuivent divers objectifs ou « finalités » :
- cookies nécessaires : ces cookies sont nécessaires pour que le Site fonctionne correctement. 
Ils comprennent, par exemple, les cookies qui vous permettent de naviguer et d’utiliser le Site, 
tels que les cookies qui se rappellent que vous vous êtes connecté(e) à votre compte personnel. 
Ces cookies ne peuvent pas être désactivés.
- cookies fonctionnels : Ces cookies collectent des informations sur la manière dont les visiteurs 
utilisent le Site, ils surveillent les performances du Site et sont utilisés pour reconnaître et 
mémoriser vos préférences. Si vous acceptez de recevoir des e-mails de notre part, nous les utilisons 
également pour suivre vos réactions aux e-mails que nous vous envoyons et pour savoir si vous avez 
ouvert ou supprimé un e-mail. Les cookies fonctionnels peuvent également être utilisés pour 
identifier et résoudre les problèmes opérationnels du Site. Ils ne seront utilisés qu’avec votre consentement. 

#### 10.2. Quelle est la durée des cookies ?

Les cookies sont classés en deux catégories :
- les cookies de session sont stockés temporairement et supprimés une fois que 
vous fermez la fenêtre de votre navigateur Internet ; et
- les cookies permanents sont stockés sur votre appareil entre les sessions du navigateur Internet, 

La durée de conservation des cookies sur votre appareil varie en fonction du cookie spécifique 
utilisé et est décrite dans le tableau ci-dessous. En tout état de cause, la durée de vie maximale 
d’un cookie sur votre appareil n’excèdera pas treize (13) mois.

#### 10.3. Quels cookies utilisons-nous ?

Vous trouverez dans le tableau ci-dessous des informations relatives aux cookies utilisés sur le Site, leur finalité et leur durée.

| Finalité | Nom du cookie | Editeur du cookie | Durée du cookie |
| :------- | :------------ | :---------------- | :-------------- |
| Necessaire : enregistrer votre choix des cookies fonctionnels | consent | dline | 12 mois |
| Necessaire : enregistrer votre session de connection | RefreshToken | dline | 7 jours |
| Fonctionnel : mesurer et analyser le trafic sur le site | _ga | google analytics | 24 mois |
| Fonctionnel : mesurer et analyser le trafic sur le site | _gat / _gid | google analytics | 1 jour |

#### 10.4. Gestion et suppression des cookies

Afin de contrôler l’utilisation des cookies sur notre Site, nous avons mis en place un outil 
vous permettant à tout moment de configurer les cookies. Cet outil est accessible via un lien 
hypertexte disponible sur chaque page du Site et à 
l’adresse [${fullPath("CookiesSettings", { baseUrl: true })}](${fullPath("CookiesSettings")})

Si vous refusez les cookies, nous ne placerons plus d’autres cookies sur votre appareil, à l’exception 
des cookies « nécessaires » listés ci-dessus (y compris, notamment un cookie permettant de mémoriser 
le fait que vous ne voulez pas que des cookies soient installés lorsque vous consultez le Site).
    
Veuillez noter que si vous choisissez de supprimer ou de refuser certains cookies, cela pourrait affecter 
le fonctionnement optimal du Site et/ou de tout ou partie de ses fonctionnalités.
        `
    },
    {
        name: "article 11. modification de cette politique",
        content: `
Dline se réserve le droit de modifier la Politique à tout moment, afin de refléter les évolutions 
du Site et de ses fonctionnalités et afin de garantir sa conformité avec le droit en vigueur.

Par conséquent, l’Utilisateur est invité à la consulter régulièrement, afin de se tenir informé 
des derniers changements qui lui seront apportés. Toutefois, en cas de modification substantielle 
de la Politique, l’Utilisateur en sera spécifiquement informé, par exemple au moyen d’une notification 
lors de la prochaine visite de l’Utilisateur sur le Site ou par e-mail à l’adresse communiquée 
par l’Utilisateur, le cas échéant.
        `
    },
    {
        name: "article 12. contact",
        content: `
Si vous avez des questions concernant cette Politique ou si vous souhaitez exercer l’un de vos droits, 
veuillez nous contacter :
- par courrier à l’adresse : *Dline, ${POSTAL_ADRESS}* ;
- par courrier électronique à l’adresse [${CONTACT_EMAIL}](mailto:${CONTACT_EMAIL}) ; ou
- via le formulaire de contact accessible à l'adresse [${fullPath("ContactUs", { baseUrl: true })}](${fullPath("ContactUs")}).
        `
    },
];

export default function CguPage() {

    const classes = useStyles();
    const { pathname } = useLocation();

    useEffect(() => window.scrollTo({ top: 0 }), [pathname]);

    let title = "Conditions générales d'utilisation";
    let description = title;
    let articles = TERMS_OF_SERVICES_ARTICLES;

    if (pathname === fullPath("PrivacyPolicy")) {
        title = "Politique de confidentialité";
        description = title;
        articles = PRIVACY_POLICY_ARTICLES;
    }

    return <>
        <PageLayout tab={undefined}>

            <Helmet>
                <title>{title} - Dline</title>
                <meta name="description" content={description} />
            </Helmet>

            <CentralColumnLayout size="md" className={classes.layout}>

                <Typography variant="h5" component="h1" className={classes.title}>
                    {title}
                </Typography>

                <Box className={classes.articles}>
                    {articles.map((a, idx) =>
                        <Box component="section" key={idx}>
                            <Typography className={classes.article_title} variant="h6">
                                {a.name}
                            </Typography>

                            <Markdown>
                                {a.content}
                            </Markdown>
                        </Box>
                    )}

                    <Markdown variant="body2" component="span">
                        {`*Last modification on ${formatLongDate(LAST_UDPATE_DATE)}*`}
                    </Markdown>
                </Box>

            </CentralColumnLayout>

            <Footer />

        </PageLayout >
    </>
}

