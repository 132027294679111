//Generated code, do not modify

export enum ContouringWorkshopState {
  None = 0,
  Past = 1,
  Present = 2,
  Future = 3,
}

export const ContouringWorkshopStateOptions = [
  {
    label: "None",
    value: ContouringWorkshopState.None,
  },
  {
    label: "Past",
    value: ContouringWorkshopState.Past,
  },
  {
    label: "Present",
    value: ContouringWorkshopState.Present,
  },
  {
    label: "Future",
    value: ContouringWorkshopState.Future,
  },
];
