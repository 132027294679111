import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useSearchParams<T = any>() {
    const location = useLocation();

    return useMemo(() => {
        const params = new URLSearchParams(location.search);
        const result = {} as any;
        params.forEach((v, k) => {
            result[k] = v.toString();
        });
        return result as T;
    }, [location.search]);
}