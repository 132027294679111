import React, { useCallback, useState } from "react";
import { Box, Typography } from "@material-ui/core";

import { Guid } from "domain/static/Guid";
import { Contour, ContouringWorkshopViewerResponse } from "domain/public/response/ContouringWorkshopViewerResponse";
import { useViewerContext } from "contexts/Viewer";
import {
    ContourList, ContourSeparator,
    ContourItem as BaseContourItem,
    ItemComponentProps,
} from "components/ClinicalData";
import { contourInfoLabel } from "tools/StringExtension";

type ContourItemProps = ItemComponentProps<Contour> & {
    numberOfParticipant: number;
}

function ContourItem({ contour, numberOfParticipant, onClick }: ContourItemProps) {
    const {
        data: viewerData,
        dispatcher: viewerDispatcher
    } = useViewerContext();

    const contourData = viewerData.getContourById(contour.id);

    const onVisibilityHandle = useCallback((id: string, visibled: boolean) => {
        viewerDispatcher({
            type: "CONTOURS_VISIBILITY",
            contourIds: [id],
            visibled: visibled,
        });
    }, [viewerDispatcher]);

    return <BaseContourItem
        id={contour.id}
        color={contour.color}
        label={contourInfoLabel(contour)}
        sublabel={contour.organizationName}
        visibled={contourData ? contourData.visible : true}
        onToggleVisibled={onVisibilityHandle}
        onClick={onClick}
        indicator={contour.isEditable &&
            <Typography variant="body2" component="span"
                title={`participation : ${contour.numberOfParticipantResult} results / ${numberOfParticipant} participants`}
            >
                {contour.numberOfParticipantResult}/{numberOfParticipant}
            </Typography>
        }
    />
}

export interface ContourListPanelProps {
    contouringWorkshop: ContouringWorkshopViewerResponse;
    onClick: (contourId: Guid) => void;
}

export default function ContourListPanel({ contouringWorkshop, onClick }: ContourListPanelProps) {
    const { dispatcher: viewerDispatcher } = useViewerContext();

    const { contours, numberOfParticipant } = contouringWorkshop;

    const [checked, setChecked] = useState(true);

    const onCheckHandle = useCallback((_checked: boolean) => {
        const contourIds = contours.map(x => x.id);
        viewerDispatcher({
            type: "CONTOURS_VISIBILITY",
            contourIds: contourIds,
            visibled: _checked,
        });
        setChecked(_checked);
    }, [contours, viewerDispatcher]);

    return <Box>
        <ContourSeparator
            label={`all (${contours.length})`}
            checked={checked}
            onCheck={onCheckHandle}
        />

        <ContourList
            contours={contours}
            ItemComponent={(props) => <ContourItem {...props}
                numberOfParticipant={numberOfParticipant}
            />}
            onClick={onClick}
        />
    </Box>
}