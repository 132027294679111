import React from "react";

import { Guid } from "domain/static/Guid";
import { ContouringWorkshopViewerResponse } from "domain/public/response/ContouringWorkshopViewerResponse";
import { useViewerContext } from "contexts/Viewer";
import { ContourPanel as BaseContourPanel, ContourPanelLoader } from "components/ClinicalData";

export interface ContourPanelProps {
    contourId: Guid;
    contouringWorkshop: ContouringWorkshopViewerResponse;
    onClose: () => void;
    disabledEdition: boolean;
}

export default function ContourPanel({ contourId, contouringWorkshop, onClose, disabledEdition }: ContourPanelProps) {
    const {
        data: viewerData,
        dispatcher: viewerDispatcher,
        app: viewerApp,
    } = useViewerContext();

    const contour = contouringWorkshop.contours.find(x => x.id === contourId);
    const contourData = viewerData.getContourById(contourId);
    const contourHelper = viewerApp.getContourById(contourId);

    if (!contour) {
        return <ContourPanelLoader />
    }

    const article = contouringWorkshop.contourArticles.find(x => x.id === contour.articleId);

    const onChangeColorHandle = (id: Guid, newColor: string) => {
        if (contourData) {
            viewerDispatcher({
                type: "CONTOUR_COLOR",
                contourId: id,
                color: newColor,
            });
        }
    }

    const onCleanHandle = async () => {
        if (contourHelper) {
            await contourHelper.clean();
        }
    }

    return <BaseContourPanel
        contour={contour}
        color={contourData ? contourData.color : "white"}
        onClose={onClose}
        article={article}
        onColorChanged={disabledEdition || contour.isEditable !== true ? undefined : onChangeColorHandle}
        onClean={disabledEdition || contour.isEditable !== true ? undefined : onCleanHandle}
    />
}

