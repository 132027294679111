import { FusionType } from "domain/static/FusionType";
import { Guid } from "domain/static/Guid";
import { ImagingDefault } from "domain/static/ImagingDefault";
import { Range } from "domain/static/Range";
import ViewerData, { DrawingType } from "./ViewerData";

export interface Action_Initialize {
  type: "INITIALIZE";
  data: ViewerData;
}

export interface Action_ImageWindowing {
  type: "IMAGE_WINDOWING";
  imageId: Guid;
  windowing: Range;
}

export interface Action_ImageLut {
  type: "IMAGE_LUT";
  imageId: Guid;
  lut: string;
}

export interface Action_ImagePrimary {
  type: "IMAGE_PRIMARY";
  imageId: Guid;
}

export interface Action_ImageOverlay {
  type: "IMAGE_OVERLAY";
  imageId: Guid;
}

export interface Action_StacksVisibility {
  type: "STACKS_VISIBILITY";
  stackIds: Guid[];
  visibled: boolean;
}

export interface Action_StackWindowing {
  type: "STACK_WINDOWING";
  stackId: Guid;
  windowing: Range;
}

export interface Action_ContoursVisibility {
  type: "CONTOURS_VISIBILITY";
  contourIds: Guid[];
  visibled: boolean;
}

export interface Action_ContourColor {
  type: "CONTOUR_COLOR";
  contourId: Guid;
  color: string;
}

export interface Action_ContourDrawing {
  type: "CONTOUR_DRAWING";
  contourId: Guid | null;
}

export interface Action_ContourMove {
  type: "CONTOUR_MOVE";
  addedIndex: number;
  removedIndex: number;
}

export interface Action_DrawingSize {
  type: "DRAWING_SIZE";
  size: number;
}

export interface Action_DrawingType {
  type: "DRAWING_TYPE";
  drawingType: DrawingType;
}

export interface Action_FusionType {
  type: "FUSION_TYPE";
  fusionType: FusionType;
}

export interface Action_Loading {
  type: "LOADING";
  loading: boolean;
}

export type ActionsType =
  | Action_Initialize
  | Action_ImageWindowing
  | Action_ImageLut
  | Action_ImagePrimary
  | Action_ImageOverlay
  | Action_StacksVisibility
  | Action_StackWindowing
  | Action_ContourColor
  | Action_ContoursVisibility
  | Action_ContourDrawing
  | Action_ContourMove
  | Action_DrawingSize
  | Action_DrawingType
  | Action_FusionType
  | Action_Loading;

export function reducer(draft: ViewerData, action: ActionsType) {
  switch (action.type) {
    case "INITIALIZE": {
      return new ViewerData(action.data);
    }

    case "IMAGE_WINDOWING": {
      const e = draft.getImageById(action.imageId);
      if (!e) return;

      e.windowing = action.windowing;
      return;
    }

    case "IMAGE_LUT": {
      const e = draft.getImageById(action.imageId);
      if (!e) return;

      e.lut = action.lut;
      return;
    }

    case "IMAGE_PRIMARY": {
      const old = draft.getImageByImagingDefault(ImagingDefault.Primary);
      if (old) {
        old.default = ImagingDefault.None;
      }

      const e = draft.getImageById(action.imageId);
      if (e) {
        e.default = ImagingDefault.Primary;
      }

      return;
    }

    case "IMAGE_OVERLAY": {
      const old = draft.getImageByImagingDefault(ImagingDefault.Overlay);
      if (old) {
        old.default = ImagingDefault.None;
      }

      const e = draft.getImageById(action.imageId);
      if (e) {
        e.default = ImagingDefault.Overlay;
      }

      return;
    }

    case "STACKS_VISIBILITY": {
      for (const stackId of action.stackIds) {
        const e = draft.getStackById(stackId);
        if (e) {
          e.visible = action.visibled;
        }
      }
      return;
    }

    case "STACK_WINDOWING": {
      const e = draft.getStackById(action.stackId);
      if (!e) return;

      e.windowing = action.windowing;
      return;
    }

    case "CONTOUR_COLOR": {
      const e = draft.getContourById(action.contourId);
      if (!e) return;

      e.color = action.color;

      return;
    }

    case "CONTOURS_VISIBILITY": {
      for (const contourId of action.contourIds) {
        const e = draft.getContourById(contourId);
        if (e) {
          e.visible = action.visibled;
        }
      }
      return;
    }

    case "CONTOUR_DRAWING": {
      const old = draft.getContourDrawing();
      if (old) {
        old.drawing = false;
      }

      const e =
        action.contourId !== null
          ? draft.getContourById(action.contourId)
          : undefined;
      if (e) {
        e.drawing = true;
        e.visible = true;
      }
      return;
    }

    case "CONTOUR_MOVE": {
      draft.contours.splice(
        action.addedIndex,
        0,
        draft.contours.splice(action.removedIndex, 1)[0]
      );

      return;
    }

    case "LOADING": {
      draft.loading = action.loading;
      return;
    }

    default:
      return draft;
  }
}
