import React, { useCallback, useState } from "react";
import {
    createStyles, makeStyles, Theme,
    IconButton, Popover, Typography, Slider, Tooltip, Box, TextField, MenuItem
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import {
    CloseIcon, FusionCrossIcon, FusionHorizontalIcon,
    FusionVerticalIcon, ToolsIcon, OpacityIcon
} from "App/Theme";
import LutSelect from "./LutSelect";
import { useViewerContext } from "contexts/Viewer";
import { Range } from "domain/static/Range";
import { FusionType, FusionTypeOptions } from "domain/static/FusionType";
import { OnDesktop, OnMobile } from "components/Page";
import { ImagingDefault } from "domain/static/ImagingDefault";
import { EmptyGuid, Guid } from "domain/static/Guid";

const useStyles = makeStyles((theme: Theme) => createStyles({
    btn: {
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
    },
    popover: {
        padding: theme.spacing(1, 2, 2, 2),
        borderRadius: theme.shape.borderRadius * 4,
        width: "100%",
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            maxWidth: 320,
        }
    },
    closeBtn: {
        position: "absolute",
        top: 0,
        right: 0
    },
    primary_title: {
        display: "block",
        margin: theme.spacing(4, 0, 0.5, 0),
    },
    overlay_title: {
        display: "block",
        margin: theme.spacing(2, 0, 0.5, 0),
    },
    overlay_mode: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1),
    }
}));

interface Image {
    id: Guid;
    name: string;
    range: Range;
}

export interface ImagesSettingsProps {
    images: Image[];
}

export default function ImagesSettings({ images }: ImagesSettingsProps) {
    const classes = useStyles();

    const { data: viewerData, dispatcher: viewerDispatcher } = useViewerContext();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [sliding, setSliding] = useState(false);
    const [fusionType, setFusionType] = useState(viewerData.defaultFusionType);

    const open = Boolean(anchorEl);
    const imageData_primary = viewerData.getImagePrimary();

    const onImageChangeHandle = useCallback((imageId: Guid, imagingDefault: ImagingDefault) => {
        viewerDispatcher({
            type: imagingDefault === ImagingDefault.Primary ? "IMAGE_PRIMARY" : "IMAGE_OVERLAY",
            imageId,
        })
    }, [viewerDispatcher])

    if (!imageData_primary) {
        return null;
    }

    const image_primary = images.find(x => x.id === imageData_primary.id) as Image;
    const imageData_overlay = viewerData.getImageOverlay();
    const image_overlay = imageData_overlay ? images.find(x => x.id === imageData_overlay.id) : undefined;

    const primaryOptions = viewerData.images;
    const overlayOptions = viewerData.getImageOverlayOptions();

    return <>
        <Tooltip title="Adjust images contrast and LUT" placement="bottom-end" arrow>
            <IconButton
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                }}
                className={classes.btn}
                color="primary"
                style={{ opacity: open ? 0 : 1 }}
            >
                <ToolsIcon />
            </IconButton>
        </Tooltip>

        <Popover
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            classes={{
                paper: classes.popover
            }}
            style={{
                opacity: sliding ? 0.3 : 1
            }}
        >
            <IconButton
                size="medium"
                color="primary"
                className={classes.closeBtn}
                onClick={() => setAnchorEl(null)}
            >
                <CloseIcon />
            </IconButton>

            {imageData_primary && <>
                <Typography variant="button" className={classes.primary_title}>primary</Typography>

                <OnMobile>
                    <Box marginBottom={1}>
                        <TextField
                            select
                            variant="outlined"
                            fullWidth
                            value={image_primary.id}
                            onChange={(e) => onImageChangeHandle(e.target.value as Guid, ImagingDefault.Primary)}
                        >
                            {primaryOptions.map(x =>
                                <MenuItem value={x.id} key={x.id}>{images.find(img => img.id === x.id)?.name}</MenuItem>
                            )}
                        </TextField>
                    </Box>
                </OnMobile>

                <Slider
                    valueLabelDisplay="auto"
                    value={[imageData_primary.windowing.min, imageData_primary.windowing.max]}
                    min={image_primary.range.min}
                    max={image_primary.range.max}
                    onChangeCommitted={() => setSliding(false)}
                    onChange={(_e: any, newValue: any) => {
                        setSliding(true);
                        viewerDispatcher({
                            type: "IMAGE_WINDOWING",
                            imageId: imageData_primary.id,
                            windowing: new Range(newValue)
                        });
                    }}
                />

                <LutSelect
                    value={imageData_primary.lut}
                    onChange={newLut => {
                        viewerDispatcher({
                            type: "IMAGE_LUT",
                            imageId: imageData_primary.id,
                            lut: newLut,
                        });
                    }}
                />
            </>}

            {overlayOptions.length > 0 && <>
                <Typography variant="button" className={classes.overlay_title}>overlay</Typography>

                <OnMobile>
                    <Box marginBottom={2}>
                        <TextField
                            select
                            variant="outlined"
                            fullWidth
                            value={imageData_overlay?.id ?? EmptyGuid()}
                            onChange={(e) => onImageChangeHandle(e.target.value as Guid, ImagingDefault.Overlay)}
                        >
                            <MenuItem value={EmptyGuid()}><em>Select overlay</em></MenuItem>
                            {overlayOptions.map(x =>
                                <MenuItem value={x.id} key={x.id}>{images.find(img => img.id === x.id)?.name}</MenuItem>
                            )}
                        </TextField>
                    </Box>
                </OnMobile>

                <OnDesktop>
                    {!imageData_overlay &&
                        <Typography variant="caption">
                            No overlay image selected.
                        </Typography>
                    }
                </OnDesktop>

                {imageData_overlay && <>
                    <Box className={classes.overlay_mode}>
                        <Typography>Mode</Typography>
                        <ToggleButtonGroup
                            exclusive
                            value={fusionType}
                            onChange={(_e: any, value: FusionType) => {
                                setFusionType(value);
                                viewerDispatcher({
                                    type: "FUSION_TYPE",
                                    fusionType: value,
                                }, "viewer");
                            }}
                            aria-label="fusion type"
                            size="small"
                        >
                            <ToggleButton value={FusionType.Cross} aria-label={FusionTypeOptions[0].label}>
                                <FusionCrossIcon />
                            </ToggleButton>
                            <ToggleButton value={FusionType.Vertical} aria-label={FusionTypeOptions[1].label}>
                                <FusionVerticalIcon />
                            </ToggleButton>
                            <ToggleButton value={FusionType.Horizontal} aria-label={FusionTypeOptions[2].label}>
                                <FusionHorizontalIcon />
                            </ToggleButton>
                            <ToggleButton value={FusionType.Opacity} aria-label={FusionTypeOptions[3].label}>
                                <OpacityIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>

                    <Slider
                        disabled={!imageData_overlay}
                        valueLabelDisplay="auto"
                        value={imageData_overlay ? [imageData_overlay.windowing.min, imageData_overlay.windowing.max] : [0, 1]}
                        min={image_overlay ? image_overlay.range.min : 0}
                        max={image_overlay ? image_overlay.range.max : 1}
                        onChangeCommitted={() => setSliding(false)}
                        onChange={(_e: any, newValue: any) => {
                            setSliding(true);
                            imageData_overlay && viewerDispatcher({
                                type: "IMAGE_WINDOWING",
                                imageId: imageData_overlay.id,
                                windowing: new Range(newValue)
                            });
                        }}
                    />

                    <LutSelect
                        disabled={!imageData_overlay}
                        value={imageData_overlay ? imageData_overlay.lut : "Grayscale"}
                        onChange={(newLut: string) => {
                            imageData_overlay && viewerDispatcher({
                                type: "IMAGE_LUT",
                                imageId: imageData_overlay.id,
                                lut: newLut,
                            });
                        }}
                    />
                </>}
            </>}

        </Popover>
    </>
}
