import React, { useCallback, useMemo } from "react";
import { IField } from "cocoreact";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";

import { Form, FormFieldsBuilder } from "components/Form";
import { ResetUserPasswordCommand } from "domain/public/command/ResetUserPasswordCommand";

const useStyles = makeStyles((theme: Theme) => createStyles({
    submitBtn: {
        marginTop: theme.spacing(2),
    }
}));

class ResetPasswordResponse {
    password!: string;
    passwordConfirmation!: string;

    public static Fields = {
        password: { name: "password", type: "password" },
        passwordConfirmation: { name: "passwordConfirmation", type: "password" },
    } as Record<keyof ResetPasswordResponse, IField>;
}

export interface ResetPasswordFormProps {
    token: string;
    onAfterSubmit?: () => void;
}

export default function ResetPasswordForm({ token, onAfterSubmit }: ResetPasswordFormProps) {
    const classes = useStyles();

    const fields = useMemo(buildField, []);

    const buildMessage = useCallback((data: ResetPasswordResponse) => {
        return new ResetUserPasswordCommand({
            password: data.password,
            passwordConfirmation: data.passwordConfirmation,
            token,
        });
    }, [token]);

    const onSuccess = useCallback(() => {
        onAfterSubmit && onAfterSubmit();
    }, [onAfterSubmit]);

    return (
        <Form<ResetPasswordResponse>
            fields={fields}
            initial={{ password: "", passwordConfirmation: ""}}
            buildMessage={buildMessage}
            onSuccess={onSuccess}
        >
            {() => (
                <>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submitBtn}
                    >
                        change password
                    </Button>
                </>
            )}
        </Form>
    );
}

function buildField() {

    const builder = new FormFieldsBuilder<ResetPasswordResponse>();

    const fields = ResetPasswordResponse.Fields;

    builder
        .initialize(fields)
        .set(fields.password, {
            position: 0,
        })
        .set(fields.passwordConfirmation, {
            label: "confirm password",
            position: 1,
        })
        ;

    return builder.build();
}
