import React from "react";

import {
  AppBar, Toolbar as MuiToolbar,
  Typography, IconButton,
  makeStyles, createStyles, Theme, Box
} from "@material-ui/core";

import { FeedbackIcon, PreviousIcon } from "App/Theme";
import { OnDesktop, OnMobile, Spacer } from "components/Page";
import MenuTabs, { MenuTabsProps } from "./MenuTabs";
import { Link, useHistory } from "react-router-dom";
import AccountButton from "./AccountButton";
import { fullPath } from "tools/Route";

// --------------------------------------------------------

const useToolbarBaseStyles = makeStyles((theme: Theme) => createStyles({
  appBar: {
    zIndex: theme.zIndex.drawer,
    marginLeft: theme.mixins.drawer.width,
    userSelect: "none"
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
      padding: theme.spacing(0, 4),
    }
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
  }
}));

export function ToolbarBase({ children }: { children: any }) {
  const classes = useToolbarBaseStyles();

  return <AppBar position="fixed" className={classes.appBar}>
    <MuiToolbar className={classes.toolbar}>
      {children}
    </MuiToolbar>
  </AppBar>
}

export function ToolbarTitle({ disabledHomeLink, children }: { disabledHomeLink?: boolean, children: any }) {
  const elt = <Typography variant="h5" style={{ marginLeft: 8 }}>{children}</Typography>
  if (!disabledHomeLink) {
    return <Link to={fullPath("Home")}>{elt}</Link>
  }
  return elt;
}

export function ToolbarActions({ children }: { children: any }) {
  const classes = useToolbarBaseStyles();
  return <Box className={classes.actions}>{children}</Box>
}

// --------------------------------------------------------

const useToolbarStyles = makeStyles((theme: Theme) => createStyles({
  primaryActionBtn: {
    marginRight: theme.spacing(2),
  },
}));

export type ToolbarMobileActionType = "menu" | "previous";

export interface ToolbarProps {
  mobileActionType?: ToolbarMobileActionType;
  mobileTitle?: string;
  mobileActions?: React.ReactNode | React.ReactNode[];
  onFeedbackClick: any;
  tab?: MenuTabsProps["tab"] | false;
}

export default function Toolbar({
  mobileActionType, mobileTitle, mobileActions, onFeedbackClick, tab
}: ToolbarProps) {

  const classes = useToolbarStyles();
  const history = useHistory();

  return <ToolbarBase>

    <OnMobile>
      {mobileActionType === "previous" &&
        <IconButton
          color="inherit"
          aria-label="Go back"
          onClick={(e: any) => history.goBack()}
          className={classes.primaryActionBtn}
        >
          <PreviousIcon />
        </IconButton>
      }

      <ToolbarTitle disabledHomeLink>
        {mobileTitle ?? "Dline"}
      </ToolbarTitle>

      <Spacer />

      <ToolbarActions>
        {mobileActions}
        <IconButton color="inherit" onClick={onFeedbackClick}>
          <FeedbackIcon />
        </IconButton>
      </ToolbarActions>
    </OnMobile>

    <OnDesktop>
      <ToolbarTitle>
        Dline
      </ToolbarTitle>

      <Spacer />

      {tab !== false && <ToolbarActions>
        <MenuTabs tab={tab} />
        <AccountButton />
        <IconButton color="inherit" onClick={onFeedbackClick}>
          <FeedbackIcon />
        </IconButton>
      </ToolbarActions>}
    </OnDesktop>

  </ToolbarBase>
}
