import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class GetNewAccessTokenQuery implements IMessage {
    refreshToken!: string;

    public constructor(init?: Partial<GetNewAccessTokenQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/users/access-token`;

    getQueryString = () => undefined;

    getBody = () => undefined;

    needAuthentication = () => false;
}
