import { useCallback, useEffect, useMemo, useState } from "react";
import { GetProfileClinicalCaseViewerQuery } from "domain/public/query/GetProfileClinicalCaseViewerQuery";
import { ClinicalCaseViewerResponse } from "domain/public/response/ClinicalCaseViewerResponse";
import { sendMessage } from "tools/Message";
import { EmptyClinicalCaseViewerResponse } from "./useClinicalCaseViewer";

export default function useProfileClinicalCaseViewer(
  slug: string
): [boolean, ClinicalCaseViewerResponse, Error | null] {
  const query = useMemo(
    () =>
      new GetProfileClinicalCaseViewerQuery({
        slug,
      }),
    [slug]
  );

  const [state, setState] = useState({
    loading: true,
    data: EmptyClinicalCaseViewerResponse(),
    error: undefined as any | undefined,
  });

  const updateData = useCallback(async () => {
    setState((s) => ({ ...s, loading: true, error: undefined }));
    try {
      const data = await sendMessage<ClinicalCaseViewerResponse>(query);
      setState((s) => ({ data, loading: false, error: undefined }));
    } catch (e) {
      setState((s) => ({ ...s, loading: false, error: e as any }));
    }
  }, [query]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return [state.loading, state.data, state.error];
}
