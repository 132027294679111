//Generated code, do not modify

export class Range {
  min: number;
  max: number;

  constructor(init?: Range | number[]) {
    this.min = 0.0;
    this.max = 0.0;

    if (init) {
      if (Array.isArray(init)) {
        this.min = init[0];
        this.max = init[1];
      } else if (init) {
        this.min = init.min;
        this.max = init.max;
      }
    }
  }
}
