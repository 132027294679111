import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class UpdateParticipantContoursCommand implements IMessage {
    id!: Guid;
    contours!: Contour[];

    public constructor(init?: Partial<UpdateParticipantContoursCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/profile/contouring-workshops/${this.id.toString()}/participant-contours`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            contours: this.contours,
        };
    }
    
    needAuthentication = () => true;
}

export class Contour {
    id!: Guid;
    fileId!: Guid | null;
    dice!: number;
}
