import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, createStyles, makeStyles, Paper, SvgIconProps, Theme, Typography } from "@material-ui/core";
import {
    LanguageOutlined as WebAppIcon,
    SchoolOutlined as GuidelineIcon,
    LockOpenOutlined as FreeAccessIcon,
    FingerprintOutlined as AnonymousIcon,
    FlipToBackOutlined as MPRIcon,
    DevicesOutlined as ResponsiveIcon,
    PlayCircleOutlineOutlined as PlayIcon
} from "@material-ui/icons";

import { fullPath } from "tools/Route";
import VideoDialog from "./VideoDialog";

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        position: "relative",
        backgroundColor: theme.palette.background.default,
        '&::before': {
            content: '""',
            display: "block",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            opacity: 0.05,
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/bubble-bg.png)`,
            backgroundBlendMode: "normal",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
        }
    },
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(6, 1),
        margin: 0,
        textAlign: "center",
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            padding: theme.spacing(6, 0),
            margin: theme.spacing(0, "auto"),
            maxWidth: 992,
        },
    },
    cards: {
        flexWrap: "wrap",
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(2),
        margin: theme.spacing(4, 0),
    },
    card: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "flex-start",
        textAlign: "start",
        gap: theme.spacing(2),
        "& h6": {
            lineHeight: 1.2,
        },
        [theme.breakpoints.up("sm")]: {
            maxWidth: 320,
        }
    },
    actions: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(1),
        "& button": {
            width: 200,
        },
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            flexDirection: "row",
            gap: theme.spacing(2),
        }
    }
}));

interface CardProps {
    title: string;
    content: string
    icon: React.ReactElement<SvgIconProps>;
}

function Card({ title, content, icon }: CardProps) {
    const classes = useStyles();

    return <Paper className={classes.card}>
        {icon}
        <Box>
            <Typography component="h6" variant="h6">
                {title}
            </Typography>
            <Typography component="p" variant="body2">
                {content}
            </Typography>
        </Box>
    </Paper>
}

export default function AtlasSection() {
    const classes = useStyles();

    const [videoOpen, setVideoOpen] = useState(false);

    return <>
        <Box className={classes.wrapper}>
            <Box className={classes.container}>
                <Typography component="h4" variant="h4" color="primary">
                    <strong>Discover our ATLAS</strong>
                </Typography>
                <Typography component="h6" variant="h6" color="textSecondary">
                    We provide well-documented clinical cases to help you in your daily practice.
                </Typography>

                <Box className={classes.cards}>
                    <Card
                        icon={<WebAppIcon />}
                        title="Web application"
                        content="Browse our atlas directly in your navigator from everywhere, no installation required."
                    />
                    <Card
                        icon={<GuidelineIcon />}
                        title="Guidelines based"
                        content="Clinical cases contours are based on international consensus guidelines (RTOG, EORTC, ESTRO-ACROP, ...)."
                    />
                    <Card
                        icon={<FreeAccessIcon />}
                        title="Free access"
                        content="All clinical cases, contours and guidelines are free access, no account required."
                    />
                    <Card
                        icon={<AnonymousIcon />}
                        title="Anonymous"
                        content="All patient private data and meta informations are permanently deleted to ensure privacy."
                    />
                    <Card
                        icon={<MPRIcon />}
                        title="Embedded MPR viewer"
                        content="Explore anatomic and contours datas in  axial, coronal and sagittal axes."
                    />
                    <Card
                        icon={<ResponsiveIcon />}
                        title="Responsive design"
                        content="Access to our platfrom from anywhere via any devices (smartphone, digital tablet, desktop)."
                    />
                </Box>

                <Box className={classes.actions}>
                    <Link to={fullPath("ListClinicalCases")}>
                        <Button variant="contained" color="primary">
                            browse atlas
                        </Button>
                    </Link>
                    <Button
                        variant="outlined" color="default"
                        startIcon={<PlayIcon />}
                        onClick={() => setVideoOpen(true)}
                    >
                        view demo
                    </Button>
                </Box>
            </Box>
        </Box>

        <VideoDialog
            video={
                <iframe
                    src="https://player.vimeo.com/video/579840259?autoplay=1"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    title="Dline viewer demo"
                />
            }
            open={videoOpen}
            onClose={() => setVideoOpen(false)}
        />
    </>
}
