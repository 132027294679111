import React from "react";
import { createStyles, IconButton, Theme, Tooltip, withStyles } from "@material-ui/core";

import { MPROneIcon, MPRThreeIcon } from "App/Theme";

const CustomButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            "&:hover": {
                backgroundColor: theme.palette.background.paper,
            },
        }
    })
)(IconButton);

export type MPRMode = "one" | "three";

export interface MPRButtonProps {
    mode: MPRMode;
    onClick: () => void;
}

export default function MPRButton({ mode, onClick }: MPRButtonProps) {
    return <Tooltip title="Swith one/three view" placement="bottom-end" arrow>
            <CustomButton onClick={onClick} color="primary">
                {mode === "one" ? <MPRThreeIcon /> : <MPROneIcon />}
            </CustomButton>
        </Tooltip>
}
