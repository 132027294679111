import React, { useEffect, useRef, useState } from "react";
import {
    createStyles, makeStyles, Theme,
    IconButton, InputAdornment, InputBase, InputBaseProps, CircularProgress,
} from '@material-ui/core';
import { CloseIcon, PreviousIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    input: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    progressWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: theme.spacing(2),
    }
}));

export interface SearchInputProps {
    loading?: boolean;
    onChange: (keywords: string) => void;
    onClose: () => void;
    onKeyDown?: InputBaseProps["onKeyDown"];
    classes?: InputBaseProps["classes"];
}

export default function SearchInput({ loading, onChange, onClose, onKeyDown, classes }: SearchInputProps) {
    const _classes = useStyles();

    const input = useRef<HTMLInputElement>(null);
    const timer = useRef<any>(null);
    const [value, setValue] = useState("");

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        if (value.length === 0) {
            onChange(value);
            return;
        }
        timer.current = setTimeout(() => {
            onChange(value);
        }, 500)
    }, [onChange, value]);

    return (
        <InputBase
            fullWidth
            autoFocus
            ref={input}
            placeholder="search organ, guideline, ..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    onClose();
                    return;
                }
                onKeyDown && onKeyDown(e);
            }}
            className={_classes.input}
            classes={classes}
            startAdornment={
                <InputAdornment position="start">
                    <IconButton
                        color="primary"
                        onClick={onClose}
                    >
                        <PreviousIcon />
                    </IconButton>
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    {loading === true &&
                        <div className={_classes.progressWrapper}>
                            <CircularProgress size={24} />
                        </div>
                    }
                    {value.length > 0 && loading !== true &&
                        <IconButton
                            color="primary"
                            onClick={() => {
                                setValue("");
                                if (input.current) {
                                    input.current.click();
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    }
                </InputAdornment>
            }
        />
    )
}
