import React, { useState } from "react";
import {
    makeStyles, Theme, createStyles,
    Box, IconButton, Typography, Button, CircularProgress,
    Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from "@material-ui/core";
import { FormatColorFillRounded as PaletteIcon } from "@material-ui/icons";
import { DeleteOutline as TrashIcon } from "@material-ui/icons";

import { Guid } from "domain/static/Guid";
import { VolumeType, VolumeTypeOptions } from "domain/static/VolumeType";
import { CloseIcon, OrganizationIcon, PreviousIcon, VolumeTypeIcon } from "App/Theme";
import { ContourInfo, contourInfoLabel } from "tools/StringExtension";
import { ColorPickerPopup, Markdown, Spacer } from "components/Page";
import { ArticleBib } from "components/ClinicalData";
import { ArticleBibProps } from "./ArticleBib";
import { enumValueToString } from "tools/EnumExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    label: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(1, 0),
    },
    label_previousBtn: {
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(1),
    },
    chip: {
        padding: theme.spacing(0, 1),
        "& svg": {
            color: theme.palette.action.disabled
        }
    },
    colorCircle: {
        width: 32,
        height: 32,
        borderRadius: "50%",
    },
    loader_wrapper: {
        textAlign: "center",
        minHeight: 120,
        height: 120,
        marginTop: theme.spacing(6),
        color: theme.palette.text.hint
    },
    content_wrapper: {
        margin: theme.spacing(3, 0),
    },
    dialog_actions: {
        margin: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between"
    }
}));

type ContourType = ContourInfo & {
    id: Guid;
    content?: string;
    organizationName?: string;
};


export function ContourPanelLoader() {
    const classes = useStyles();

    return <Box className={classes.loader_wrapper}>
        <CircularProgress color="inherit" />
    </Box>
}


export interface ContourPanelLabelProps {
    contour: ContourInfo;
    onClose?: () => void;
}

export function ContourPanelLabel({ contour, onClose }: ContourPanelLabelProps) {
    const classes = useStyles();
    return <Typography component="h4" variant="h6" className={classes.label} >
        {onClose &&
            <IconButton onClick={onClose} className={classes.label_previousBtn}>
                <PreviousIcon />
            </IconButton>
        }
        {contourInfoLabel(contour)}
    </Typography >
}


export function ContourPanelInfoChips({ contour }: { contour: ContourType }) {
    const classes = useStyles();
    return <>
        {contour && contour.volumeType !== VolumeType.None && <Chip
            label={enumValueToString(contour.volumeType, VolumeTypeOptions)}
            icon={<VolumeTypeIcon />}
            className={classes.chip}
        />}
        {contour.organizationName && <Chip
            label={contour.organizationName}
            icon={<OrganizationIcon />}
            className={classes.chip}
        />}
    </>
}


export interface ContourPanelColorProps {
    color: string;
    onChange?: (newColor: string) => void;
}

export function ContourPanelColor({ color, onChange }: ContourPanelColorProps) {
    const classes = useStyles();
    const [colorAnchorEl, setColorAnchorEl] = useState<null | HTMLElement>(null);

    if (onChange === undefined) {
        return <Box
            component="span"
            className={classes.colorCircle}
            style={{ backgroundColor: color }}
        />
    }

    return <>
        <IconButton
            style={{ color: color }}
            size="small"
            onClick={e => setColorAnchorEl(e.currentTarget)}
        >
            <PaletteIcon fontSize="large" />
        </IconButton>

        <ColorPickerPopup
            color={color}
            anchorEl={colorAnchorEl}
            onChange={(c) => onChange(c.hex)}
            onClose={() => setColorAnchorEl(null)}
        />
    </>
}


export interface ContourPanelCleanProps {
    contour: ContourType;
    onClean: (id: Guid) => void;
}

export function ContourPanelClean({ contour, onClean }: ContourPanelCleanProps) {
    const [confirmOpen, setConfirmOpen] = useState(false);

    const onCleanHandle = () => {
        onClean(contour.id);
        setConfirmOpen(false);
    }

    return <>
        <IconButton
            size="medium"
            onClick={() => setConfirmOpen(true)}
        >
            <TrashIcon fontSize="medium" />
        </IconButton>

        <CleanConfirmation
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
            title={contourInfoLabel(contour)}
            onAcceptClean={onCleanHandle}
        />
    </>
}

export interface ContourPanelInformationsProps {
    contour: ContourType;
    color: string;
    onColorChanged?: (id: Guid, newColor: string) => void
    onClean?: (id: Guid) => void;
}

export function ContourPanelInformations({
    contour, color, onColorChanged, onClean
}: ContourPanelInformationsProps) {
    return <Box display="flex" gridGap={8} alignItems="center">
        <ContourPanelInfoChips contour={contour} />

        <Spacer />

        <ContourPanelColor
            color={color}
            onChange={onColorChanged
                ? (newColor) => onColorChanged(contour.id, newColor)
                : undefined
            }
        />

        {onClean &&
            <ContourPanelClean contour={contour} onClean={onClean} />
        }
    </Box>
}

export function ContourPanelContent({ value }: { value?: string }) {
    const classes = useStyles();
    return <Markdown className={classes.content_wrapper}>
        {value ?? "*no guideline available for this contour.*"}
    </Markdown>
}


export type ContourPanelProps =
    ContourPanelInformationsProps
    & Partial<Pick<ArticleBibProps, "article">>
    & ContourPanelLabelProps;

export default function ContourPanel({
    contour, article, onClose, ...props
}: ContourPanelProps) {
    return <Box marginX={2} marginTop={1} marginBottom={2}>

        <ContourPanelLabel contour={contour} onClose={onClose} />

        <ContourPanelInformations 
            contour={contour}
            {...props}
        />

        <ContourPanelContent value={contour.content} />

        {article && <ArticleBib article={article} collapsable={false} />}

    </Box>
}

interface CleanConfirmationProps {
    open: boolean;
    onClose: () => void;
    onAcceptClean: () => void;
    title: string;
}

function CleanConfirmation({ open, onClose, onAcceptClean, title }: CleanConfirmationProps) {
    const classes = useStyles();
    return <Dialog open={open}>
        <DialogTitle>
            Confirmation
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are you sure to clean all contour <strong>{title}</strong> ?
            </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialog_actions}>
            <Button
                variant="outlined"
                onClick={onClose}
                startIcon={<CloseIcon />}
            >
                close
            </Button>

            <Button
                color="primary"
                variant="contained"
                onClick={onAcceptClean}
                startIcon={<TrashIcon />}
            >
                yes, clean contour
            </Button>
        </DialogActions>
    </Dialog>
}