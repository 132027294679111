import React from "react";
import {
    makeStyles, Theme, createStyles,
    Box, Typography,
} from "@material-ui/core";

import { ArticleIcon } from "App/Theme";
import { Markdown } from "components/Page";
import { formatLongDate } from "tools/DateExtension";
import ArticleBib, { ArticleBibProps } from "./ArticleBib";
import { Link } from "react-router-dom";
import { fullPath } from "tools/Route";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(1, 2),
    },
    metas: {
        padding: theme.spacing(2, 0),
        marginBottom: theme.spacing(2),
        borderBottom: `1px ${theme.palette.divider} solid`,
        "& > *": {
            marginBottom: theme.spacing(1),
            display: "flex",
            alignItems: "flex-start",
        },
        "& > *:last-child": {
            marginBottom: 0,
        },
        "& svg": {
            color: theme.palette.text.hint,
            marginRight: theme.spacing(0.5),
        }
    },
    publishedAt: {
        margin: theme.spacing(3, 0),
        fontStyle: "italic",
    },
    summary: {
        "& ul": {
            padding: theme.spacing(0, 0, 0, 3),
        }
    },
    articles_title: {
        margin: theme.spacing(3, 0, 2, 0),
        paddingBottom: theme.spacing(1),
        borderBottom: `1px ${theme.palette.divider} solid`,
        color: theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        "& svg": {
            marginRight: theme.spacing(1),
        }
    },
    articles_list: {
        listStyle: "none",
        padding: 0,
        margin: 0,
        "& > *": {
            margin: theme.spacing(2, 0),
        },
    },
    links_wrapper: {
        margin: theme.spacing(4, 0, 2, 0)
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
        }
    },
}));

const LINKS = [
    {
        label: "Contact us",
        href: fullPath("ContactUs")
    },
    {
        label: "About us",
        href: fullPath("AboutUs")
    },
    {
        label: "Terms of service",
        href: fullPath("TermsOfService")
    },
    {
        label: "Privacy policy",
        href: fullPath("PrivacyPolicy")
    },
    {
        label: "Cookies settings",
        href: fullPath("CookiesSettings")
    },
];

export interface SummaryPanelProps {
    summary: string;
    articles: ArticleBibProps["article"][];
    publishedAt?: Date;
    metas?: any;
}

export default function SummaryPanel({
    summary, publishedAt, articles, metas
}: SummaryPanelProps) {
    const classes = useStyles();

    return <Box className={classes.root}>

        {metas && <>
            <Box className={classes.metas}>
                {metas}
            </Box>
        </>}

        <Markdown className={classes.summary}>
            {summary}
        </Markdown>

        {publishedAt &&
            <Typography variant="body2" component="p" className={classes.publishedAt}>
                Published on {formatLongDate(publishedAt)}
            </Typography>
        }

        {articles && articles.length > 0 &&
            <>
                <Typography variant="h6" component="p" className={classes.articles_title}>
                    <ArticleIcon />
                    Article{articles.length > 1 ? "s" : ""} ({articles.length})
                </Typography>

                <ul className={classes.articles_list}>
                    {articles.map(a =>
                        <li key={a.id}><ArticleBib article={a} /></li>
                    )}
                </ul>
            </>
        }

        <Typography variant="subtitle2" className={classes.links_wrapper}>
            © {new Date().getFullYear()} Dline Inc.
            {LINKS.map((x, idx) => <span key={idx}>
                &nbsp;·&nbsp;
                <Link to={x.href} className={classes.link}>{x.label}</Link>
            </span>)}
        </Typography>

    </Box>
}