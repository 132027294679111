import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class ContactCommand implements IMessage {
    firstname!: string;
    lastname!: string;
    email!: string;
    content!: string;

    public constructor(init?: Partial<ContactCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST" as RequestMethod;

    getPath = () => `/contact`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            content: this.content,
        };
    }
    
    needAuthentication = () => false;
}
