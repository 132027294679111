import React from "react";
import clsx from "clsx";
import {
    Box,
    createStyles, Divider, lighten, makeStyles, Theme, Typography
} from "@material-ui/core";

import { ClinicalCaseCardResponse } from "domain/public/response/ClinicalCaseCardResponse";
import { SearchIcon, TimeIcon } from "App/Theme";
import { Link } from "react-router-dom";
import { fullPath } from "tools/Route";

const useStyles = makeStyles((theme: Theme) => createStyles({
    list: {
        listStyle: "none",
        backgroundColor: theme.palette.background.default,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        margin: 0,
    },
    item: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        "&:hover": {
            backgroundColor: theme.palette.grey[100],
        }
    },
    iconWrapper: {
        marginRight: theme.spacing(2),
        height: 32,
        minHeight: 32,
        maxHeight: 32,
        width: 32,
        minWidth: 32,
        maxWidth: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey[700],
    },
    error: {
        backgroundColor: lighten(theme.palette.error.light, 0.8),
        color: theme.palette.error.light
    }
}));

export interface EmptySearchResultProps {
    value: string;
}

export function EmptySearchResult({ value }: EmptySearchResultProps) {
    const classes = useStyles();

    return (
        <li className={classes.item}>
            <Box className={clsx(classes.iconWrapper, classes.error)}>
                <SearchIcon fontSize="small" />
            </Box>
            <Typography variant="body2">{value}</Typography>
        </li>
    )
}

export interface SearchResultProps {
    value: ClinicalCaseCardResponse;
    type: "search" | "history";
    divider?: boolean;
}

export function SearchResult({ value, type, divider }: SearchResultProps) {
    const classes = useStyles();

    const icon = type === "search"
        ? <SearchIcon fontSize="small" />
        : <TimeIcon fontSize="small" />;

    const href = fullPath("ShowClinicalCase", {
        params: {
            slug: value.slug
        }
    });
    
    return (
        <li>
            <Link to={href} className={classes.item}>
                <Box className={classes.iconWrapper}>
                    {icon}
                </Box>
                <Typography variant="body1">{value.name}</Typography>
            </Link>

            {divider === true &&
                <Divider style={{ marginLeft: 64, marginRight: 16 }} />
            }
        </li>
    )
}

export interface SearchResultsProps {
    loading: boolean;
    keywords: string;
    clinicalCases: ClinicalCaseCardResponse[];
}

export default function SearchResults({ loading, keywords, clinicalCases }: SearchResultsProps) {
    const classes = useStyles();

    return (
        <Box className={classes.list} component="ul">
            {clinicalCases.length === 0 && loading === false && keywords.length > 0 &&
                <EmptySearchResult value="no atlas found" />
            }

            {clinicalCases.map((x, idx) =>
                <SearchResult
                    key={x.id}
                    value={x}
                    type="search"
                    divider={idx !== clinicalCases.length - 1}
                />
            )}
        </Box>
    )
}
