//Generated code, do not modify

export enum ContouringWorkshopUserRole {
  None = 0,
  Participant = 1,
  Referrer = 2,
  Organizer = 3,
}

export const ContouringWorkshopUserRoleOptions = [
  {
    label: "None",
    value: ContouringWorkshopUserRole.None,
  },
  {
    label: "Participant",
    value: ContouringWorkshopUserRole.Participant,
  },
  {
    label: "Referrer",
    value: ContouringWorkshopUserRole.Referrer,
  },
  {
    label: "Organizer",
    value: ContouringWorkshopUserRole.Organizer,
  },
];
