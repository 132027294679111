import React from "react";

import { ContouringWorkshopState } from "domain/static/ContouringWorkshopState";
import { ContouringWorkshopViewerResponse } from "domain/public/response/ContouringWorkshopViewerResponse";
import FutureAdvertisement from "./FutureAdvertisement";
import EditViewerContainer from "./Edit/ViewerContainer";
import ResultViewerContainer from "./Result/ViewerContainer";

export interface ViewerContainerProps {
    entity: ContouringWorkshopViewerResponse;
}

export default function ViewerContainer({ entity }: ViewerContainerProps) {
    return <>
        {entity.state === ContouringWorkshopState.Future &&
            <FutureAdvertisement name={entity.name} startAt={entity.startAt} />
        }

        {entity.state === ContouringWorkshopState.Past &&
            <ResultViewerContainer entity={entity} />
        }

        {entity.state === ContouringWorkshopState.Present &&
            <EditViewerContainer entity={entity} />
        }
    </>
}
