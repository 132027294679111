import { useCallback, useEffect, useState } from "react";
import { Guid, IsValidGuid } from "domain/static/Guid";
import { ProfileContouringWorkshopItemResponse } from "domain/public/response/ProfileContouringWorkshopItemResponse";
import { ListProfileContouringWorkshopsQuery } from "domain/public/query/ListProfileContouringWorkshopsQuery";
import { sendMessage } from "tools/Message";

export default function useListProfileContouringWorkshops(
  userId: Guid
): [boolean, ProfileContouringWorkshopItemResponse[]] {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<ProfileContouringWorkshopItemResponse[]>(
    []
  );

  const _updateItems = useCallback(async () => {
    setLoading(true);
    let _items = [] as ProfileContouringWorkshopItemResponse[];
    if (IsValidGuid(userId)) {
      const query = new ListProfileContouringWorkshopsQuery();
      _items = await sendMessage<ProfileContouringWorkshopItemResponse[]>(
        query
      );
    }
    setItems(_items);
    setLoading(false);
  }, [userId]);

  useEffect(() => {
    _updateItems();
  }, [_updateItems]);

  return [loading, items];
}
