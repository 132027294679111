import React, { useState } from "react";

import { makeStyles, Theme, createStyles, Typography, IconButton, Box } from "@material-ui/core";

import Toolbar from "./Toolbar";
import { EmptyGuid } from "domain/static/Guid";
import useFilterClinicalCases from "domain/hooks/useFilterClinicalCases";
import { ErrorBoundary, LoadingWrapper } from "cocoreact";
import { SearchIcon } from "App/Theme";
import SearchMobileDialog from "./SearchMobileDialog";
import useSearchClinicalCases from "domain/hooks/useSearchClinicalCases";
import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { OnMobile, Footer } from "components/Page";
import { Card } from "components/ClinicalCase";
import { CardGrid } from "components/ClinicalData";
import { useWindowEffectScrollTop } from "tools/useWindowEffectScrollTop";
import { fullPath } from "tools/Route";
import { FatalErrorDialog } from "pages/Errors";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        position: "relative",
    },
    results: {
        marginTop: theme.spacing(10),
        padding: theme.spacing(0, 2),
        [theme.breakpoints.up("lg")]: {
            padding: 0,
        }
    },
    results_header: {
        marginBottom: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
}));

export default function ListClinicalCasesPage() {
    const classes = useStyles();

    useWindowEffectScrollTop();

    const [anatomicZoneId, setAnatomicZoneId] = useState(EmptyGuid());
    const [filterLoading, filterResults] = useFilterClinicalCases(anatomicZoneId);

    const [searchKeywords, setSearchKeywords] = useState("");
    const [searchLoading, searchResults] = useSearchClinicalCases(searchKeywords);

    const [mobileSearchEnabled, setMobileSearchEnabled] = useState(false);

    return (
        <PageLayout
            tab="atlas"
            mobileActions={
                <IconButton color="inherit" onClick={() => setMobileSearchEnabled(true)}>
                    <SearchIcon />
                </IconButton>
            }
        >
            <OnMobile>
                <SearchMobileDialog
                    open={mobileSearchEnabled}
                    loading={searchLoading}
                    results={searchResults}
                    onChange={setSearchKeywords}
                    onClose={() => setMobileSearchEnabled(false)}
                />
            </OnMobile>

            <CentralColumnLayout size="xl" className={classes.container}>

                <Toolbar
                    onAnatomicZoneChange={setAnatomicZoneId}
                    searchLoading={searchLoading}
                    searchResults={searchResults}
                    onSearchChange={setSearchKeywords}
                />

                <ErrorBoundary component={FatalErrorDialog}>
                    <LoadingWrapper loading={filterLoading}>
                        <Box className={classes.results}>

                            <Box className={classes.results_header}>
                                <Typography component="p" variant="h6">
                                    {filterResults.length} result{filterResults.length > 1 ? "s" : ""}
                                </Typography>
                            </Box>

                            {filterResults.length > 0 &&
                                <CardGrid>
                                    {filterResults.map(x => <Card
                                        key={x.id}
                                        href={fullPath("ShowClinicalCase", { params: { slug: x.slug } })}
                                        value={{ ...x, date: x.publishedAt }}
                                    />)}
                                </CardGrid>
                            }

                            {filterResults.length === 0 &&
                                <Typography variant="caption">
                                    No result found
                                </Typography>
                            }
                        </Box>
                    </LoadingWrapper>
                </ErrorBoundary>

            </CentralColumnLayout>

            <Footer />
        </PageLayout >
    );
}

