import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class ResetUserPasswordCommand implements IMessage {
    token!: string;
    password!: string;
    passwordConfirmation!: string;

    public constructor(init?: Partial<ResetUserPasswordCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/users/password`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            token: this.token,
            password: this.password,
            passwordConfirmation: this.passwordConfirmation,
        };
    }
    
    needAuthentication = () => false;
}
