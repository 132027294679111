import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",        
    },
});

export default function Spacer() {
    const classes = useStyles();
    return <div className={classes.root}></div>
}