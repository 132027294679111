import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class GetProfileContouringWorkshopViewerQuery implements IMessage {
    slug!: string;

    public constructor(init?: Partial<GetProfileContouringWorkshopViewerQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/profile/contouring-workshops/${this.slug.toString()}`;

    getQueryString = () => undefined;

    getBody = () => undefined;

    needAuthentication = () => true;
}
