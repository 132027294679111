import React from "react";
import clsx from "clsx";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import { RedoIcon, UndoIcon } from "App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    btn: {
        height: theme.spacing(6),
        padding: theme.spacing(1, 3),
        borderRadius: theme.shape.borderRadius * 8,
        color: theme.palette.getContrastText(theme.palette.grey[900]),
        backgroundColor: theme.palette.grey[900],
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.body1.fontWeight,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        boxShadow: theme.shadows[4],
        "&:hover": {
            backgroundColor: theme.palette.grey[800],
        },
    },
}));

export interface UndoRedoButtonProps {
    onClick: () => void;
    className?: string;
}

export function UndoButton({ onClick, className }: UndoRedoButtonProps) {
    const classes = useStyles();

    return <Button
        className={clsx(classes.btn, className)}
        onClick={onClick}
        startIcon={<UndoIcon />}
    >
        undo
    </Button>
}

export function RedoButton({ onClick, className }: UndoRedoButtonProps) {
    const classes = useStyles();

    return <Button
        className={clsx(classes.btn, className)}
        onClick={onClick}
        endIcon={<RedoIcon />}
    >
        redo
    </Button>
}