import React, { useState } from "react";
import {
    makeStyles, Theme, createStyles,
    Paper, Slide
} from "@material-ui/core";

import { Guid } from "domain/static/Guid";
import { ContouringWorkshopViewerResponse } from "domain/public/response/ContouringWorkshopViewerResponse";
import ContourListPanel from "./ContourListPanel";
import { HeaderCover, Tabs, InformationTab, TabContent } from "components/ClinicalData";
import { SummaryPanel } from "components/ContouringWorkshop";
import ContourPanel from "./ContourPanel";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: theme.mixins.viewer.smallWidth,
        minWidth: theme.mixins.viewer.smallWidth,
        maxWidth: theme.mixins.viewer.smallWidth,
        zIndex: theme.zIndex.drawer,
    },
}));

export interface DrawerProps {
    open: boolean;
    contouringWorkshop: ContouringWorkshopViewerResponse;
}

export default function Drawer({ open, contouringWorkshop }: DrawerProps) {
    const classes = useStyles();

    const [tab, setTab] = useState<InformationTab>("summary");
    const [selectContour, setSelectContour] = useState<Guid | null>(null);

    return <>
        <Slide in={open} direction="left">
            <Paper className={classes.root} elevation={12} square={true}>

                <HeaderCover
                    title={contouringWorkshop.name}
                    coverId={contouringWorkshop.coverId}
                    size="small"
                />

                <Tabs
                    value={tab}
                    onChange={setTab}
                    textColor="primary"
                    indicatorColor="primary"
                />

                <TabContent hidden={tab !== "summary"}>
                    <SummaryPanel contouringWorkshop={contouringWorkshop} />
                </TabContent>

                <TabContent hidden={tab !== "contours" || selectContour !== null}>
                    <ContourListPanel
                        contours={contouringWorkshop.contours}
                        onClick={setSelectContour}
                    />
                </TabContent>

                <TabContent hidden={tab !== "contours" || selectContour === null}>
                    <ContourPanel
                        contourId={selectContour as Guid}
                        onClose={() => setSelectContour(null)}
                        contouringWorkshop={contouringWorkshop}
                    />
                </TabContent>
            </Paper>
        </Slide >
    </>
}
