import React from "react";
import { Helmet } from "react-helmet-async";
import { Box, Button, createStyles, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { ErrorComponentProps } from "cocoreact";

import { CentralColumnLayout, PageLayout } from "components/Layouts";
import { useWindowEffectScrollTop } from "tools/useWindowEffectScrollTop";
import { fullPath } from "tools/Route";

// settings file
declare var APP_CONTACT_EMAIL: string;

const useStyles = makeStyles((theme: Theme) => createStyles({
    layout: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(-5),
        marginBottom: 0,
        maxWidth: 780,
        "& strong": {
            color: theme.palette.primary.dark,
        }
    },
    row404: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        "& #img": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 80,
            height: 80,
            borderRadius: "50%",
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: 6,
        }
    }
}));

export default function PageNotFoundPage(_props: ErrorComponentProps) {
    useWindowEffectScrollTop();

    const classes = useStyles();
    const contactEmail = APP_CONTACT_EMAIL;

    return (
        <PageLayout tab={false}>
            <Helmet>
                <title>404 - Page not found - Dline</title>
            </Helmet>

            <CentralColumnLayout className={classes.layout}>

                <Typography variant="h1">Ooops ... :(</Typography>

                <Box className={classes.row404}>
                    <Typography variant="h1" component="span">4</Typography>
                    <Box id="img">
                        <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="logo" height={60} />
                    </Box>
                    <Typography variant="h1" component="span" style={{ transform: "scale(-1, 1)" }}>4</Typography>
                </Box>

                <Box>
                    <Typography variant="h5">
                        We're sorry, the page you requested could not be found. Please go back to the homepage or contact us at <strong>{contactEmail}</strong>.
                    </Typography>

                    <p></p>

                    <Link href={fullPath("Home")}>
                        <Button
                            color="primary"
                            variant="contained"
                        >
                            go to homepage
                        </Button>
                    </Link>
                </Box>

            </CentralColumnLayout>
        </PageLayout >
    );
}