import React, { useCallback, useMemo } from "react";
import { MenuItem } from "@material-ui/core";

import { EmptyGuid, Guid, IsEmptyGuid } from "domain/static/Guid";
import { useViewerContext } from "contexts/Viewer";
import Select from "./Select";

interface Image {
    id: Guid;
    name: string;
}

export interface ImageSelectProps {
    images: Image[];
}

export default function ImagePrimarySelect({ images }: ImageSelectProps) {
    const { data: viewerData, dispatcher: viewerDispatcher } = useViewerContext();

    const value = useMemo(() => {
        const img = viewerData.getImagePrimary();
        return img ? img.id : EmptyGuid();
    }, [viewerData]);

    const options = useMemo(() => {
        const imgOptions = viewerData.images;
        const results = imgOptions.map(x => ({
            value: x.id,
            label: images.find(img => img.id === x.id)?.name,
        }))
        return results;
    }, [viewerData, images]);

    const onChangeHandle = useCallback((id: Guid) => {
        viewerDispatcher({
            type: "IMAGE_PRIMARY",
            imageId: id,
        })
    }, [viewerDispatcher]);

    if (!options || options.length === 0 || IsEmptyGuid(value)) {
        return null;
    }

    return (
        <Select
            label="Primary"
            value={value}
            onChange={onChangeHandle}
        >
            {options.map(x =>
                <MenuItem value={x.value} key={x.value}>{x.label}</MenuItem>
            )}
        </Select>
    );
}
