import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import InfoIcon from "@material-ui/icons/InfoOutlined";
import { fullPath } from "tools/Route";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(6, 1),
        margin: 0,
        gap: theme.spacing(1),
        textAlign: "center",
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            padding: theme.spacing(6, 0),
            margin: theme.spacing(0, "auto"),
            maxWidth: 992,
        },
    },
}));

export default function MissionSection() {
    const classes = useStyles();

    return <Box className={classes.container}>
        <Typography component="h4" variant="h4">
            <strong>Our MISSION</strong>
        </Typography>
        <Typography component="h6" variant="h6" color="textSecondary">
            Enhancing the safety and quality of radiation therapy.
        </Typography>
        <Link to={fullPath("AboutUs")}>
            <Button
                variant="outlined" color="default"
                startIcon={<InfoIcon />}
            >
                about us
            </Button>
        </Link>
    </Box>
}
