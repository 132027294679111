import React from "react";

import { 
    createStyles, makeStyles,  Theme, 
    Tab, Tabs, Box, CircularProgress 
} from "@material-ui/core";
import { EmptyGuid, Guid } from "domain/static/Guid";
import { IdNameResponse } from "domain/public/response/IdNameResponse";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        overflowX: "auto",
    },
    tabs: {
    },
    indicator: {
        backgroundColor: theme.palette.primary.main,
    },
    tab: {
        height: 56,
        width: "auto",
        minWidth: "auto",
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    selected: {
        color: theme.palette.primary.main,
    },
    progressWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: theme.spacing(2),
    }
}));


export interface AnatomicZoneTabsProps {
    value: Guid;
    onChange: (id: Guid) => void;
    anatomicZones: IdNameResponse[];
    loading?: boolean;
}

const allGuid = EmptyGuid();

export default function AnatomicZoneTabs({ value, onChange, anatomicZones, loading }: AnatomicZoneTabsProps) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Tabs
                value={value}
                variant="scrollable"
                aria-label="anatomic zone toolbar"
                classes={{
                    root: classes.tabs,
                    indicator: classes.indicator,
                }}
            >
                <Tab
                    label="all"
                    value={allGuid}
                    onClick={() => onChange(allGuid)}
                    classes={{
                        root: classes.tab,
                        selected: classes.selected,
                    }}
                />

                {loading !== true && anatomicZones.map((az, idx) =>
                    <Tab
                        key={idx}
                        label={az.name}
                        value={az.id}
                        onClick={() => onChange(az.id)}
                        classes={{
                            root: classes.tab,
                            selected: classes.selected,
                        }}
                    />
                )}

            </Tabs>

            {loading === true &&
                <Box className={classes.progressWrapper}>
                    <CircularProgress size={24} />
                </Box>
            }
        </Box>
    )
}
