import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class SearchClinicalCaseCardsQuery implements IMessage {
    top!: number;
    skip!: number;
    anatomicZoneId!: Guid | null;
    keywords!: string;

    public constructor(init?: Partial<SearchClinicalCaseCardsQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/clinical-cases`;

    getQueryString = () => {
        return {
            top: this.top,
            skip: this.skip,
            anatomicZoneId: this.anatomicZoneId,
            keywords: this.keywords,
        }
    }
    

    getBody = () => undefined;

    needAuthentication = () => false;
}
