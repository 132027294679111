import React from "react";

import { ActionsType } from "./reducer";
import ViewerData from "./ViewerData";
import AppViewerHelper from "tools/Viewer";

export type DispatchTarget = "data" | "viewer" | "all" | "none";

export interface IViewerContext {
    app: AppViewerHelper;
    data: ViewerData;
    dispatcher: (action: ActionsType, dispathcOn?: DispatchTarget) => Promise<void>;
}

const ViewerContext = React.createContext<IViewerContext>({
    app: new AppViewerHelper(),
    data: new ViewerData(),
    dispatcher: async () => console.error("not implemented method"),
});

export default ViewerContext;
