import React from "react";

import { ContouringWorkshopState } from "domain/static/ContouringWorkshopState";
import { ContouringWorkshopViewerResponse } from "domain/public/response/ContouringWorkshopViewerResponse";
import EditViewerContainer from "./Edit/ViewerContainer";
import ResultsViewerContainer from "./Results/ViewerContainer";

export interface ViewerContainerProps {
    entity: ContouringWorkshopViewerResponse;
}

export default function ViewerContainer({ entity }: ViewerContainerProps) {
    return <>
        {entity.state === ContouringWorkshopState.Future &&
            <EditViewerContainer entity={entity} />
        }

        {(entity.state === ContouringWorkshopState.Past || entity.state === ContouringWorkshopState.Present) &&
            <ResultsViewerContainer entity={entity} />
        }
    </>
}
