import React, { useCallback, useMemo } from "react";
import { Box, MenuItem } from "@material-ui/core";

import { EmptyGuid, Guid } from "domain/static/Guid";
import { useViewerContext } from "contexts/Viewer";
import { ImageSelectProps } from "./ImagePrimarySelect";
import Select from "./Select";

export default function ImageOverlaySelect({ images }: ImageSelectProps) {
    const { data: viewerData, dispatcher: viewerDispatcher } = useViewerContext();

    const value = useMemo(() => {
        const img = viewerData.getImageOverlay();
        return img ? img.id : EmptyGuid();
    }, [viewerData]);

    const options = useMemo(() => {
        const imgyOptions = viewerData.getImageOverlayOptions();
        const results = imgyOptions.map(x => ({
            value: x.id,
            label: images.find(img => img.id === x.id)?.name,
        }))
        return results;
    }, [viewerData, images]);

    const onChangeHandle = useCallback((id: Guid) => {
        viewerDispatcher({
            type: "IMAGE_OVERLAY",
            imageId: id,
        })
    }, [viewerDispatcher]);

    if (!options || options.length === 0) {
        return null;
    }

    return (
        <Select
            label="Overlay"
            value={value}
            onChange={onChangeHandle}
            tooltip={
                <Box component="span">
                    Use <em>Ctrl + Left click</em> to control the fusion point.
                </Box>
            }
        >
            <MenuItem value={EmptyGuid()}><em>Select overlay</em></MenuItem>
            {options.map(x =>
                <MenuItem value={x.value} key={x.value}>{x.label}</MenuItem>
            )}
        </Select>
    );
}
