import React, { useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles, Select, MenuItem } from "@material-ui/core";

import { LUT, LUTColorMaps } from "dline-viewer/dist/data";
import { LUTColorComputer } from "dline-viewer/dist/processing";

export const LUT_NAMES = LUTColorMaps.getPresetNames();

const useStyles = makeStyles((theme: Theme) => createStyles({
    select: {
        "& input": {
            display: "none",
        }
    },
    selectRoot: {
        padding: theme.spacing(1, 3, 0.5, 1),
    },
    canvas: {
        width: "100%",
        height: theme.spacing(3),
    }
}));

function LutWidget({ name }: { name: string }) {
    const classes = useStyles();
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (canvasRef.current) {
            const lut = new LUT();
            lut.setPreset(name);
            LUTColorComputer.fillCanvasElement(lut, canvasRef.current);
        }
    }, [name]);

    return (
        <canvas className={classes.canvas} ref={canvasRef}></canvas>
    );
}

export interface LutSelectProps {
    disabled?: boolean;
    value: string;
    onChange: (newLut: string) => void;
}

export default function LutSelect({ disabled, value, onChange }: LutSelectProps) {
    const classes = useStyles();

    return <Select
        fullWidth
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value as string)}
        variant="outlined"
        className={classes.select}
        classes={{ root: classes.selectRoot }}
    >
        {LUT_NAMES.map((lutName, idx) => (
            <MenuItem key={idx} value={lutName}>
                <LutWidget name={lutName} />
            </MenuItem>
        ))}
    </Select>
}
