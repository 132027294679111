import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class UpdateProfileInformationsCommand implements IMessage {
    firstname!: string;
    lastname!: string;
    status!: string;
    abstract!: string;
    professionalLink!: string;
    scientificLink!: string;

    public constructor(init?: Partial<UpdateProfileInformationsCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT" as RequestMethod;

    getPath = () => `/profile/informations`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            firstname: this.firstname,
            lastname: this.lastname,
            status: this.status,
            abstract: this.abstract,
            professionalLink: this.professionalLink,
            scientificLink: this.scientificLink,
        };
    }
    
    needAuthentication = () => true;
}
