import React, { useCallback, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom';
import {
    createStyles, makeStyles, Theme, Button, Paper, Box,
    Typography as MuiTypography
} from '@material-ui/core';
import { Lock as PrivacyIcon } from '@material-ui/icons';
import { useCookies } from "react-cookie";
import { addYears } from 'date-fns';

import { fullPath } from 'tools/Route';
import { Typography } from 'components/Page';

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        zIndex: theme.zIndex.modal,
        pointerEvents: "none",
    },
    paper: {
        pointerEvents: "auto",
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1, 3),
        borderRadius: theme.shape.borderRadius,
        maxWidth: theme.breakpoints.values.lg,
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
        }
    },
    content: {
        padding: theme.spacing(1),
        "& a": {
            textDecoration: "underline",
            fontWeight: "bold",
        }
    },
    actions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& > *": {
            marginLeft: theme.spacing(1),
        },
        "& > *:first-child": {
            marginLeft: 0,
        },
        "& button": {
            width: 120,
        }
    }
}));

export function useCookiesConsent(): [boolean | undefined, (flag: boolean) => void] {
    const [cookies, setCookies, removeCookie] = useCookies(["consent", "_ga", "_gid", "_gat"]);

    const consent = useMemo(() => {
        if (cookies.consent) {
            return JSON.parse(cookies.consent) as boolean;
        }
        return undefined;
    }, [cookies.consent]);

    const setConsent = useCallback((flag: boolean) => {
        setCookies("consent", flag, {
            expires: addYears(new Date(), 1),
        });
        if (flag === false) {
            removeCookie("_ga");
            removeCookie("_gid");
            removeCookie("_gat");
        }

    }, [removeCookie, setCookies]);

    return [consent, setConsent];
}

export default function CookiesSettings() {
    const classes = useStyles();
    const location = useLocation();
    const [consent, setConsent] = useCookiesConsent();

    if (consent !== undefined) {
        return null;
    }

    if (location.pathname === fullPath("CookiesSettings")) {
        return null;
    }

    return <Box className={classes.container}>
        <Paper className={classes.paper} elevation={8}>
            <Box className={classes.content}>
                <Typography startIcon={<PrivacyIcon />} variant="h6" color="textPrimary">
                    Your privacy
                </Typography>
                <MuiTypography variant="body2" color="textSecondary">
                    We use cookies and other similar technologies to count visits and
                    traffic sources so we can measure and improve the performance of our site.
                    If you do not allow these cookies, information from these
                    cookies will not be used to help site performance.
                    <br />
                    By Accept or activating an option in Cookie preferences, you have defined
                    the conditions set out in
                    our <Link to={fullPath("PrivacyPolicy")}>Privacy Policy</Link>.
                    To change your preferences or withdraw your consent, you must update
                    your <Link to={fullPath("CookiesSettings")}>cookie preferences</Link>.
                </MuiTypography>
            </Box>
            <Box className={classes.actions}>
                <Button variant="outlined" onClick={() => setConsent(false)}>
                    reject
                </Button>
                <Button variant="contained" color="primary" onClick={() => setConsent(true)}>
                    accept
                </Button>
            </Box>
        </Paper>
    </Box >
}