import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class GetClinicalCaseViewerQuery implements IMessage {
    slug!: string;

    public constructor(init?: Partial<GetClinicalCaseViewerQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/clinical-cases/${this.slug.toString()}`;

    getQueryString = () => undefined;

    getBody = () => undefined;

    needAuthentication = () => false;
}
