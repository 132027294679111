import React, { useCallback, useState } from "react";
import {
    makeStyles, Theme, createStyles,
    Paper, Slide
} from "@material-ui/core";

import ContourListPanel from "./ContourListPanel";
import ContourPanel from "./ContourPanel";
import { Guid } from "domain/static/Guid";
import { ClinicalCaseViewerResponse } from "domain/public/response/ClinicalCaseViewerResponse";
import { SummaryPanel } from "components/ClinicalCase";
import { useViewerContext } from "contexts/Viewer";
import { HeaderCover, InformationTab, TabContent, Tabs } from "components/ClinicalData";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: theme.mixins.viewer.smallWidth,
        minWidth: theme.mixins.viewer.smallWidth,
        maxWidth: theme.mixins.viewer.smallWidth,
        zIndex: theme.zIndex.drawer,
    },
}));

export interface DrawerProps {
    open: boolean;
    clinicalCase: ClinicalCaseViewerResponse;
    children?: React.ReactNode | React.ReactNode[];
}

export default function Drawer({
    open, clinicalCase, children
}: DrawerProps) {
    const classes = useStyles();

    const [tab, setTab] = useState<InformationTab>("summary");
    const [contourId, setContourId] = useState<Guid | null>(null);

    const { dispatcher: viewerDispatcher, app: viewerApp } = useViewerContext();

    const setContourHighlight = useCallback(async (id: Guid, enabled: boolean) => {
        if (enabled) {
            viewerDispatcher({
                type: "CONTOURS_VISIBILITY",
                contourIds: [id],
                visibled: true,
            });
        }
        const contourHelper = viewerApp.getContourById(id);
        if (contourHelper) {
            await contourHelper.setHighlight(enabled);
        }
    }, [viewerApp, viewerDispatcher]);

    const onSelectHandle = useCallback(async (id: Guid) => {
        setContourId(id);
        setContourHighlight(id, true);
    }, [setContourHighlight]);

    const onUnselectHandle = useCallback(() => {
        const id = contourId;
        setContourId(null);
        if (id) {
            setContourHighlight(id, false);
        }
    }, [contourId, setContourHighlight]);

    return <Slide in={open} direction="left">
        <Paper className={classes.root} elevation={12} square={true}>

            <HeaderCover title={clinicalCase.name} coverId={clinicalCase.coverId} />

            <Tabs
                value={tab}
                onChange={setTab}
                textColor="primary"
                indicatorColor="primary"
            />

            <TabContent hidden={tab !== "summary"}>
                <SummaryPanel clinicalCase={clinicalCase} />
            </TabContent>

            <TabContent hidden={tab !== "contours" || contourId !== null}>
                <ContourListPanel
                    contours={clinicalCase.contours}
                    onClick={onSelectHandle}
                />
            </TabContent>

            <TabContent hidden={tab !== "contours" || contourId === null}>
                <ContourPanel
                    contourId={contourId as Guid}
                    onClose={onUnselectHandle}
                    clinicalCase={clinicalCase}
                />
            </TabContent>

            {children}

        </Paper>
    </Slide>
}