import {
  format as _format,
  formatDistance as _formatDistance,
  isPast as _isPast,
  isFuture as _isFuture,
  endOfYear as _endOfYear,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";

export function toUtcDate(date: Date): Date {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
}

export function timeToDate(time: string, date?: Date): Date {
  const parts = time.split(":").map((x) => parseInt(x, 10));

  let _date = date ?? new Date();
  _date = setHours(_date, parts[0]);
  _date = setMinutes(_date, parts[1]);
  _date = setSeconds(_date, 0);

  return _date;
}

export function format(date: Date, format: string): string {
  return _format(date, format);
}

export function formatLongDate(date: Date): string {
  return _format(date, "MMMM d, yyyy");
}

export function formatLongDateTime(date: Date): string {
  return _format(date, "MMMM d, yyyy 'at' HH:mm");
}

export function formatDistance(date: Date): string {
  return _formatDistance(new Date(), date);
}

export function isPast(date: Date): boolean {
  return _isPast(date);
}

export function isFuture(date: Date): boolean {
  return _isFuture(date);
}

export function endOfYear(date?: Date): Date {
  return date === undefined ? _endOfYear(new Date()) : _endOfYear(date);
}
