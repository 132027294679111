import React from 'react'
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(6, 1),
        margin: 0,
        gap: theme.spacing(1),
        textAlign: "center",
        [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
            padding: theme.spacing(6, 0),
            margin: theme.spacing(0, "auto"),
            maxWidth: 992,
        },
    },
    cover: {
        height: 120,
        width: "100%",
        textAlign: "center",
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/workshop-device3.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.up("sm")]: {
            height: 200,
        }
    }
}));

export default function ServiceSection() {
    const classes = useStyles();

    return <Box className={classes.container}>
        <Typography component="h4" variant="h4">
            <strong>Online easy-to-use Service</strong>
        </Typography>

        <Box className={classes.cover} />

        <Typography component="h6" variant="h6" color="textSecondary">
            Customize your workshop, follow the progress and analyze results.
        </Typography>
    </Box>
}
