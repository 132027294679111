import React from "react";
import { alpha, Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import { numberFormat } from "tools/StringExtension";
import { Coordinate } from "domain/static/Coordinate";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        color: alpha(theme.palette.common.white, 0.6),
        padding: theme.spacing(1),
        userSelect: "none",
    },
    break: {
        flexBasis: "100%",
        height: 0,
    }
}));

type ImageInfo = {
    spacing: Coordinate;
    size: Coordinate;
}

export interface ImageInformationsProps {
    image: ImageInfo
}

export default function ImageInformations({ image }: ImageInformationsProps) {
    const classes = useStyles();
    return <>
        <Box className={classes.break} />
        <Box className={classes.root}>
            <Typography variant="caption">
                spacing : {numberFormat(image.spacing.x)} x {numberFormat(image.spacing.y)} x {numberFormat(image.spacing.z)} mm
            </Typography>
            <Typography variant="caption">
                size : {image.size.x} x {image.size.y} x {image.size.z}
            </Typography>
        </Box>
    </>
}
