import React from "react";
import { Collapse, List } from "@material-ui/core";

export interface ItemComponentProps<ContourType> {
    contour: ContourType;
    onClick?: (id: string) => void;
}

export interface ContourListProps<ContourType> {
    contours: ContourType[];
    onClick?: (id: string) => void;
    ItemComponent: React.FunctionComponent<ItemComponentProps<ContourType>>;
    collapsed?: boolean;
}

export default function ContourList<Contour extends { id: string; }>({
    contours, ItemComponent, onClick, collapsed
}: ContourListProps<Contour>) {
    return <Collapse in={collapsed === undefined ? true : collapsed}>
        <List style={{ padding: 0 }}>
            {contours.map(c =>
                <ItemComponent
                    key={c.id}
                    contour={c}
                    onClick={onClick}
                />
            )}
        </List>
    </Collapse>
}
