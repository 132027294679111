import React from "react";
import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { EmptyGuid, Guid } from "domain/static/Guid";
import useFileQueryPath from "domain/hooks/useFileQueryPath";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.grey[300],
        position: "relative",
        display: "flex",
        flexDirection: "column-reverse",
        padding: theme.spacing(1, 2),
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: theme.zIndex.drawer,
    },
    root_medium: {
        height: 160,
        minHeight: 160,
        maxHeight: 160,
    },
    root_small: {
    },
    shadow: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: `linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(255, 255, 255, 0.7))`,
        zIndex: -1,
    },
    title: {
        textShadow: "1px 1px 2px " + theme.palette.action.focus,
    },
}));

export interface HeaderCoverProps {
    title: string;
    coverId: Guid | null;
    size?: "small" | "medium";
}

export default function HeaderCover({ title, coverId, size: _size }: HeaderCoverProps) {
    const classes = useStyles();

    const size = _size ?? "medium";
    const sizeClassName = size === "small" ? classes.root_small : classes.root_medium;

    const coverPath = useFileQueryPath(coverId ?? EmptyGuid());
    const coverUrl = coverPath ?? `${process.env.PUBLIC_URL}/cover_default.png`;

    return <Box
        className={clsx(classes.root, sizeClassName)}
        style={{ backgroundImage: `url(${coverUrl})` }}
    >
        <Box className={classes.shadow} />

        <Typography variant="h6" component="h1" className={classes.title}>
            {title}
        </Typography>
    </Box>
}
