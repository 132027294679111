import Hammer from "hammerjs";
import { App } from "dline-viewer/dist/app";
import { SceneAxisAndGridEntity } from "dline-viewer/dist/app/Entity/Scene/SceneAxisAndGridEntity";
import { SceneCameraEntity } from "dline-viewer/dist/app/Entity/Scene/SceneCameraEntity";
import { SceneMouseStateCameraDomSizeEntity } from "dline-viewer/dist/app/Entity/Mouse/SceneMouseStateCameraDomSizeEntity";
import { SceneFusionPointEntity } from "dline-viewer/dist/app/Entity/Fusion/SceneFusionPointEntity";
import { ScreenWorldConversion } from "dline-viewer/dist/processing";
import { SceneInteractor } from "dline-viewer/dist/app/Interactors";
import { OrientationType } from "domain/static/OrientationType";

type HammerPanType = "singlepan" | "doublepan";
type HammerPinchType = "pinchstart" | "pinchend" | "pinchmove";

const hammerPanTypes = ["singlepan", "doublepan"] as HammerPanType[];
const hammerPinchTypes = [
  "pinchstart",
  "pinchend",
  "pinchmove",
] as HammerPinchType[];

export class MobileSceneInteractor implements SceneInteractor {
  private _app: App;
  private _element: HTMLElement | null;
  private _hammer: HammerManager | null;
  private _entities: {
    camera: SceneCameraEntity;
    fusion: SceneFusionPointEntity;
    axis: SceneAxisAndGridEntity;
    mouse: SceneMouseStateCameraDomSizeEntity;
  };
  private _panStart: any;
  private _pinchStart: any;

  constructor(app: App) {
    this._app = app;
    this._element = null;
    this._hammer = null;
    this._entities = null as any;
    this._panStart = null;
    this._pinchStart = null;

    this._onTouchEndHandle = this._onTouchEndHandle.bind(this);
    this._onPanHandle = this._onPanHandle.bind(this);
    this._onPinchHandle = this._onPinchHandle.bind(this);
  }

  initialize(element: HTMLElement, sceneId: string) {
    if (this._element !== null) {
      throw new Error("the instance is already initialized !");
    }

    this._element = element;
    this._initializeEntities(sceneId);
    this._initializeListeners(element);
  }

  private _initializeEntities(sceneId: string) {
    const [camera, fusion, axis, mouse] = this._app.db.Select1111(
      SceneCameraEntity,
      SceneFusionPointEntity,
      SceneAxisAndGridEntity,
      SceneMouseStateCameraDomSizeEntity,
      (camera, fusion, axis, mouse) =>
        camera.idScene === sceneId &&
        fusion.idScene === sceneId &&
        axis.idScene === sceneId &&
        mouse.idScene === sceneId
    )[0];

    this._entities = { camera, fusion, axis, mouse };
  }

  private _initializeListeners(element: HTMLElement) {
    this._hammer = new Hammer.Manager(element);
    this._hammer.add(new Hammer.Pinch({ threshold: 0.1 }));
    this._hammer.add(
      new Hammer.Pan({ event: "doublepan", threshold: 20, pointers: 2 })
    );
    this._hammer.add(
      new Hammer.Pan({ event: "singlepan", threshold: 50, pointers: 1 })
    );

    this._hammer.get("doublepan").recognizeWith("singlepan");
    this._hammer.get("singlepan").requireFailure("doublepan");

    this._hammer.on(hammerPanTypes.join(" "), this._onPanHandle);
    this._hammer.on(hammerPinchTypes.join(" "), this._onPinchHandle);

    element.addEventListener("touchend", this._onTouchEndHandle);
  }

  clear() {
    this._clearListeners();
  }

  private _clearListeners() {
    if (!this._element || !this._hammer) return;

    this._hammer.off(hammerPanTypes.join(" "));
    this._hammer.off(hammerPinchTypes.join(" "));
    this._element.removeEventListener("touchend", this._onTouchEndHandle);
  }

  private async _onTouchEndHandle(e: TouchEvent) {
    if (this._panStart && e.touches.length === 0) {
      this._panStart = null;
      if (this._hammer) {
        this._hammer.get("singlepan").set({ enable: true });
        this._hammer.get("doublepan").set({ enable: true });
      }
    }
  }

  private async _onPanHandle(input: HammerInput) {
    if (
      input.pointerType !== "touch" ||
      this._pinchStart ||
      !this._entities ||
      !this._hammer
    )
      return;

    const { camera, mouse, axis, fusion } = this._entities;

    if (!this._panStart) {
      this._panStart = {
        cx: camera.camera.cx,
        cy: camera.camera.cy,
      };
    }

    const type = input.type as HammerPanType;
    switch (type) {
      case "singlepan":
        this._hammer.get("doublepan").set({ enable: false });

        if (this._element) {
          const rect = this._element.getBoundingClientRect();
          const screenX = input.center.x - rect.left;
          const screenY = input.center.y - rect.top;

          const [worldX, worldY] = ScreenWorldConversion.ScreenToWorld(
            mouse.domSize,
            mouse.camera,
            screenX,
            screenY
          );

          const out = new Float32Array(2);
          axis.grid.getIJFromWorldXY(worldX, worldY, out);

          let ratioX = out[0] / axis.grid.size[0];
          let ratioY = out[1] / axis.grid.size[1];

          // fusion.fusionPoint[0] = Math2D.Clamp(ratioX, 0, 1);
          // fusion.fusionPoint[1] = Math2D.Clamp(ratioY, 0, 1);

          if (ratioX < 0.0 || ratioX > 1.0) return;
          if (ratioY < 0.0 || ratioY > 1.0) return;

          fusion.fusionPoint[0] = ratioX;
          fusion.fusionPoint[1] = ratioY;

          await this._app.derivation.UpdateAsync(fusion);
        }
        break;
      case "doublepan":
        this._hammer.get("singlepan").set({ enable: false });

        const dx = input.deltaX * -0.2;
        const dy = input.deltaY * -0.2;
        const order = axis.axisIndex === OrientationType.Axial ? 1.0 : -1.0;
        const cx = this._panStart.cx + dx;
        const cy = this._panStart.cy + dy * order;

        camera.camera.cx = cx;
        camera.camera.cy = cy;
        await this._app.derivation.UpdateAsync(camera);
        break;
    }
  }

  private async _onPinchHandle(input: HammerInput) {
    if (input.pointerType !== "touch" || this._panStart || !this._entities)
      return;

    const { camera } = this._entities;

    const type = input.type as HammerPinchType;
    switch (type) {
      case "pinchstart":
        this._pinchStart = {
          width: camera.camera.width,
          height: camera.camera.height,
        };
        break;

      case "pinchend":
        this._pinchStart = null;
        break;

      case "pinchmove":
        let delta = input.scale - 1.0;
        delta = delta > 0 ? delta * 0.1 : delta;
        const scale = 1.0 - delta;
        const width = this._pinchStart.width * scale;
        const height = this._pinchStart.height * scale;

        if (width < 10 || height < 10) {
          return;
        }

        camera.camera.width = width;
        camera.camera.height = height;
        await this._app.derivation.UpdateAsync(camera);
        break;
    }
  }
}
