import { ClinicalCaseCardResponse } from "domain/public/response/ClinicalCaseCardResponse";
import { SearchClinicalCaseCardsQuery } from "domain/public/query/SearchClinicalCaseCardsQuery";
import { useCallback, useEffect, useRef, useState } from "react";
import { sendMessage } from "tools/Message";

export default function useSearchClinicalCases(
  keywords: string
): [boolean, ClinicalCaseCardResponse[]] {
  const [state, setState] = useState({
    loading: false,
    results: [] as ClinicalCaseCardResponse[],
  });

  const query = useRef(
    new SearchClinicalCaseCardsQuery({
      top: 100,
      skip: 0,
      anatomicZoneId: null,
      keywords: "",
    })
  );

  const updateResults = useCallback(async () => {
    setState((s) => ({ ...s, loading: true }));
    query.current.keywords = keywords;

    let results = [] as ClinicalCaseCardResponse[];
    if (keywords.length > 0) {
      results = await sendMessage<ClinicalCaseCardResponse[]>(query.current);
    }

    setState({ loading: false, results });
  }, [keywords]);

  useEffect(() => {
    updateResults();
  }, [updateResults]);

  return [state.loading, state.results];
}
