import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    createStyles, makeStyles, Theme, Box, useScrollTrigger, Slide,
} from "@material-ui/core";
import { Helmet } from "react-helmet-async";

import SearchInput from "./SearchInput";
import SearchButton from "./SearchButton";
import AnatomicZoneTabs from "./AnatomicZoneTabs";
import { EmptyGuid, Guid } from "domain/static/Guid";
import useListAnatomicZones from "domain/hooks/useListAnatomicZones";
import { ClinicalCaseCardResponse } from "domain/public/response/ClinicalCaseCardResponse";
import SearchResults from "./SearchResults";
import { OnDesktop } from "components/Page";
import { slugify } from "tools/StringExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: theme.zIndex.appBar,
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        maxWidth: 1200,
    },
    resultsWrapper: {
        position: "absolute",
        top: 57,
        left: 0,
        right: 0,
        width: "100%",
        zIndex: theme.zIndex.appBar - 1,
        boxShadow: theme.shadows[1],
        overflowY: "auto",
        maxHeight: 420,
    },
}));

export interface ToolbarProps {
    searchLoading: boolean;
    searchResults: ClinicalCaseCardResponse[];
    onAnatomicZoneChange: (anatomicZoneId: Guid) => void;
    onSearchChange: (keywords: string) => void;
}

export default function Toolbar({ searchLoading, searchResults, onAnatomicZoneChange, onSearchChange }: ToolbarProps) {
    const classes = useStyles();
    const history = useHistory();
    const trigger = useScrollTrigger();

    const [anatomicZoneId, setAnatomicZoneId] = useState(EmptyGuid());
    const [loadingAnatomicZones, anatomicZones] = useListAnatomicZones();
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchKeywords, setSearchKeywords] = useState("");

    useEffect(() => {
        if (loadingAnatomicZones)
            return;

        const hash = history.location.hash.replace('#', '');
        const anatomicZone = anatomicZones.find(x => slugify(x.name) === hash);
        if (anatomicZone) {
            setAnatomicZoneId(anatomicZone.id);
        }
        else if (hash === "") {
            setAnatomicZoneId(EmptyGuid()); // all
        }
    }, [anatomicZones, history.location.hash, loadingAnatomicZones])

    useEffect(() => {
        onAnatomicZoneChange(anatomicZoneId);
    }, [onAnatomicZoneChange, anatomicZoneId, anatomicZones])

    const onAnatomicZoneChangeHandle = useCallback((id: Guid) => {
        const anatomicZone = !loadingAnatomicZones
            ? anatomicZones.find(x => x.id === id)
            : undefined;
        if (anatomicZone) {
            history.push(`#${slugify(anatomicZone.name)}`);
        }
        else {
            history.push("#");
        }
    }, [anatomicZones, history, loadingAnatomicZones]);

    const onSearchChangeHandle = useCallback((keywords: string) => {
        onSearchChange(keywords);
        setSearchKeywords(keywords);
    }, [onSearchChange]);

    const onSearchOpenHandle = useCallback(() => {
        setSearchOpen(true);
    }, []);

    const onSearchCloseHandle = useCallback(() => {
        setSearchOpen(false);
        onSearchChangeHandle("");
        onAnatomicZoneChange(anatomicZoneId);
    }, [onSearchChangeHandle, onAnatomicZoneChange, anatomicZoneId]);

    const anatomicZoneName = anatomicZones.find(x => x.id === anatomicZoneId)?.name ?? "All";

    return <>
        <Helmet>
            <title>Atlas - {anatomicZoneName} clinical cases - Dline</title>
            <meta
                name="description"
                content={`Found out free access clinical cases for anatomic zones : ${anatomicZones.map(x => x.name).join(", ")}`}
            />
        </Helmet>

        <Slide appear={false} direction="down" in={!trigger} style={{ marginTop: 56 }}>
            <Box className={classes.root}>

                {searchOpen === false && <>
                    <OnDesktop>
                        <SearchButton onClick={onSearchOpenHandle} />
                    </OnDesktop>

                    <AnatomicZoneTabs
                        value={anatomicZoneId}
                        onChange={onAnatomicZoneChangeHandle}
                        anatomicZones={anatomicZones}
                        loading={loadingAnatomicZones}
                    />
                </>}

                {searchOpen === true &&
                    <SearchInput
                        loading={searchLoading}
                        onClose={onSearchCloseHandle}
                        onChange={onSearchChangeHandle}
                    />
                }

                <OnDesktop>
                    <Box className={classes.resultsWrapper}>
                        <SearchResults
                            loading={searchLoading}
                            keywords={searchKeywords}
                            clinicalCases={searchResults}
                        />
                    </Box>
                </OnDesktop>

            </Box>
        </Slide>
    </>
}