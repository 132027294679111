import React from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles, SvgIconProps, Theme, Typography } from "@material-ui/core";

import { Guid } from "domain/static/Guid";
import { fullPath } from "tools/Route";
import { slugify } from "tools/StringExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    list: {
        display: "flex",
        flexWrap: "wrap",
    },
    link: {
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.primary.light,
            textDecoration: "underline",
        }
    }
}));

type IPerson = {
    id: Guid;
    firstname: string;
    lastname: string;
}

export interface PersonProps {
    value: IPerson;
    first?: boolean;
}

export default function Person({ value, first }: PersonProps) {
    const classes = useStyles();

    const href = fullPath("PublicUserInformations", {
        params: {
            id: value.id,
            slug: slugify(value.firstname + " " + value.lastname)
        }
    });

    return <>
        {first === true ? <span>&nbsp;</span> : <span>,&nbsp;</span>}
        <Link to={href} className={classes.link}>
            {value.firstname} {value.lastname}
        </Link>
    </>
}

export interface PersonListProps {
    icon: React.ReactElement<SvgIconProps>;
    label: string;
    children: IPerson[];
}

export function PersonList({ label, icon, children }: PersonListProps) {
    const classes = useStyles();

    return <Typography variant="body2" component="div">
        {icon}
        <Typography variant="body2" component="p" className={classes.list}>
            {label}
            {children.map((x, idx) =>
                <Person key={idx} value={x} first={idx === 0} />
            )}
        </Typography>
    </Typography>
}
