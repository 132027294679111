import React from "react";

import { CentralColumnLayout } from "components/Layouts";
import { Typography } from "components/Page";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
    layout: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(-5),
        marginBottom: 0,
        maxWidth: 780,
        "& strong": {
            color: theme.palette.primary.dark,
        }
    }
}));

export default function TokenInvalid() {
    const classes = useStyles();

    return <CentralColumnLayout size="sm" className={classes.layout}>
        <Typography variant="h5">
            This clinical case has already been reviewed
        </Typography>
        <Typography>
            This link is no more available.
        </Typography>

    </CentralColumnLayout>
}
