import { useCallback, useEffect, useMemo, useState } from "react";
import { EmptyGuid } from "domain/static/Guid";
import { ContouringWorkshopViewerResponse } from "domain/public/response/ContouringWorkshopViewerResponse";
import { GetProfileContouringWorkshopViewerQuery } from "domain/public/query/GetProfileContouringWorkshopViewerQuery";
import { sendMessage } from "tools/Message";
import { ContouringWorkshopState } from "domain/static/ContouringWorkshopState";
import { ContouringWorkshopUserRole } from "domain/static/ContouringWorkshopUserRole";
import { ContouringWorkshopUserPermission } from "domain/static/ContouringWorkshopUserPermission";

function emptyResponse() {
  const response = new ContouringWorkshopViewerResponse();
  response.id = EmptyGuid();
  response.name = "";
  response.slug = "";
  response.summary = "";
  response.anatomicZoneName = "";
  response.coverId = null;
  response.limitOfParticipants = 0;
  response.numberOfParticipant = 0;
  response.startAt = new Date();
  response.endAt = new Date();
  response.state = ContouringWorkshopState.None;
  response.isPublished = false;
  response.publishedAt = null;
  response.globalContentLength = 0;
  response.userRole = ContouringWorkshopUserRole.None;
  response.userPermission = ContouringWorkshopUserPermission.None;
  response.images = [];
  response.contours = [];
  response.articles = [];
  response.contourArticles = [];
  response.referrers = [];
  return response;
}

export default function useProfileContouringWorkshopViewer(
  slug: string
): [boolean, ContouringWorkshopViewerResponse, any | undefined] {
  const query = useMemo(
    () => new GetProfileContouringWorkshopViewerQuery({ slug }),
    [slug]
  );

  const [state, setState] = useState({
    loading: true,
    data: emptyResponse(),
    error: undefined as any | undefined,
  });

  const updateData = useCallback(async () => {
    setState((s) => ({ ...s, loading: true, error: undefined }));
    try {
      const data = await sendMessage<ContouringWorkshopViewerResponse>(query);
      setState((s) => ({ data, loading: false, error: undefined }));
    } catch (e) {
      setState((s) => ({ ...s, loading: false, error: e }));
    }
  }, [query]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return [state.loading, state.data, state.error];
}
