import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class GetProfileInformationsQuery implements IMessage {

    public constructor(init?: Partial<GetProfileInformationsQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET" as RequestMethod;

    getPath = () => `/profile/informations`;

    getQueryString = () => undefined;

    getBody = () => undefined;

    needAuthentication = () => true;
}
