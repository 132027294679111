import React from "react";

import { AuthContextProvider as CocoAuthContextProvider } from "cocoreact";
import { authTokenService } from "services";

export interface AuthContextProviderProps {
    children: React.ReactNode | React.ReactNode[];
}

export default function AuthContextProvider({
    children
}: AuthContextProviderProps) {

    return (
        <CocoAuthContextProvider authService={authTokenService}>
            {children}
        </CocoAuthContextProvider>
    );
}
