import { Guid } from "domain/static/Guid";
import { IMessage, RequestMethod } from "cocoreact";

//Generated code, do not modify

export class AssembleFilePartsCommand implements IMessage {
    fileId!: Guid;
    checksum!: string;

    public constructor(init?: Partial<AssembleFilePartsCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PATCH" as RequestMethod;

    getPath = () => `/files/${this.fileId.toString()}/parts`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            checksum: this.checksum,
        };
    }
    
    needAuthentication = () => true;
}
