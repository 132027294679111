import React, { useCallback, useEffect } from "react";
import { DialogContentText } from "@material-ui/core";
import { useNotificationContext } from "cocoreact";

import { SendIcon } from "App/Theme";
import { ContactCommand } from "domain/public/command/ContactCommand";
import { sendMessage } from "tools/Message";
import ResponsiveFormDialog from "./ResponsiveFormDialog";
import ContactFormFields, { useContactForm } from "./ContactFormFields";
import MailIssueWarning from "./MailIssueWarning";

export interface FeedbackDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function FeedbackDialog({ open, onClose }: FeedbackDialogProps) {

    const { success, error } = useNotificationContext();
    const [formValue, setFieldValue, cleanFromValues] = useContactForm();

    useEffect(() => {
        if (open === false) {
            cleanFromValues();
        }
    }, [cleanFromValues, open])

    const onSubmitHandle = useCallback(async () => {
        try {
            const message = new ContactCommand(formValue);
            await sendMessage(message);

            success("Your message has been sending successfully");
            onClose();
        }
        catch (e) {
            error("An error occurred while sending your message !");
        }
    }, [error, onClose, formValue, success]);

    return <ResponsiveFormDialog
        open={open}
        onClose={onClose}
        title="Report a problem or send feedback"
        actionLabel="contact us"
        actionIcon={<SendIcon />}
        onSubmit={onSubmitHandle}
    >
        {({ loading }) => <>

            <MailIssueWarning />
            
            <DialogContentText>
                Feedback helps us to understand your experience on the application.
                To submit feedback or report an issue fill out the following form.
            </DialogContentText>

            <ContactFormFields
                value={formValue}
                onChange={setFieldValue}
                disabled={loading}
            />

        </>}
    </ResponsiveFormDialog>;
}
