import {
  AxiosHttpClient,
  JwtHttpClientDecorator,
  IRefreshJwtService,
} from "cocoreact";

export default class HttpClient extends JwtHttpClientDecorator {
  constructor(baseUrl: string, refreshJwtService: IRefreshJwtService) {
    super(new AxiosHttpClient(baseUrl), refreshJwtService);
  }
}
