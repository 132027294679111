import { App } from "dline-viewer/dist/app";
import { AppEntity } from "dline-viewer/dist/app/Entity/App/AppEntity";
import { AppFusionEntity } from "dline-viewer/dist/app/Entity/Fusion/AppFusionEntity";
import {
  ItkImage,
  EditableVoxelVolume,
  PolygonVolume,
} from "dline-viewer/dist/data";
import {
  BooleanOperation,
  EditableVoxelVolumeBoolean,
} from "dline-viewer/dist/processing";
import { VoxelMargin } from "dline-viewer/dist/processing/VoxelMargin";
import { RoiEditableVoxelVolumeEntity } from "dline-viewer/dist/app/Entity/Roi/RoiEditableVoxelVolumeEntity";

import { ImagingDefault } from "domain/static/ImagingDefault";
import { OrientationType } from "domain/static/OrientationType";
import { FusionType } from "domain/static/FusionType";
import { Range } from "domain/static/Range";

import { AppViewerDerivation } from "./AppViewerDerivation";
import SceneHelper, { SceneIndexCallback } from "./SceneHelper";
import ImageHelper from "./ImageHelper";
import ContourHelper from "./ContourHelper";
import StackHelper from "./StackHelper";
import DrawingHelper from "./DrawingHelper";

import { ViewerData, ImageData, ContourData, StackData } from "contexts/Viewer";
import { RgbToHex } from "tools/ColorExtension";

export interface ContourDrawn {
  id: string;
  color: string;
  polygonVolume: PolygonVolume | null;
  dice: number;
}

export type BooleanType =
  | "union"
  | "intersection"
  | "soustraction"
  | "exclusion";

export default class AppViewerHelper extends App {
  private _scenes: SceneHelper[];
  private _images: ImageHelper[];
  private _contours: ContourHelper[];
  private _stacks: StackHelper[];
  private _drawing: DrawingHelper;
  private _appDerivation: AppViewerDerivation;

  constructor() {
    super();
    this._scenes = [];
    this._images = [];
    this._contours = [];
    this._stacks = [];
    this._drawing = null as any;
    this._appDerivation = new AppViewerDerivation();
  }

  async configure(debug: boolean = false) {
    this.Configure();

    if (debug) {
      (window as any)["viewerApp"] = this;
      this.derivation.setLogLevel(2);
      this.db.logLevel = 1;
    }

    this._appDerivation.Configure(this);
    await this.derivation.DoAsync((transaction) => {
      transaction.Insert(new AppEntity());
    });

    this._drawing = new DrawingHelper(this);
  }

  clear() {
    this._appDerivation.clear();
    for (const scene of this._scenes) {
      scene.clear();
    }
  }

  async initialize(data: ViewerData) {
    // prevent multiple initialization
    if (this._drawing) {
      return;
    }

    await this.configure();

    const primary = data.getImagePrimary();
    await this.setImage(ImagingDefault.Primary, primary);
    const overlay = data.getImageOverlay();
    if (overlay) {
      await this.setImage(ImagingDefault.Overlay, overlay);
    }

    await this.setContours(data.contours);
    await this.setStacks(data.stacks);
  }

  // Scene methods

  get numberOfScenes() {
    return this._scenes.length;
  }

  getSceneAt(idx: number) {
    return this._scenes[idx];
  }

  getSceneByOrientation(orientation: OrientationType) {
    return this._scenes.find((x) => x.isOrientation(orientation));
  }

  async createScene(
    orientation: OrientationType,
    container: HTMLDivElement,
    onIndexChange?: SceneIndexCallback
  ) {
    const scene = new SceneHelper(this, orientation, container, onIndexChange);
    await scene.initialize();
    this._scenes.push(scene);
  }

  async setFusionType(fusionType: FusionType) {
    for (const scene of this._scenes) {
      await scene.setFusionType(fusionType);
    }
  }

  // Image methods

  get numberOfImages() {
    return this._images.length;
  }

  getImageAt(idx: number) {
    return this._images[idx];
  }

  getImageById(id: string) {
    return this._images.find((x) => x.id === id);
  }

  getImageByImaging(imagingDefault: ImagingDefault) {
    return this._images.find((x) => x.isImaging(imagingDefault));
  }

  getImagePrimary() {
    return this.getImageByImaging(ImagingDefault.Primary);
  }

  getImageOverlay() {
    return this.getImageByImaging(ImagingDefault.Overlay);
  }

  getImageData(imageId: string) {
    const image = this.getImageById(imageId);
    if (!image) return undefined;

    return image.getItkImage();
  }

  getImageDimensionsMax() {
    if (this.numberOfImages > 0) {
      const imgData = this._images[0].getItkImage();
      if (imgData) {
        return imgData.grid.size;
      }
    }
    return [0, 0, 0];
  }

  async setImage(imagingDefault: ImagingDefault, img: ImageData) {
    await this.setImageData(imagingDefault, img.id, img.getData());
    await this.setImagesWindowing(img.id, img.windowing);
    await this.setImagesLutPreset(img.id, img.lut);
  }

  async setImageData(
    imagingDefault: ImagingDefault,
    imageId: string,
    imageData: ItkImage
  ) {
    if (this.numberOfImages === 0) {
      const sceneImage1 = new ImageHelper(this, ImagingDefault.Primary);
      await sceneImage1.setImage(imageId, imageData);
      this._images.push(sceneImage1);
      const sceneImage2 = new ImageHelper(this, ImagingDefault.Overlay);
      await sceneImage2.setImage(imageId, imageData);
      this._images.push(sceneImage2);

      const fusionIndex1 = sceneImage1.getFusionIndexEntity();
      const fusionIndex2 = sceneImage2.getFusionIndexEntity();

      const appFusion = new AppFusionEntity();
      appFusion.idItkImage1 = fusionIndex1?.idItkImage;
      appFusion.idItkImage2 = fusionIndex2?.idItkImage;

      await this.derivation.InsertAsync(fusionIndex1, fusionIndex2, appFusion);
    } else {
      const img = this.getImageByImaging(imagingDefault);
      if (img && img.id !== imageId) {
        await img.setImage(imageId, imageData);
      }
    }
  }

  async setImagesWindowing(imageId: string, windowing: Range) {
    const images = this._images.filter((x) => x.id === imageId);
    for (const image of images) {
      await image.setWindowing(windowing);
    }
  }

  async setImagesLutPreset(imageId: string, lutPreset: string) {
    const images = this._images.filter((x) => x.id === imageId);
    for (const image of images) {
      await image.setLutPreset(lutPreset);
    }
  }

  // Contours methods

  get numberOfContours() {
    return this._contours.length;
  }

  getContourAt(idx: number) {
    return this._contours[idx];
  }

  getContourById(id: string) {
    return this._contours.find((x) => x.id === id);
  }

  getContoursDrawn(references?: any[]): ContourDrawn[] {
    let contoursDrawn = [] as ContourDrawn[];
    for (const contour of this._contours) {
      const polygonVolume = contour.computePolygonVolumeFromDrawing();
      if (polygonVolume !== undefined) {
        var dice = 0.0;
        const reference = references?.find((x) => x.id === contour.id);
        if (reference) {
          dice = contour.ComputeDistance(reference);
        }
        contoursDrawn.push({
          id: contour.id,
          color: RgbToHex(contour.getColor()),
          polygonVolume,
          dice,
        });
      }
    }
    return contoursDrawn;
  }

  async setContours(contours: ContourData[]) {
    for (const contour of contours) {
      this.setContour(contour);
    }
  }

  async setContour(contour: ContourData) {
    const contourHelper = new ContourHelper(this);
    await contourHelper.fromReadonly(contour.getData());
    await contourHelper.setVisible(contour.visible);

    this._contours.push(contourHelper);
  }

  async setContourColor(contourId: string, color: number[]) {
    const contour = this.getContourById(contourId);
    if (!contour) return;

    await contour.setColor(color);
  }

  async setContourHighliht(contourId: string, enable: boolean) {
    const contour = this.getContourById(contourId);
    if (!contour) return;

    await contour.setHighlight(enable);
  }

  async setContourVisible(contourId: string, visible: boolean) {
    const contour = this.getContourById(contourId);
    if (!contour) return;

    await contour.setVisible(visible);
  }

  // Stacks methods

  get numberOfStacks() {
    return this._stacks.length;
  }

  getStackAt(idx: number) {
    return this._stacks[idx];
  }

  getStackById(id: string) {
    return this._stacks.find((x) => x.id === id);
  }

  async setStacks(stacks: StackData[]) {
    for (const stack of stacks) {
      this.setStack(stack);
    }
  }

  async setStack(stack: StackData) {
    const stackHelper = new StackHelper(this);
    await stackHelper.setImage(stack.id, stack.data);
    await stackHelper.setVisible(stack.visible);
    await stackHelper.setWindowing(stack.windowing);

    this._stacks.push(stackHelper);
  }

  async setStackVisible(contourId: string, visible: boolean) {
    const stack = this.getStackById(contourId);
    if (!stack) return;

    await stack.setVisible(visible);
  }

  async setStackWindowing(contourId: string, windowing: Range) {
    const stack = this.getStackById(contourId);
    if (!stack) return;

    await stack.setWindowing(windowing);
  }

  // Drawing methods

  get draw() {
    return this._drawing;
  }

  // Process methods

  async undo() {
    if (!this.undoRedoDraw.UndoEnabled()) return;

    const volume = this.undoRedoDraw.Undo();
    await this._updateEditableVoxelVolume(volume);
  }

  async redo() {
    if (!this.undoRedoDraw.RedoEnabled()) return;

    const volume = this.undoRedoDraw.Redo();
    await this._updateEditableVoxelVolume(volume);
  }

  private async _updateEditableVoxelVolume(volume: EditableVoxelVolume) {
    const editable = this.db.First(
      RoiEditableVoxelVolumeEntity,
      (x) => x.editableVoxelVolume === volume
    );

    await this.derivation.UpdateAsync(editable);
  }

  async boolean(
    type: BooleanType,
    targetContourId: string,
    contour1Id: string,
    contour2Id: string
  ) {
    const target = this.getContourById(targetContourId);
    const source1 = this.getContourById(contour1Id);
    const source2 = this.getContourById(contour2Id);

    if (!target || !source1 || !source2) return;

    await source1.setMode("drawing");
    await source2.setMode("drawing");
    await target.setMode("drawing");

    const targetVoxelEntity = target.getEditableVoxelVolume();
    const targetVolume = targetVoxelEntity.editableVoxelVolume;
    const source1Volume = source1.getEditableVoxelVolume().editableVoxelVolume;
    const source2Volume = source2.getEditableVoxelVolume().editableVoxelVolume;

    let operator: BooleanOperation;
    switch (type) {
      case "union":
        operator = BooleanOperation.Union;
        break;
      case "intersection":
        operator = BooleanOperation.Intersection;
        break;
      case "soustraction":
        operator = BooleanOperation.Soustraction;
        break;
      case "exclusion":
        operator = BooleanOperation.Exclusion;
        break;
      default:
        throw new Error("undefiend BooleanType " + type);
    }

    const resultVolume = new EditableVoxelVolume(
      this.CreateVoxelVolume(targetVolume.voxelVolume.grid)
    );

    EditableVoxelVolumeBoolean.Operation(
      operator,
      source1Volume,
      source2Volume,
      resultVolume
    );

    this.undoRedoDraw.StartVolume(targetVolume);
    target.clean();
    targetVolume.SetVoxelsFrom(resultVolume);
    this.undoRedoDraw.StopVolume(targetVolume);

    await this.derivation.UpdateAsync(targetVoxelEntity);
  }

  async margin(margins: number[], targetContourId: string, contourId: string) {
    const target = this.getContourById(targetContourId);
    const source = this.getContourById(contourId);

    if (!target || !source) return;

    await source.setMode("drawing");
    await target.setMode("drawing");

    const targetVoxelEntity = target.getEditableVoxelVolume();
    const targetVolume = targetVoxelEntity.editableVoxelVolume;
    const sourceVolume = source.getEditableVoxelVolume().editableVoxelVolume;

    this.undoRedoDraw.StartVolume(targetVolume);

    target.clean();

    const voxelMargin = new VoxelMargin();
    targetVolume.SetVoxelsFrom(sourceVolume);
    voxelMargin.ApplyMargin(targetVolume, margins);

    this.undoRedoDraw.StopVolume(targetVolume);

    await this.derivation.UpdateAsync(targetVoxelEntity);
  }
}
