import React, { useCallback, useState } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    AppBar, Toolbar, IconButton, Box, Slide, SvgIconProps,
    makeStyles, createStyles, Theme, CircularProgress
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";

import { CloseIcon } from "App/Theme";
import { OnDesktop, OnMobile, Spacer } from "components/Page";

const useStyles = makeStyles((theme: Theme) => createStyles({
    appBarSpacer: {
        height: theme.mixins.toolbar.height,
        minHeight: theme.mixins.toolbar.minHeight,
        maxHeight: theme.mixins.toolbar.maxHeight,
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    },
    title: {
        paddingBottom: theme.spacing(1),
    },
    content: {
        paddingTop: `${theme.spacing(1)}px!important`,
    },
    actions: {
        padding: theme.spacing(1, 3, 2, 3),
    },
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface ResponsiveFormDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => Promise<void>;
    title: string;
    actionLabel: string;
    actionIcon?: React.ReactElement<SvgIconProps>;
    children: (props: { loading: boolean }) => (React.ReactNode | React.ReactNode[]);
}

export default function ResponsiveFormDialog({
    open, onClose, onSubmit, title, actionLabel, actionIcon, children
}: ResponsiveFormDialogProps) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const onSubmitHandle = useCallback(async (e: React.SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        await onSubmit();
        setLoading(false);
    }, [onSubmit]);

    return <>
        <OnDesktop>
            <Dialog open={open} onClose={loading ? undefined : onClose}>
                <form onSubmit={loading ? undefined : onSubmitHandle}>

                    <DialogTitle className={classes.title}>
                        {title}
                    </DialogTitle>

                    <DialogContent className={classes.content}>
                        {children({ loading })}
                    </DialogContent>

                    <DialogActions className={classes.actions}>
                        <Button
                            variant="outlined"
                            onClick={loading ? undefined : onClose}
                            startIcon={<CloseIcon />}
                            disabled={loading}
                        >
                            close
                        </Button>

                        <Spacer />

                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            endIcon={loading ? <CircularProgress size={16} /> : actionIcon}
                            disabled={loading}
                        >
                            {actionLabel}
                        </Button>
                    </DialogActions>

                </form>
            </Dialog>
        </OnDesktop>

        <OnMobile>
            <Dialog
                open={open}
                onClose={loading ? undefined : onClose}
                fullScreen={true}
                TransitionComponent={Transition}
            >
                <form onSubmit={loading ? undefined : onSubmitHandle}>

                    <AppBar color="default">
                        <Toolbar className={classes.toolbar}>
                            <IconButton
                                color="inherit"
                                onClick={onClose}
                                disabled={loading}
                            >
                                <CloseIcon />
                            </IconButton>

                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} /> : actionLabel}
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Box className={classes.appBarSpacer} />

                    <DialogTitle className={classes.title}>
                        {title}
                    </DialogTitle>

                    <DialogContent className={classes.content}>
                        {children({ loading })}
                    </DialogContent>

                </form>
            </Dialog>
        </OnMobile>
    </>;
}
