import React from "react";
import { Typography } from "@material-ui/core";
import {
    EventAvailable as StartAtIcon,
    EventBusy as EndAtIcon,
    AccountCircle as ReferrerIcon
} from "@material-ui/icons";

import { ContouringWorkshopState } from "domain/static/ContouringWorkshopState";
import { Article, User } from "domain/public/response/ContouringWorkshopViewerResponse";
import { PersonList, SummaryPanel as BaseSummaryPanel } from "components/ClinicalData";
import { formatLongDateTime } from "tools/DateExtension";

type ContouringWorkshopType = {
    startAt: Date;
    endAt: Date;
    publishedAt: Date | null;
    state: ContouringWorkshopState;
    summary: string;
    articles: Article[];
    referrers: User[];
}

export interface SummaryPanelProps {
    contouringWorkshop: ContouringWorkshopType;
}

export default function SummaryPanel({ contouringWorkshop }: SummaryPanelProps) {

    const { articles, summary, publishedAt, startAt, endAt, state, referrers } = contouringWorkshop;

    return <BaseSummaryPanel
        summary={summary}
        articles={articles}
        publishedAt={publishedAt ?? undefined}
        metas={<>
            <Typography variant="body2" component="p">
                <StartAtIcon fontSize="small" />
                {state === ContouringWorkshopState.Future ? "Start on " : "Started on "}
                {formatLongDateTime(startAt)}
            </Typography>
            <Typography variant="body2" component="p">
                <EndAtIcon fontSize="small" />
                {state === ContouringWorkshopState.Past ? "Ended on " : "End on "}
                {formatLongDateTime(endAt)}
            </Typography>
            {referrers && referrers.length > 0 &&
                <PersonList
                    label="Referred by"
                    icon={<ReferrerIcon fontSize="small" />}
                >
                    {referrers}
                </PersonList>
            }
        </>
        }
    />
}