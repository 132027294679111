import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    makeStyles, Theme, createStyles,
    Box, Paper, Slide, Button, CircularProgress, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Chip
} from "@material-ui/core";
import { Done as DoneIcon, Lock as LockIcon } from "@material-ui/icons";
import { useNotificationContext } from "cocoreact";

import { Guid } from "domain/static/Guid";
import { FileParentType } from "domain/static/FileParentType";
import { ClinicalCaseState } from "domain/static/ClinicalCaseState";
import { ClinicalCaseViewerResponse, Contour } from "domain/public/response/ClinicalCaseViewerResponse";
import { UpdateClinicalCaseContoursCommand } from "domain/public/command/UpdateClinicalCaseContoursCommand";
import { SetStateClinicalCaseCommand } from "domain/public/command/SetStateClinicalCaseCommand";
import ContourListPanel from "./ContourListPanel";
import { HeaderCover, Tabs, InformationTab, TabContent } from "components/ClinicalData";
import { SummaryPanel } from "components/ClinicalCase";
import { useViewerContext } from "contexts/Viewer";
import { CloseIcon, SaveIcon } from "App/Theme";
import { ContourDrawn } from "tools/Viewer";
import { FilePartUploader } from "tools/FileExtension";
import { toArrayBuffer } from "tools/StringExtension";
import { sendMessage } from "tools/Message";
import { Spacer } from "components/Page";
import { formatLongDateTime } from "tools/DateExtension";
import { fullPath } from "tools/Route";
import { Prompt } from "react-router-dom";
import ContourPanel from "./ContourPanel";
import { EditableVoxelVolume } from "dline-viewer/dist/data";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: theme.mixins.viewer.smallWidth,
        minWidth: theme.mixins.viewer.smallWidth,
        maxWidth: theme.mixins.viewer.smallWidth,
        zIndex: theme.zIndex.drawer,
    },
    actions: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        padding: theme.spacing(2, 4),
        borderTop: `1px ${theme.palette.divider} solid`,
        boxShadow: theme.shadows[8],
        "& > *": {
            marginBottom: theme.spacing(1),
        },
        "& > :last-child": {
            marginBottom: 0,
        },
    },
}));

async function updateContoursCommand(
    command: UpdateClinicalCaseContoursCommand,
    contours: Contour[],
    contoursDrawn: ContourDrawn[]
) {
    const fileUploader = new FilePartUploader(
        "ReadOnlyROI_polygonVolume.json",
        "application/json"
    );

    command.contours = [];
    for (const contour of contours) {
        if (contour.isEditable === true) {
            const id = contour.id;
            let color = contour.color;
            let fileId = contour.fileId;

            const contourDrawn = contoursDrawn.find(x => x.id === id);
            if (contourDrawn) {
                fileId = null;
                color = contourDrawn.color;
                if (contourDrawn.polygonVolume) {
                    const dataRoi = JSON.stringify(contourDrawn.polygonVolume);
                    fileUploader.setParent(id, FileParentType.ClinicalContour);
                    fileId = await fileUploader.send(toArrayBuffer(dataRoi));
                }
            }

            command.contours.push({ id, color, fileId });
        }
    }
}

export interface DrawerProps {
    open: boolean;
    clinicalCase: ClinicalCaseViewerResponse;
}

export default function Drawer({ open, clinicalCase }: DrawerProps) {
    const classes = useStyles();

    const [clinicalCaseState, setClinicalCaseState] = useState(clinicalCase.state);
    const [tab, setTab] = useState<InformationTab>("summary");
    const [selectContour, setSelectContour] = useState<Guid | null>(null);
    const [poposalState, setPoposalState] = useState<ProposalState>("CLOSE");

    const { success, error } = useNotificationContext();
    const {
        app: viewerApp,
        dispatcher: viewerDispatcher
    } = useViewerContext();

    const isEditable = useMemo(
        () => clinicalCaseState === ClinicalCaseState.Draft,
        [clinicalCaseState]
    );

    const worker = useRef(new Worker(`${process.env.PUBLIC_URL}/workers/viewer.worker.js`));

    useEffect(() => {
        if (!isEditable) {
            viewerDispatcher({
                type: "CONTOUR_DRAWING",
                contourId: null
            });
        }
    }, [isEditable, viewerDispatcher]);

    const onUnselectHandle = useCallback(() => {
        setSelectContour(null);
        viewerDispatcher({
            type: "CONTOUR_DRAWING",
            contourId: null,
        });
    }, [viewerDispatcher]);

    const workerMessageHandler = React.useCallback(async (ev: MessageEvent) => {
        const contourId = ev.data.contourId as Guid;
        const editableVoxelVolume = ev.data.editableVoxelVolume as EditableVoxelVolume;

        const contourHelper = viewerApp.getContourById(contourId);

        if (contourHelper) {
            await contourHelper.updateEditableVolume(editableVoxelVolume);

            viewerDispatcher({
                type: "LOADING",
                loading: false,
            }, "data");

            await viewerDispatcher({
                type: "CONTOUR_DRAWING",
                contourId: contourId,
            });
        }
    }, [viewerApp, viewerDispatcher]);

    const onSelectHandle = useCallback(async (contourId: Guid) => {
        setSelectContour(contourId);
        if (!isEditable) return;

        const contour = clinicalCase.contours.find(x => x.id === contourId);
        if (!contour || contour.isEditable !== true) return;

        const contourHelper = viewerApp.getContourById(contourId);
        const readonlyRoi = contourHelper ? contourHelper.getReadonlyRoi() : null;
        if (readonlyRoi && contourHelper && contourHelper.mode !== "drawing") {
            viewerDispatcher({
                type: "LOADING",
                loading: true,
            }, "data");
            await contourHelper.initializeEditableVolume();

            worker.current.onmessage = workerMessageHandler;
            worker.current.postMessage({
                msg: "POLYGON_TO_VOXEL",
                polygonVolume: readonlyRoi.polygonVolume,
                contourId: contourId,
            });
        }
        else if (contourHelper && contourHelper.mode === "drawing") {
            await viewerDispatcher({
                type: "CONTOUR_DRAWING",
                contourId: contourId,
            });
        }
    }, [clinicalCase.contours, isEditable, viewerApp, viewerDispatcher, workerMessageHandler]);

    const saveContours = useCallback(async () => {
        try {
            const contoursDrawn = viewerApp.getContoursDrawn();
            const command = new UpdateClinicalCaseContoursCommand({ id: clinicalCase.id });
            await updateContoursCommand(command, clinicalCase.contours, contoursDrawn);
            await sendMessage(command);

            success("You contours has been save with success");
        }
        catch (e) {
            error("An error occured while saving your contours !")
        }
    }, [clinicalCase, error, success, viewerApp]);

    const submitClinicalCase = useCallback(async () => {
        try {
            const command = new SetStateClinicalCaseCommand({
                id: clinicalCase.id,
                state: ClinicalCaseState.Submitted
            });
            await sendMessage(command);

            setClinicalCaseState(ClinicalCaseState.Submitted);
            success("You work has been submitted");
        }
        catch (e) {
            error("An error occured while submitted your work !")
        }
    }, [clinicalCase.id, error, success]);

    const onSaveContoursHandle = useCallback(async () => {
        setPoposalState("SAVING");
        await saveContours();
        setPoposalState("CLOSE");
    }, [saveContours]);

    const onSubmitHandle = useCallback(async () => {
        await onSaveContoursHandle();
        setPoposalState("CONFIRM_SUBMIT");
    }, [onSaveContoursHandle]);

    const onPreviousProposalHandler = useCallback(() => {
        setPoposalState("CLOSE");
    }, []);

    const onAfterProposalHandler = useCallback(async (proposalState: ProposalState) => {
        switch (proposalState) {
            case "CONFIRM_SUBMIT":
                setPoposalState("SUBMITTING");
                await submitClinicalCase();
                setPoposalState("CLOSE");
                break;
            default:
                setPoposalState("CLOSE");
        }
    }, [submitClinicalCase]);

    const publishedUrl = fullPath("ShowClinicalCase", {
        params: {
            slug: clinicalCase.slug
        }
    });

    useEffect(() => {
        if (isEditable) {
            window.onbeforeunload = () => true;
        }
        return () => {
            window.onbeforeunload = null;
        };
    }, [isEditable]);

    return <>
        {isEditable &&
            <Prompt
                message="Did you save your contours ? Are you sure to leave the page ?"
            />
        }

        <Slide in={open} direction="left">
            <Paper className={classes.root} elevation={12} square={true}>

                <HeaderCover
                    title={clinicalCase.name}
                    coverId={clinicalCase.coverId}
                    size="small"
                />

                <Tabs
                    value={tab}
                    onChange={setTab}
                    textColor="primary"
                    indicatorColor="primary"
                />

                <TabContent hidden={tab !== "summary"}>
                    <SummaryPanel clinicalCase={clinicalCase} />
                </TabContent>

                <TabContent hidden={tab !== "contours" || selectContour !== null}>
                    <ContourListPanel
                        contours={clinicalCase.contours}
                        onClick={onSelectHandle}
                        disabledEdition={!isEditable}
                    />
                </TabContent>

                <TabContent hidden={tab !== "contours" || selectContour === null}>
                    <ContourPanel
                        contourId={selectContour as Guid}
                        onClose={onUnselectHandle}
                        clinicalCase={clinicalCase}
                        disabledEdition={!isEditable}
                    />
                </TabContent>

                <Spacer />

                {isEditable && <>
                    <Box
                        hidden={tab !== "summary"}
                        className={classes.actions}
                    >
                        <Typography variant="caption" component="p">
                            <strong>After completed contours</strong>, you can submit your work. Then
                            the reviewer will analyze your data and accept or reject it.
                        </Typography>

                        <Button
                            fullWidth
                            size="large"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={onSubmitHandle}
                        >
                            save & submit clinical case
                        </Button>
                    </Box>

                    <Box
                        hidden={tab !== "contours"}
                        className={classes.actions}
                    >
                        <Typography variant="caption" component="p">
                            Save your contours any times you wan't, <strong>then submit your work</strong>.
                        </Typography>

                        <Button
                            fullWidth
                            size="large"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={onSaveContoursHandle}
                        >
                            save contours
                        </Button>
                    </Box>
                </>}

                {clinicalCaseState === ClinicalCaseState.Submitted && <>
                    <Box
                        className={classes.actions}
                    >
                        <Box textAlign="center">
                            <Chip label="Submitted" icon={<LockIcon fontSize="small" />} />
                        </Box>
                        <Typography variant="caption" component="p">
                            <strong>It is no more possible to update this clinical case</strong> because
                            it has been <strong>submitted</strong> the <em>{formatLongDateTime(clinicalCase.submittedAt ?? new Date())}</em>.
                        </Typography>
                        <Typography variant="caption" component="p">
                            Waiting that the administrator analyze your data : your submit will be <strong>accepted</strong> or <strong>rejected</strong>.
                        </Typography>
                    </Box>
                </>}

                {clinicalCaseState === ClinicalCaseState.Published && <>
                    <Box
                        className={classes.actions}
                    >
                        <Box textAlign="center">
                            <Chip label="Published" icon={<LockIcon fontSize="small" />} />
                        </Box>
                        <Typography variant="caption" component="p">
                            <strong>It is no more possible to update this clinical case</strong> because
                            it has been <strong>published</strong> the <em>{formatLongDateTime(clinicalCase.publishedAt ?? new Date())}</em>.
                        </Typography>
                        <Typography variant="caption" component="p">
                            It is available on the following public url :
                            <a href={publishedUrl} target="_blank" rel="noreferrer">{publishedUrl}</a>
                        </Typography>
                    </Box>
                </>}

            </Paper>
        </Slide >

        <ProposalDialog
            state={poposalState}
            onAfter={onAfterProposalHandler}
            onPrevious={onPreviousProposalHandler}
        />

    </>
}

type ProposalState = "CLOSE" | "SAVING" | "CONFIRM_SUBMIT" | "SUBMITTING";

interface ProposalDialogProps {
    state: ProposalState;
    onPrevious: (state: ProposalState) => void;
    onAfter: (state: ProposalState) => void;
}

function ProposalDialog({ state, onAfter, onPrevious }: ProposalDialogProps) {

    let open = true;
    let loading = false;
    let content = undefined as string | undefined;
    let previousLabel = "close";
    let previousIcon = <CloseIcon />;
    let afterLabel = "save;"
    let afterIcon = <DoneIcon />;

    switch (state) {
        case "CLOSE":
            open = false;
            break;
        case "SAVING":
        case "SUBMITTING":
            loading = true;
            break;
        case "CONFIRM_SUBMIT":
            content = "Make sure about your contouring is complete, it will not be possible to update your contour until the administrator answer.";
            previousLabel = "continue contouring";
            afterLabel = "submit my work";
            break;
    }

    if (!open) return null;

    if (loading) {
        return <Dialog open={true}>
            <DialogContent>
                <CircularProgress size={48} />
            </DialogContent>
        </Dialog>
    }

    return <Dialog open={true}>
        <DialogTitle>
            Confirmation
        </DialogTitle>

        <DialogContent>
            <DialogContentText>
                {content}
            </DialogContentText>
        </DialogContent>

        <DialogActions>
            <Box margin={1} width="100%" display="flex">
                <Button
                    variant="outlined"
                    onClick={() => onPrevious(state)}
                    startIcon={previousIcon}
                >
                    {previousLabel}
                </Button>

                <Spacer />

                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => onAfter(state)}
                    startIcon={afterIcon}
                >
                    {afterLabel}
                </Button>
            </Box>
        </DialogActions>
    </Dialog>
}